

import React, { useState } from 'react';
import {
  Form, Input, InputNumber, Button, Select, Radio, Switch
} from 'antd';
import { useSelector } from 'react-redux';

import BaseDrawer from 'modals/base-drawer';
import MetadataDomain from 'domains/metadata';

import './drawer.scss';

function Drawer({
  definition,
  onSubmit,
  onCancel
}) {
  const actionTitle = definition ? '更新' : '创建';

  return (
    <BaseDrawer
      title={`${actionTitle}客户指标`}
      onClose={onCancel}
    >
      <DefinitionForm
        definition={definition}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </BaseDrawer>
  );
}

function DefinitionForm({
  definition,
  onCancel,
  onSubmit
}) {
  function handleSubmit(result) {
    if (result.operator) result.conditions = [{
      operator: result.operator,
      value: result.value
    }];
    onSubmit(result);
  }

  function onTypeChange(e) {
    const type = e.target.value;

    setType(type);

    if (type === 'AGGREGATION') {
      setAggregationType('COUNT');

      form.setFieldsValue({
        logic: {
          eventName: '打开 app',
          aggregationType: 'COUNT',
          aggregationKey: '*',
          timeFrameType: 'CURRENT',
          timeFrameUnit: 'MONTH',
          timeFrameQuantity: 1
        }
      });
      setTimeFrameType('CURRENT');
    } else if (type === 'ATTRIBUTE') {
      form.setFieldsValue({
        logic: {
          attributeFieldName: 'nps'
        }
      });
    } else {
      form.setFieldsValue({
        logic: {}
      });
    }
  }

  function handleChangeAggregationType(e) {
    setAggregationType(e);

    const logic = form.getFieldsValue().logic;

    if (e === 'COUNT') {
      form.setFieldsValue({
        logic: {
          ...logic,
          aggregationKey: '*'
        }
      });
    } else {
      form.setFieldsValue({
        logic: {
          ...logic,
          aggregationKey: 'info.amount'
        }
      });
    }
  }

  function onTimeFrameTypeChange(e) {
    const type = e.target.value;
    const logic = form.getFieldsValue().logic;

    setTimeFrameType(type);

    if (type === 'CURRENT') {
      form.setFieldsValue({
        logic: {
          ...logic,
          timeFrameUnit: 'MONTH',
          timeFrameQuantity: 1
        }
      });
    } else {
      form.setFieldsValue({
        logic: {
          ...logic,
          timeFrameUnit: 'DAY',
          timeFrameQuantity: 14
        }
      });
    }
  }

  const initialData = definition || {
    name: '',
    description: '',
    pinned: false,
    type: 'ATTRIBUTE',
    overallAggregation: 'SUM',
    logic: {
      divideByCustomerUserCount: false,
      attributeFieldName: 'nps'
    }
  };

  const [form] = Form.useForm();

  const fieldsMetadata = useSelector(MetadataDomain.selectors.fieldsMetadata);
  const client = useSelector(MetadataDomain.selectors.client);

  const [type, setType] = useState(initialData.type);
  const [timeFrameType, setTimeFrameType] = useState(initialData.logic.timeFrameType);
  const [aggregationType, setAggregationType] = useState(null);

  const nameField = (
    <div>
      <Form.Item
        name="name"
        label="名称"
        rules={[
          { required: true, message: `请输入指标名称` }
        ]}
      >
        <Input placeholder="指标名称" />
      </Form.Item>
    </div>
  );

  const descriptionField = (
    <div>
      <Form.Item
        name="description"
        label="描述"
      >
        <Input.TextArea autoSize={{ minRows: 3 }} placeholder="指标描述" />
      </Form.Item>
    </div>
  );

  const sectionField = (
    <div>
      <Form.Item
        name="section"
        label="分类"
      >
        <Input placeholder="指标分类" />
      </Form.Item>
    </div>
  );

  const pinnedField = (
    <div>
      <Form.Item
        name="pinned"
        label="置顶"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </div>
  );

  const typeField = (
    <div>
      <Form.Item
        name="type"
        label="类型"
      >
        <Radio.Group
          buttonStyle="solid"
          onChange={onTypeChange}
        >
          <Radio.Button value="ATTRIBUTE">字段</Radio.Button>
          <Radio.Button value="AGGREGATION">计算</Radio.Button>
          <Radio.Button value="MANUAL">输入</Radio.Button>
        </Radio.Group>
      </Form.Item>
    </div>
  );

  const divideByCustomerUserCountField = (
    <div>
      <Form.Item
        name={['logic', 'divideByCustomerUserCount']}
        label="人均"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </div>
  );

  const attributeFieldNameField = (
    <div>
      <Form.Item
        name={['logic', 'attributeFieldName']}
        label="字段"
      >
        <Select>
          {
            Object.keys(fieldsMetadata.customers)
              .filter((name, i) => {
                const metadata = fieldsMetadata.customers[name];
                return metadata.type === 'NUMBER';
              })
              .map((name, i) => {
                const title = fieldsMetadata.customers[name].title;
                const snakeCaseName = name.replace(/[A-Z]/g, (c) => `_${c.toLowerCase()}`);
                return <Select.Option key={i} value={snakeCaseName}>{title}</Select.Option>;
              })
          }
        </Select>
      </Form.Item>
    </div>
  );

  const eventNameField = (
    <Form.Item
      name={['logic', 'eventName']}
      label="事件"
      rules={[
        { required: true, message: `请输入事件名称` }
      ]}
    >
      <Input placeholder="事件名称" />
    </Form.Item>
  );

  const aggregationField = (
    <Form.Item
      label="计算"
    >
      <Input.Group compact>
        <Form.Item
          name={['logic', 'aggregationType']}
          noStyle
        >
          <Select style={{ width: '50%' }} onChange={handleChangeAggregationType}>
            <Select.Option value="COUNT">次数</Select.Option>
            <Select.Option value="SUM">合</Select.Option>
            <Select.Option value="AVG">平均</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name={['logic', 'aggregationKey']}
          noStyle
        >
          {
            aggregationType === 'COUNT' ?
            <Select style={{ width: '50%' }}>
              <Select.Option value="*">发生次数</Select.Option>
              <Select.Option value="info.customerUserId">涉及用户数量</Select.Option>
            </Select> :
            <Input style={{ width: '50%' }} />
          }
        </Form.Item>
      </Input.Group>
    </Form.Item>
  );

  const overallAggregationField = (
    <Form.Item
      name="overallAggregation"
      label="整体计算"
    >
      <Radio.Group
        buttonStyle="solid"
      >
        <Radio.Button value="SUM">总共</Radio.Button>
        <Radio.Button value="AVG">平均</Radio.Button>
      </Radio.Group>
    </Form.Item>
  );

  const timeFrameTypeField = (
    <div>
      <Form.Item
        name={['logic', 'timeFrameType']}
        label="类型"
      >
        <Radio.Group
          buttonStyle="solid"
          onChange={onTimeFrameTypeChange}
        >
          <Radio.Button value="CURRENT">当前</Radio.Button>
          <Radio.Button value="ROLLING">最近</Radio.Button>
        </Radio.Group>
      </Form.Item>
    </div>
  );

  const currentTimeFrameField = (
    <>
      <Form.Item
        label="时间"
      >
        <Input value="本月" disabled />
      </Form.Item>
      <Form.Item
        name={['logic', 'timeFrameQuantity']}
        hidden
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['logic', 'timeFrameUnit']}
        hidden
      >
        <Input />
      </Form.Item>
    </>
  );

  const rollingTimeFrameField = (
    <Form.Item
      label="最近"
    >
      <Input.Group compact>
        <Form.Item
          name={['logic', 'timeFrameQuantity']}
          noStyle
        >
          <Input style={{ width: '50%' }} />
        </Form.Item>
        <Form.Item
          name={['logic', 'timeFrameUnit']}
          noStyle
        >
          <Select style={{ width: '50%' }}>
            <Select.Option value="DAY">天</Select.Option>
          </Select>
        </Form.Item>
      </Input.Group>
    </Form.Item>
  );

  const goalField = (
    <div>
      <Form.Item
        name="goal"
        label="目标"
      >
        <InputNumber placeholder="目标" />
      </Form.Item>
    </div>
  );

  return (
    <div className="drawer-definition-form">
      <Form
        name="definition"
        initialValues={initialData}
        onFinish={handleSubmit}
        form={form}
        labelCol={{ span: 3 }}
      >
        <div className="sub-title">1. 基本信息</div>
        {nameField}
        {descriptionField}
        {sectionField}
        {pinnedField}
        <div className="divider"></div>
        <div className="sub-title">2. 指标逻辑</div>
        {typeField}
        {
          type === 'ATTRIBUTE' &&
          attributeFieldNameField
        }
        {
          type === 'AGGREGATION' && client.type === 'b2b' &&
          divideByCustomerUserCountField
        }
        {
          type === 'AGGREGATION' &&
          <>
            {eventNameField}
            {aggregationField}
          </>
        }
        {overallAggregationField}
        {goalField}
        <div className="divider"></div>
        {
          type === 'AGGREGATION' &&
          <>
            <div className="sub-title">3. 时间阶段</div>
            {timeFrameTypeField}
            {timeFrameType === 'CURRENT' && currentTimeFrameField}
            {timeFrameType === 'ROLLING' && rollingTimeFrameField}
            <div className="divider"></div>
          </>
        }
        <div className="submit">
          <Form.Item noStyle>
            <Button
              type="primary"
              htmlType="submit"
            >
              确认
            </Button>
          </Form.Item>
          <Button onClick={onCancel}>取消</Button>
        </div>
      </Form>
    </div>
  );
}

export default Drawer;
