
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button
} from 'antd';

import { history } from 'domains/history';
import Rbac from 'domains/rbac';
import BaseModal from '../base-modal';

class RolesDelete extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    role: PropTypes.object.isRequired,
    redirect: PropTypes.bool
  };

  handleSubmit = () => {
    const { role, onSubmit, redirect } = this.props;
    onSubmit(role.id, redirect);
  };

  render() {
    const {
      onCancel,
      role
    } = this.props;

    return (
      <BaseModal title={`确定删除${role.name}？`}>
        <RolesDeleteForm
          onCancel={onCancel}
          onSubmit={this.handleSubmit}
        />
      </BaseModal>
    )
  }
}

function mapStateToProps(state) {
  const { role, redirect } = state.modal.context;

  return {
    role,
    redirect
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onCancel() {
      dispatch({ type: 'closeModal' });
    },
    onSubmit(id, redirect) {
      dispatch({
        type: 'api',
        request: () => Rbac.deleteRole(id),
        success() {
          dispatch({ type: 'closeModal' });
          dispatch({
            type: 'rolesDelete',
            id
          });
          if (redirect) history.push('/permissions');
        }
      });
    }
  };
}

function RolesDeleteForm({
  onCancel,
  onSubmit
}) {
  return (
    <div className="roles-delete-form">
      <Button
        type="primary"
        htmlType="submit"
        onClick={onSubmit}
      >
        提交
      </Button>
      <Button onClick={onCancel}>取消</Button>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RolesDelete);
