
import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import moment from 'moment';

import {
  Form,
  Input,
  InputNumber,
  DatePicker,
  Select,
  Button
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';

import './index.scss';

function InPlaceEdit({
  title,
  value,
  type,
  selectOptions,
  onSubmit,
  onEdit = () => {},
  onCancel = () => {},
  displayMode = 'vertical'
}) {
  function handleOverallClick() {
    if (type === 'readonly') return;
    if (mode === 'read') {
      setMode('edit');
      onEdit();
    }
  }

  function handleSubmit(values) {
    const { field } = values;
    const value = (type === 'date' && field) ?
      (field).format('YYYY-MM-DD') :
      field;
    setMode('read');
    onSubmit(value);
  }

  function handleCancel() {
    form.resetFields();
    setMode('read');
    onCancel();
  }

  const [mode, setMode] = useState('read');
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  const className = classnames(
    { readonly: type === 'readonly' },
    'in-place-edit',
    displayMode,
    mode,
    type
  );
  
  function renderEdit() {
    let inputField;
    if (type === 'text') {
      inputField = <Input />;
    } else if (type === 'number' || type === 'percent') {
      inputField = <InputNumber />;
    } else if (type === 'date') {
      inputField = <DatePicker style={{ width: '100%' }}/>;
    } else if (type === 'select') {
      inputField = (
        <Select>
          {selectOptions.map(({ value, title }, i) => (
            <Select.Option key={i} value={value}>{title}</Select.Option>
          ))}
        </Select>
      );
    } else if (type === 'textarea') {
      inputField = <Input.TextArea autoSize={{ minRows: 5 }} />;
    } else {
      return (
        <div className="not-implemented">
          <div>暂不支持编辑此字段</div>
          <Button
            onClick={handleCancel}
          >
            <CloseOutlined />
          </Button>
        </div>
      );
    }

    return (
      <EditForm
        form={form}
        value={value}
        type={type}
        inputField={inputField}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        displayMode={type === 'textarea' ? 'vertical' : 'horizontal'}
      />
    );
  }

  let readValue = value;
  if (value !== null) {
    if (type === 'select') {
      const selectedOption =  selectOptions.find((option) => option.value === value);
      readValue = selectedOption ? selectedOption.title : '';
    } else if (type === 'date') {
      readValue = value.format('YYYY-MM-DD');
    } else if (type === 'percent') {
      readValue = `${readValue}%`;
    }
  }

  return (
    <div
      className={className}
      onClick={handleOverallClick}
    >
      { title && <div className="title">{title}</div>}
      { mode === 'read' && <div className="read">{readValue}</div>}
      { mode === 'edit' && renderEdit()}
    </div>
  )
}

function EditForm({
  form,
  value,
  type,
  inputField,
  onSubmit,
  onCancel,
  displayMode = 'horizontal'
}) {
  let initialValue = value;
  if (type === 'date') {
    initialValue = value ? moment(value) : moment();
  }

  return (
    <Form
      form={form}
      initialValues={{ field: initialValue }}
      onFinish={onSubmit}
      style={{ width: '100%' }}
    >
      <Form.Item
        name="field" noStyle
      >
        {inputField}
      </Form.Item>
      <div>
        <Form.Item noStyle shouldUpdate>
          {() => {
            const currentValue = form.getFieldValue('field');
            return (
              <Button
                type="primary"
                htmlType="submit"
                disabled={currentValue === initialValue}
              >
                <CheckOutlined />
              </Button>
            );
          }}
        </Form.Item>
        <Button
          onClick={onCancel}
        >
          <CloseOutlined />
        </Button>
      </div>
    </Form>
  );
}

export default InPlaceEdit;
