
/********* Data seeds *********/
const totalMembershipCount = 254;

const memberCountTrendData = [
  { timestamp: '2022-04', 订阅量: 105, 增加: [100, 125], 减少: [125, 105]},
  { timestamp: '2022-05', 订阅量: 105, 增加: [105, 128], 减少: [128, 105]},
  { timestamp: '2022-06', 订阅量: 110, 增加: [105, 130], 减少: [130, 110]},
  { timestamp: '2022-07', 订阅量: 120, 增加: [110, 140], 减少: [140, 120]},
  { timestamp: '2022-08', 订阅量: 118, 增加: [120, 141], 减少: [141, 118]},
  { timestamp: '2022-09', 订阅量: 125, 增加: [118, 146], 减少: [146, 125]},
  { timestamp: '2022-10', 订阅量: 133, 增加: [125, 152], 减少: [152, 133]},
  { timestamp: '2022-11', 订阅量: 156, 增加: [133, 162], 减少: [162, 156]},
  { timestamp: '2022-12', 订阅量: 175, 增加: [156, 182], 减少: [182, 175]},
  { timestamp: '2023-01', 订阅量: 201, 增加: [175, 206], 减少: [206, 201]},
  { timestamp: '2023-02', 订阅量: 226, 增加: [201, 237], 减少: [237, 231]},
  { timestamp: '2023-03', 订阅量: totalMembershipCount, 增加: [226, 258], 减少: [258, 254]},
].sort((a, b) => a.timestamp.replace('-', '') - b.timestamp.replace('-', ''));

const superAnnualIncreaseRatio = 0.15
const annualIncreaseRatio = 0.3
const monthlyIncreaseRatio = 0.25

const superAnnualDecreaseRatio = 0.1
const annualDecreaseRatio = 0.3
const monthlyDecreaseRatio = 0.35

const superAnnualArr = 240000;
const annualArr = 120000;
const monthlyArr = 150000;

/***************** Data generation ****************/

function createFakeChangeTable(dataByMembershipType) {
  function getIncreasingDiff(membershipType) {
    return dataByMembershipType[membershipType].at(-1)['增加'][1] - dataByMembershipType[membershipType].at(-1)['增加'][0];
  }

  function getDecreasingDiff(category) {
    return dataByMembershipType[category].at(-1)['减少'][1] - dataByMembershipType[category].at(-1)['减少'][0];
  }

  const superAnnualDiff = [getIncreasingDiff('superAnnual'), getDecreasingDiff('superAnnual')];
  const annualDiff = [getIncreasingDiff('annual'), getDecreasingDiff('annual')];
  const monthlyDiff = [getIncreasingDiff('monthly'), getDecreasingDiff('monthly')];

  const res = {
    superAnnual: {
      拉新: Math.round(superAnnualDiff[0] * 0.8),
      召回: Math.round(superAnnualDiff[0] * 0.09),
      增购: Math.round(superAnnualDiff[0] * 0.11),
      降级: 0,
      流失: Math.round(superAnnualDiff[1]),
      总共: superAnnualDiff[0] + superAnnualDiff[1]
    },
    annual: {
      拉新: Math.round(annualDiff[0] * 0.8),
      召回: Math.round(annualDiff[0] * 0.05),
      增购: Math.round(annualDiff[0] * 0.15),
      降级: Math.round(annualDiff[1] * 0.1),
      流失: Math.round(annualDiff[1] * 0.9),
      总共: annualDiff[0] + annualDiff[1]

    },
    monthly: {
      拉新: Math.round(monthlyDiff[0] * 0.9),
      召回: Math.round(monthlyDiff[0] * 0.1),
      增购: 0,
      降级: Math.round(monthlyDiff[1] * 0.13),
      流失: Math.round(monthlyDiff[1] * 0.87),
      总共: monthlyDiff[0] + monthlyDiff[1]
    }
  };

  res['total'] = {
    拉新: res.superAnnual['拉新'] + res.annual['拉新']+ res.monthly['拉新'],
    召回: res.superAnnual['召回'] + res.annual['召回']+ res.monthly['召回'],
    增购: res.superAnnual['增购'] + res.annual['增购']+ res.monthly['增购'],
    降级: res.superAnnual['降级'] + res.annual['降级']+ res.monthly['降级'],
    流失: res.superAnnual['流失'] + res.annual['流失']+ res.monthly['流失'],
    总共: res.superAnnual['总共'] + res.annual['总共']+ res.monthly['总共'],
  }

  return res;
}

// Break up total to membership type
function fakeBreakupTrend({trendData, key}) {
  function convert(increaseRatio, decreaseRatio) {
    //hacks to generate a reasonable water fall chart
    let lastValue = trendData[0]['增加'][0] * increaseRatio;
    return trendData.map((record, index) => {
      const increaseDelta = (record['增加'][1] - record['增加'][0]) * increaseRatio;
      const decreaseDelta = (record['减少'][0] - record['减少'][1]) * decreaseRatio;
      const increase = [lastValue, lastValue + increaseDelta];
      const decrease = [lastValue + increaseDelta, lastValue + increaseDelta - decreaseDelta];
      const memberCount = lastValue + increaseDelta - decreaseDelta;

      lastValue = memberCount;
      
      return {
        timestamp: record.timestamp, 
        [key]: memberCount, 
        增加: increase, 
        减少: decrease
      };
    })
  }

  return {
    total: trendData,
    superAnnual: convert(superAnnualIncreaseRatio, superAnnualDecreaseRatio),
    annual: convert(annualIncreaseRatio, annualDecreaseRatio),
    monthly: convert(monthlyIncreaseRatio, monthlyDecreaseRatio)
  }
}

function fakeComputeArr(membershipCountData){
  function computeArr(memberCountTrendData, unitArr) {
    return memberCountTrendData.map(record => {
      return {
        timestamp: record.timestamp, 
        ARR: Math.round(record['订阅量'] * unitArr),
        增加: record['增加'].map(value => Math.round(value * unitArr)),
        减少: record['减少'].map(value => Math.round(value * unitArr)),
      }
    })
  }

  function sumTotalArr(superAnnual, annual, monthly) {
    return superAnnual.map(record => {
      const timestamp = record.timestamp;
      const annualRecord = annual.find(record => record.timestamp === timestamp);
      const monthlyRecord = monthly.find(record => record.timestamp === timestamp);

      return {
        timestamp,
        ARR: record.ARR + annualRecord.ARR + monthlyRecord.ARR,
        增加: [
          record['增加'][0] + annualRecord['增加'][0] + monthlyRecord['增加'][0],
          record['增加'][1] + annualRecord['增加'][1] + monthlyRecord['增加'][1]
        ],
        减少: [
          record['减少'][0] + annualRecord['减少'][0] + monthlyRecord['减少'][0],
          record['减少'][1] + annualRecord['减少'][1] + monthlyRecord['减少'][1]
        ]
      }
    });
  }

  const superAnnual = computeArr(membershipCountData.superAnnual, superAnnualArr);
  const annual = computeArr(membershipCountData.annual, annualArr);
  const monthly = computeArr(membershipCountData.monthly, monthlyArr);

  return {
    superAnnual,
    annual,
    monthly,
    total: sumTotalArr(superAnnual, annual, monthly)
  };
}

const membershipCountData = fakeBreakupTrend({
  trendData: memberCountTrendData, 
  key: '订阅量'
});

const arrData = fakeComputeArr(membershipCountData);

const salesData = arrData.total.map((datum) => {
  const netNewBookings = (datum['增加'][1] - datum['增加'][0]) * 12 * 0.85;
  const adHocPurchases = Math.floor(Math.random() * 2000000);
  return {
    timestamp: datum.timestamp,
    value: netNewBookings + adHocPurchases
  };
});

const ndrData = {};
['annual', 'monthly', 'superAnnual', 'total'].forEach((plan) => {
  ndrData[plan] = arrData[plan].map((datum, i) => {
    const inc = (datum['增加'][1] - datum['增加'][0]) * 0.15;
    const dec = datum['减少'][0] - datum['减少'][1];
    const prevARR = i === 0 ? datum.ARR * 0.9 : arrData[plan][i - 1].ARR;
    return {
      timestamp: datum.timestamp,
      value: (prevARR + inc - dec) / prevARR
    };
  });
});

const grrData = {};
['annual', 'monthly', 'superAnnual', 'total'].forEach((plan) => {
  grrData[plan] = arrData[plan].map((datum, i) => {
    const dec = datum['减少'][0] - datum['减少'][1];
    const prevARR = i === 0 ? datum.ARR * 0.9 : arrData[plan][i - 1].ARR;
    return {
      timestamp: datum.timestamp,
      value: (prevARR - dec) / prevARR
    };
  });
});

const logoRetentionData = {};
['annual', 'monthly', 'superAnnual', 'total'].forEach((plan) => {
  logoRetentionData[plan] = membershipCountData[plan].map((datum, i) => {
    const prevCount = i === 0 ?
      datum['订阅量'] * 0.9 :
      membershipCountData[plan][i - 1]['订阅量'];
    return {
      timestamp: datum.timestamp,
      value: (datum['订阅量'] - (datum['增加'][1] - datum['增加'][0])) / prevCount
    };
  });
});

const churnTrendData = {
  logoPercent: membershipCountData.total.map((datum, i) => {
    const prevCount = i === 0 ?
      datum['订阅量'] * 0.9 :
      membershipCountData.total[i - 1]['订阅量'];
    const dec = datum['减少'][1] - datum['减少'][0];
    return {
      timestamp: datum.timestamp,
      value: dec / prevCount
    };
  }),
  logo: membershipCountData.total.map((datum) => {
    return {
      timestamp: datum.timestamp,
      value: datum['减少'][1] - datum['减少'][0]
    };
  }),
  arr: arrData.total.map((datum) => {
    return {
      timestamp: datum.timestamp,
      value: datum['减少'][1] - datum['减少'][0]
    };
  }),
  arrPercent: arrData.total.map((datum, i) => {
    const prevARR = i === 0 ? datum.ARR * 0.9 : arrData.total[i - 1].ARR;
    const dec = datum['减少'][1] - datum['减少'][0];
    return {
      timestamp: datum.timestamp,
      value: dec / prevARR
    };
  })
}

const subscriptionChangeData = createFakeChangeTable(membershipCountData);

const arrChangeData = createFakeChangeTable(arrData);

export {
  arrData,
  salesData,
  ndrData,
  grrData,
  logoRetentionData,
  churnTrendData,
  membershipCountData,
  subscriptionChangeData,
  arrChangeData
};
