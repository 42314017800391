
import React from 'react';

import ChartPanel from 'components/chart-panel';

import YTDChart from './ytd-chart';

function YTDRenewalToGoal() {
  const data = [
    { timestamp: '2023-01', value: 4250000 },
    { timestamp: '2023-02', value: 10750000 },
    { timestamp: '2023-03', value: 14250000 },
    { timestamp: '2023-04', value: 19250000 },
    { timestamp: '2023-05', value: 21750000 },
    { timestamp: '2023-06', value: 29250000 },
    { timestamp: '2023-07', value: 30250000 },
    { timestamp: '2023-08', value: 32750000 },
    { timestamp: '2023-09', value: 33250000 }
  ];

  return (
    <ChartPanel 
      title="YTD 累积续费总额"
    >
      <YTDChart
        data={data}
      />
    </ChartPanel>
  );
}

export default YTDRenewalToGoal;
