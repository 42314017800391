
import API from 'adapters/api';

/**
 * state structure:
 * {
 *   data: [
 *     {Ticket}
 *   ]
 * }
 */

const limit = 20; // changing this is not an important feature right now

const Tickets = {
  selectors: {},
  actions: {}
};

Tickets.selectors.data = (state) => state.tickets.data;
Tickets.selectors.count = (state) => state.tickets.count;

Tickets.actions.create = ({
  ticket, success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'tickets',
        action: 'create',
        data: ticket
      });

      if (status < 300) {
        const { id } = data.ticket;

        dispatch({
          type: 'ticketsAdd',
          data: { ...ticket, id }
        });

        success && success();
      }

      return { status, data };
    }
  }
};

Tickets.actions.readList = (page, conditions) => {
  const offset = (page - 1) * limit;
  const params = {
    ...conditions,
    offset,
    limit
  };

  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'tickets',
        action: 'readList',
        params
      });

      if (status < 300) {
        const { tickets } = data;

        dispatch({
          type: 'ticketsRefreshData',
          data: tickets.data,
          count: tickets.count
        });
      }

      return { status, data };
    }
  }
};

Tickets.actions.read = (id) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const { status, data } = await API.request({
        resource: 'tickets',
        action: 'read',
        data: { id }
      });

      if (status < 300) {
        dispatch({
          type: 'ticketsAdd',
          data: data.ticket
        });
      }

      return { status, data };
    }
  };
};

Tickets.actions.update = ({
  ticket,
  success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'tickets',
        action: 'update',
        data: ticket
      });

      if (status < 300) {
        dispatch({
          type: 'ticketsUpdate',
          data: data.ticket
        });

        success && success();
      }

      return { status, data };
    }
  }
};

Tickets.actions.delete = ({
  id,
  success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'tickets',
        action: 'delete',
        data: { id }
      });

      if (status < 300) {
        dispatch({
          type: 'ticketsRemove',
          data: { id }
        });

        success && success();
      }

      return { status, data };
    }
  }
};

const reducers = {
  tickets(state, action) {
    if (state === undefined) return { data: null, count: 0 };

    if (action.type === 'ticketsAdd') {
      return {
        ...state,
        data: [
          ...state.data,
          action.data
        ],
        count: state.count + 1
      };
    } else if (action.type === 'ticketsRefreshData') {
      return {
        ...state,
        data: action.data,
        count: action.count
      };
    } else if (action.type === 'ticketsUpdate') {
      return {
        ...state,
        data: state.data.map((datum) => {
          if (datum.id === action.data.id) {
            return {
              ...datum,
              ...action.data
            };
          }
          return datum;
        })
      }
    } else if (action.type === 'ticketsRemove') {
      return {
        ...state,
        data: state.data.filter((datum) => datum.id !== action.data.id)
      };
    }

    return state;
  }
}

export { reducers };
export default Tickets;
