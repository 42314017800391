
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Timeline } from 'antd';

import EventsDomain from 'domains/events';
import Panel from 'components/panel';

import './payment-history-panel.scss';

function PaymentHistoryPanel({
  customer
}) {
  useEffect(() => {
    dispatch(EventsDomain.actions.paymentHistory(customer.id));
  }, []);

  const dispatch = useDispatch();

  const perCustomer = useSelector(EventsDomain.selectors.perCustomer)[customer.id] || {};
  const events = perCustomer.paymentHistory || []
    .sort(
      (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
    );

  const timelineItems = [];
  let lastDate;
  events.forEach((event, i) => {
    const { timestamp } = event;
    const eventDate = timestamp.substring(0, 10);
    const eventTime = timestamp.substring(11, 19);

    const eventMessage = eventToMessage(event);
    const color = '#f5222d';

    let label;
    if (eventDate !== lastDate) {
      label = eventDate;
      lastDate = eventDate;
    }

    timelineItems.push(
      <Timeline.Item label={label} color={color} key={i}>
        <div className="event-row" style={{ color }}>
          <div>{eventTime}</div>
          <div>{eventMessage}</div>
          <div>{JSON.stringify(event.info)}</div>
        </div>
      </Timeline.Item>
    );
  });

  return (
    <div className="payment-history-panel">
      <Panel title="付费历史">
        {
          timelineItems.length > 0 &&
          <div className="timeline">
            <Timeline mode="left">
              {timelineItems}
            </Timeline>
          </div>
        }
        {
          !events &&
          <div className="no-data">-- 加载中 --</div>
        }
        {
          events && timelineItems.length === 0 &&
          <div className="no-data">-- 暂无数据 --</div>
        }
      </Panel>
    </div>
  )
}

function eventToMessage(event) {
  const { info } = event;
  const { type } = info;
  
  if (type === 'registration') {
    return '注册';
  } else if (type === 'subscription') {
    const invoiceMessage = info.orderId ? `订单 ID ${info.orderId}` : '无订单 ID'
    return `订阅：${info.planId}，${invoiceMessage}`;
  } else if (type === 'adHoc') {
    const invoiceMessage = info.orderId ? `订单 ID ${info.orderId}` : '无订单 ID'
    return `单独购买：产品 ${info.productId}，${invoiceMessage}`;
  } else if (type === 'renewal') {
    const invoiceMessage = info.orderId ? `订单 ID ${info.orderId}` : '无订单 ID'
    return `续费：订阅 ${info.planId}，${invoiceMessage}`;
  } else if (type === 'upgrade') {
    return `增购：新订阅 ${info.newPlanId}，旧订阅 ${info.oldPlanId}`;
  } else if (type === 'downgrade') {
    return `降级：新订阅 ${info.newPlanId}，旧订阅 ${info.oldPlanId}`;
  } else if (type === 'changePlan') {
    return `改订阅：新订阅 ${info.newPlanId}，旧订阅 ${info.oldPlanId}`;
  } else if (type === 'churn') {
    return `流失：订阅 ${info.planId}`;
  } else if (type === 'recall') {
    const invoiceMessage = info.orderId ? `订单 ID ${info.orderId}` : '无订单 ID'
    return `唤回：订阅 ${info.planId}，${invoiceMessage}`;
  } else if (type === 'enableAutoRenewal') {
    return '开启自动续费';
  } else if (type === 'disableAutoRenewal') {
    return '停止自动续费';
  } else if (type === 'suspendSubscription') {
    return `订阅暂停：开始时间 ${info.suspensionStartDate}，结束时间 ${info.suspensionEndDate}`;
  }
}

export default PaymentHistoryPanel;
