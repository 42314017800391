
import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Tabs, Result } from 'antd';

import TimeSeries from './time-series';
import Cohurts from './cohorts';
import CustomerMetricDefinitions from './customer-metric-definitions';

import './index.scss';

function CustomerMetrics({
  match
}) {
  function handleChangeTab(key) {
    const redirectUrl = `/customer-metrics/${key}`
    history.push(redirectUrl);
    setActiveTab(key);
  }

  const { tab } = match.params;

  const history = useHistory();
  const [activeTab, setActiveTab] = useState(tab);

  useEffect(() => {
    document.title = '云策智慧 - 数据探索';
  }, []);

  return (
    <div className="page-customer-metrics">
      <div className="content">
        <Tabs activeKey={activeTab} onChange={handleChangeTab}>
          <Tabs.TabPane key="customer-metric-definitions" tab="客户定义">
            <CustomerMetricDefinitions />
          </Tabs.TabPane>
          <Tabs.TabPane key="time-series" tab="时序探索">
            <TimeSeries />
          </Tabs.TabPane>
          <Tabs.TabPane key="cohort" tab="群组分析">
            <Cohurts />
          </Tabs.TabPane>
          <Tabs.TabPane key="funnel" tab="漏斗">
            <Result
              status="warning"
              title="抱歉，漏斗探索尚在开发中！"
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default withRouter(CustomerMetrics);
