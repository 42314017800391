
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';

import TicketsDomain from 'domains/tickets';
import MetricPanel from 'components/metric-panel';
import DistributionPanel from 'components/distribution-panel';

function TicketsMetricPanels({ customer }) {
  const { id } = customer;

  const dispatch = useDispatch();
  const tickets = useSelector(TicketsDomain.selectors.data);

  useEffect(() => {
    dispatch(TicketsDomain.actions.readList(1, { customerId: id }));
  }, []);

  let count, past30DaysCount = 0,
    priorityDistribution = { P0: 0, P1: 0, P2: 0, P3: 0 },
    severityDistribution = { S0: 0, S1: 0, S2: 0, S3: 0 };

  if (tickets) {
    const unresolvedTickets = tickets.filter((ticket) => (
      ticket.status !== 'RESOLVED'
    ));

    count = unresolvedTickets.length;

    if (count > 0) {
      past30DaysCount = unresolvedTickets.filter((ticket) => (
        new Date() - new Date(ticket.createdAt) >= 30 * 24 * 60 * 60 * 1000
      )).length;
  
      unresolvedTickets.forEach((ticket) => {
        const { priority, severity } = ticket;
        priorityDistribution[priority]++;
        severityDistribution[severity]++;
      });

      priorityDistribution = Object.keys(priorityDistribution).map((priority) => {
        return { category: priority, percent: priorityDistribution[priority] / count };
      });
      severityDistribution = Object.keys(severityDistribution).map((severity) => {
        return { category: severity, percent: severityDistribution[severity] / count };
      });
    }
  }

  return (
    <div>
      {
        !tickets && <div>加载中</div>
      }
      {
        tickets &&
        <Row gutter={10}>
          <Col span={6}>
            <MetricPanel
              title="未完成 SOP，超过 30 天占比"
              text={count}
              distribution={{
                neutral: count - past30DaysCount,
                bad: past30DaysCount
              }}
            />
          </Col>
          <Col span={6}>
            <DistributionPanel
              title="优先级分布"
              data={priorityDistribution}
              height={110}
            />
          </Col>
          <Col span={6}>
            <DistributionPanel
              title="严重性分布"
              data={severityDistribution}
              height={110}
            />
          </Col>
        </Row>
      }
    </div>
  );
}

export default TicketsMetricPanels;
