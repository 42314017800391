
import React, { useEffect } from 'react';
import {
  Tabs
} from 'antd';

import Complaints from './feedback/complaints';
import FeedbackGeneral from './feedback/feedback-general';
import Surveys from './surveys';

import './index.scss';

function FeedbackManagement() {
  useEffect(() => {
    document.title = '云策智慧 - 反馈管理';
  }, []);

  return (
    <div className="page-feedback-management">
      <div className="content">
        <Tabs>
          <Tabs.TabPane key="surveys" tab="问卷设置">
            <Surveys />
          </Tabs.TabPane>
          <Tabs.TabPane key="feedback-general" tab="反馈意见">
            <FeedbackGeneral />
          </Tabs.TabPane>
          <Tabs.TabPane key="complaints" tab="投诉">
            <Complaints />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default FeedbackManagement;
