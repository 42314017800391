
import React, { useEffect, useRef } from 'react';
import { Chart } from '@antv/g2';

import themeColor from 'theme/_color.scss';
import './index.scss';

function BreakdownChart({
  score,
  categoryScores,
  selfName,
  avgCategoryScores
}) {
  const chartContainer = useRef(null);

  useEffect(() => {
    createChart(chartContainer.current, score, categoryScores, selfName, avgCategoryScores);
  }, [categoryScores]);

  return (
    <div className="breakdown-chart" ref={chartContainer}></div>
  );
}

function createChart(container, score, data, selfName, comparisonData) {
  container.innerHTML = '';

  let color = themeColor.healthGood;
  if (score < 50) {
    color = themeColor.healthBad;
  } else if (score < 80) {
    color = themeColor.healthMedium;
  }

  const chart = new Chart({
    container,
    autoFit: true,
    padding: 10
  });

  const combinedData = [...data || [], ...comparisonData || []]
    .map((datum) => {
      const score = parseFloat(datum.score);
      return {
        ...datum,
        score
      };
    });
  chart.data(combinedData);

  chart.scale('score', {
    min: 0,
    max: 100,
    ticks: [50, 80]
  });

  chart.coordinate('polar', {
    radius: 0.8,
  });

  chart.axis('score', {
    line: null,
    tickLine: null,
    label: null,
    grid: {
      line: {
        type: 'line',
        style: {
          lineDash: null,
        },
      },
    },
  });

  chart.axis('name', {
    line: null
  });

  chart.tooltip({
    position: 'top',
    offset: 50,
    shared: true,
    showCrosshairs: true,
    crosshairs: {
      line: {
        style: {
          lineDash: [4, 4],
          stroke: '#333'
        }
      }
    },
    customItems: (items) => {
      const entityToTitle = {
        self: selfName || '当前客户',
        comparison: '平均值'
      };
      return items.map((item) => {
        return {
          ...item,
          value: parseFloat(item.value).toFixed(1),
          name: entityToTitle[item.name]
        };
      });
    }
  });

  chart.legend(false);

  chart
    .line()
    .position('name*score')
    .color('entity', [color, '#C0C0C0'])
    .size(2);

  chart.render();
}

export default BreakdownChart;
