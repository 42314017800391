
import API from 'adapters/api';

/**
 * state structure:
 * {
 *   generalFeedbcak: [
 *     {feedback}
 *   ],
 *   complaint: [
 *     {feedback}
 *   ],
 *   perCustomer: {
 *     [customerId]: [
 *       {feedback | nps | surveyEntries}
 *     ]
 *   }
 * }
 */

const Feedback = {
  selectors: {},
  actions: {}
};

Feedback.selectors.generalFeedback = (state) => {
  return state.feedback.generalFeedback;
};

Feedback.selectors.complaint = (state) => {
  return state.feedback.complaint;
};

Feedback.selectors.perCustomer = (state) => {
  return state.feedback.perCustomer;
};

Feedback.actions.readList = (category, page = 1, limit=10) => {
  const offset = (page - 1) * limit;

  return {
    type: 'query',
    async request(state, dispatch) {
      const params = {
        offset,
        limit,
        category
      };

      const {
        status, data
      } = await API.request({
        resource: 'surveys-feedback',
        action: 'readList',
        params
      });

      if (status < 300) {
        const feedback = data.feedback.data;

        for (const f of feedback) {
          f.createdAt = f.createdAt.substring(0, 10) + ' '
            + f.createdAt.substring(12,19);
        }
        dispatch({
          type: 'feedbackRefreshData',
          category,
          count: data.feedback.count,
          data: feedback
        });
      }

      return { status, data };
    }
  }
};

Feedback.actions.perCustomer = (customerId) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `customers/${customerId}/feedback`,
        action: 'read'
      });

      if (status < 300) {
        const feedback = data.feedback;

        dispatch({
          type: 'perCustomerFeedback',
          customerId,
          data: feedback
        });
      }

      return { status, data };
    }
  }
};

const reducers = {
  feedback(state, action) {
    if (state === undefined) {
      return {
        generalFeedback: { data: [], count: 0 },
        complaint: { data: [], count:0 },
        perCustomer: {}
      };
    }

    if (action.type === 'feedbackRefreshData') {
      if (action.category === 'complaint') {
        return {
          ...state,
          complaint: { data: action.data, count: action.count }
        };
      } else {
        return {
          ...state,
          generalFeedback: { data: action.data, count: action.count }
        }
      }
    } else if (action.type === 'perCustomerFeedback') {
      return {
        ...state,
        perCustomer: {
          ...state.perCustomer,
          [action.customerId]: action.data
        }
      };
    }

    return state;
  }
}

export { reducers };
export default Feedback;
