
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';

import UserMetricsDomain from 'domains/user-metrics';
import MetricPanel from 'components/metric-panel';

function WorkLogMetricsPanels({ customer }) {
  const { id } = customer;

  const dispatch = useDispatch();
  const metrics = useSelector(UserMetricsDomain.getForCustomerSelector(id));

  useEffect(() => {
    dispatch(UserMetricsDomain.actions.readForCustomer(id));
  }, []);

  return (
    <div>
      {
        !metrics && <div>加载中</div>
      }
      {
        metrics &&
        <Row gutter={10}>
          {
            Object.keys(metrics).map((type) => {
              const { title, latest, trend } = metrics[type];
              let daysSince, meaning;
              if (latest === undefined) {
                daysSince = '无数据';
                meaning = 'noData';
              } else {
                daysSince = Math.floor(
                  (new Date() - new Date(latest)) / 1000 / 60 / 60 / 24
                );
                meaning = daysSince <= 7 ? 'good' : 'bad';
              }

              return (
                <Col key={type} span={6}>
                  <MetricPanel
                    title={`距离上一次${title}（天）`}
                    text={daysSince}
                    trend={trend || []}
                    meaning={meaning}
                  />
                </Col>
              );
            })
          }
        </Row>
      }
    </div>
  );
}

export default WorkLogMetricsPanels;
