
import API from 'adapters/api';

/**
 * state structure:
 * {
 *   data: [
 *     {Survey}
 *   ]
 * }
 */

const Surveys = {
  selectors: {},
  actions: {}
};

Surveys.selectors.data = (state) => {
  return state.surveys.data;
};

Surveys.actions.readList = () => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'surveys',
        action: 'readList'
      });

      if (status < 300) {
        const { surveys } = data;

        dispatch({
          type: 'surveysRefreshData',
          data: surveys.data
        });
      }

      return { status, data };
    }
  }
};

Surveys.actions.create = ({
  survey, success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'surveys',
        action: 'create',
        data: survey
      });

      if (status < 300) {
        const { id } = data.survey;

        dispatch({
          type: 'surveysAdd',
          data: { ...survey, id }
        });

        success && success();
      }

      return { status, data };
    }
  }
};

Surveys.actions.update = ({
  survey,
  success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'surveys',
        action: 'update',
        data: survey
      });

      if (status < 300) {
        dispatch({
          type: 'surveysUpdate',
          data: survey
        });

        success && success();
      }

      return { status, data };
    }
  }
};

Surveys.actions.delete = ({
  id,
  success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'surveys',
        action: 'delete',
        data: { id }
      });

      if (status < 300) {
        dispatch({
          type: 'surveysRemove',
          data: { id }
        });

        success && success();
      }

      return { status, data };
    }
  }
};

const reducers = {
  surveys(state, action) {
    if (state === undefined) return { data: [] };

    if (action.type === 'surveysAdd') {
      return {
        ...state,
        data: [...state.data, action.data]
      };
    } else if (action.type === 'surveysRefreshData') {
      return {
        ...state,
        data: action.data
      };
    } else if (action.type === 'surveysUpdate') {
      return {
        ...state,
        data: state.data.map((datum) => {
          if (datum.id === action.data.id) {
            return {
              ...datum,
              ...action.data
            };
          }
          return datum;
        })
      }
    } else if (action.type === 'surveysRemove') {
      return {
        ...state,
        data: state.data.filter((datum) => datum.id !== action.data.id)
      };
    }

    return state;
  }
}

export { reducers };
export default Surveys;
