
import React, {useState} from 'react';

import ChartPanel from 'components/chart-panel';
import ChartToggleButtons from 'components/chart-toggle-button';

import { formatNumber } from 'utils/number';

import './subscription-change-table.scss';

function SubscriptionChangeTable({subscriptionChangeData, arrChangeData}) {
  const [choice, setChoice] = useState('total');
  const handleToggle = function(event, value) {
    if (value !== null) {
      setChoice(value);
    }
  }
  const subscriptionChange = subscriptionChangeData[choice];
  const arrChange = arrChangeData[choice];

  const toggleButtons = (
    <ChartToggleButtons 
      value={choice} 
      onChange={handleToggle}
      choices={{
        total:'总共',
        superAnnual: '旗舰版',
        annual: '标准版'
      }}
    />
  );

  function createTable() {
    const rowNames = Object.keys(subscriptionChange);
    return rowNames.filter(rowName => rowName !== '总共').map((rowName, i) => {
      const state = ['拉新', '增购', '召回'].includes(rowName) ?
        'increase' : 'decrease'

      return (
        <div key={i} className={`row ${state}`}>
          <div>{rowName}</div>
          <div>{formatNumber(subscriptionChange[rowName])}</div>
          <div>{formatNumber(arrChange[rowName])}</div>
        </div>
      )
    });
  }

  /*
  const upgradeCount = choice !== 'total' ?
    formatNumber(subscriptionChange['增购']) : 
    `(${formatNumber(subscriptionChange['增购'])})`
  const downgradeCount = choice !== 'total' ?
    formatNumber(subscriptionChange['降级']) : 
    `(${formatNumber(subscriptionChange['降级'])})`
  */
  return (
    <ChartPanel 
      title="本月当前订阅变化" 
      actions={toggleButtons}
    >
      <div className="analytics-chart-table">
        <div className="row header">
          <div>总共</div>
          <div>客户量 {Math.round(subscriptionChange['总共'])}</div>
          <div>ARR {formatNumber(arrChange['总共'])}</div>
        </div>
        <div className="divider"></div>
        {createTable()}
      </div>
    </ChartPanel>
  );
}

export default SubscriptionChangeTable;
