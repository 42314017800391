
import React, { useEffect, useRef } from 'react';
import { Chart } from '@antv/g2';

import Panel from 'components/panel';

import themeColor from 'theme/_color.scss';
import './future-renewals.scss';

function FutureRenewals() {
  const chartContainer = useRef(null);

  useEffect(() => {
    chartContainer.current.innerHTML = '';
    createChart(chartContainer.current);
  }, []);

  function createChart(container) {
    const chart = new Chart({
      container,
      autoFit: true,
      height: 230
    });

    chart.data(fakeData);
    chart.scale('value', {
      min: 0,
      max: 10,
      nice: true,
      ticks: [0, 5, 10]
    });

    chart.tooltip({
      showMarkers: false
    });

    chart
      .interval()
      .position('timestamp*value')
      .color('healthLabel', (label) => {
        return {
          '健康': themeColor.healthGood,
          '中等': themeColor.healthMedium,
          '危险': themeColor.healthBad
        }[label];
      })
      .adjust('stack');
    
    chart.tooltip({
      shared: true,
      marker: false
    });

    chart.interaction('active-region');

    chart.render();
  }

  return (
    <div className="future-renewals">
      <Panel title="未来 90 天续费客户" style={{ height: 290 }}>
        <div ref={chartContainer}></div>
      </Panel>
    </div>
  );
}

const fakeData = generateFakeData();
function generateFakeData() {
  const fakeData = [];

  const healthLabels = [
    '健康',
    '中等',
    '危险'
  ];

  const today = new Date();
  for (let i = 0; i < 90; i++) {
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) month = `0${month}`;
    let day = today.getDate();
    if (day < 10) day = `0${day}`;
    const dateStr = `${year}-${month}-${day}`;

    healthLabels.forEach((label) => {
      let value = Math.floor(Math.random() * 7) - 4;
      if (value < 0) value = 0;
      fakeData.push({
        timestamp: dateStr,
        value,
        healthLabel: label
      });
    });

    today.setDate(today.getDate() + 1);
  }

  return fakeData;
}

export default FutureRenewals;
