
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import CustomerMetricsDomain from 'domains/customer-metrics';
import ListItemActions from 'components/list-item-actions';
import TrendChart from 'components/trend-chart';
import Panel from 'components/panel';
import { formatNumber } from 'utils/number';

import Drawer from './drawer';
import DeletionModal from './deletion-modal';

import './definition.scss';

function Definition({ definition, metrics }) {
  function handleShowDeletionModal() {
    setShowDeletionModal(true);
  }

  function handleHideDeletionModal() {
    setShowDeletionModal(false);
  }

  function handleHideDrawer() {
    setShowDrawer(false);
  }

  function handleShowDrawer() {
    setShowDrawer(true);
  }

  function handleDelete() {
    dispatch(CustomerMetricsDomain.actions.deleteDefinition({
      id: definition.id,
      success: handleHideDeletionModal
    }));
  }

  function handleSubmit(v) {
    if (!v.logic) v.logic = {};
    dispatch(CustomerMetricsDomain.actions.updateDefinition({
      definition: {
        ...v,
        id: definition.id
      },
      success: handleHideDrawer
    }));
  }

  const dispatch = useDispatch();

  const [showDrawer, setShowDrawer] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  let number = '无数据';
  if (metrics && metrics.length > 0) {
    number = metrics[metrics.length - 1].value;
    number = formatNumber(number);
  }

  let delta = '无对比数据', deltaClass = 'grey';
  if (metrics && metrics.length > 1) {
    const last = metrics[metrics.length - 1].value;
    const secondLast = metrics[metrics.length - 2].value;
    const diff = (last - secondLast) / Math.abs(secondLast) * 100;

    if (diff > 0) {
      deltaClass = 'green';
      delta = `+ ${diff.toFixed(1)}%`;
    } else if (diff < 0) {
      deltaClass = 'red';
      delta = `- ${diff.toFixed(1).substring(1)}%`;
    } else if (last === secondLast && !isNaN(last)) {
      delta = '无变化';
    }
  }

  if (number === '无数据') delta = '-';

  const style = number === '无数据' ? { fontSize: 24, color: '#C0C0C0' } : {};

  return (
    <Panel
      title={definition.name}
      actions={
        <ListItemActions
          actions={[
            { name: '修改', onClick: handleShowDrawer, disabled: definition.systemOwned },
            { name: '删除', onClick: handleShowDeletionModal, disabled: definition.systemOwned }
          ]}
        />
      }
    >
      <div className="definition">
        <div className="content">
          <div className="number">
            <div>{definition.overallAggregation === 'SUM' ? '总和' : '平均'}</div>
            <div style={style}>{number}</div>
            <div className={deltaClass}>{delta}</div>
          </div>
          <div className="chart">
            {metrics && <TrendChart data={metrics} />}
          </div>
        </div>
        {
          showDrawer &&
          <Drawer
            definition={definition}
            onSubmit={handleSubmit}
            onCancel={handleHideDrawer}
          />
        }
        {
          showDeletionModal &&
          <DeletionModal
            definition={definition}
            onSubmit={handleDelete}
            onCancel={handleHideDeletionModal}
          />
        }
      </div>
    </Panel>
  );
}

export default Definition;
