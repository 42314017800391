
import React from 'react';

import HealthDomain from 'domains/health';

import './factors.scss';

function Factors({
  selected,
  modelWithScores,
  customer,
  customerMetrics,
  metricDefinitions
}) {
  if (!selected) {
    return (
      <div className="factors">
        选择一个健康模型分类查看因素
      </div>
    );
  }

  const node = HealthDomain.findNode(modelWithScores, selected);
  const { score, formula, children } = node;
  const scoreHealth = HealthDomain.scoreToHealth(score);

  return (
    <div className="factors">
      <div className="title">
        {selected}{'，健康分 '}
        <span className={`score ${scoreHealth}`}>
          {`${Math.round(score)}/100`}
        </span>
      </div>
      {formula && renderFormulaFactors({ formula, customer, customerMetrics, metricDefinitions })}
      {children && renderChildrenFactors({ score, children })}
    </div>
  );
}

function metricConditionMatch(condition, customerMetrics) {
  const { definitionId, operator, value } = condition;

  const metric = customerMetrics.find((m) => m.definitionId === Number(definitionId)).value;
  let operatorSymbol = operatorToSymbol(operator);
  if (operatorSymbol === 'x') operatorSymbol = '*';
  if (operatorSymbol === '=') operatorSymbol = '===';
  const statement = `return ${Math.round(metric)} ${operatorSymbol} ${value};`;

  return new Function(statement)(); // eslint-disable-line
}

function attributeConditionMatch(condition, customer) {
  const { fieldName, operator, value } = condition;

  let operatorSymbol = operatorToSymbol(operator);
  if (operatorSymbol === 'x') operatorSymbol = '*';
  if (operatorSymbol === '=') operatorSymbol = '===';
  const statement = `return ${Math.round(customer[fieldName])} ${operatorSymbol} ${value};`;

  return new Function(statement)(); // eslint-disable-line
}

function renderFormulaFactors({
  formula,
  customer,
  customerMetrics,
  metricDefinitions
}) {
  const attributes = {};
  const metrics = {};

  let matchedConditionIndex = null;
  formula.forEach(({ condition, outcome }, i) => {
    if (condition.type === 'METRIC') {
      const metricName = metricDefinitions.find((d) => d.id === Number(condition.definitionId)).name;
      metrics[metricName] = customerMetrics.find((m) => m.definitionId === Number(condition.definitionId)).value;

      if (metricConditionMatch(condition, customerMetrics)) {
        matchedConditionIndex = i;
      }
    } else if (condition.type === 'ATTRIBUTE') {
      const { fieldName } = condition;
      attributes[fieldName] = customer[fieldName];

      if (attributeConditionMatch(condition, customer)) {
        matchedConditionIndex = i;
      }
    }

    if (outcome.type === 'METRIC') {
      const metricName = metricDefinitions.find((d) => d.id === Number(outcome.definitionId)).name;
      metrics[metricName] = customerMetrics.find((m) => m.definitionId === Number(outcome.definitionId)).value;
    } else if (outcome.type === 'ATTRIBUTE') {
      const { fieldName } = outcome;
      attributes[fieldName] = customer[fieldName];
    }
  });

  return (
    <div>
      {
        formula.map(({ condition, outcome }, i) => {
          let matched = false;
          if (
            i === matchedConditionIndex ||
            (matchedConditionIndex === null && condition.type === 'ELSE')
          ) {
            matched = true;
          }

          return (
            <div key={i} className={`condition-outcome ${matched}`}>
              <div>{i + 1}.</div>
              <div>
                <div className="condition">
                  <div>如果：</div>
                  {conditionToString(condition, metricDefinitions)}
                </div>
                <div className="outcome">
                  <div>则：</div>
                  {outcomeToString(outcome, metricDefinitions)}
                </div>
              </div>
            </div>
          );
        })
      }
      <div className="metrics">
        <div>相关指标：</div>
        <div className="list">
          {Object.keys(metrics).length === 0 && <div className="empty">无相关指标</div>}
          {
            Object.keys(metrics).length > 0 &&
            Object.keys(metrics).map((metricName, i) => {
              return (
                <div key={i} className="metric">
                  <div>{metricName}：</div>
                  <div>{metrics[metricName]}</div>
                </div>
              );
            })
          }
        </div>
      </div>
      <div className="attributes">
        <div>相关字段：</div>
        <div className="list">
          {Object.keys(attributes).length === 0 && <div className="empty">无相关字段</div>}
          {
            Object.keys(attributes).length > 0 &&
            Object.keys(attributes).map((fieldName, i) => {
              return (
                <div key={i} className="attribute">
                  <div>{fieldName}：</div>
                  <div>{attributes[fieldName]}</div>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
}

function conditionToString(condition, metricDefinitions) {
  const { type } = condition;
  if (type === 'ELSE') {
    return <div>其余情况</div>;
  } else if (type === 'METRIC') {
    return metricExpressionToString(condition, metricDefinitions);
  } else {
    return attributeExpressionToString(condition);
  }
}

function outcomeToString(outcome, metricDefinitions) {
  const { type } = outcome;
  if (type === 'FIXED') {
    return <div>{outcome.value}</div>;
  } else if (type === 'METRIC') {
    return metricExpressionToString(outcome, metricDefinitions);
  } else {
    return attributeExpressionToString(outcome);
  }
}

function metricExpressionToString(expression, metricDefinitions) {
  const { definitionId, operator, value } = expression;
  const definitionName = metricDefinitions.find((d) => d.id === Number(definitionId)).name;

  return (
    <div className="expression">
      <div className="metric-definition">指标：{definitionName}</div>
      <div>{operatorToSymbol(operator)}</div>
      <div>{value}</div>
    </div>
  );
}

function attributeExpressionToString(expression) {
  const { fieldName, operator, value } = expression;
  return (
    <div className="expression">
      <div className="field-name">字段：{fieldName}</div>
      <div>{operatorToSymbol(operator)}</div>
      <div>{value}</div>
    </div>
  );
}

function operatorToSymbol(operator) {
  return {
    EQUAL: '=',
    GREATER: '>',
    GREATER_EQUAL: '>=',
    LESS: '<',
    LESS_EQUAL: '<=',
    ADD: '+',
    SUBTRACT: '-',
    MULTIPLY: 'x',
    DIVIDE: '/'
  }[operator];
}

function renderChildrenFactors({
  score,
  children
}) {
  function scoreToHealth(score) {
    if (score >= 80) return 'good';
    else if (score >= 50) return 'medium';
    return 'bad';
  }

  return (
    <div className="child-nodes">
      {
        children.map((child, i) => {
          const { name, weight, score } = child;
          const sign = i === (children.length - 1) ? '+' : '';

          return (
            <div key={i} className="child-node">
              <div>{sign}</div>
              <div></div>
              <div>{name}</div>
              <div className={scoreToHealth(score)}>{Math.round(score)}</div>
              <div>x</div>
              <div>{weight}%</div>
            </div>
          );
        })
      }
      <div className="divider"></div>
      <div className="child-node">
        <div>=</div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div className={scoreToHealth(score)}>{Math.round(score)}</div>
      </div>
    </div>
  );
}

export default Factors;
