
import API from 'adapters/api';

/**
 * state structure:
 * {
 *   count: {Number}
 *   metadata: {
 *     fields: {
 *       [fieldName]: {Field}
 *     }
 *   }
 *   data: [
 *     {Customer}
 *   ]
 * }
 */

const limit = 20; // changing this is not an important feature right now

const Customers = {
  selectors: {},
  actions: {}
};

Customers.selectors.metadata = (state) => state.customers.metadata;

Customers.selectors.data = (state) => state.customers.data;

Customers.selectors.count = (state) => state.customers.count;

Customers.actions.create = (customer, success) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'customers',
        action: 'create',
        data: customer
      });

      if (status < 300) {
        dispatch({
          type: 'customersAdd',
          data: customer
        });
        success && success();
      }

      return { status, data };
    }
  }
};

Customers.actions.delete = ({ id, success }) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'customers',
        action: 'delete',
        data: { id }
      });

      if (status < 300) {
        dispatch(Customers.actions.readList());
        success && success();
      }

      return { status, data };
    }
  }
};

Customers.actions.readChurnPredictions = (customerIds) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'customers-churn-prediction',
        action: 'readList',
        params: { customerIds }
      });

      if (status < 300) {
        const { churnPredictions } = data;
        dispatch({
          type: 'customersAddChurnPredictions',
          data: churnPredictions
        });
      }

      return { status, data };
    }
  }
}

Customers.actions.readList = (page = 1, filters = [], baseListId) => {
  const offset = (page - 1) * limit;

  return {
    type: 'query',
    async request(state, dispatch) {
      const params = {
        offset,
        limit,
        filtersJson: JSON.stringify(filters),
        baseListId
      };

      const {
        status, data
      } = await API.request({
        resource: 'customers',
        action: 'readList',
        params,
        skipCache: true,
      });

      if (status < 300) {
        const { customers } = data;
        const { count } = customers;

        dispatch({
          type: 'customersRefreshData',
          count,
          data: customers.data
        });

        const customerIds = customers.data.map(datum => datum.id);
        // ideally dispatch this after customers data finishes refresh data
        if (customerIds.length > 0) dispatch(Customers.actions.readChurnPredictions(customerIds));
      }

      return { status, data };
    }
  }
};

Customers.actions.getAdHocListStats = (category, journeyStage, success) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      let params = {
        filters: []
      };

      if (category && journeyStage) {
        params = {
          filters: [
            {
              fieldName: 'category',
              operatorName: 'EQUAL',
              value: category
            },
            {
              fieldName: 'journeyStage',
              operatorName: 'EQUAL',
              value: journeyStage
            }
          ]
        };
      }

      const {
        status, data
      } = await API.request({
        resource: 'customer-lists-ad-hoc-stats',
        action: 'read',
        params
      });

      if (status < 300) {
        success(data.stats);
      }

      return { status, data };
    }
  }
};

Customers.actions.read = (id) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const { status, data } = await API.request({
        resource: 'customers',
        action: 'read',
        data: { id }
      });

      if (status < 300) {
        dispatch({
          type: 'customersRefreshData',
          data: [data.customer],
          count: 1
        });
      }

      dispatch(Customers.actions.readChurnPredictions([id]));

      return { status, data };
    }
  };
};

Customers.actions.update = (customer) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'customers',
        action: 'update',
        data: customer
      });

      if (status < 300) {
        dispatch({
          type: 'customersUpdate',
          customer
        });
      }

      return { status, data };
    }
  }
};

Customers.actions.getTags = (customer) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `customers/${customer.id}/tags`,
        action: 'read',
      });

      if (status < 300) {
        dispatch({
          type: 'customersUpdate',
          customer: { ...customer, tags: data.tags }
        })
      }

      return { status, data };
    }
  };
}

Customers.actions.createTag = (customer, tag) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `customers/${customer.id}/tags`,
        action: 'create',
        data: { tag }
      });

      if (status < 300) {
        const oldTags = customer.tags === undefined ? [] : customer.tags;
        dispatch({
          type: 'customersUpdate',
          customer: { ...customer, tags: [...oldTags, tag] }
        })
      }
      return { status, data };
    }
  }
}

Customers.actions.deleteTag = (customer, tag) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `customers/${customer.id}/tags`,
        action: 'delete',
        data: { tag }
      });

      if (status < 300) {
        const tags = customer.tags === undefined ? [] : customer.tags.filter(t => t !== tag);
        dispatch({
          type: 'customersUpdate',
          customer: { ...customer, tags }
        })
      }

      return { status, data };
    }
  }
}

const reducers = {
  customers(state, action) {
    if (state === undefined) return {
      count: 0,
      data: []
    };

    if (action.type === 'init') {
      return {
        ...state,
        count: 0,
        data: []
      };
    }

    if (action.type === 'customersAdd') {
      return {
        ...state,
        data: [
          ...state.data,
          action.data,
        ],
        count: state.count + 1
      };
    } else if (action.type === 'customersRefreshData') {
      const {
        data,
        count
      } = action;

      return {
        ...state,
        count,
        data
      };
    } else if (action.type === 'customersUpdate') {
      return {
        ...state,
        data: state.data.map((customer) => {
          if (customer.id === action.customer.id) {
            return Object.assign({}, customer, action.customer);
          } else {
            return customer;
          }
        })
      };
    } else if (action.type === 'customersRemove') {
      return {
        ...state,
        data: state.data.filter((customer) => {
          return customer.id !== action.data.id;
        })
      };
    } else if (action.type === 'customersAddChurnPredictions') {
      let customers = state.data;
      const churnPredictions = action.data;

      for (const prediction of churnPredictions) {
        customers = customers.map(customer => {
          if (customer.id === prediction.customerId) {
            return {
              ...customer,
              churnPrediction: prediction.prediction
            }
          } else {
            return customer;
          }
        })
      }

      return {
        ...state,
        data: customers
      }
    }

    return state;
  }
}

export { reducers };
export default Customers;
