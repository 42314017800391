
import React, { useEffect } from 'react';
import G6 from '@antv/g6';

import './breakdown-graph-chart.scss';
import {
  healthGood, healthMedium, healthBad, colorPrimary
} from 'theme/_color.scss';

function BreakdownGraphChart({
  selected,
  setSelected,
  healthScore
}) {
  useEffect(() => {
    const data = processData(healthScore);
    createChart(data, selected, setSelected);
  }, [healthScore, selected]);

  return (
    <div className="breakdown-graph-chart"></div>
  );
}

const nodeHeight = 50;
const nodeWidth = 200;

function createChart(data, selected, setSelected) {
  G6.registerNode(
    'health-score-graph-node',
    {
      draw: (node, group) => {
        let { name, weight, score } = node;
  
        let color = healthBad;
        if (score === undefined) {
          color = '#C0C0C0';
        } else {
          score = Math.round(score);
          if (score >= 70) {
            color = healthGood;
          } else if (score >= 40) {
            color = healthMedium;
          }
        } 
  
        let height = nodeHeight;
        let width = nodeWidth;
        if (node.depth === 0) {
          height = height * 1.5;
          width = width * 0.7;
        }
        const isSelected = name === selected;
  
        if (isSelected) {
          group.addShape('rect', {
            attrs: {
              width,
              height,
              fill: 'none',
              shadowBlur: 20,
              stroke: colorPrimary
            }
          });
        }

        return group.addShape('dom', {
          attrs: {
            width,
            height,
            html: `
              <div class="node depth-${node.depth}">
                <div class="mask" data-name="${name}"></div>
                <div class="score" style="color: ${color}">
                  <svg viewBox="0 0 36 36" class="circular-chart">
                    <path class="circle-inner"
                      d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path class="circle"
                      stroke=${color}
                      stroke-dasharray="${score}, 100"
                      d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <text
                      x="18" y="23"
                      fill="${color}"
                      text-anchor="middle"
                    >
                      ${score === undefined ? '' : score}
                    </text>
                  </svg>
                </div>
                <div class="text">
                  <div class="title">${name}</div>
                  ${
                    node.weight ?
                    `<div class="weight">权重 ${weight}%</div>` :
                    ''
                  }
                </div>
              </div>
            `,
          }
        });
      },
    },
    'single-node',
  );

  const container = document.querySelector('.breakdown-graph-chart');
  container.innerHTML = '';

  let width = container.scrollWidth;
  let height = container.scrollHeight;
  if (width < 500) width = 500;
  if (height < 600) height = 600;

  const graph = new G6.TreeGraph({
    container,
    width,
    height,
    fitView: true,
    animate: false,
    defaultNode: {
      type: 'health-score-graph-node',
      anchorPoints: [
        [0, 0.5],
        [1, 0.5]
      ]
    },
    defaultEdge: {
      type: 'cubic-horizontal',
      sourceAnchor: 1,
      targetAnchor: 0
    },
    layout: {
      type: 'mindmap',
      direction: 'H',
      geWidth() {
        return nodeWidth;
      },
      getHeight() {
        return nodeHeight;
      },
      getHGap() {
        return nodeWidth * 0.6;
      },
      getVGap() {
        return nodeHeight * 0.2;
      },
      getSide() {
        return 'right';
      }
    },
    renderer: 'svg'
  });

  graph.data(data);
  graph.render();

  function registerListeners() {
    container
      .querySelectorAll('.node .mask')
      .forEach((ele) => {
        ele.addEventListener('click', (param) => {
          const selected = param.target.dataset.name;
          setSelected(selected);
        });
      });
  }
  registerListeners();

  // hack: most likely because AntV repaints
  // the graph on fitView, wiping out previous
  // listener attachments
  window.setTimeout(() => {
    registerListeners()
  }, 1000);
};

function processData(data) {
  return {
    ...data,
    name: '总分'
  };
}

export default BreakdownGraphChart;
