
import React, { useState } from 'react';

import ChartPanel from 'components/chart-panel';
import ChartToggleButtons from 'components/chart-toggle-button';

import SubscriptionChangeTrendChart from './subscription-change-trend-chart';

function SubscriptionChangeTrend({ arrData }) {
  const [choice, setChoice] = useState('total');
  const handleToggle = function(event, value) {
    setChoice(value);
  }

  const data = [];
  arrData[choice].forEach((datum) => {
    const inc = datum['增加'][1] - datum['增加'][0];
    const dec = datum['增加'][0] - datum['增加'][1];

    data.push({
      timestamp: datum.timestamp,
      value: inc * 0.8,
      type: '拉新'
    });

    data.push({
      timestamp: datum.timestamp,
      value: inc * 0.2,
      type: '增购'
    });

    data.push({
      timestamp: datum.timestamp,
      value: dec * 0.7,
      type: '流失'
    });

    data.push({
      timestamp: datum.timestamp,
      value: dec * 0.3,
      type: '降级'
    });    
  });

  const toggleButtons = (
    <ChartToggleButtons 
      value={choice} 
      onChange={handleToggle}
      choices={{
        total: '总共',
        superAnnual: '旗舰版',
        annual: '标准版'
      }}
    />
  );

  return (
    <ChartPanel 
      title="ARR 变化趋势"
      actions={toggleButtons}>
      <SubscriptionChangeTrendChart
        data={data}
        valueName="value"
        colorMapper={() => '#7E92B5'}
      />
    </ChartPanel>
  );
}

export default SubscriptionChangeTrend;
