
import React, { useEffect, useRef } from 'react';
import { Chart } from '@antv/g2';

import themeColor from 'theme/_color.scss';
import './index.scss';

function ColumnChart({
  data
}) {
  function createChart(container, data) {
    const chart = new Chart({
      container,
      autoFit: true
    });

    chart.data(data);

    chart.interval()
      .position('category*value')
      .color('category', (val) => {
        if (val >= 9) return themeColor.healthGood;
        if (val >= 7) return themeColor.healthMedium;
        return themeColor.healthBad;
      });

    chart.scale({
      value: { min: 0 }
    });

    chart.axis('category', {
      tickLine: false
    });
    chart.axis('value', false);

    chart.legend(false);
  
    chart.render();
  }

  const chartContainer = useRef(null);

  useEffect(() => {
    createChart(chartContainer.current, data);
  }, []);

  return (
    <div className="component-column-chart" ref={chartContainer}></div>
  );
}

export default ColumnChart;
