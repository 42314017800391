
import React, { useEffect, useRef } from 'react';
import { Chart } from '@antv/g2';

import themeColor from 'theme/_color.scss';
import './index.scss';

function OverallTrendChart({
  size = 'large',
  trend,
  comparisonTrend
}) {
  const chartContainer = useRef(null);

  useEffect(() => {
    createChart({
      container: chartContainer.current,
      size,
      data: trend,
      comparisonData: comparisonTrend
    });
  }, []);

  return (
    <div className={`overall-trend-chart ${size}`} ref={chartContainer}></div>
  );
}

function createChart({
  container,
  size,
  data,
  comparisonData = []
}) {
  if (data.length === 0) return;

  const chart = new Chart({
    container,
    autoFit: true
  });

  const lastValue = data[data.length - 1].value;
  let color = themeColor.healthGood;
  if (lastValue < 50) {
    color = themeColor.healthBad;
  } else if (lastValue < 80) {
    color = themeColor.healthMedium;
  }

  chart.data(processData(data, comparisonData));

  chart.scale('timestamp', {
    range: [0, 0.8]
  });

  chart.tooltip({
    showCrosshairs: true,
    shared: true,
    offsetX: 80,
    position: 'right',
    customItems: (items) => {
      const entityToTitle = {
        self: '当前客户',
        comparison: '平均值'
      };

      return items.map((item) => {
        return {
          ...item,
          value: parseFloat(item.value).toFixed(1),
          name: entityToTitle[item.name]
        };
      });
    }
  });

  chart.axis(false);
  chart.legend(false);

  chart.scale('value', {
    min: -5,
    max: 105
  });

  chart.annotation().line({
    top: true,
    start: ['min', 50],
    end: ['max', 50],
    style: {
      stroke: '#787878',
      lineWidth: 1,
      lineDash: [3, 3],
    },
    text: {
      content: 50,
      position: 'end',
      offsetX: -20,
      offsetY: -2
    }
  });

  if (size === 'large') {
    chart.annotation().text({
      top: true,
      content: '最近 6 个月',
      position: ['start', 'start'],
      offsetY: -5,
      offsetX: 5
    });

    chart.annotation().line({
      top: true,
      start: ['min', 80],
      end: ['max', 80],
      style: {
        stroke: '#787878',
        lineWidth: 1,
        lineDash: [3, 3],
      },
      text: {
        content: 80,
        position: 'end',
        offsetX: -20,
        offsetY: -2
      }
    });
  }

  chart
    .line()
    .position('timestamp*value')
    .color('entity', [color, '#C0C0C0']);

  chart.render();
}

function processData(data, comparisonData) {
  const mergedData = mergeData(data, comparisonData);
  const paddedData = padData(mergedData); // leave space right side of chart
  return paddedData;
}

function mergeData(data, comparisonData) {
  return [
    ...data.map((datum) => ({ ...datum, entity: 'self' })),
    ...comparisonData.map((datum) => ({ ...datum, entity: 'comparison' }))
  ];
}

function padData(data) {
  const len = data.length;
  const [endYear, endMonth, endDate] = data[len - 1].timestamp.split('-');
  let end;

  let appendData = [];

  if (endDate) {
    end = new Date(`${endYear}-${endMonth}-${endDate}`);
    for (let i = 0; i < 5; i++) {
      end.setDate(end.getDate() + 1);
      let month = end.getMonth() + 1;
      month = month > 9 ? `${month}` : `0${month}`;
      let date = end.getDate() + 1;
      date = date > 9 ? `${date}` : `0${date}`;
      appendData.push({
        timestamp: `${end.getFullYear()}-${month}-${date}`
      });
    }
  } else {
    end = new Date(`${endYear}-${endMonth}`);
    end.setMonth(end.getMonth() + 1);
    const year = end.getFullYear();
    let month = end.getMonth() + 1;
    month = month > 9 ? `${month}` : `0${month}`;
    appendData.push({
      timestamp: `${year}-${month}`
    });
  }
  
  return data.concat(appendData);
}

export default OverallTrendChart;
