
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HealthDomain from 'domains/health';
import CustomerMetricsDomain from 'domains/customer-metrics';

import HealthScorePanel from './health-score-panel';
import Panel from 'components/panel';
import BreakdownGraphChart from './breakdown-graph-chart';
import Factors from './factors';

import './index.scss';

function HealthScoreDetails({
  customer
}) {
  const { id } = customer;

  const [selected, setSelected] = useState(null);
  const modelWithScores = useSelector(HealthDomain.selectors.getModelWithScoresSelector(customer))
  const modelWithAvgScores = useSelector(HealthDomain.selectors.getModelWithAvgScoresSelector(customer))
  const scoresTrend = useSelector(HealthDomain.selectors.trend)[id];
  const avgScoresTrend = useSelector(HealthDomain.selectors.avgTrend);
  const customerMetrics = useSelector(CustomerMetricsDomain.selectors.perCustomer)[id];
  const metricDefinitions = useSelector(CustomerMetricsDomain.selectors.definitions);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(HealthDomain.actions.queryScoresMap(id));
    dispatch(HealthDomain.actions.queryScoresMap('avg'));
    dispatch(HealthDomain.actions.queryTrend(id));
    dispatch(HealthDomain.actions.queryTrend('avg'));
    dispatch(CustomerMetricsDomain.actions.readPerCustomer(id));
  }, []);

  if (
    !modelWithScores ||
    !modelWithAvgScores ||
    !scoresTrend ||
    !avgScoresTrend ||
    !customerMetrics
  ) return <div>--- 加载中 ---</div>;

  return (
    <div>
      <HealthScorePanel
        modelWithScores={modelWithScores}
        modelWithAvgScores={modelWithAvgScores}
        scoresTrend={scoresTrend}
        avgScoresTrend={avgScoresTrend}
      />
      <div style={{ height: 10 }}></div>
      <Panel>
        <div className="health-score-details">
          <BreakdownGraphChart
            selected={selected}
            setSelected={setSelected}
            healthScore={modelWithScores}
          />
          <div className="divider"></div>
          <Factors
            selected={selected}
            modelWithScores={modelWithScores}
            customer={customer}
            customerMetrics={customerMetrics}
            metricDefinitions={metricDefinitions}
          />
        </div>
      </Panel>
    </div>
  )
}

export default HealthScoreDetails;
