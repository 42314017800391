
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Select, Timeline
} from 'antd';

import EventsDomain from 'domains/events';
import Panel from 'components/panel';

import './index.scss';

function History({
  customer
}) {
  function handleEventTypeChange(type) {
    setEventType(type);
  }

  const [eventType, setEventType] = useState('all');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(EventsDomain.actions.recentCustomerHistory(customer.id));
  }, []);

  const perCustomer = useSelector(EventsDomain.selectors.perCustomer)[customer.id] || {};
  const events = perCustomer.recent || [];
  const filteredEvents = filterEvents(events || [], eventType);
  const sortedEvents = filteredEvents.sort(
    (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
  );

  const filter = (
    <div className="filter">
      <div>类型</div>
      <div className="select">
        <Select value={eventType} onChange={handleEventTypeChange}>
          <Select.Option value="all">全部</Select.Option>
          <Select.Option value="lifecycle">业务</Select.Option>
          <Select.Option value="behavior">行为</Select.Option>
          <Select.Option value="nps">NPS 提交</Select.Option>
          <Select.Option value="feedback">反馈</Select.Option>
          <Select.Option value="customer">客户数据手动变更</Select.Option>
        </Select>
      </div>
    </div>
  );
  
  const timelineItems = [];
  let lastDate;
  sortedEvents.forEach((event, i) => {
    const { subType, timestamp } = event;
    const eventDate = timestamp.substring(0, 10);
    const eventTime = timestamp.substring(11, 19);

    const eventMessage = eventToMessage(event);
    let color;
    if (subType === 'feedback' && event.info.type === 'nps') {
      color = eventTypeToColor['nps']
    } else {
      color = eventTypeToColor[subType];
    }

    let label;
    if (eventDate !== lastDate) {
      label = eventDate;
      lastDate = eventDate;
    }

    timelineItems.push(
      <Timeline.Item label={label} color={color} key={i}>
        <div className="event-row" style={{ color }}>
          <div>{eventTime}</div>
          <div>{eventMessage}</div>
          <div>{JSON.stringify(event.info)}</div>
        </div>
      </Timeline.Item>
    );
  });

  return (
    <div className="history">
      <Panel>
        {filter}
        {
          timelineItems.length > 0 &&
          <div className="timeline">
            <Timeline mode="left">
              {timelineItems}
            </Timeline>
          </div>
        }
        {
          !events &&
          <div className="no-data">-- 加载中 --</div>
        }
        {
          events && timelineItems.length === 0 &&
          <div className="no-data">-- 暂无数据 --</div>
        }
      </Panel>
    </div>
  );
}

const eventTypeToColor = {
  lifecycle: '#f5222d',
  behavior: '#096dd9',
  feedback: '#eb2f96',
  customer: '#707070',
  nps: '#08979c'
};

function filterEvents(events, type) {
  if (type === 'all') return events;
  if (type === 'nps') return events.filter((event) => {
    return event.subType === 'feedback' && event.info.type === 'nps';
  });
  return events.filter((event) => {
    if (event.subType === 'feedback' && event.info.type === 'nps') return false;
    return event.subType === type;
  });
}

function eventToMessage(event) {
  if (event.subType === 'feedback' && event.info.type !== 'nps') {
    return feedbackEventToMessage(event);
  } else if (event.subType === 'feedback' && event.info.type === 'nps') {
    return npsEventToMessage(event);
  } else if (event.subType === 'lifecycle') {
    return lifeCycleEventToMessage(event);
  } else if (event.subType === 'behavior') {
    return behaviorEventToMessage(event);
  } else if (event.subType === 'customer') {
    const { id, clientId, ...rest } = event.info.customer;
    return `用户 ${event.info.authorId} 改客户数据为 ${JSON.stringify(rest)}`;
  }
}

function lifeCycleEventToMessage(event) {
  const { info } = event;
  const { type } = info;
  
  if (type === 'registration') {
    return '注册';
  } else if (type === 'subscription') {
    const invoiceMessage = info.orderId ? `订单 ID ${info.orderId}` : '无订单 ID'
    return `初始订阅： ${info.planId}，${invoiceMessage}`;
  } else if (type === 'adHoc') {
    const invoiceMessage = info.orderId ? `订单 ID ${info.orderId}` : '无订单 ID'
    return `单独购买：产品 ${info.productId}，${invoiceMessage}`;
  } else if (type === 'renewal') {
    const invoiceMessage = info.orderId ? `订单 ID ${info.orderId}` : '无订单 ID'
    return `续费：订阅 ${info.planId}，${invoiceMessage}`;
  } else if (type === 'upgrade') {
    return `增购：新订阅 ${info.newPlanId}，旧订阅 ${info.oldPlanId}`;
  } else if (type === 'downgrade') {
    return `降级：新订阅 ${info.newPlanId}，旧订阅 ${info.oldPlanId}`;
  } else if (type === 'changePlan') {
    return `改订阅：新订阅 ${info.newPlanId}，旧订阅 ${info.oldPlanId}`;
  } else if (type === 'churn') {
    return `流失：订阅 ${info.planId}`;
  } else if (type === 'recall') {
    const invoiceMessage = info.orderId ? `订单 ID ${info.orderId}` : '无订单 ID'
    return `唤回：订阅 ${info.planId}，${invoiceMessage}`;
  } else if (type === 'enableAutoRenewal') {
    return '开启自动续费';
  } else if (type === 'disableAutoRenewal') {
    return '停止自动续费';
  } else if (type === 'suspendSubscription') {
    return `订阅暂停：开始时间 ${info.suspensionStartDate}，结束时间 ${info.suspensionEndDate}`;
  }
}

function behaviorEventToMessage(event) {
  return `使用 app：${event.info.name}`;
}

function feedbackEventToMessage(event) {
  const { info } = event;
  const { type } = info;
   
  if (type === 'complaint') {
    return `投诉：${info.content}`;
  } else if (type === 'generalFeedback') {
    return `整体反馈：${info.content}`;
  } else if (type === 'createSurveyEntry') {
    return `收到反馈问题：问题 ${info.surveyId}`;
  } else if (type === 'submitSurveyEntry') {
    return `提交反馈问题：问题 ${info.surveyId}，回复 ${info.answer}`;
  } else if (type === 'suggestion') {
    return `建议：${info.content}`;
  } else {
    return `其他：${info.content}`;
  }
}

function npsEventToMessage(event) {
  return `提交 NPS：${event.info.response}`;
}

export default History;
