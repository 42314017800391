
import StoresCreate from './stores-create';
import StoresDelete from './stores-delete';

import RolesCreate from './roles-create';
import RolesDelete from './roles-delete';

import UsersCreate from './users-create';
import UsersDelete from './users-delete';

import CustomersCreate from './customers-create';
import CustomersDelete from './customers-delete';

const modals = {
  StoresCreate,
  StoresDelete,
  RolesCreate,
  RolesDelete,
  UsersCreate,
  UsersDelete,
  CustomersCreate,
  CustomersDelete
};

export default modals;
