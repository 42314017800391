
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Alert, Spin, ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale/zh_CN';

import { history } from 'domains/history';
import Cockpit from 'pages/cockpit';
import CustomersMonitoring from 'pages/customers-monitoring';
import CustomerMetrics from 'pages/customer-metrics';
import CustomersHealth from 'pages/customers-health';
import AIAssistant from 'pages/ai-assistant';
import CustomersManagement from 'pages/customers-management';
import Customer from 'pages/customer';
import CustomersDashboards from 'pages/customers-dashboards';
import AlertsManagement from 'pages/alerts-management';
import FeedbackManagement from 'pages/feedback-management';
import Tickets from 'pages/tickets';
import Ticket from 'pages/ticket';
import Notifications from 'pages/notifications';
import Employees from 'pages/employees';
import Setup from 'pages/setup';
import Profile from 'pages/profile';
import Role from 'pages/role';
import Permissions from 'pages/permissions';
import Modals from 'modals';

import Nav from './nav';

import './index.scss';

class Container extends React.Component {
  static propTypes = {
    modal: PropTypes.object,
    alert: PropTypes.object,
    spin: PropTypes.number.isRequired,
    onCloseAlert: PropTypes.func.isRequired
  };

  renderModal() {
    const { name } = this.props.modal;
    const Modal = Modals[name];

    return (
      <Modal />
    );
  }

  renderAlerts = () => {
    return (
      <div className="alerts">
        {this.props.alert.data.map(this.renderAlert)}
      </div>
    );
  };

  renderAlert = (datum) => {
    const {
      id,
      type,
      message
    } = datum;

    return (
      <div key={id} className="alert">
        <Alert
          type={type}
          message={message}
          closable afterClose={() => this.props.onCloseAlert(id)}
        />
      </div>
    );
  };

  render() {
    return (
      <Spin spinning={this.props.spin > 0} tip="加载中..." >
        <section id="app" className="container">
          {this.props.modal && this.renderModal()}
          {this.props.alert.data.length > 0 && this.renderAlerts()}
          <Router history={history}>
            <Nav />
            <ConfigProvider locale={zh_CN}>
              <div className="content">
                <Switch>
                  <Route path="/cockpit/:tab" exact><Cockpit /></Route>
                  <Route path="/customers-monitoring" exact><CustomersMonitoring /></Route>
                  <Route path="/customer-metrics/:tab" exact><CustomerMetrics /></Route>
                  <Route path="/customers-health/:tab" exact><CustomersHealth /></Route>
                  <Route path="/ai-assistant" exact><AIAssistant /></Route>
                  <Route path="/customers-management" exact><CustomersManagement /></Route>
                  <Route path="/customers-management/:id/:tab" exact><Customer /></Route>
                  <Route path="/customers-dashboards/:tab" exact><CustomersDashboards /></Route>
                  <Route path="/alerts-management" exact><AlertsManagement /></Route>
                  <Route path="/feedback-management" exact><FeedbackManagement /></Route>
                  <Route path="/tickets" exact><Tickets /></Route>
                  <Route path="/ticket/:id" exact><Ticket /></Route>
                  <Route path="/notifications" exact><Notifications /></Route>
                  <Route path="/employees" exact><Employees /></Route>
                  <Route path="/setup/:tab" exact><Setup /></Route>
                  <Route path="/profile" exact><Profile /></Route>
                  <Route path="/permissions" exact><Permissions /></Route>
                  <Route path="/permissions/:id" exact><Role /></Route>
                  <Redirect to="/cockpit/summary" />
                </Switch>
              </div>
            </ConfigProvider>
          </Router>
        </section>
      </Spin>
    );
  }
};

function mapStateToProps(state) {
  return {
    spin: state.spin,
    alert: state.alert,
    modal: state.modal
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onCloseAlert(id) {
      dispatch({
        type: 'closeAlert',
        id
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);
