
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Store from 'state/store';
import Init from 'domains/init';
import Container from 'pages/container';
import { url as authUrl } from 'adapters/auth';
import './hacks';

import './antd.less';
import './reset.css';
import './index.scss';

Init.bootstrap().then(({ error, redirect, initialState }) => {
  if (error) {
    ReactDOM.render(
      <div style={{ height: '100%' }}>
        <div style={{ fontSize: '40px', margin: '50px auto' }}>
          服务器错误，无法初始化应用
        </div>
        <div style={{ fontSize: '24px' }}>请联系 support@revenueengine.io</div>
      </div>,
      document.getElementById('root')
    );
    return;
  }

  if (redirect) {
    window.location.assign(authUrl);
    return;
  }

  const store = Store.init(initialState);

  render();
  store.subscribe(render);

  function render() {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <Container />
        </Provider>
      </React.StrictMode>,
      document.getElementById('root')
    );
  }  
});
