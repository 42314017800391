
import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerInteraction } from '@antv/g2';

import { formatNumber, formatPercent } from 'utils/number';

import './index.scss';

function DistributionTrendChart({
  data,
  valueName,
  colorMapper,
  type = 'interval',
  valueType = 'number',
  legend = true
}) {
  const [savedChart, setChart] = useState(null);

  const chartContainer = useRef(null);

  useEffect(() => {
    if (savedChart !== null) {
      savedChart.destroy();
    }

    registerInteraction('element-link', {
      start: [
        { trigger: 'interval:mouseenter', action: 'element-link-by-color:link' }
      ],
      end: [
        { trigger: 'interval:mouseleave', action: 'element-link-by-color:unlink' }
      ]
    });

    const chart = new Chart({
      container: chartContainer.current,
      autoFit: true
    });
    
    setChart(chart);

    chart.data(data);
    
    if (type === 'line') {
      chart
        .line()
        .position(`timestamp*${valueName}`)
        .color(`health`, colorMapper)
        .tooltip(`health*${valueName}`, (health, value) => {
          return {
            name: health,
            value: valueType === 'number' ? formatNumber(value) : formatPercent(value)
          };
        });
    } else if (type === 'area') {
      chart
        .line()
        .position(`timestamp*${valueName}`)
        .adjust('stack')
        .color(`health`, colorMapper)
        .tooltip(`health*${valueName}`, (health, value) => {
          return {
            name: health,
            value: valueType === 'number' ? formatNumber(value) : formatPercent(value)
          };
        });
      chart
        .area()
        .position(`timestamp*${valueName}`)
        .adjust('stack')
        .color(`health`, colorMapper)
        .tooltip(`health*${valueName}`, (health, value) => {
          return {
            name: health,
            value: valueType === 'number' ? formatNumber(value) : formatPercent(value)
          };
        });
    } else {
      chart
        .interval()
        .position(`timestamp*${valueName}`)
        .size(10)
        .color(`health*timestamp`, colorMapper)
        .adjust('stack')
        .tooltip(`health*${valueName}`, (health, value) => {
          return {
            name: health,
            value: valueType === 'number' ? formatNumber(value) : formatPercent(value)
          };
        });

      chart.interaction('element-highlight-by-color');
      chart.interaction('element-link');
    }

    chart.tooltip({
      shared: true,
      marker: false
    });

    chart.scale({
      [valueName]: {
        tickCount: 3,
        nice: true
      },
      date: {
        formatter: formatDate
      }
    });

    chart.axis('timestamp', {
      line: false,
      tickLine: false
    });

    chart.axis(valueName, {
      label: {
        formatter: valueType === 'number' ? formatNumber : formatPercent
      }
    });
  
    if (!legend) {
      chart.legend(false);
    }

    chart.render();
  }, [data, valueName]);

  return (
    <div className="component-chart-distribution-trend" ref={chartContainer}></div>
  );
}

function formatDate(dateString) {
  return `${dateString.split('-')[1]}月`;
}

export default DistributionTrendChart;
