
import React, { useState } from 'react';
import {
  Form, Input, Button, Select
} from 'antd';
import { useSelector } from 'react-redux';

import BaseDrawer from 'modals/base-drawer';
import UsersDomain from 'domains/users';
import MeDomain from 'domains/me';

import './ticket-drawer.scss';

const { Option } = Select;
const { TextArea } = Input;

function TicketModal({
  customer,
  onSubmit,
  onCancel
}) {
  return (
    <BaseDrawer
      title={'创建 SOP'}
      onClose={onCancel}
    >
      <div className="modal-ticket-create-update">
        <TicketForm
          customer={customer}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </div>
    </BaseDrawer>
  );
}

function TicketForm({
  customer,
  onSubmit,
  onCancel
}) {
  function handleSubmit(result) {
    const ticket = {
      ...result,
      customerId: customer.id,
      status: 'NOT_STARTED'
    }
    onSubmit(ticket);
  }

  const users = useSelector(UsersDomain.selectors.data);
  const me = useSelector(MeDomain.selectors.data);


  const initialData = {
    assigneeId: me.id,
    priority: 'P2',
    severity: 'S2',
  };

  const [form] = Form.useForm();

  const [message, setMessage] = useState(initialData.message);

  const assigneeIdField = (
    <div>
      <Form.Item
        name="assigneeId"
        label="处理人"
        rules={[
          { required: true, message: '请选择处理人' }
        ]}
      >
        <Select>
          {users.map((user) => {
            return <Option
              key={user.id}
              value={user.id}
            >
              {user.name}
            </Option>
          })}
        </Select>
      </Form.Item>
    </div>
  );

  const priorityField = (
    <div>
      <Form.Item
        name="priority"
        label="优先级"
        rules={[
          { required: true, message: '请选择优先级' }
        ]}
      >
        <Select>
          <Option key='P0' value='P0'>P0</Option>
          <Option key='P1' value='P1'>P1</Option>
          <Option key='P2' value='P1'>P2</Option>
          <Option key='P3' value='P1'>P3</Option>
        </Select>
      </Form.Item>
    </div>
  );

  const severityField = (
    <div>
      <Form.Item
        name="severity"
        label="严重性"
        rules={[
          { required: true, message: '请选择严重性' }
        ]}
      >
        <Select>
          <Option key='S0' value='S0'>S0</Option>
          <Option key='S1' value='S1'>S1</Option>
          <Option key='S2' value='S2'>S2</Option>
          <Option key='S3' value='S3'>S3</Option>
        </Select>
      </Form.Item>
    </div>
  );

  const titleField = (
    <div>
      <Form.Item
        name="title"
        label="标题"
        rules={[
          { required: true, message: '请输入 SOP 标题' }
        ]}
      >
        <Input placeholder="SOP 标题" />
      </Form.Item>
    </div>
  );

  const messageField = (
    <div>
      <Form.Item
        name="message"
        label="正文"
        rules={[
          { required: true, message: '请输入 SOP 信息' }
        ]}
      >
        <TextArea
          rows={4}
          placeholder="SOP 信息"
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
      </Form.Item>
    </div>
  );

  return (
    <div className="ticket-form">
      <Form
        name="Ticket"
        initialValues={initialData}
        onFinish={handleSubmit}
        form={form}
      >
        <div className="sub-title">1. SOP 设置</div>
        {assigneeIdField}
        {priorityField}
        {severityField}
        <div className="divider"></div>
        <div className="sub-title">2. SOP 内容</div>
        {titleField}
        {messageField}
        <div className="submit">
          <Form.Item noStyle>
            <Button
              type="primary"
              htmlType="submit"
            >
              确认
            </Button>
          </Form.Item>
          <Button onClick={onCancel}>取消</Button>
        </div>
      </Form>
    </div>
  );
}

export default TicketModal;