
import React from 'react';
import {
  Button
} from 'antd';

import BaseModal from 'modals/base-modal';

function CustomerUserDeleteModal({
  customerUser,
  onCancel,
  onSubmit
}) {
  const customerUserName = customerUser ? customerUser.name : ''
  return (
    <BaseModal
      title={`确定删除${customerUserName}`}
      onCancelOverride={onCancel}
    >
      <div className='submit'>
        <Button type="primary" danger onClick={onSubmit}>
          确定
        </Button>
        <Button onClick={onCancel}>
          取消
        </Button>
      </div>
    </BaseModal>
  );
}

export default CustomerUserDeleteModal;
