
import React from 'react';
import { Link } from 'react-router-dom';

import './index.scss'

function UserActivityCard({
  activity
}) {
  const activityTime = activity.activityTime.replace('T', ' ').slice(0, 19);

  const {
    type, title, content,
    userId, userName,
    customerId, customerName
  } = activity;

  return (
    <div className={`component-user-activity-card type-${type}`}>
      <div>
        <div className="type">类型：{type}</div>
        <div>时间：{activityTime}</div>
        <div className="link">
          <span>员工：</span>
          <Link to={idToLink(userId)}>{userName}</Link>
        </div>
        <div>
          <span>客户：</span>
          <Link to={`/customers-management/${customerId}/summary`}>{customerName}</Link>
        </div>
      </div>
      <div>
        <div className="title">
          <div>标题</div><div>{title}</div>
        </div>
        <div className="content">
          <div>内容</div><div>{content}</div>
        </div>
      </div>
    </div>
  );
}

function idToLink(id) {
  return `/employees/${id}`;
}

export default UserActivityCard;
