
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { Tabs } from 'antd';

import MeDomain from 'domains/me';
import Summary from './summary';
import WorkLog from './work-log';

import './index.scss';

function Cockpit({ match }) {
  function handleChangeTab(key) {
    const redirectUrl = `/cockpit/${key}`
    history.push(redirectUrl);
    setActiveTab(key);
  }

  const { tab } = match.params;

  const history = useHistory();

  const user = useSelector(MeDomain.selectors.data);
  const [activeTab, setActiveTab] = useState(tab);

  useEffect(() => {
    document.title = '云策智慧 - 驾驶舱';
  }, []);

  return (
    <div className="page-cockpit">
      <Tabs activeKey={activeTab} onChange={handleChangeTab}>
        <Tabs.TabPane key="summary" tab="总结">
          <Summary />
        </Tabs.TabPane>
        <Tabs.TabPane key="user-activities" tab="工作记录">
          <WorkLog user={user} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default withRouter(Cockpit);
