
import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button } from 'antd';

import './roles-table.scss';

function RolesTable({
  roles,
  onOpenRolesDeleteModal
}) {
  const columns = [
    {
      title: '角色',
      dataIndex: 'name',
      width: '10%',
      render(text, record) {
        return (
          <Link to={`/permissions/${record.id}`}>{record.name}</Link>
        )
      }
    },
    {
      title: '描述',
      dataIndex: 'description'
    },
    {
      width: '25%',
      render: (text, record) => {
        return (
          <div className="actions">
            <Button
              danger
              onClick={() => onOpenRolesDeleteModal(record)}
            >
              删除
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="roles-table">
      <div className="title">角色列表</div>
      <Table
        dataSource={roles}
        rowKey="id"
        columns={columns}
        bordered
        pagination={{ position: ['bottomLeft'] }}
      />
    </div>
  );
}

export default RolesTable;
