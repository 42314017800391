
import API from 'adapters/api';

/**
 * state structure:
 * [
 *   {
 *     referenceTable: <String>,
 *     referenceId: <Integer>
 *   }
 * ]
 */

const UserPins = {
  selectors: {},
  actions: {}
};

UserPins.selectors.pinnedItems = (collection) => {
  return (state) => {
    if (!collection) return state.userPins;
    return state.userPins.filter((pin) => pin.referenceTable === collection);
  };
};

UserPins.actions.create = (userPin, success) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `user-pins`,
        action: 'create',
        data: userPin
      });

      if (status < 300) {
        dispatch({ 
          type: 'userPinsAdd',
          data: { ...data.userPin, ...userPin }
        });

        success && success();
      }

      return { status, data };
    }
  };
};

UserPins.actions.delete = (userPin, success) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `user-pins`,
        action: 'delete',
        data: { id: userPin.id }
      });

      if (status < 300) {
        dispatch({ 
          type: 'userPinsRemove',
          id: userPin.id
        });

        success && success();
      }

      return { status, data };
    }
  };
};

const reducers = {
  userPins(state, action) {
    if (state === undefined) return [];

    if (action.type === 'userPinsAdd') {
      return [
        ...state,
        action.data
      ];
    } else if (action.type === 'userPinsRemove') {
      return state.filter((userPin) => userPin.id !== action.id);
    }

    return state;
  }
}

export { reducers };
export default UserPins;
