
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HealthDomain from 'domains/health';
import DistributionTrendChart from 'components/distribution-trend-chart';
import BreakdownChart from 'components/breakdown-chart';

import { healthGood, healthMedium, healthBad }  from 'theme/_color.scss';
import './cell.scss';

function Cell({
  category,
  journeyStage
}) {
  const dispatch = useDispatch();

  const models = useSelector(HealthDomain.selectors.models);
  // hack
  const model = models[`${category}-${journeyStage}`];

  const [avgScoresMap, setAvgScoresMap] = useState([]);
  const [distributionTrend, setDistributionTrend] = useState([]);

  const categoryScores = model.children.map((child) => {
    const matched = avgScoresMap.find((row) => row.name === child.name);
    const score = matched ? matched.score : 0;
    return {
      name: child.name,
      score,
      entity: 'self'
    };
  });

  useEffect(() => {
    const conditions = { category, journeyStage };

    dispatch(HealthDomain.actions.queryDistributionTrend((data) => {
      setDistributionTrend(data.distributionTrend);
    }, conditions));

    dispatch(HealthDomain.actions.healthAvgScoresMap(conditions, (scoresMap) => {
      setAvgScoresMap(scoresMap);
    }));
  }, []);

  return (
    <div className="cell">
      <div className="radar">
        <BreakdownChart
          score={category === 'MM' && journeyStage === '维护客户' ? 50 : 30}
          categoryScores={categoryScores}
        />
      </div>
      <div className="distribution-trend">
        <DistributionTrendChart
          data={distributionTrend}
          valueName="count"
          colorMapper={colorMapper}
          legend={false}
        />
      </div>
    </div>
  );
}

function colorMapper(health) {
  const alpha = 'FF';
  if (health === '良好') return healthGood + alpha;
  if (health === '中等') return healthMedium + alpha;
  return healthBad + alpha;
}

export default Cell;
