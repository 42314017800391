
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';
import classnames from 'classnames';

import CustomerMetricsDomain from 'domains/customer-metrics';
import MetricPanel from 'components/metric-panel';
import ListItemActions from 'components/list-item-actions';
import ManualMetricModal from '../metrics/manual-metric-modal';

import './index.scss';

// TODO: refactor to avoid duplication
function Metrics({
  customer
}) {
  const dispatch = useDispatch();

  const metrics = useSelector(CustomerMetricsDomain.selectors.perCustomer)[customer.id];
  const metricDefinitions = useSelector(CustomerMetricsDomain.selectors.definitions);

  const pinnedDefinitions = (metricDefinitions || []).filter((definition) => definition.pinned);

  useEffect(() => {
    dispatch(CustomerMetricsDomain.actions.readPerCustomer(customer.id));
  }, []);

  function renderMetric(metricDefinition) {
    const matchedMetric = metrics.find(metric => metric.definitionId === metricDefinition.id);

    return (
      <MetricPanelContainer
        key={metricDefinition.id}
        customer={customer}
        definition={metricDefinition}
        metric={matchedMetric}
      />
    );
  }

  return (
    <div className="metrics">
      {!metrics && <div>-- 加载中 --</div>}
      {
        metrics &&
        <>
          <Row gutter={[10, 10]}>
            {pinnedDefinitions.map(renderMetric)}
          </Row>
        </>
      }
    </div>
  );
}

function MetricPanelContainer({
  customer, definition, metric
}) {
  function handleShowModal() {
    setShowModal(true);
  }

  function handleHideModal() {
    setShowModal(false);
  }

  function handleSubmit(v) {
    dispatch(CustomerMetricsDomain.actions.recordManualMetric({
      metric: v,
      success: handleHideModal
    }));
  }

  let value = undefined;
  if (metric) {
    value = metric.value;
  }

  let trend = value ? [{
    timestamp: (new Date()).toISOString().substring(0, 10),
    value
  }] : [];
  if (metric && metric.timeSeries.length > 0) {
    trend = metric.timeSeries.map((datum) => {
      return {
        ...datum,
        value: parseFloat(datum.value)
      };
    });
  }

  const { name, goal } = definition;

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  let actions = null;
  if (definition.type === 'MANUAL') {
    actions = (
      <ListItemActions
        actions={[
          { name: '手动记录', onClick: handleShowModal }
        ]}
      />
    )
  }

  const className = classnames(
    'definition'
  );

  return (
    <Col span={6}>
      <div className={className}>
        <MetricPanel
          title={name}
          text={value}
          actions={actions}
          trend={trend}
          annotation={goal}
        />
        {
          showModal &&
          <ManualMetricModal
            customerId={customer.id}
            definition={definition}
            onCancel={handleHideModal}
            onSubmit={handleSubmit}
          />
        }
      </div>
    </Col>
  )
}

export default Metrics;
