
import React from 'react';
import {
  Button
} from 'antd';

import BaseModal from 'modals/base-modal';

function NotesDelete({
  note,
  handleDelete,
  onCancel
}) {
  return (
    <BaseModal
      title={`确定删除于${note.createdAt.substring(0, 19).replace('T', ' ')}创建的笔记？`}
      onCancelOverride={onCancel}
    >
      <div className='submit'>
        <Button
          type="primary"
          danger
          onClick={() => handleDelete(note)}
        >
          提交
        </Button>
        <Button onClick={onCancel}>取消</Button>
      </div>
    </BaseModal>
  );
}

export default NotesDelete;
