
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button
} from 'antd';

import UsersDomain from 'domains/users';
import UsersTable from './users-table';

import './index.scss';

class Employees extends React.Component {
  static propTypes = {
    users: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
    usersRoles: PropTypes.array.isRequired,
    onOpenUsersCreateModal: PropTypes.func.isRequired,
    onUsersUpdate: PropTypes.func.isRequired,
    onOpenUsersDeleteModal: PropTypes.func.isRequired
  };

  componentDidMount() {
    document.title = '云策智慧 - 员工管理';
  }

  render() {
    const {
      users,
      roles,
      usersRoles,
      onOpenUsersCreateModal,
      onUsersUpdate,
      onOpenUsersDeleteModal
    } = this.props;

    return (
      <div className="page-employees">
        <Button
          type="primary"
          onClick={onOpenUsersCreateModal}
        >
          创建员工
        </Button>
        <UsersTable
          users={users}
          roles={roles}
          usersRoles={usersRoles}
          onUsersUpdate={onUsersUpdate}
          onOpenUsersDeleteModal={onOpenUsersDeleteModal}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users,
    roles: state.roles,
    usersRoles: state.usersRoles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onOpenUsersCreateModal() {
      dispatch({
        type: 'modal',
        name: 'UsersCreate'
      });
    },
    onUsersUpdate(user) {
      dispatch({
        type: 'api',
        request: () => UsersDomain.update(user),
        success(data) {
          dispatch({
            type: 'usersUpdate',
            user: data.user
          });
        }
      });
    },
    onOpenUsersDeleteModal(user) {
      dispatch({
        type: 'modal',
        name: 'UsersDelete',
        context: { user }
      })
    }
  };
}

const Connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Employees);

export default Connected;
