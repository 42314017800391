
import React, { useState, useEffect, useRef } from 'react';
import G6 from '@antv/g6';

import {
  colorPrimary
} from 'theme/_color.scss';

const nodeHeight = 50;
const nodeWidth = 150;

function HealthModelGraph({
  selectedNodeId,
  setSelectedNodeId,
  model,
  mode,
  dirtyCount,
  setDirtyCount
}) {
  function createChart(container, model, selectedNodeId, setSelectedNodeId) {
    const graph = new G6.TreeGraph({
      container,
      fitView: true,
      layout: {
        type: 'mindmap',
        direction: 'H',
        getHeight() {
          return nodeHeight;
        },
        getWidth() {
          return nodeWidth;
        },
        getHGap() {
          return nodeWidth * 0.2;
        },
        getVGap() {
          return nodeHeight * 0.3;
        },
        getSide() {
          return 'right';
        }
      },
      defaultNode: {
        type: 'rect',
        size: [nodeWidth, nodeHeight],
        style: {
          stroke: '#CED4D9',
          lineWidth: 1,
          fill: '#FFFFFF',
          shadowBlur: 15
        }
      },
      defaultEdge: {
        type: 'polyline',
        size: 1,
        color: '#CED4D9',
        style: {
          radius: 1
        }
      },
      nodeStateStyles: {
        selected: {
          stroke: colorPrimary,
          shadowBlur: 15,
          fill: 'none',
          lineWidth: 1
        }
      },
      renderer: 'svg',
      animate: false
    });

    graph.node((node) => {
      return {
        label: node.id
      };
    });
  
    graph.data(model);

    graph.node(function(node) {
      return {
        label: `${node.name} ${node.edited ? '*' : ''}`
      };
    });

    graph.on('node:click', (event) => {
      const node = event.item;
      const { id } = node._cfg;

      const selected = graph.findAllByState('node', 'selected')[0];
      if (selected && selected._cfg.id === id) return;

      const dirtyCount = graph.get('dirtyCount');

      let confirmation = true;
      if (dirtyCount > 0) {
        confirmation = window.confirm('当前节点还在编辑中，是否放弃编辑内容？');
      }
      if (confirmation) {
        graph.set('dirtyCount', 0);
        setDirtyCount(0);
        setSelectedNodeId(id);
      }
    });

    const selectedNode = graph.findById(selectedNodeId);
    if (selectedNode) {
      graph.setItemState(selectedNode, 'selected', true);
    }

    graph.render();

    return graph;
  }

  const [graph, setGraph] = useState(null);

  const containerRef = useRef(null);

  useEffect(() => {
    let prevDirtyCount;
    if (graph) {
      prevDirtyCount = graph.get('dirtyCount');
    }
    containerRef.current.innerHTML = '';
    const newGraph = createChart(containerRef.current, model, selectedNodeId, setSelectedNodeId);
    if (prevDirtyCount !== undefined) {
      newGraph.set('prevDirtyCount', prevDirtyCount);
    }

    const selectedNode = newGraph.findById(selectedNodeId);
    if (selectedNode) {
      newGraph.setItemState(selectedNode, 'selected', true);
    }
    setGraph(newGraph);
  }, [model]);

  useEffect(() => {
    if (!graph) return;

    const nodes = graph.findAllByState('node', 'selected')
    nodes.forEach((node) => {
      graph.setItemState(node, 'selected', false);
    });

    const selectedNode = graph.findById(selectedNodeId);
    if (selectedNode) {
      graph.setItemState(selectedNode, 'selected', true);
    }
  }, [selectedNodeId]);

  useEffect(() => {
    if (!graph) return;
    graph.set('dirtyCount', dirtyCount || 0);
  }, [dirtyCount])

  return (
    <div style={{ width: '100%', height: '100%' }} ref={containerRef}></div>
  );
}

export default HealthModelGraph;
