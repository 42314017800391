
import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  Checkbox,
  Button
} from 'antd';

import './app-permissions.scss';

class AppPermissions extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    pages: PropTypes.array.isRequired
  };

  state = {
    enabled: false
  };

  handleSwitch = (status) => {
    this.setState({ enabled: status });
  };

  render() {
    const { app, pages } = this.props;
    const { enabled } = this.state;

    return (
      <div className="app-permissions">
        <div className="title-row">
          <Switch
            checked={enabled}
            onChange={this.handleSwitch}
          />
          <div className="title">{app.title}</div>
        </div>
        {enabled && <div className="divider"></div>}
        {enabled &&
          <div className="pages">
            页面
            <div className="pages-list">
              {pages.map((page) => {
                return (
                  <div key={page.key} className="page-toggle">
                    <Checkbox />
                    {page.title}
                  </div>
                );
              })}
            </div>
            <Button type="primary">提交</Button>
            <Button>重置</Button>
          </div>
        }
      </div>
    );
  }
}

export default AppPermissions;
