
import React from 'react';
import {
  Alert
} from 'antd';

import DashboardHeader from 'components/dashboard-header';
import FutureRenewals from './future-renewals';
import Summary from './summary';
import CurrentMonthRenewalList from './current-month-renewal-list';

function Retention() {
  return (
    <div className="retention">
      <Alert
        message="本页面包括未完成功能，以及测试数据！"
        type="error"
      />
      <div style={{ height: 15 }}></div>
      <DashboardHeader
        title="续费管理"
      />
      <FutureRenewals />
      <Summary />
      <CurrentMonthRenewalList />
    </div>
  );
}

export default Retention;
