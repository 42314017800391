
import React from 'react';
import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import {
  HomeOutlined
} from '@ant-design/icons';

import './index.scss';

function Breadcrumb({
  navArray
}) {
  return (
    <div className="component-breadcrumb">
      <AntdBreadcrumb>
        <AntdBreadcrumb.Item>
          <HomeOutlined />
        </AntdBreadcrumb.Item>
        {renderBreadcrumbItems(navArray)}
      </AntdBreadcrumb>
    </div>
  );
}

function renderBreadcrumbItems(navArray) {
  return navArray.map((navItem, i) => {
    return (
      <AntdBreadcrumb.Item key={i}>
        {
          navItem.link ?
          (
            <Link to={navItem.link}>
              {navItem.content}
            </Link>
          ) :
          navItem.content
        }
      </AntdBreadcrumb.Item>
    )
  });
}

export default Breadcrumb;
