
import React from 'react';
import classnames from 'classnames';

import './object-selection.scss';

function ObjectSelection({
  objectsMetadata,
  selectedObjectId,
  setSelectedObjectId
}) {
  return (
    <div className="object-selection">
      {objectsMetadata.map((objectMetadata) => {
        const { id, title } = objectMetadata;
        const selected = selectedObjectId === id;
        const className = classnames('object-row', { selected });
        return (
          <div
            key={id}
            className={className}
            onClick={() => setSelectedObjectId(id)}
          >
            {title}
          </div>
        );
      })}
    </div>
  );
}

export default ObjectSelection;
