
import React from 'react';
import { Popover } from 'antd';

import './index.scss';

function BulletChart({
  goal // { measure, target, range }
}) {
  const { range, measure, target } = goal;
  const scale = 100 / range;
  const measureWidth = scale * measure;
  const targetDistance = scale * target;

  function tooltipItem(name, value) {
    return (
      <div className='tool-tip-item'>
        <div className='tool-tip-dot'> &#8226; </div>
        <div className='tool-tip-value'>
          <div>{name}</div>
          <div>{value}</div>
        </div>
      </div>
    )
  }

  return (
    <Popover 
      placement="topRight"
      mouseEnterDelay={0.1}
      content={
        (
          <div>
            {tooltipItem("已完成", measure)}
            {tooltipItem("目标", target)}
          </div>
        )
      }
    >
      <div className="component-bullet-chart">
        <div className="bar">
          <div className="measure" style={{ width: `${measureWidth}%`}}></div>
          <div className="target" style={{ left: `${targetDistance}%` }}></div>
          <div className="label" style={{ left: '110%' }}></div>
          <div className="label" style={{ left: '110%' }}>
            <span>目标：{target}</span>
          </div>
        </div>
      </div>
    </Popover>
  );
}

export default BulletChart;
