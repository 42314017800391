
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Panel from 'components/panel';
import CustomerListsDomain from 'domains/customer-lists';
import UserPinsDomain from 'domains/user-pins';

import './pinned-lists-panel.scss';

function PinnedListsPanel() {
  const lists = useSelector(CustomerListsDomain.selectors.data);
  const systemLists = lists.filter((list) => list.ownerId === null);
  const pinnedListIds = useSelector(UserPinsDomain.selectors.pinnedItems('customerLists'))
    .map((pin) => pin.referenceId);
  const pinnedLists = lists.filter((list) => pinnedListIds.includes(list.id));

  const allLists = [...systemLists, ...pinnedLists];

  return (
    <Panel>
      <div className="lists-header">
        <div>名称</div>
        <div>类型</div>
        <div>客户数量</div>
        <div>平均 ARR</div>
        <div>平均总付款</div>
        <div className="nps-distribution">NPS</div>
        <div className="health-distribution">健康分布</div>
        <div></div>
      </div>
      <div style={{ height: 0, borderBottom: '1px solid #E0E0E0' }}></div>
      <div className="lists-content">
        {
          allLists.map((list) => {
            const { id, name, type, stats } = list;

            const {
              customerCount,
              avgArr, avgTotalPayment,
              avgNps, npsDistribution, avgHealth, healthDistribution
            } = stats;
            let { good, medium, bad, noData } = healthDistribution;
            if (
              !good &&
              !medium &&
              !bad
            ) noData = 100;

            if (
              !npsDistribution.good &&
              !npsDistribution.medium &&
              !npsDistribution.bad
            ) npsDistribution.noData = 100;

            const npsMeaning = npsToMeaning(avgNps);
            const avgNpsToDisplay = avgNps === null ?
              '无数据' :
              `${avgNps.toFixed(0)}`;
            const health = scoreToHealth(avgHealth);
            const avgHealthToDisplay = avgHealth === null ?
              '无数据' :
              `平均 ${avgHealth.toFixed(0)}`;

            return (
              <div className="list" key={id}>
                <div><Link to={`/customers-management?customerListId=${id}`}>{name}</Link></div>
                <div>{{ LIVE: '筛选条件', STATIC: '静态列表' }[type]}</div>
                <div>{customerCount}</div>
                <div className={avgArr === undefined ? 'noData' : ''}>
                  {avgArr === undefined ? '无数据' : Math.round(avgArr)}
                </div>
                <div className={avgTotalPayment === undefined ? 'noData' : ''}>
                  {avgTotalPayment === undefined ? '无数据' : Math.round(avgTotalPayment)}</div>
                <div className="nps-distribution">
                  <div className={`score ${npsMeaning}`}>
                    {avgNpsToDisplay}
                  </div>
                  <div className="bar">
                    {
                      !!npsDistribution.good &&
                      <div className="good" style={{ width: npsDistribution.good / 2 * 3 }}></div>
                    }
                    {
                      !!npsDistribution.medium &&
                      <div className="medium" style={{ width: npsDistribution.medium / 2 * 3 }}></div>
                    }
                    {
                      !!npsDistribution.bad &&
                      <div className="bad" style={{ width: npsDistribution.bad / 2 * 3 }}></div>
                    }
                    {
                      !!npsDistribution.noData &&
                      <div className="no-data" style={{ width: npsDistribution.noData / 2 * 3 }}></div>
                    }
                  </div>
                </div>
                <div className="health-distribution">
                  <div className={`score ${health}`}>
                    {avgHealthToDisplay}
                  </div>
                  <div className="bar">
                    {
                      !!good &&
                      <div className="good" style={{ width: good / 2 * 3 }}></div>
                    }
                    {
                      !!medium &&
                      <div className="medium" style={{ width: medium / 2 * 3 }}></div>
                    }
                    {
                      !!bad &&
                      <div className="bad" style={{ width: bad / 2 * 3 }}></div>
                    }
                    {
                      !!noData &&
                      <div className="no-data" style={{ width: noData / 2 * 3 }}></div>
                    }
                  </div>
                </div>
                <div></div>
              </div>
            );
          })
        }
      </div>
    </Panel>
  );
}

function npsToMeaning(nps) {
  if (nps === null) return 'noData';
  if (nps >= 30) return 'good';
  if (nps >= 0) return 'medium';
  return 'bad';
}

function scoreToHealth(score) {
  if (score === null) return 'noData';
  if (score >= 80) return 'good';
  if (score >= 50) return 'medium';
  return 'bad';
}

export default PinnedListsPanel;
