
// TODO:
import API from 'adapters/api';
// import { url } from 'adapters/apps';

const Init = {};

Init.bootstrap = async () => {
  const {
    status,
    data
  } = await API.request({
    resource: 'bootstrap/cdp',
    action: 'readList'
  });

  if (status === 401) {
    return { redirect: true };
  }

  if (status >= 400) return { error: true };

  delete data.message;

  const { healthModels, ...rest } = data;

  rest.health = {
    models: {},
    perCustomer: {}
  };
  healthModels.forEach((model) => {
    rest.health.models[`${model.category}-${model.journeyStage}`] = model.model;
  });

  return { initialState: rest };
};

export default Init;
