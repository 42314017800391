
import React, { useState, useEffect, useRef } from 'react';
import G6 from '@antv/g6';

import {
  colorPrimary
} from 'theme/_color.scss';

function JourneyMap({
  nodes, edges, onSelectNode, selectedNodeId
}) {
  function createChart(container, nodes, edges, selectedNodeId) {
    const graph = new G6.Graph({
      container,
      fitView: true,
      layout: {
        type: 'dagre',
        rankdir: 'LR',
        nodeSep: 30,
        rankSep: 20,
      },
      defaultEdge: {
        style: {
          endArrow: {
            path: 'M 0,0 L -8,4 L -8,-4 Z'
          }
        }
      },
      nodeStateStyles: {
        selected: {
          stroke: colorPrimary,
          shadowBlur: 10,
          fill: 'none',
          lineWidth: 1
        }
      },
      renderer: 'svg'
    });

    graph.data({ nodes, edges });

    graph.render();

    graph.on('afterrender', () => {
      const selectedNode = graph.findById(selectedNodeId);
      graph.setItemState(selectedNode, 'selected', true);
    });

    graph.on('node:click', (event) => {
      const node = event.item;
      const { id } = node._cfg;

      if (id !== 'start' && id !== 'end') {
        const { id } = node._cfg;
        onSelectNode(id);
      }
    });

    setGraph(graph);
  }

  const [graph, setGraph] = useState(null);

  const containerRef = useRef(null);

  const processedNodes = nodes.map((node) => {
    if (node.type === 'end') {
      return {
        ...node,
        type: 'circle',
        size: 20,
        label: '',
        style: {
          fill: '#ffe7ba',
          stroke: '#ffa940'
        }
      };
    } else {
      return {
        ...node,
        type: 'rect',
        size: [100, 40],
        style: {
          stroke: '#CED4D9',
          fill: '#ffffff',
          shadowBlur: 10
        }
      }
    }
  });

  useEffect(() => {
    createChart(containerRef.current, processedNodes, edges, selectedNodeId);
  }, []);

  useEffect(() => {
    if (!graph) return;

    const nodes = graph.findAllByState('node', 'selected')
    nodes.forEach((node) => {
      graph.setItemState(node, 'selected', false);
    });

    const selectedNode = graph.findById(selectedNodeId);
    graph.setItemState(selectedNode, 'selected', true);
  }, [selectedNodeId]);

  return <div style={{ height: '100%' }} ref={containerRef}></div>;
}

export default JourneyMap;
