
import React from 'react';
import {
  Button
} from 'antd';

import BaseModal from 'modals/base-modal';

import './deletion-modal.scss';

function DeletionModal({
  notification,
  onCancel,
  onSubmit
}) {
  return (
    <BaseModal
      title={`确定删除消息？`}
      onCancelOverride={onCancel}
    >
      <div className="modal-notifications-deletion">
        <div className="title">
          <div>{notification.title}</div>
        </div>
        <div className="message">
          <div>{notification.message}</div>
        </div>
        <div></div>
        <div className="submit">
          <Button type="primary" onClick={onSubmit}>
            确定
          </Button>
          <Button onClick={onCancel}>
            取消
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}

export default DeletionModal;
