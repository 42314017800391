
import React from 'react';

import {
  Timeline
} from 'antd';

import './index.scss';

function EventsLog({
  events
}) {
  if (!events || events.length === 0) {
    return <div className="component-events-log"></div>;
  }

  const sortedEvents = events.sort(
    (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
  );

  const timelineItems = [];
  let lastDate;
  sortedEvents.forEach((event, i) => {
    const { subType, timestamp, customerName, customerUserName } = event;
    const eventDate = timestamp.substring(0, 10);
    const eventTime = timestamp.substring(11, 19);

    const eventMessage = eventToMessage(event);
    let color;
    if (subType === 'feedback' && event.info.type === 'nps') {
      color = eventTypeToColor['nps']
    } else {
      color = eventTypeToColor[subType];
    }

    const subTypeText = {
      behavior: '行为',
      feedback: '反馈',
      lifecycle: '业务',
      outcome: '结果'
    }[subType];

    let label;
    if (eventDate !== lastDate) {
      label = eventDate;
      lastDate = eventDate;
    }

    timelineItems.push(
      <Timeline.Item label={label} color={color} key={i}>
        <div className="event-row">
          <div>{eventTime}</div>
          <div>{customerName}</div>
          <div>{customerUserName}</div>
          <div style={{ backgroundColor: color }}>{subTypeText}</div>
          <div>{eventMessage}</div>
        </div>
      </Timeline.Item>
    );
  });

  const content = (
    <div>
      {
        timelineItems.length > 0 &&
        <div className="timeline">
          <div className="header">
            <div>日期</div>
            <div></div>
            <div>时间</div>
            <div>客户</div>
            <div>用户</div>
            <div>类型</div>
            <div>事件</div>
          </div>
          <Timeline mode="left">
            {timelineItems}
          </Timeline>
        </div>
      }
      {
        timelineItems.length === 0 &&
        <div className="no-data">-- 暂无数据 --</div>
      }
    </div>
  );

  return (
    <div className="component-events-log">
      {content}
    </div>
  );
}

const eventTypeToColor = {
  lifecycle: '#f5222d',
  behavior: '#006d75',
  feedback: '#eb2f96',
  customer: '#707070',
  nps: '#08979c',
  outcome: 'darkgreen'
};

function eventToMessage(event) {
  if (event.subType === 'feedback' && event.info.type !== 'nps') {
    return feedbackEventToMessage(event);
  } else if (event.subType === 'feedback' && event.info.type === 'nps') {
    return npsEventToMessage(event);
  } else if (event.subType === 'lifecycle') {
    return lifeCycleEventToMessage(event);
  } else if (event.subType === 'behavior') {
    return behaviorEventToMessage(event);
  } else if (event.subType === 'customer') {
    const { id, clientId, ...rest } = event.info.customer;
    return `用户 ${event.info.authorId} 改客户数据为 ${JSON.stringify(rest)}`;
  } else {
    return JSON.stringify(event.info);
  }
}

function lifeCycleEventToMessage(event) {
  const { name, info } = event;
  const { type } = info;
  
  if (type === 'registration') {
    return name;
  } else if (type === 'subscription') {
    const invoiceMessage = info.orderId ? `订单 ID ${info.orderId}` : '无订单 ID'
    return `${name} - ${info.planId}，${invoiceMessage}`;
  } else if (type === 'adHoc') {
    const invoiceMessage = info.orderId ? `订单 ID ${info.orderId}` : '无订单 ID'
    return `${name} - ${info.productId}，${invoiceMessage}`;
  } else if (type === 'renewal') {
    const invoiceMessage = info.orderId ? `订单 ID ${info.orderId}` : '无订单 ID'
    return `${name} - ${info.planId}，${invoiceMessage}`;
  } else if (type === 'upgrade') {
    return `${name} - ${info.newPlanId}，旧订阅 ${info.oldPlanId}`;
  } else if (type === 'downgrade') {
    return `${name} - ${info.newPlanId}，旧订阅 ${info.oldPlanId}`;
  } else if (type === 'changePlan') {
    return `${name} - ${info.newPlanId}，旧订阅 ${info.oldPlanId}`;
  } else if (type === 'churn') {
    return `${name} - ${info.planId}`;
  } else if (type === 'recall') {
    const invoiceMessage = info.orderId ? `订单 ID ${info.orderId}` : '无订单 ID'
    return `${name} - ${info.planId}，${invoiceMessage}`;
  } else if (type === 'enableAutoRenewal') {
    return name;
  } else if (type === 'disableAutoRenewal') {
    return name;
  } else if (type === 'suspendSubscription') {
    return `${name} - ${info.suspensionStartDate}，结束时间 ${info.suspensionEndDate}`;
  } else {
    return name;
  }
}

function behaviorEventToMessage(event) {
  return event.info.name;
}

function feedbackEventToMessage(event) {
  const { name, info } = event;
  const { type } = info;
   
  if (type === 'complaint') {
    return `${name} - ${info.content}`;
  } else if (type === 'generalFeedback') {
    return `${name} - ${info.content}`;
  } else if (type === 'createSurveyEntry') {
    return `${name} - ${info.surveyId}`;
  } else if (type === 'submitSurveyEntry') {
    return `${name} - 问题 ${info.surveyId}，回复 ${info.answer}`;
  } else if (type === 'suggestion') {
    return `${name} - ${info.content}`;
  } else {
    return `${name} - ${info.content}`;
  }
}

function npsEventToMessage(event) {
  return `${event.name} - ${event.info.response}`;
}

export default EventsLog;
