
import React, { useState } from 'react';
import { Avatar, Dropdown, Badge } from 'antd';
import { Link } from 'react-router-dom';
import {
  HomeOutlined,
  DashboardOutlined,
  ContactsOutlined,
  MessageOutlined,
  AlertOutlined,
  SettingOutlined,
  SoundOutlined,
  LogoutOutlined,
  ProfileOutlined,
  AimOutlined,
  FireOutlined,
  DatabaseOutlined,
  RobotOutlined,
  HeartOutlined,
  InteractionOutlined,
  BulbOutlined,
  CommentOutlined,
  TeamOutlined,
  MonitorOutlined
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  Divider, Card
} from '@mui/material';

import AuthDomain from 'domains/auth';
import MeDomain from 'domains/me';
import NotificationsDomain from 'domains/notifications';
import NavMenu from 'components/nav-menu';
import FeedbackDrawer from './feedback-drawer';

import './nav.scss';

const leftMenuItemsData = [
  { name: '驾驶舱', icon: <HomeOutlined />, url: '/cockpit/summary' },
  {
    name: '运营智慧',
    icon: <BulbOutlined />,
    children: [
      {
        name: '客户监控', icon: <MonitorOutlined />, url: '/customers-monitoring',
        description: '客户行为监控，事件汇总'
      },
      {
        name: '客户指标', icon: <InteractionOutlined />, url: '/customer-metrics/customer-metric-definitions',
        description: '客户指标设置与分析，互动性探索'
      },
      {
        name: '客户健康', icon: <HeartOutlined />, url: '/customers-health/dashboard',
        description: '客户健康模型，真正的掌控客户'
      },
      {
        name: 'AI 助理', icon: <RobotOutlined />, url: '/ai-assistant',
        description: '人工智能数据分析和预测助手'
      }
    ]
  },
  {
    name: '客户 360°',
    icon: <ContactsOutlined />,
    children: [
      {
        name: '客户管理', icon: <DatabaseOutlined />, url: '/customers-management',
        description: '客户静态列表，筛选条件，信息细节'
      },
      {
        name: '客户成功看板', icon: <DashboardOutlined />, url: '/customers-dashboards/summary',
        description: '监测 KPI，深入分析，找到可执行洞察'
      }
    ]
  },
  {
    name: '客户触达',
    icon: <AimOutlined />,
    children: [
      {
        name: '自动报警', icon: <AlertOutlined />, url: '/alerts-management',
        description: '监测异常和指标变化，让运营人放心'
      },
      {
        name: '反馈管理', icon: <SoundOutlined />, url: '/feedback-management',
        description: '客户反馈统计与管理'
      },
      {
        name: 'SOP 管理', icon: <FireOutlined />, url: '/tickets',
        description: '需要人工检查或处理的事件'
      }
    ]
  }
];

function TopNav() {
  function openFeedbackDrawer() {
    setShowFeedbackDrawer(true);
  }

  function handleSubmitFeedback(fields) {
    dispatch(
      MeDomain.actions.submitFeedback(
        fields.message,
        handleCancelSubmitFeedback
      )
    );
  }

  function handleCancelSubmitFeedback() {
    setShowFeedbackDrawer(false);
  }

  const [showFeedbackDrawer, setShowFeedbackDrawer] = useState(false);

  const notificationsUnreadCount = useSelector(NotificationsDomain.selectors.count)['unread'];

  const rightMenuItemsData = [
    {
      icon: (
        <Badge dot={notificationsUnreadCount}><MessageOutlined /></Badge>
      ),
      url: '/notifications'
    },
    {
      icon: (
        <TeamOutlined />
      ),
      url: '/employees'
    },
    { icon: <SettingOutlined />, url: '/setup/operational-metadata-management' }
  ];

  const user = useSelector(MeDomain.selectors.data);
  const dispatch = useDispatch();

  const userMenuOverlay = (
    <Card variant="outlined">
      <div className="nav-dropdown-menu">
        <Link to="/profile">
          <div className="user-menu-item">
            <div className="icon"><ProfileOutlined /></div>
            <div>个人信息</div>
          </div>
        </Link>
        <div style={{ height: 5 }}></div>
        <Divider />
        <div style={{ height: 5 }}></div>
        <div className="user-menu-item" onClick={openFeedbackDrawer}>
          <div className="icon"><CommentOutlined /></div>
          <div>提交反馈</div>
        </div>
        <div style={{ height: 5 }}></div>
        <Divider />
        <div style={{ height: 5 }}></div>
        <div className="user-menu-item" onClick={() => AuthDomain.signOut()}>
          <div className="icon"><LogoutOutlined /></div>
          <div>退出登录</div>
        </div>
      </div>
    </Card>
  );

  return (
    <div className="nav">
      <div className="logo">
        <img src="/logo-transparent-full-dark-no-color.png" alt="logo" />
      </div>
      <NavMenu menuItemsData={leftMenuItemsData} />
      <div style={{ flex: 1 }}></div>
      <NavMenu menuItemsData={rightMenuItemsData} />
      <Dropdown
        overlay={userMenuOverlay}
        trigger={['click']}
      >
        <div className="current-user">
          <Avatar
            alt="头像" shape="circle" size="small"
          >
            {user.name[0]}
          </Avatar>
        </div>
      </Dropdown>
      {
        showFeedbackDrawer &&
        <FeedbackDrawer
          onSubmit={handleSubmitFeedback}
          onCancel={handleCancelSubmitFeedback}
        />
      }
    </div>
  );
}

export default TopNav;
