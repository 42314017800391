
import React from 'react';

import './index.scss';

function DistributionBar({
  data
}) {
  const {
    neutral,
    good,
    medium,
    bad,
    noData
  } = data;

  return (
    <div className="component-distribution-bar">
      {
        !!neutral &&
        <div className="neutral" style={{ flex: neutral }}></div>
      }
      {
        !!good &&
        <div className="good" style={{ flex: good }}></div>
      }
      {
        !!medium &&
        <div className="medium" style={{ flex: medium }}></div>
      }
      {
        !!bad &&
        <div className="bad" style={{ flex: bad }}></div>
      }
      {
        !!noData &&
        <div className="no-data" style={{ flex: noData }}></div>
      }
    </div>
  );
}

export default DistributionBar;
