
import React from 'react';
import { connect } from 'react-redux';
import { Radio } from 'antd';

import DashboardDomain from 'domains/time-series-exploration';
import MetricChart from './metric-chart';

import './index.scss';

function currentPastColorMapper(value) {
  if (value === 'current' || value === '当前') {
    return 'hsl(216, 32%, 40%)';
  } else {
    return '#CACACA';
  }
}

const compareColorMapper = [
  '#4e79a7',
  '#f28e2c',
  '#e15759',
  '#76b7b2',
  '#59a14f',
  '#edc949',
  '#af7aa1',
  '#ff9da7',
  '#9c755f',
  '#bab0ab'
];

function MetricsArea({
  granularity,
  data,
  config,
  compareDimensionName,
  timeFrame,
  onGranularityUpdate
}) {
  let colorMapper;
  if (compareDimensionName) {
    colorMapper = compareColorMapper;
  } else {
    colorMapper = currentPastColorMapper;
  }

  function handleGranularityUpdate(e) {
    onGranularityUpdate(e.target.value);
  }

  return (
    <section className="metrics-area">
      <div className="overall-control">
        <Radio.Group value={granularity} onChange={handleGranularityUpdate}>
          <Radio.Button value="MONTH" disabled={timeFrame === 'CURRENT_MONTH'}>月</Radio.Button>
          <Radio.Button value="DAY">日</Radio.Button>
        </Radio.Group>
      </div>
      <div className="charts">
        {config.metrics.map((metric) => {
          const metricData = data.metrics[metric.name];
          return (
            <MetricChart
              key={metric.name}
              title={metric.title}
              type="line"
              data={metricData}
              colorMapper={colorMapper}
            />
          );
        })}
      </div>
    </section>
  );
}

function mapStateToProps(state) {
  const spec = DashboardDomain.selectors.spec(state);
  const data = DashboardDomain.selectors.data(state);
  const config = DashboardDomain.selectors.config(state);

  return {
    granularity: spec.granularity,
    data,
    config,
    compareDimensionName: DashboardDomain.selectors.comparisonDimensionName(state),
    timeFrame: spec.timeFrame
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onGranularityUpdate(granularity) {
      return dispatch(
        DashboardDomain.actions.granularityUpdate(granularity)
      );
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MetricsArea);
