
import API from 'adapters/api';

/**
 * state structure:
 * {
 *   perCustomer: {
 *     [customerId]: [
 *       {CustomerUser}
 *     ]
 *   }
 * }
 */

const CustomerUsers = {
  selectors: {},
  actions: {}
};

CustomerUsers.selectors.perCustomer = (state) => state.customerUsers.perCustomer;

CustomerUsers.actions.create = (customerUser, success) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `customer-users`,
        action: 'create',
        data: customerUser
      });

      if (status < 300) {
        dispatch({ 
          type: 'customerUsersPerCustomerAdd',
          customerId: customerUser.customerId,
          data: { ...customerUser, ...data.customerUser }
        });

        success && success();
      }

      return { status, data };
    }
  };
};

CustomerUsers.actions.update = (customerUser, success) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `customer-users`,
        action: 'update',
        data: customerUser
      });

      if (status < 300) {
        dispatch({ 
          type: 'customerUsersPerCustomerUpdate',
          customerId: customerUser.customerId,
          data: customerUser
        });

        success && success();
      }

      return { status, data };
    }
  };
};

CustomerUsers.actions.delete = (customerUser, success) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `customer-users`,
        action: 'delete',
        data: customerUser
      });

      if (status < 300) {
        dispatch({ 
          type: 'customerUsersPerCustomerDelete',
          customerId: customerUser.customerId,
          data: customerUser
        });

        success && success();
      }

      return { status, data };
    }
  };
};

CustomerUsers.actions.getList = (customerId) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `customer-users?customerId=${customerId}`,
        action: 'read'
      });

      if (status < 300) {
        dispatch({ 
          type: 'customerUsersPerCustomerRefershData',
          customerId,
          data: data.customerUsers
        });
      }

      return { status, data };
    }
  };
};

const reducers = {
  customerUsers(state, action) {
    if (state === undefined) return {
      perCustomer: {}
    };

    if (action.type === 'customerUsersPerCustomerRefershData') {
      return {
        ...state,
        perCustomer: {
          ...state.perCustomer,
          [action.customerId]: action.data
        }
      };
    } else if (action.type === 'customerUsersPerCustomerAdd') {
      return {
        ...state,
        perCustomer: {
          ...state.perCustomer,
          [action.customerId]: [
            ...state.perCustomer[action.customerId],
            action.data
          ]
        }
      }
    } else if (action.type === 'customerUsersPerCustomerUpdate') {
      const customerUserForCustomer = state.perCustomer[action.customerId].map(user => {
        if (user.id === action.data.id) {
          return action.data;       
        }
        return user;
      });

      return {
        ...state,
        perCustomer: {
          ...state.perCustomer,
          [action.customerId]: customerUserForCustomer
        }
      }
    } else if (action.type === 'customerUsersPerCustomerDelete') {
      return {
        ...state,
        perCustomer: {
          ...state.perCustomer,
          [action.customerId]: state.perCustomer[action.customerId].filter(user => user.id !== action.data.id),
        }
      }
    } 

    return state;
  }
}

export { reducers };
export default CustomerUsers;
