

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Select, Input, InputNumber, Button
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
  MinusCircleOutlined
} from '@ant-design/icons';

import CustomerMetricsDomain from 'domains/customer-metrics';
import MetadataDomain from 'domains/metadata';

import './formula-details.scss';

function FormulaDetails({
  node,
  overallMode,
  onUpdateNode,
  onChangeDirtyCount
}) {
  function handleEdit() {
    if (overallMode === 'READ') return;
    onChangeDirtyCount(1);
    setMode('EDIT');
    setSubmitDisabled(true);
  }

  function handleSubmit() {
    onChangeDirtyCount(-1);
    onUpdateNode(
      node.name,
      { formula: localFormula }
    );
    setMode('READ');
  }

  function handleCancel() {
    setLocalFormula(JSON.parse(JSON.stringify(node.formula)));
    onChangeDirtyCount(-1);
    setMode('READ');
  }

  function getFormulaUpdateHandler(index, part, key) {
    return (value) => {
      setSubmitDisabled(false);

      const newLocalFormula = localFormula.map((conditionOutcome, i) => {
        if (i !== index) return conditionOutcome;

        const defaultOperator = part === 'condition' ? 'EQUAL' : 'PLUS';

        let newPart;
        if (key === 'type') {
          if (value === 'METRIC') {
            newPart = {
              type: 'METRIC',
              definitionId: defaultMetricId,
              operator: defaultOperator,
              value: 0
            };
          } else if (value === 'ATTRIBUTE') {
            newPart = {
              type: 'ATTRIBUTE',
              fieldName: 'nps',
              operator: defaultOperator,
              value: 0
            };
          } else {
            newPart = {
              type: 'FIXED',
              value: 0
            };
          }
        } else {
          let newValue = value;
          if (value === null) {
            newValue = value;
          } else if (typeof value === 'object') {
            newValue = value.target.value;
          }

          newPart = {
            ...conditionOutcome[part],
            [key]: newValue
          };
        }

        return {
          ...conditionOutcome,
          [part]: newPart
        };
      });

      setLocalFormula(newLocalFormula);
    };
  }

  function getFormulaRemoveHandler(index) {
    return () => {
      setSubmitDisabled(false);
      setLocalFormula(localFormula.filter((_, i) => i !== index));
    };
  }

  function handleFormulaAdd() {
    setSubmitDisabled(false);

    const rest = [...localFormula];
    const last = rest.pop();

    const newConditionOutcome = {
      condition: {
        type: 'METRIC',
        definitionId: defaultMetricId,
        operator: 'GREATER',
        value: 0
      },
      outcome: {
        type: 'FIXED',
        value: 0
      }
    };

    setLocalFormula([
      ...rest,
      newConditionOutcome,
      last
    ]);
  }

  const metricDefinitions = useSelector(CustomerMetricsDomain.selectors.definitions);
  const defaultMetricId = metricDefinitions[0].id;
  const fieldsMetadata = useSelector(MetadataDomain.getSelector('fields', 'customers'));

  const fieldsMetadataByCategory = Object.keys(fieldsMetadata).reduce((total, curr) => {
    const fieldMetadata = fieldsMetadata[curr];
    fieldMetadata.fieldName = curr; // put key inside val body

    let { category } = fieldMetadata;
    if (!category) category = '其他';

    if (total[category]) {
      total[category].push(fieldMetadata);
    } else {
      total[category] = [fieldMetadata];
    }
    return total;
  }, {});

  const { formula } = node;

  const [mode, setMode] = useState('READ');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [localFormula, setLocalFormula] = useState(
    JSON.parse(JSON.stringify(formula))
  );

  useEffect(() => {
    setLocalFormula(JSON.parse(JSON.stringify(node.formula)));
  }, [node]);

  const readRender = (
    <div onClick={handleEdit} >
      {
        localFormula.map(({ condition, outcome }, i) => {
          return (
            <div key={i} className="condition-outcome">
              <div>{i + 1}.</div>
              <div>
                <div className="condition">
                  <div>如果：</div>
                  {conditionToString(condition, metricDefinitions)}
                </div>
                <div className="outcome">
                  <div>则：</div>
                  {outcomeToString(outcome, metricDefinitions)}
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );

  const selectSearchOptions = {
    showSearch: true,
    filterOption: (input, option) => {
      if (!option.children) return false;
      return option.children.includes(input);
    }
  };

  const selectMetricDefinitionOptions = (
    metricDefinitions.map((definition) => {
      const { id, name } = definition;
      return (
        <Select.Option key={id} value={id}>
          {name}
        </Select.Option>
      );
    })
  );

  const selectAttributeOptions = (
    Object.keys(fieldsMetadataByCategory).map((category) => {
      return (
        <Select.OptGroup key={category} label={category}>
          {
            fieldsMetadataByCategory[category]
              .filter((fieldMetadata) => {
                return (
                  fieldMetadata.fieldName !== 'healthScore' &&
                  (
                    !fieldMetadata.applicableTo ||
                    fieldMetadata.applicableTo.includes('b2b')
                  )
                );
              })
              .map((fieldMetadata) => {
                const { fieldName, title } = fieldMetadata;
                const snakeCaseFieldName = fieldName.replace(/[A-Z]/g, (c) => `_${c.toLowerCase()}`);
                return (
                  <Select.Option key={snakeCaseFieldName} value={snakeCaseFieldName}>
                    {title}
                  </Select.Option>
                );
              })
          }
        </Select.OptGroup>
      );
    })
  );

  const selectNumericAttributeOptions = (
    Object.keys(fieldsMetadataByCategory).map((category) => {
      return (
        <Select.OptGroup key={category} label={category}>
          {
            fieldsMetadataByCategory[category]
              .filter((fieldMetadata) => {
                return (
                  fieldMetadata.fieldName !== 'healthScore' &&
                  (
                    !fieldMetadata.applicableTo ||
                    fieldMetadata.applicableTo.includes('b2b')
                  ) &&
                  fieldMetadata.type === 'NUMBER'
                );
              })
              .map((fieldMetadata) => {
                const { fieldName, title } = fieldMetadata;
                const snakeCaseFieldName = fieldName.replace(/[A-Z]/g, (c) => `_${c.toLowerCase()}`);
                return (
                  <Select.Option key={snakeCaseFieldName} value={snakeCaseFieldName}>
                    {title}
                  </Select.Option>
                );
              })
          }
        </Select.OptGroup>
      );
    })
  );

  function renderConditionOutcomeForm(conditionOutcome, i) {
    const { condition, outcome } = conditionOutcome;

    let allOperators = true;
    if (condition.type === 'ATTRIBUTE') {
      const fieldMetadata = fieldsMetadata[condition.fieldName];
      if (fieldMetadata.type !== 'NUMBER') allOperators = false;
    }

    return (
      <div key={i} className="condition-outcome">
        <div>{i + 1}.</div>
        <div>
          <div className="condition">
            <div>如果：</div>
            <Input.Group compact>
              <Select
                value={condition.type}
                disabled={condition.type === 'ELSE'}
                onChange={getFormulaUpdateHandler(i, 'condition', 'type')}
                style={{ width: condition.type === 'ELSE' ? '100%' : 80 }}
                showArrow={false}
              >
                <Select.Option value="METRIC">指标</Select.Option>
                <Select.Option value="ATTRIBUTE">字段</Select.Option>
                {
                  condition.type === 'ELSE' &&
                  <Select.Option value="ELSE">其他</Select.Option>
                }
              </Select>
              {
                condition.type === 'METRIC' &&
                <>
                  <Select
                    value={condition.definitionId}
                    onChange={getFormulaUpdateHandler(i, 'condition', 'definitionId')}
                    style={{ width: 200 }}
                    {...selectSearchOptions}
                    showArrow={false}
                  >
                    {selectMetricDefinitionOptions}
                  </Select>
                  <Select
                    className="operator"
                    value={condition.operator}
                    onChange={getFormulaUpdateHandler(i, 'condition', 'operator')}
                    style={{ width: 80 }}
                    showArrow={false}
                  >
                    <Select.Option value="EQUAL">{'等于'}</Select.Option>
                    <Select.Option value="NOT_EQUAL">{'不等于'}</Select.Option>
                    <Select.Option value="GREATER">{'大于'}</Select.Option>
                    <Select.Option value="GREATER_EQUAL">{'大等于'}</Select.Option>
                    <Select.Option value="LESS">{'小于'}</Select.Option>
                    <Select.Option value="LESS_EQUAL">{'小等于'}</Select.Option>
                  </Select>
                  <div style={{ width: 'calc(100% - 357px)' }}>
                    <InputNumber
                      value={condition.value}
                      onChange={getFormulaUpdateHandler(i, 'condition', 'value')}
                      style={{ width: '100%', height: 32 }}
                    />
                  </div>
                </>
              }
              {
                condition.type === 'ATTRIBUTE' &&
                <>
                  <Select
                    value={condition.fieldName}
                    onChange={getFormulaUpdateHandler(i, 'condition', 'fieldName')}
                    style={{ width: 200 }}
                    {...selectSearchOptions}
                    showArrow={false}
                  >
                    {selectAttributeOptions}
                  </Select>
                  <Select
                    className="operator"
                    value={condition.operator}
                    onChange={getFormulaUpdateHandler(i, 'condition', 'operator')}
                    style={{ width: 80 }}
                    showArrow={false}
                  >
                    <Select.Option value="EQUAL">{'等于'}</Select.Option>
                    <Select.Option value="NOT_EQUAL">{'不等于'}</Select.Option>
                    {
                      allOperators &&
                      <>
                        <Select.Option value="GREATER">{'大于'}</Select.Option>
                        <Select.Option value="GREATER_EQUAL">{'大等于'}</Select.Option>
                        <Select.Option value="LESS">{'小于'}</Select.Option>
                        <Select.Option value="LESS_EQUAL">{'小等于'}</Select.Option>
                      </>
                    }
                  </Select>
                  <div style={{ width: 'calc(100% - 357px)' }}>
                    <Input
                      value={condition.value}
                      onChange={getFormulaUpdateHandler(i, 'condition', 'value')}
                      style={{ width: '100%', height: 32 }}
                    />
                  </div>
                </>
              }
            </Input.Group>
          </div>
          <div className="outcome">
            <div>则：</div>
            <Input.Group compact>
              <Select
                value={outcome.type}
                onChange={getFormulaUpdateHandler(i, 'outcome', 'type')}
                style={{ width: 80 }}
                showArrow={false}
              >
                <Select.Option value="METRIC">指标</Select.Option>
                <Select.Option value="ATTRIBUTE">字段</Select.Option>
                <Select.Option value="FIXED">固定</Select.Option>
              </Select>
              {
                outcome.type === 'METRIC' &&
                <>
                  <Select
                    value={outcome.definitionId}
                    onChange={getFormulaUpdateHandler(i, 'outcome', 'definitionId')}
                    style={{ width: 200 }}
                    {...selectSearchOptions}
                    showArrow={false}
                  >
                    {selectMetricDefinitionOptions}
                  </Select>
                  <Select
                    className="operator"
                    value={outcome.operator}
                    onChange={getFormulaUpdateHandler(i, 'outcome', 'operator')}
                    style={{ width: 80 }}
                    showArrow={false}
                  >
                    <Select.Option value="PLUS">加</Select.Option>
                    <Select.Option value="MINUS">减</Select.Option>
                    <Select.Option value="MULTIPLY">乘</Select.Option>
                    <Select.Option value="DIVIDE">除</Select.Option>
                  </Select>
                  <div style={{ width: 'calc(100% - 357px)' }}>
                    <InputNumber
                      value={outcome.value}
                      onChange={getFormulaUpdateHandler(i, 'outcome', 'value')}
                      style={{ width: '100%', height: 32 }}
                    />
                  </div>
                </>
              }
              {
                outcome.type === 'ATTRIBUTE' &&
                <>
                  <Select
                    value={outcome.fieldName}
                    onChange={getFormulaUpdateHandler(i, 'outcome', 'fieldName')}
                    style={{ width: 200 }}
                    {...selectSearchOptions}
                    showArrow={false}
                  >
                    {selectNumericAttributeOptions}
                  </Select>
                  <Select
                    className="operator"
                    value={outcome.operator}
                    onChange={getFormulaUpdateHandler(i, 'outcome', 'operator')}
                    style={{ width: 80 }}
                    showArrow={false}
                  >
                    <Select.Option value="PLUS">加</Select.Option>
                    <Select.Option value="MINUS">减</Select.Option>
                    <Select.Option value="MULTIPLY">乘</Select.Option>
                    <Select.Option value="DIVIDE">除</Select.Option>
                  </Select>
                  <div style={{ width: 'calc(100% - 357px)' }}>
                    <InputNumber
                      value={outcome.value}
                      onChange={getFormulaUpdateHandler(i, 'outcome', 'value')}
                      style={{ width: '100%', height: 32 }}
                    />
                  </div>
                </>
              }
              {
                outcome.type === 'FIXED' &&
                <div style={{ width: 'calc(100% - 79px)' }}>
                  <InputNumber
                    value={outcome.value}
                    onChange={getFormulaUpdateHandler(i, 'outcome', 'value')}
                    style={{ width: '100%', height: 32 }}
                  />
                </div>
              }
            </Input.Group>
          </div>
        </div>
        {
          (i === localFormula.length - 1) ?
          <div className="deletion-button disabled">
            <MinusCircleOutlined />
          </div> :
          <div
            className="deletion-button"
            onClick={getFormulaRemoveHandler(i)}
          >
            <MinusCircleOutlined />
          </div>
        }
      </div>
    );
  }

  function outcomeToString(outcome, metricDefinitions) {
    const { type } = outcome;
    if (type === 'FIXED') {
      return <div>{outcome.value}</div>;
    } else if (type === 'METRIC') {
      return metricExpressionToString(outcome, metricDefinitions);
    } else {
      return attributeExpressionToString(outcome);
    }
  }

  function attributeExpressionToString(expression) {
    const { fieldName, operator, value } = expression;
    const fieldNameCamelCase = fieldName.substring(0, 7) === 'custom_' ?
      fieldName :
      fieldName.replace(/[_](.)/g, (_, c) => c.toUpperCase());
    // TODO: decide on custom fields camel or snake case
    const { title } = fieldsMetadata[fieldNameCamelCase];

    return (
      <div className="expression">
        <div className="field-name">字段：{title}</div>
        <div>{operatorToSymbol(operator)}</div>
        <div>{value}</div>
      </div>
    );
  }

  function conditionToString(condition, metricDefinitions) {
    const { type } = condition;
    if (type === 'ELSE') {
      return <div>其余情况</div>;
    } else if (type === 'METRIC') {
      return metricExpressionToString(condition, metricDefinitions);
    } else {
      return attributeExpressionToString(condition);
    }
  }

  const editRender = (
    <div>
      {localFormula.map(renderConditionOutcomeForm)}
      <Button
        icon={<PlusOutlined />}
        onClick={handleFormulaAdd}
        disabled={localFormula.length > 3}
      >
        添加
      </Button>
      <div style={{ marginTop: 15 }}>
        <Button type="primary" disabled={submitDisabled} onClick={handleSubmit}>
          <CheckOutlined />
        </Button>
        <Button onClick={handleCancel}><CloseOutlined /></Button>
      </div>
    </div>
  );

  return (
    <div className={`formula-details overall-${overallMode} ${mode}`} >
      {
        mode === 'READ' ?
        readRender :
        editRender
      }
    </div>
  );
}

function metricExpressionToString(expression, metricDefinitions) {
  const { definitionId, operator, value } = expression;
  const definitionName = metricDefinitions.find(
    (d) => d.id === Number(definitionId)
  ).name;

  return (
    <div className="expression">
      <div className="metric-definition">指标：{definitionName}</div>
      <div>{operatorToSymbol(operator)}</div>
      <div>{value}</div>
    </div>
  );
}

function operatorToSymbol(operator) {
  return {
    EQUAL: '=',
    NOT_EQUAL: '!=',
    GREATER: '>',
    GREATER_EQUAL: '>=',
    LESS: '<',
    LESS_EQUAL: '<=',
    ADD: '+',
    SUBTRACT: '-',
    MULTIPLY: 'x',
    DIVIDE: '/'
  }[operator];
}

export default FormulaDetails;
