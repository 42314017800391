
function calculateScale({ data, key='value', buffer=[0.4, 0.2] }) {
  let [max, min] = data.reduce((prev, cur) => {
    const curValue = cur[key];
    const [prevMax, prevMin] = prev;
    const max = prevMax > curValue ? prevMax : curValue;
    const min = prevMin < curValue ? prevMin : curValue;
    return [max, min];
  }, [0, 100000000]);

  let paddedMin = min, paddedMax = max;
  if (max === min) {
    paddedMax++;
    paddedMin--;
  }
  const diff = paddedMax - paddedMin;

  return [
    Math.ceil(paddedMax + diff * buffer[0]),
    Math.floor(paddedMin - diff * buffer[1]),
    max,
    min
  ];
}

export { calculateScale };
