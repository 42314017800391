
import React from "react";
import {
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';

import './index.scss';

function ChartToggleButtons({value, onChange, choices}) {
  return (
    <div className="component-toggle-chart-buttons">
      <ToggleButtonGroup
        color="primary"
        value={value}
        exclusive
        sx={{ height: 28 }}
        onChange={onChange}
      >
        {
          Object.keys(choices).map(value => {
            return <ToggleButton value={value} key={value}>{choices[value]}</ToggleButton>
          })
        }
      </ToggleButtonGroup>
    </div>
  )
}

export default ChartToggleButtons;
