
import React from 'react';
import {
  Form,
  Button,
  Input,
  Select,
  DatePicker
} from 'antd';
import moment from 'moment';

import './index.scss';

const { RangePicker } = DatePicker;

function FilterPanel({
  filters,
  setFilters
}) {
  function onFinish(values) {
    let {
      timeFrame,
      customerEventType,
      eventName,
      customerName,
      customerUserName
    } = values;

    if (eventName) eventName = eventName.trim();
    if (customerName) customerName = customerName.trim();
    if (customerUserName) customerUserName = customerUserName.trim();

    form.setFieldsValue({
      ...values,
      eventName,
      customerName,
      customerUserName
    });

    const startDate = timeFrame[0].format('YYYY-MM-DD');
    const endDate = timeFrame[1].format('YYYY-MM-DD');

    if (customerEventType === 'all') customerEventType = null;
    if (eventName === '') eventName = null;
    if (customerName === '') customerName = null;

    const filters = {
      endDate,
      startDate,
      customerEventType,
      eventName,
      customerName,
      customerUserName
    };

    setFilters(filters);
  }

  function onCancel() {
    form.resetFields();
  }

  function getInitialValues(filters) {
    const {
      endDate,
      startDate,
      customerEventType,
      eventName,
      customerName,
      customerUserName
    } = filters;

    return {
      timeFrame: [moment(startDate), moment(endDate)],
      customerEventType: customerEventType || 'all',
      customerEventInfoType: 'all',
      eventName,
      customerList: 'all',
      customerName,
      customerUserName
    };
  }

  const [form] = Form.useForm();

  const initialValues = getInitialValues(filters);

  return (
    <div className="filter-panel-form">
      <Form
        name="filters"
        initialValues={initialValues}
        onFinish={onFinish}
        form={form}
        labelCol={{ span: 7 }}
      >
        {timeFrameField}
        {customerEventTypeField}
        {eventName}
        {customerListField}
        {customerNameField}
        {customerUserNameField}
        <div className="submit">
          <Form.Item noStyle>
            <Button
              type="primary"
              htmlType="submit"
            >
              搜索
            </Button>
          </Form.Item>
          <Button onClick={onCancel}>重置</Button>
        </div>
      </Form>
    </div>
  )
}

const timeFrameField = (
  <Form.Item
    name="timeFrame"
    label="月份"
  >
    <RangePicker />
  </Form.Item>
);

const customerEventTypeField = (
  <Form.Item
    name="customerEventType"
    label="事件类型"
  >
    <Select>
      <Select.Option value="all">全部</Select.Option>
      <Select.Option value="lifecycle">业务</Select.Option>
      <Select.Option value="outcome">结果</Select.Option>
      <Select.Option value="behavior">行为</Select.Option>
      <Select.Option value="feedback">反馈</Select.Option>
      <Select.Option value="support">客服</Select.Option>
    </Select>
  </Form.Item>
);

const eventName = (
  <Form.Item
    name="eventName"
    label="事件名"
  >
    <Input />
  </Form.Item>
);

const customerListField = (
  <Form.Item
    name="customerList"
    label="客户分组"
  >
    <Select disabled={true}>
      <Select.Option value="all">全部（尚未实现）</Select.Option>
    </Select>
  </Form.Item>
);

const customerNameField = (
  <Form.Item
    name="customerName"
    label="客户名"
  >
    <Input />
  </Form.Item>
);

const customerUserNameField = (
  <Form.Item
    name="customerUserName"
    label="用户名"
  >
    <Input />
  </Form.Item>
);

export default FilterPanel;
