
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button
} from 'antd';

import BaseModal from '../base-modal';

class DimensionsRemove extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  render() {
    const {
      onSubmit,
      onCancel,
      name
    } = this.props;

    return (
      <BaseModal
        title={`确定暂时移除"${name}"？`}
        onCancelOverride={onCancel}
      >
        <div className="submit">
          <Button
            danger
            onClick={onSubmit}
          >
            提交
          </Button>
          <Button onClick={onCancel}>取消</Button>
        </div>
      </BaseModal>
    )
  }
}

export default DimensionsRemove;
