
import {
  arrData, 
  membershipCountData
} from './fake-data';
import {
  extractSummary
} from './fake-data-helpers';

const membershipSummary = extractSummary(membershipCountData.total, '订阅量');
const arrSummary = extractSummary(arrData.total, 'ARR');

export {
  membershipSummary,
  arrSummary
};
