
import { combineReducers } from 'redux';

import { reducers as uiReducers } from 'domains/ui';
import { reducers as metadataReducers } from 'domains/metadata';
import { reducers as storesReducers } from 'domains/stores';
import { reducers as usersReducers } from 'domains/users';
import { reducers as authReducers } from 'domains/auth';
import { reducers as rbacReducers } from 'domains/rbac';
import { reducers as customersReducers } from 'domains/customers';
import { reducers as customerListsReducers } from 'domains/customer-lists';
import { reducers as timeSeriesExplorationReducers } from 'domains/time-series-exploration';
import { reducers as eventsReducers } from 'domains/events';
import { reducers as surveysReducers } from 'domains/surveys';
import { reducers as alertRulesReducers } from 'domains/alert-rules';
import { reducers as feedbackReducers } from 'domains/feedback';
import { reducers as surveyAnalyticsReducers } from 'domains/survey-analytics';
import { reducers as notificationsReducers } from 'domains/notifications';
import { reducers as ticketsReducers } from 'domains/tickets';
import { reducers as ticketCommentsReducers } from 'domains/ticket-comments';
import { reducers as customerMetricsReducers } from 'domains/customer-metrics';
import { reducers as customerNotesReducers } from 'domains/customer-notes';
import { reducers as customerUsersReducers } from 'domains/customer-users';
import { reducers as healthReducers } from 'domains/health';
import { reducers as userActivitiesReducers } from 'domains/user-activities';
import { reducers as userMetricsReducers } from 'domains/user-metrics';
import { reducers as analyticsReducers } from 'domains/analytics';
import { reducers as customFormulaeReducers } from 'domains/custom-fomulae';
import { reducers as machineLearningReducers } from 'domains/machine-learning';
import { reducers as userPinsReducers } from 'domains/user-pins';
import { reducers as planReducers } from 'domains/plans';
import { reducers as aggregateCustomerMetricsReducers } from 'domains/aggregate-customer-metrics';

const combinedReducer = combineReducers(Object.assign(
  {},
  uiReducers,
  metadataReducers,
  storesReducers,
  usersReducers,
  authReducers,
  rbacReducers,
  customersReducers,
  customerListsReducers,
  timeSeriesExplorationReducers,
  eventsReducers,
  surveysReducers,
  alertRulesReducers,
  feedbackReducers,
  surveyAnalyticsReducers,
  notificationsReducers,
  ticketsReducers,
  ticketCommentsReducers,
  customerMetricsReducers,
  customerNotesReducers,
  customerUsersReducers,
  healthReducers,
  userActivitiesReducers,
  userMetricsReducers,
  analyticsReducers,
  customFormulaeReducers,
  machineLearningReducers,
  userPinsReducers,
  planReducers,
  aggregateCustomerMetricsReducers
));

export { combinedReducer };
