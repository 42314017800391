
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form,
  Input,
  Select,
  Button,
  Row, Col
} from 'antd';
import {
  UserOutlined,
  LockOutlined
} from '@ant-design/icons';

import Users from 'domains/users';
import BaseModal from '../base-modal';

import './index.scss';

class UsersCreate extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired
  };

  render() {
    const { onCancel, onSubmit, roles, users } = this.props;

    return (
      <BaseModal title="创建员工">
        <UsersCreateForm
          onCancel={onCancel}
          onSubmit={onSubmit}
          roles={roles}
          users={users}
        />
      </BaseModal>
    )
  }
}

function mapStateToProps(state) {
  return {
    users: state.users,
    roles: state.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onCancel() {
      dispatch({ type: 'closeModal' });
    },
    onSubmit(user) {
      dispatch({
        type: 'api',
        request: () => Users.create(user),
        success(data) {
          const { roles } = user;
          dispatch({ type: 'usersCreate', user: data.user })
          roles.forEach((id) => {
            dispatch({
              type: 'usersRolesAdd',
              userRole: { userId: data.user.id, roleId: id }
            });
          });
          dispatch({ type: 'closeModal' });
        }
      })
    }
  };
}

function UsersCreateForm({
  onCancel,
  onSubmit,
  roles,
  users
}) {
  const nameField = (
    <div>
      <div className="label">名</div>
      <Form.Item
        name="name"
        rules={[
          { required: true, message: `请输入名称` }
        ]}
      >
        <Input
          placeholder="名称"
          prefix={<UserOutlined />}
        />
      </Form.Item>
    </div>
  );

  const typeField = (
    <div>
      <div className="label">类型</div>
      <Form.Item
        name="type"
      >
        <Select>
          <Select.Option value="admin">
            管理员
          </Select.Option>
          <Select.Option value="regular">
            普通员工
          </Select.Option>
        </Select>
      </Form.Item>
    </div>
  );

  const statusField = (
    <div>
      <div className="label">状态</div>
      <Form.Item
        name="status"
      >
        <Select>
          <Select.Option value="active">
            在职
          </Select.Option>
          <Select.Option value="inactive">
            停职
          </Select.Option>
        </Select>
      </Form.Item>
    </div>
  );

  const managerField = (
    <div>
      <div className="label">所属上级</div>
      <Form.Item
        name="managerId"
      >
        <Select>
          {users.map((user) => {
            return (
              <Select.Option key={user.id} value={user.id}>
                {user.name}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </div>
  );

  const emailField = (
    <div>
      <div className="label">电子邮件</div>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: `请输入电子邮件` },
          { type: 'email', message: '电子邮件格式错误' }
        ]}
      >
        <Input
          placeholder={"电子邮件"}
          prefix={<UserOutlined />}
        />
      </Form.Item>
    </div>
  );

  const passwordField = (
    <div>
      <div className="label">密码</div>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: `请输入密码` },
          { min: 8, message: '密码必须至少 8 个字符' }
        ]}
      >
        <Input.Password
          placeholder={"密码"}
          prefix={<LockOutlined />}
        />
      </Form.Item>
    </div>
  );

  const passwordConfirmField = (
    <div>
      <div className="label">确认密码</div>
      <Form.Item
        name="passwordConfirm"
        rules={[
          { required: true, message: '确认密码' },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('密码匹配失败');
            }
          })
        ]}
        dependencies={['password']}
      >
        <Input.Password
          placeholder={"确认密码"}
          prefix={<LockOutlined />}
        />
      </Form.Item>
    </div>
  );

  const rolesField = (
    <div>
      <div className="label">角色</div>
      <Form.Item
        name="roles"
      >
        <Select
          mode="multiple"
        >
          {roles.map((role) => {
            return (
              <Select.Option key={role.id} value={role.id}>
                {role.name}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </div>
  );

  return (
    <div className="users-create-form">
      <Form
        name="UsersCreate"
        initialValues={
          {
            status: 'active',
            type: 'regular'
          }
        }
        onFinish={onSubmit}
      >
        <Row gutter={10}>
          <Col span={12}>{nameField}</Col>
        </Row>
        <Row gutter={10}>
          <Col span={8}>{typeField}</Col>
          <Col span={8}>{statusField}</Col>
          <Col span={8}>{managerField}</Col>
        </Row>
        {emailField}
        <Row gutter={10}>
          <Col span={12}>{passwordField}</Col>
          <Col span={12}>{passwordConfirmField}</Col>
        </Row>
        {rolesField}
        <div className="submit">
          <Form.Item noStyle>
            <Button
              type="primary"
              htmlType="submit"
            >
              提交
            </Button>             
          </Form.Item>
          <Button onClick={onCancel}>取消</Button>
        </div>
      </Form>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersCreate);
