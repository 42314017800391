
import API from 'adapters/api';

/**
 * state structure:
 * {
 *   perCustomer: {
 *     [customerId]: {
 *       recent: [{Event}],
 *       paymentHistory: [{Event}]
 *     }
 *   }
 * }
 */

const Events = {
  selectors: {},
  actions: {}
};

Events.selectors.perCustomer = (state) => state.events.perCustomer;
Events.selectors.overall = (state) => state.events.overall;

Events.actions.recentCustomerHistory = (customerId) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const params = { customerId };

      const {
        status, data
      } = await API.request({
        resource: 'events',
        action: 'recent',
        params,
        methodOverride: 'GET'
      });

      if (status < 300) {
        const { events } = data;

        dispatch({
          type: 'eventsPerCustomerRecent',
          customerId,
          data: events.data
        });
      }

      return { status, data };
    }
  };
};

Events.actions.paymentHistory = (customerId) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const params = { customerId };

      const {
        status, data
      } = await API.request({
        resource: 'events',
        action: 'payment-history',
        params,
        methodOverride: 'GET'
      });

      if (status < 300) {
        const { events } = data;

        dispatch({
          type: 'eventsPerCustomerPaymentHistory',
          customerId,
          data: events.data
        });
      }

      return { status, data };
    }
  };
};

Events.actions.overall = (filters, { offset, limit }, success) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'events',
        action: 'overall',
        params: { ...filters, offset, limit },
        methodOverride: 'GET'
      });

      if (status < 300) {
        const { events } = data;

        dispatch({
          type: 'eventsOverallRefresh',
          data: events.data,
          count: events.count,
          countByDate: events.countByDate
        });

        success && success();
      }

      return { status, data };
    }
  };
};

const reducers = {
  events(state, action) {
    if (state === undefined) return {
      perCustomer: {}
    };

    if (action.type === 'eventsPerCustomerRecent') {
      return {
        ...state,
        perCustomer: {
          ...state.perCustomer,
          [action.customerId]: {
            ...state.perCustomer[action.customerId],
            recent: action.data
          }
        }
      };
    } else if (action.type === 'eventsPerCustomerPaymentHistory') {
      return {
        ...state,
        perCustomer: {
          ...state.perCustomer,
          [action.customerId]: {
            ...state.perCustomer[action.customerId],
            paymentHistory: action.data
          }
        }
      };
    } else if (action.type === 'eventsOverallRefresh') {
      return {
        ...state,
        overall: {
          data: action.data,
          count: action.count,
          countByDate: action.countByDate
        }
      };
    }

    return state;
  }
}

export { reducers };
export default Events;
