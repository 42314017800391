
import React from 'react';
import { Row, Col } from 'antd';

import HealthOverall from 'analytics/health-overall';
import BreakdownChart from 'components/breakdown-chart';
import OverallTrendChart from 'components/overall-trend-chart';
import ScoreIndicator from 'components/score-indicator';
import Panel from 'components/panel';

import './health-score-panel.scss';

function HealthScorePanel({
  modelWithScores,
  modelWithAvgScores,
  scoresTrend,
  avgScoresTrend
}) {
  const overallScoresTrend = scoresTrend.filter((datum) => {
    return datum.name === '总分';
  });

  const avgOverallScoresTrend = avgScoresTrend.filter((datum) => {
    return datum.name === '总分';
  });

  const mappedCategoryScores = modelWithScores.children.map((datum) => {
    return {
      ...datum,
      entity: 'self'
    };
  });

  const mappedCategoryAvgScores = modelWithAvgScores.children.map((datum) => {
    return {
      ...datum,
      entity: 'comparison'
    };
  });

  return (
    <div className="health-score-panel">
      <Row gutter={10}>
        <Col span={8}>
          <Panel title="健康总分">
            <HealthOverall
              modelWithScores={modelWithScores}
              overallScoresTrend={overallScoresTrend}
              avgOverallScoresTrend={avgOverallScoresTrend}
            />
          </Panel>
        </Col>
        <Col span={16}>
          <Panel title="健康类别分">
            <div className="health-model-category-panel">
              <BreakdownChart
                score={modelWithScores.score}
                categoryScores={mappedCategoryScores}
                avgCategoryScores={mappedCategoryAvgScores}
              />
              <div className="category-trends">
                {modelWithScores.children.map((category, i) => {
                  const { name, score } = category;
                  return (
                    <div key={i}>
                      {renderCategorySummary({
                        name,
                        score,
                        trend: scoresTrend.filter((datum) => datum.name === name),
                        comparisonTrend: avgScoresTrend.filter((datum) => datum.name === name)
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </Panel>
        </Col>
      </Row>
    </div>
  )
}

function renderCategorySummary({
  name,
  score,
  trend,
  comparisonTrend
}) {
  return (
    <div className="category-trend">
      <div>
        <div>{name}</div>
        <ScoreIndicator
          score={score}
          trend={trend}
          size="small"
        />
      </div>
      <OverallTrendChart
        size="small"
        trend={trend}
        comparisonTrend={comparisonTrend}
      />
    </div>
  )
}

export default HealthScorePanel;
