
import React from 'react';
import {
  Form, Input, Button
} from 'antd';

import BaseDrawer from 'modals/base-drawer';

function FeedbackDrawer({
  onSubmit,
  onCancel
}) {
  return (
    <BaseDrawer
      title={'提交反馈'}
      onClose={onCancel}
    >
      <FeedbackForm
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </BaseDrawer>
  );
}

function FeedbackForm({
  onSubmit,
  onCancel
}) {
  const messageField = (
    <div>
      <Form.Item name="message">
        <Input.TextArea
          rows={8}
          maxLength={4000}
          placeholder="信息"
        />
      </Form.Item>
    </div>
  );

  return (
    <div>
      <Form
        name="user-feedback"
        onFinish={onSubmit}
      >
        {messageField}
        <div className="submit">
          <Form.Item noStyle>
            <Button
              type="primary"
              htmlType="submit"
            >
              提交
            </Button>
          </Form.Item>
          <Button onClick={onCancel}>取消</Button>
        </div>
      </Form>
    </div>
  );
}

export default FeedbackDrawer;