
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Bar, Line, RingProgress, Column } from '@antv/g2plot';

import SurveyAnalyticsDomain from 'domains/survey-analytics';
import ChartToggleButtons from 'components/chart-toggle-button';

import './survey-analytics.scss';

function SurveyAnalytics({ activeSurveyId }) {
  const dispatch = useDispatch();

  const surveyResults = useSelector(SurveyAnalyticsDomain.selectors.data)[activeSurveyId];

  const [answersTimelineChartType, setAnswerTimelineChartType] = useState('area');

  const overallChartContainer = useRef(null);
  const distributionTrendChartContainer = useRef(null);
  const answerDistributionChartContainer = useRef(null);
  const responseRateChartContainer = useRef(null);

  useEffect(()=> {
    // hack: quick fix
    if (!surveyResults) return;
    createCharts();
    createDistributionTrendChart();
  }, [surveyResults]);

  useEffect(() => {
    dispatch(SurveyAnalyticsDomain.actions.read({ id: activeSurveyId }));
  }, []);

  useEffect(() => {
    // hack: quick fix
    if (!surveyResults) return;
    createDistributionTrendChart();
  }, [answersTimelineChartType]);

  if (!surveyResults) return <div>--- 加载中 ---</div>;

  function createDistributionTrendChart() {
    distributionTrendChartContainer.current.innerHTML = '';
  
    let answersTimeLineChart;
    if (answersTimelineChartType === 'area') {
      answersTimeLineChart = new Column(distributionTrendChartContainer.current, {
        data: surveyResults.answersTimeline,
        xField: 'date',
        yField: 'count',
        seriesField: 'answer',
        isPercent: true,
        isStack: true,
        height: 240,
        maxColumnWidth: 30,
      });
    } else {
      answersTimeLineChart = new Line(distributionTrendChartContainer.current, {
        data: surveyResults.answersTimeline,
        xField: 'date',
        yField: 'count',
        seriesField: 'answer',
        areaStyle: {
          fillOpacity: 0.7,
        },
        height: 240,
      });
    }
    answersTimeLineChart.render();
  }

  const createCharts = () => {
    overallChartContainer.current.innerHTML = '';
    answerDistributionChartContainer.current.innerHTML = '';
    responseRateChartContainer.current.innerHTML = '';

    const answersDistributionChart = new Bar(answerDistributionChartContainer.current, {
      data: surveyResults.answersDistribution,
      xField: 'count',
      yField: 'answer',
      seriesField: 'answer',
      legend: {
        position: 'top-left',
      },
      maxBarWidth: 30,
      height: 240
    });
    answersDistributionChart.render();

    const responseRate = surveyResults.responseRate;
    let submitted = responseRate.find(r => r.status === 'submitted');
    submitted = submitted === undefined ? 0 : submitted.count;
    const total = responseRate.reduce((prev, cur) => prev + cur.count, 0);
    const percentage = total === 0 ? 0 : submitted / total;

    const responseRateChart = new RingProgress(responseRateChartContainer.current, {
      percent: percentage,
      height: 240,
      autoFit: true,
      innerRadius: 0.75,
      radius: 0.98,
      statistic: {
        content: {
          style: { fontSize: '30px'}
        }
      },
    });
    responseRateChart.render();

    const responseRateTimelineChart = new Line(overallChartContainer.current, {
      data: surveyResults.responseRateTimeline,
      padding: 'auto',
      xField: 'date',
      yField: 'responseRate',
      xAxis: {
        tickCount: 5,
      },
      yAxis: {
        tickCount: 3,
        label: {
          formatter: (value) => {
            return value * 100 + '%';
          },
        },
      },
      tooltip: {
        formatter: (datum) => {
          return { name: '答复率', value: (datum.responseRate*100).toFixed(2) + '%' };
        }
      },
      autoFit: false,
      height: 90,
    });
    responseRateTimelineChart.render();
  }

  const handleToggle = function(event, value) {
    if (value !== null) {
      setAnswerTimelineChartType(value);
    }
  }

  const answerTimelineToggleButtons = (
    <ChartToggleButtons
      value={answersTimelineChartType} 
      onChange={handleToggle}
      choices={{
        area: '百分比',
        line: '答案总数',
      }}
    />
  )

  return (
    <div className="survey-analytics">
      <div className="chart-row">
        <ChartWithTitle
          title='答复率趋势'
          chartRef={overallChartContainer}
        />
      </div>
      <div className="chart-row">
        <ChartWithTitle
          title='答案分布趋势'
          toggleButtons={answerTimelineToggleButtons}
        >
          <div ref={distributionTrendChartContainer}></div>
        </ChartWithTitle>
        <ChartWithTitle
          title='答案分布'
          chartRef={answerDistributionChartContainer}
        />
        <ChartWithTitle
          title='答复率'
          chartRef={responseRateChartContainer}
        />
      </div>
    </div>
  )
}

function ChartWithTitle({ title, chartRef, children, toggleButtons }) {
  return (
    <div
      className="title-and-chart"
    >
      <div className="title-row">
        <div className="chart-title">{title}</div>
        {toggleButtons && toggleButtons}
      </div>
      {
        !children && 
        <div ref={chartRef} className="chart"></div>      
      }
      {
        children &&
        <div> {children} </div>
      }
    </div>
  )
}

export default SurveyAnalytics;
