
/**
 * state structure:
 * {
 *   selectedId: {Number}
 *   selected: {CustomerList}
 *   // there are three sets of filters,
 *   // one is saved, only for live lists, stored in list data
 *   // another one is applied, which may not be saved
 *   // third one is displayed but not necessarily applied
 *   // when saving, applied filters are used
 *   appliedFilters: [
 *     {
 *       fieldName: {String},
 *       operatorName: {String},
 *       value: {String|Number}
 *     }
 *   ],
 *   displayedFilters: [
 *     {Filter}
 *   ],
 *   hasAppliedFilters: {Boolean}
 *   data: [
 *     {CustomerList}
 *   ],
 *   count: {Number}
 * }
 */

import API from 'adapters/api';

const CustomerLists = {
  selectors: {},
  actions: {}
};

CustomerLists.selectors.selectedId = (state) => {
  return state.customerLists.selectedId;
};

CustomerLists.selectors.selected = (state) => {
  const { data, selectedId } = state.customerLists;
  return data.find((list) => list.id === selectedId);
};

CustomerLists.selectors.data = (state) => {
  return state.customerLists.data;
};

CustomerLists.selectors.appliedFilters = (state) => {
  return state.customerLists.appliedFilters;
};

CustomerLists.selectors.displayedFilters = (state) => {
  return state.customerLists.displayedFilters;
};

CustomerLists.selectors.hasAppliedFilters = (state) => {
  return state.customerLists.hasAppliedFilters;
};

CustomerLists.selectors.effectiveFilters = (state) => {
  return getEffectiveFilters(state.customerLists.displayedFilters);
};

function getEffectiveFilters(displayedFilters) {
  return displayedFilters.filter((filter) => filter.fieldName !== 'none');
};

CustomerLists.actions.select = (id) => {
  return {
    type: 'customerListsSelect',
    data: id
  };
};

CustomerLists.actions.applyFilters = () => {
  return {
    type: 'customerListsApplyFilters'
  };
};

CustomerLists.actions.resetFilters = () => {
  return {
    type: 'customerListsResetFilters'
  };
};

CustomerLists.actions.addFilter = () => {
  return {
    type: 'customerListsAddFilter'
  };
};

CustomerLists.actions.removeFilter = (index) => {
  return {
    type: 'customerListsRemoveFilter',
    data: index
  };
};

CustomerLists.actions.changeFilter = (i, newFilter) => {
  return {
    type: 'customerListsChangeFilter',
    data: { i, newFilter }
  };
};

CustomerLists.actions.readList = () => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'customer-lists',
        action: 'readList'
      });

      if (status < 300) {
        const { customerLists } = data;

        dispatch({
          type: 'customerListsRefresh',
          data: customerLists.data
        });

        dispatch({
          type: 'customerListsSelect',
          data: customerLists.data[0].id
        });
      }

      return { status, data };
    }
  }
};

CustomerLists.actions.create = ({
  name,
  type,
  filters,
  baseListId,
  me,
  success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'customer-lists',
        action: 'create',
        data: {
          name, type, filters, baseListId, withStats: true
        }
      });

      if (status < 300) {
        const { id, stats } = data.customerList;

        dispatch({
          type: 'customerListsAdd',
          data: {
            id,
            name,
            type,
            filters: type === 'LIVE' ? filters : null,
            ownerId: me.id,
            createdById: me.id,
            stats
          }
        });

        success && success();
      }

      return { status, data };
    }
  };
};

CustomerLists.actions.update = ({
  data,
  success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const result = await API.request({
        resource: 'customer-lists',
        action: 'update',
        data
      });

      if (result.status < 300) {
        dispatch({
          type: 'customerListsUpdate',
          data
        });

        success && success();
      }

      return result;
    }
  };
};

CustomerLists.actions.delete = ({
  id,
  success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'customer-lists',
        action: 'delete',
        data: { id }
      });

      if (status < 300) {
        dispatch({
          type: 'customerListsRemove',
          id
        });

        success && success();
      }

      return { status, data };
    }
  };
};

const reducers = {
  customerLists(state, action) {
    if (!state) return {};

    if (action.type === 'init') {
      const selectedId = state.data[0].id;
      const { appliedFilters, displayedFilters } = getNewFilters(state.data, selectedId);

      return {
        ...state,
        selectedId,
        appliedFilters,
        displayedFilters,
        hasAppliedFilters: false
      };
    }

    if (action.type === 'customerListsSelect') {
      const selectedList = state.data.find((list) => list.id === action.data);
      if (!selectedList) return state;

      const { appliedFilters, displayedFilters } = getNewFilters(state.data, action.data);

      return {
        ...state,
        appliedFilters,
        displayedFilters,
        selectedId: action.data
      };
    } else if (action.type === 'customerListsAdd') {
      return {
        ...state,
        data: [...state.data, action.data]
      };
    } else if (action.type === 'customerListsUpdate') {
      return {
        ...state,
        data: state.data.map((list) => {
          if (list.id === action.data.id) return { ...list, ...action.data };
          else return list;
        })
      };
    } else if (action.type === 'customerListsRefresh') {
      return {
        ...state,
        data: action.data
      };
    } else if (action.type === 'customerListsRemove') {
      let selectedId = state.selectedId;
      let appliedFilters = state.appliedFilters;
      let displayedFilters = state.displayedFilters;

      if (action.id === state.selectedId) {
        const listToDelete = state.data.find((list) => list.id === action.id);
        const type = listToDelete.type;
        const sameTypeLists = state.data
          .filter((list) => list.type === type)
          .filter((list) => list.id !== action.id);

        if (sameTypeLists.length === 0) {
          selectedId = state.data[0].id;
        } else {
          selectedId = sameTypeLists[0].id;
        }

        const newFilters = getNewFilters(state.data, selectedId);
        appliedFilters = newFilters.appliedFilters;
        displayedFilters = newFilters.displayedFilters;
      }

      return {
        ...state,
        appliedFilters,
        displayedFilters,
        selectedId,
        data: state.data.filter((list) => list.id !== action.id)
      };
    } else if (action.type === 'customerListsResetFilters') {
      const { appliedFilters, displayedFilters } = getNewFilters(state.data, state.selectedId);

      return {
        ...state,
        hasAppliedFilters: false,
        appliedFilters,
        displayedFilters
      };
    } else if (action.type === 'customerListsApplyFilters') {
      return {
        ...state,
        hasAppliedFilters: true,
        appliedFilters: getEffectiveFilters(state.displayedFilters)
      };
    } else if (action.type === 'customerListsAddFilter') {
      const displayedFilters = [...state.displayedFilters, { fieldName: 'none' }];
      return {
        ...state,
        displayedFilters
      };
    } else if (action.type === 'customerListsRemoveFilter') {
      const displayedFilters = state.displayedFilters.filter((_, index) => index !== action.data);
      return {
        ...state,
        displayedFilters
      };
    } else if (action.type === 'customerListsChangeFilter') {
      return {
        ...state,
        displayedFilters: state.displayedFilters.map((filter, i) => {
          if (i === action.data.i) return action.data.newFilter;
          return filter;
        })
      };
    }

    return state;
  }
};

function getNewFilters(lists, id) {
  const newList = lists.find((list) => list.id === id);
  const appliedFilters = newList.filters || [];
  const displayedFilters = appliedFilters.length === 0 ?
    [{ fieldName: 'none' }] :
    appliedFilters;

  return { appliedFilters, displayedFilters };
}

export { reducers };
export default CustomerLists;
