
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Row, Col, Button, Input, Affix
} from 'antd';
import moment from 'moment-timezone';

import UsersDomain from 'domains/users';
import Panel from 'components/panel';
import InPlaceEdit from 'components/in-place-edit';

import './ticket-details.scss';

const { TextArea } = Input;

function TicketDetails({
  ticket,
  comments,
  onUpdate,
  onAddComment
}) {
  function handleInputChange(e) {
    setComment(e.target.value);
  };

  function handleAddComment() {
    onAddComment(comment);
    setComment(null);
  }

  function disableComment() {
    return !comment;
  }

  const users = useSelector(UsersDomain.selectors.data);
  const assignee = users.filter((user) => user.id === ticket.assigneeId)[0];

  const [comment, setComment] = useState(null);

  return (
    <div className="ticket-details">
      <Row gutter={10}>
        <Col span={16}>
          <Panel>
            <div className="ticket-details-title">
              {ticket.title}
            </div>
            <div className='ticket-details-message'>
              {ticket.message}
            </div>
            <div className="comment-input">
              <TextArea
                placeholder="添加评论"
                value={comment}
                autoSize={{ minRows: 4, maxRows: 10 }}
                onChange={handleInputChange}
              />
            </div>
            <div className="add-ticket-comment">
              <Button
                type="primary"
                htmlType="submit"
                disabled={disableComment()}
                onClick={handleAddComment}
              >
                评论
              </Button>
            </div>
            <div className="comments">
              {comments.filter((comment) => comment.ticketId === parseInt(ticket.id))
                .map((comment) => {
                  const user = users.find((user) => user.id === comment.userId);
                  return (
                    <div className="comment" key={comment.id}>
                      <div className="comment-meta">
                        {user.name} {moment(comment.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                      </div>
                      <div className="comment-message">
                        {comment.comment}
                      </div>
                    </div>
                  );
                })}
            </div>
          </Panel>
        </Col>
        <Col span={8}>
          <Affix offsetTop={132}>
            <Panel>
              <div className="assignee">
                <InPlaceEdit
                  title="处理人"
                  value={assignee.id}
                  type="select"
                  displayMode='horizontal'
                  selectOptions={
                    users.map((user) => {
                      return {
                        value: user.id,
                        title: user.name
                      }
                    })
                  }
                  onSubmit={(v) => onUpdate({ 'assigneeId': v })}
                />
              </div>
              <div className="status">
                <InPlaceEdit
                  title="状态"
                  value={ticket.status}
                  type="select"
                  displayMode='horizontal'
                  selectOptions={[
                    { value: 'NOT_STARTED', title: '未处理' },
                    { value: 'IN_PROGRESS', title: '处理中' },
                    { value: 'RESOLVED', title: '已解决' },
                  ]}
                  onSubmit={(v) => onUpdate({ 'status': v })}
                />
              </div>
              <div className="priority">
                <InPlaceEdit
                  title="优先级"
                  value={ticket.priority}
                  type="select"
                  displayMode='horizontal'
                  selectOptions={[
                    { value: 'P0', title: 'P0' },
                    { value: 'P1', title: 'P1' },
                    { value: 'P2', title: 'P2' },
                    { value: 'P3', title: 'P3' }
                  ]}
                  onSubmit={(v) => onUpdate({ 'priority': v })}
                />
              </div>
              <div className="severity">
                <InPlaceEdit
                  title="严重性"
                  value={ticket.severity}
                  type="select"
                  displayMode='horizontal'
                  selectOptions={[
                    { value: 'S0', title: 'S0' },
                    { value: 'S1', title: 'S1' },
                    { value: 'S2', title: 'S2' },
                    { value: 'S3', title: 'S3' }
                  ]}
                  onSubmit={(v) => onUpdate({ 'severity': v })}
                />
              </div>
            </Panel>
          </Affix>
        </Col>
      </Row>
    </div >
  );
}

export default TicketDetails;
