
import React, { useState } from 'react';

import InPlaceEdit from 'components/in-place-edit';
import ListItemActions from 'components/list-item-actions';

import FormulaDetails from './formula-details';

import './index.scss';

function NodeDetails({
  node,
  mode,
  depth,
  onChangeDirtyCount,
  onUpdateNode,
  onDeleteNode
}) {
  function increaseDirtyCount() {
    onChangeDirtyCount(1);
  }

  function decreaseDirtyCount() {
    onChangeDirtyCount(-1);
  }

  function getUpdateHandler(fieldName) {
    return (value) => {
      onChangeDirtyCount(-1);
      onUpdateNode(
        node.name,
        { [fieldName]: value }
      );
    };
  }

  function handleAddChildNode() {
    const { children } = node;
    const newChildren = [
      ...children,
      {
        name: '新节点',
        weight: 0,
        formula: [
          {
            condition: { type: 'ELSE' },
            outcome: { type: 'FIXED', value: 0 }
          }
        ],
        children: null
      }
    ];

    onUpdateNode(
      node.name,
      {
        ...node,
        children: newChildren
      }
    );
  }

  function handleDelete() {
    const confirmed = window.confirm(`确定删除节点${node.name}？`);
    if (confirmed) {
      onDeleteNode(node.name);
    }
  }

  const { id, name, weight } = node;

  const nameInput = (
    <InPlaceEdit
      value={name}
      type={(mode === 'READ' || depth === 1) ? 'readonly' : 'text'}
      onSubmit={getUpdateHandler('name')}
      displayMode="horizontal"
      onEdit={increaseDirtyCount}
      onCancel={decreaseDirtyCount}
    />
  );

  const weightInput = (
    <InPlaceEdit
      value={mode === 'READ' ? `${weight}%` : weight}
      type={mode === 'READ' ? 'readonly' : 'percent'}
      onSubmit={getUpdateHandler('weight')}
      displayMode="horizontal"
      onEdit={increaseDirtyCount}
      onCancel={decreaseDirtyCount}
    />
  );

  let addChildNodeDisabled = true;
  if (mode === 'EDIT' && depth < 3 && node.children) {
    addChildNodeDisabled = false;
  }

  let deleteNodeDisabled = true;
  if (mode === 'EDIT' && depth > 1) {
    deleteNodeDisabled = false;
  }

  return (
    <div key={id} className="node-details">
      <div className="node-overall-actions">
        <ListItemActions
          actions={[
            {
              name: '添加子节点',
              onClick: handleAddChildNode,
              disabled: addChildNodeDisabled
            },
            {
              name: '删除节点',
              onClick: handleDelete,
              disabled: deleteNodeDisabled
            }
          ]}
        />
      </div>
      <div>
        <div>名称：</div>
        <div>{nameInput}</div>
      </div>
      {
        (weight !== undefined) &&
        <div>
          <div>权重：</div>
          <div>{weightInput}</div>
        </div>
      }
      <div className="divider"></div>
      <LeafDetails
        node={node}
        depth={depth}
        mode={mode}
        onUpdateNode={onUpdateNode}
        onChangeDirtyCount={onChangeDirtyCount}
      />
    </div>
  );
}

function LeafDetails({
  node,
  depth,
  mode,
  onUpdateNode,
  onChangeDirtyCount
}) {
  function increaseDirtyCount() {
    onChangeDirtyCount(1);
  }

  function decreaseDirtyCount() {
    onChangeDirtyCount(-1);
  }

  function handleChangeCalculation(value) {
    onChangeDirtyCount(-1);
    if (value === '公式') {
      let confirmed = true;
      if (node.children.length > 0) {
        confirmed = window.confirm('节点计算改为公式将会删除所有子节点，确定？');
      }

      if (confirmed) {
        onChangeDirtyCount(-1);
        onUpdateNode(
          node.name,
          {
            formula: [
              {
                condition: { type: 'ELSE' },
                outcome: { type: 'FIXED', value: 0 }
              }
            ],
            children: null
          }
        );
        setCalculation(value);
      }
    } else {
      let confirmed = window.confirm('节点计算改为子节点将会删除当前公式，确定？');

      if (confirmed) {
        onChangeDirtyCount(-1);
        setCalculation(value);
        onUpdateNode(
          node.name,
          {
            formula: null,
            children: []
          }
        );
      }
    }
  }

  const { children, formula } = node;
  const [calculation, setCalculation] = useState(children ? '子节点' : '公式');

  const calculationOptions = [
    { value: '公式', title: '公式' },
    { value: '子节点', title: '子节点' }
  ];

  const calculationInput = (
    <InPlaceEdit
      value={calculation}
      type={(mode === 'READ' || depth !== 2) ? 'readonly' : 'select'}
      selectOptions={calculationOptions}
      onSubmit={handleChangeCalculation}
      displayMode="horizontal"
      onEdit={increaseDirtyCount}
      onCancel={decreaseDirtyCount}
    />
  );

  return (
    <div className="leaf-details">
      <div className="calculation">
        <div>计算：</div>
        <div>{calculationInput}</div>
      </div>
      {
        children &&
        <CategoryDetails node={node} />
      }
      {
        formula &&
        <FormulaDetails
          node={node}
          overallMode={mode}
          onUpdateNode={onUpdateNode}
          onChangeDirtyCount={onChangeDirtyCount}
        />
      }
    </div>
  );
}

function CategoryDetails({
  node
}) {
  const { children } = node;

  return (
    <div className="category-details">
      {
        children.map((child, i) => {
          const { name, weight } = child;
          const sign = i === (children.length - 1) ? '+' : '';

          return (
            <div key={i} className="child-node">
              <div>{sign}</div>
              <div>{name}</div>
              <div>x</div>
              <div>{weight}%</div>
            </div>
          );
        })
      }
      <div className="divider"></div>
      <div className="category-node">
        <div>=</div>
        <div>节点总分</div>
      </div>
    </div>
  );
}

export default NodeDetails;
