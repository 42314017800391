
import env from 'env';

const {
  auth: {
    url
  }
} = env;

export { url };
