
import React from 'react';
import { connect, useSelector } from 'react-redux';

import InfoPanel from './info-panel';
import CustomFieldsPanel from './custom-fields-panel';
import CustomFormulaePanel from './custom-formulae-panel';
import PersonalPanel from './personal-panel';
import ProfilePanel from './profile-panel';
import SubscriptionPanel from './subscription-panel';
import PaymentHistoryPanel from './payment-history-panel';
import SocialMediaPanel from './social-media-panel';
import TagsPanel from './tags-panel';
import CompanyInfoPanel from './company-info-panel';
import CustomersDomain from 'domains/customers';
import MetadataDomain from 'domains/metadata';

import './index.scss';

function BasicInfo({
  customer,
  onUpdate
}) {
  function handleUpdate(field, value) {
    const { id } = customer;
    onUpdate({
      id,
      [field]: value
    });
  };

  const client = useSelector(MetadataDomain.selectors.client);

  return (
    <div className="customer-basic-info">
      <InfoPanel
        customer={customer}
        onUpdate={handleUpdate}
      />
      <SubscriptionPanel
        customer={customer}
        onUpdate={handleUpdate}
      />
      {
        client.type === 'b2c' &&
        <PersonalPanel
          customer={customer}
          onUpdate={handleUpdate}
        />
      }
      {
        client.type === 'b2b' &&
        <CompanyInfoPanel
          customer={customer}
          onUpdate={handleUpdate}
        />
      }
      <ProfilePanel
        customer={customer}
        onUpdate={handleUpdate}
      />
      <CustomFieldsPanel
        customer={customer}
        onUpdate={handleUpdate}
      />
      <CustomFormulaePanel
        customer={customer}
        onUpdate={handleUpdate}
      />
      <TagsPanel
        customer={customer}
      />
      <PaymentHistoryPanel
        customer={customer}
      />
      {
        client.type === 'b2c' &&
        <SocialMediaPanel
          customer={customer}
          onUpdate={handleUpdate}
        />
      }
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    onUpdate(customer) {
      dispatch(
        CustomersDomain.actions.update(customer)
      );
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(BasicInfo);
