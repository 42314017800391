
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { Table, Button, Tabs } from 'antd';

import ListItemActions from 'components/list-item-actions';
import MetadataDomain from 'domains/metadata';
import CustomFormulaeDomain from 'domains/custom-fomulae';
import CustomFieldDrawer from './custom-field-drawer';
import CustomFormulaDrawer from './custom-formula-drawer';
import CustomFieldDeletionModal from './custom-field-deletion-modal';
import CustomFormulaDeletionModal from './custom-formula-deletion-modal';

import './fields-management.scss';

function FieldsManagement({
  selectedObjectId
}) {
  function handleChangeTab(tab) {
    setActiveTab(tab);
  }
  
  function handleHideCustomFieldDrawer() {
    setShowCustomFieldDrawer(false);
    setCustomObject(null);
  }

  function handleShowCustomFieldDrawer(object) {
    setShowCustomFieldDrawer(true);
    setCustomObject(object);
  }

  function handleHideCustomFormulaDrawer() {
    setShowCustomFormulaDrawer(false);
    setCustomObject(null);
  }

  function handleShowCustomFormulaDrawer(object) {
    setShowCustomFormulaDrawer(true);
    setCustomObject(object);
  }

  function handleShowDrawer(object) {
    if (object.type === 'FORMULA') {
      const formula = customFormulae.find(formula => formula.customKey === object.fieldName);
      const customFormula = {
        ...object,
        formulaId: formula.id,
        name: formula.name,
        type: formula.type,
        logic: formula.logic,
      };
      handleShowCustomFormulaDrawer(customFormula);
    } else {
      handleShowCustomFieldDrawer(object);
    }
  }

  function handleHideCustomFieldModal() {
    setShowCustomFieldModal(false);
    setCustomObject(null);
  }

  function handleShowCustomFieldModal(object) {
    setShowCustomFieldModal(true);
    setCustomObject(object);
  }

  function handleHideCustomFormulaModal() {
    setShowCustomFormulaModal(false);
    setCustomObject(null);
  }

  function handleShowCustomFormulaModal(object) {
    setShowCustomFormulaModal(true);
    setCustomObject(object);
  }

  function handleShowModal(object) {
    if (object.type === 'FORMULA') {
      handleShowCustomFormulaModal(object);
    } else {
      handleShowCustomFieldModal(object);
    }
  }

  function handleUpsertCustomField(customField) {
    if (customField.fieldName) {
      dispatch(MetadataDomain.actions.updateCustomField({
        customField: {
          name: customField.fieldName,
          type: customField.type,
          title: customField.title,
        },
        success: handleHideCustomFieldDrawer
      }));
    } else {
      dispatch(MetadataDomain.actions.createCustomField({
        customField,
        success: handleHideCustomFieldDrawer
      }));
    }
  }

  function handleUpsertCustomFormula(customFormula) {
    if (customFormula.fieldName) {
      dispatch(CustomFormulaeDomain.actions.update({
        customFormula: {
          id: customObject.formulaId,
          ...customFormula
        },
        success: handleHideCustomFormulaDrawer
      }));
    } else {
      dispatch(CustomFormulaeDomain.actions.create({
        customFormula,
        success: handleHideCustomFormulaDrawer
      }));
    }
  }

  function handleDeleteCustomField() {
    dispatch(MetadataDomain.actions.deleteCustomField({
      name: customObject.fieldName,
      success: handleHideCustomFieldModal
    }));

    dispatch(CustomFormulaeDomain.actions.readList());
  }

  function handleDeleteCustomFormula() {
    const formulae = customFormulae.filter(formula => formula.customKey === customObject.fieldName);
    dispatch(CustomFormulaeDomain.actions.delete({
      id: formulae[0].id,
      name: customObject.fieldName,
      success: handleHideCustomFormulaModal
    }));
  }

  const [interactingId, setInteractingId] = useState(null);
  const [customObject, setCustomObject] = useState(null);
  const [showCustomFieldDrawer, setShowCustomFieldDrawer] = useState(false);
  const [showCustomFormulaDrawer, setShowCustomFormulaDrawer] = useState(false);
  const [showCustomFieldModal, setShowCustomFieldModal] = useState(false);
  const [showCustomFormulaModal, setShowCustomFormulaModal] = useState(false);
  const [activeTab, setActiveTab] = useState('基本信息');

  const dispatch = useDispatch();

  const fieldsMetadataSelector = MetadataDomain.getFieldsMetadataSelector({
    objectId: selectedObjectId
  });
  let fieldsMetadata = useSelector(fieldsMetadataSelector);
  const customFormulae = useSelector(CustomFormulaeDomain.selectors.data);
  const fieldsMetadataWithToday = {
    ...fieldsMetadata,
    today: {
      title: '今天'
    }
  }
  const customFormulaeMap = customFormulae.reduce((total, curr) => {
    const { type, logic } = curr;
    if (type === 'NUMBER') {
      const preField = (
        fieldsMetadataWithToday[logic.preField] && fieldsMetadataWithToday[logic.preField].title
      ) || logic.preField;
      const sufField = (
        fieldsMetadataWithToday[logic.sufField] && fieldsMetadataWithToday[logic.sufField].title
      ) || logic.sufField;
      total[curr.customKey] = `${preField} ${logic.operator} ${sufField}`;
    } else if (type === 'STRING') {
      total[curr.customKey] = (
        <div>
          {
            logic.map(({ condition, outcome }, i) => {
              const { type, fieldName, operator, value } = condition;
              if (type === 'ELSE') return <p key={i}>不然则 = {outcome}</p>;
              return <p key={i}>如果 {fieldName} {operator} {value} 则 = {outcome}</p>;
            })
          }
        </div>
      );
    }
    return total;
  }, {});

  fieldsMetadata = Object.keys(fieldsMetadata).reduce((total, curr) => {
    const fieldMetadata = {
      fieldName: curr,
      ...fieldsMetadata[curr]
    };
    let { fieldName, category, isFormula } = fieldMetadata;
    if (category === undefined) {
      if (isFormula) {
        category = '自定义公式';
      } else if (fieldName.match(/^custom_string_/) || fieldName.match(/^custom_number_/) || fieldName.match(/^custom_date_/)) {
        category = '自定义字段';
      } else {
        category = '无分类';
      }
    }

    if (total[category]) {
      total[category].push(fieldMetadata);
    } else {
      total[category] = [fieldMetadata];
    }
    return total;
  }, {});

  const columns = getColumns({
    interactingId,
    setInteractingId,
    onOpenDrawer: handleShowDrawer,
    onOpenDeleteModal: handleShowModal,
    customFormulaeMap
  });

  return (
    <div className="fields-management">
      <Tabs activeKey={activeTab} onChange={handleChangeTab}>
        {Object.keys(fieldsMetadata).map((category) => {
          const categoryFieldsMetadata = fieldsMetadata[category];

          return (
            <Tabs.TabPane key={category} tab={category}>
              <div key={category} className="category-fields-management">
                <div className="title">{category}</div>
                {
                  category === '自定义字段' &&
                  <div className="custom-button">
                    <Button
                      type="primary"
                      onClick={() => handleShowCustomFieldDrawer(null)}
                    >
                      添加{category}
                    </Button>
                  </div>
                }
                {
                  category === '自定义公式' &&
                  <div className="custom-button">
                    <Button
                      type="primary"
                      onClick={() => handleShowCustomFormulaDrawer(null)}
                    >
                      添加{category}
                    </Button>
                  </div>
                }
                <Table
                  rowKey={(record) => (record.fieldName)} // switch to id later
                  columns={columns}
                  dataSource={categoryFieldsMetadata}
                  pagination={false}
                />
              </div>
            </Tabs.TabPane>
          );
        })}
      </Tabs>
      {
        showCustomFieldDrawer &&
        <CustomFieldDrawer
          customField={customObject}
          onSubmit={handleUpsertCustomField}
          onCancel={handleHideCustomFieldDrawer}
        />
      }
      {
        showCustomFormulaDrawer &&
        <CustomFormulaDrawer
          customFormula={customObject}
          onSubmit={handleUpsertCustomFormula}
          onCancel={handleHideCustomFormulaDrawer}
        />
      }
      {
        showCustomFieldModal &&
        <CustomFieldDeletionModal
          title={customObject.title}
          onSubmit={handleDeleteCustomField}
          onCancel={handleHideCustomFieldModal}
        />
      }
      {
        showCustomFormulaModal &&
        <CustomFormulaDeletionModal
          customFormula={customObject}
          customFormulaeMap={customFormulaeMap}
          onSubmit={handleDeleteCustomFormula}
          onCancel={handleHideCustomFormulaModal}
        />
      }
    </div>
  );
}

function getColumns({
  interactingId,
  setInteractingId,
  onOpenDrawer,
  onOpenDeleteModal,
  customFormulaeMap
}) {
  return [
    {
      dataIndex: 'title',
      title: '名称',
      width: '25%'
    },
    {
      dataIndex: 'type',
      title: '类型',
      width: '10%',
      render(text) {
        return {
          STRING: '文字',
          NUMBER: '数字',
          DATE: '日期',
          SELECT: '选择',
          REFERENCE: '引用'
        }[text];
      }
    },
    {
      dataIndex: 'fieldName',
      title: 'API 名称',
      width: '20%'
    },
    {
      title: '细节',
      width: '40%',
      render: (text, record) => {
        const { type } = record;
        if (type === 'SELECT') {
          return record.options
            .map((option) => option.title)
            .join('，');
        } else if (type === 'REFERENCE') {
          const { target, targetFieldName, targetTitleFieldName } = record;
          return `指向 ${target}.${targetFieldName}，显示 ${target}.${targetTitleFieldName}`;
        } else if (record.isFormula) {
          const { fieldName } = record;
          return customFormulaeMap[fieldName];
        }
      }
    },
    {
      width: '5%',
      render: (text, record) => {
        const className = classnames(
          'actions',
          { interacting: record.id === interactingId }
        );
        const { fieldName } = record;
        const disabled = !fieldName.match(/^custom_/);
        return (
          <div className={className}>
            <ListItemActions
              actions={[
                { name: '修改', onClick: () => onOpenDrawer(record), disabled: disabled },
                { name: '删除', onClick: () => onOpenDeleteModal(record), disabled: disabled }
              ]}
              onClick={() => setInteractingId(record.id)}
              onCancel={() => setInteractingId(null)}
            />
          </div>
        );
      },
    }
  ];
}

export default FieldsManagement;
