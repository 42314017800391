
import React from 'react';

import Panel from 'components/panel';

function ChartPanel({
  title,
  actions,
  children,
  size = 'medium',
  customHeight
}) {
  const height = customHeight || {
    large: 500,
    medium: 300,
    small: 150,
  }[size];

  const paddingTop = size === 'small' ? 5 : 10;

  return (
    <div style={{ minWidth: 400, width: '100%' }}>
      <Panel
        title={title}
        actions={actions}
        style={{ height, paddingTop }}
      >
        {children}
      </Panel>
    </div>
  )
}

export default ChartPanel;
