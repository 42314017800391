
import API from 'adapters/api';

/**
 * state structure:
 * {
 *   data: [
 *     {TicketComment}
 *   ]
 * }
 */

const TicketComments = {
  selectors: {},
  actions: {}
};

TicketComments.selectors.data = (state) => state.ticketComments.data;

TicketComments.actions.readList = (ticketId) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'ticket-comments',
        action: 'readList',
        params: { ticketId }
      });

      if (status < 300) {
        dispatch({
          type: 'ticketCommentsRefreshData',
          data: data.ticketComments,
        });
      }

      return { status, data };
    }
  }
};

TicketComments.actions.create = ({
  ticketComment,
  success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'ticket-comments',
        action: 'create',
        data: ticketComment
      });

      if (status < 300) {
        dispatch({
          type: 'ticketCommentsAdd',
          data: data.ticketComment
        });

        success && success();
      }

      return { status, data };
    }
  }
};

TicketComments.actions.update = ({
  ticketComment,
  success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'ticket-comments',
        action: 'update',
        data: ticketComment
      });

      if (status < 300) {
        dispatch({
          type: 'ticketCommentsUpdate',
          data: data.ticketComment
        });

        success && success();
      }

      return { status, data };
    }
  }
};

TicketComments.actions.delete = ({
  id,
  success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'ticket-comments',
        action: 'delete',
        data: { id }
      });

      if (status < 300) {
        dispatch({
          type: 'ticketCommentsRemove',
          data: { id }
        });

        success && success();
      }

      return { status, data };
    }
  }
};

const reducers = {
  ticketComments(state, action) {
    if (state === undefined) return { data: [] };

    if (action.type === 'ticketCommentsAdd') {
      return {
        ...state,
        data: [
          ...state.data,
          action.data
        ]
      };
    } else if (action.type === 'ticketCommentsRefreshData') {
      return {
        ...state,
        data: action.data
      };
    } else if (action.type === 'ticketCommentsUpdate') {
      return {
        ...state,
        data: state.data.map((datum) => {
          if (datum.id === action.data.id) {
            return {
              ...datum,
              ...action.data
            };
          }
          return datum;
        })
      }
    } else if (action.type === 'ticketCommentsRemove') {
      return {
        ...state,
        data: state.data.filter((datum) => datum.id !== action.data.id)
      };
    }

    return state;
  }
}

export { reducers };
export default TicketComments;
