
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import {
  Tabs,
  Button
} from 'antd';
import {
  StarOutlined, ArrowLeftOutlined, ArrowRightOutlined
} from '@ant-design/icons';

import ListItemActions from 'components/list-item-actions';
import CustomerLists from 'domains/customer-lists';
import Customers from 'domains/customers';
import UserPins from 'domains/user-pins';
import Me from 'domains/me';
import ListModal from './list-modal';
import DeleteModal from './delete-modal';

import './index.scss';

function ListsSelection({
  collapsed, setCollapsed
}) {
  function isSaveDisabled() {
    if (!hasAppliedFilters) return true;

    if (selected === undefined) return true;
    return !selected.ownerId;
  }

  function handleSelectList(id) {
    if (selected.id === id) return;
    dispatch(CustomerLists.actions.select(id));
  }

  function handleAddLiveList() {
    setModalDisableName(false);
    setModalList(null);
    setModalListType('LIVE');
    setShowModal(true);
  }

  function handleSaveLiveListFilters() {
    dispatch(CustomerLists.actions.update({
      data: { id: selected.id, filters: effectiveFilters }
    }));
  }

  function handleOpenUpdateStaticListModal() {
    setModalDisableName(true);
    setModalList(selected);
    setModalListType('STATIC');
    setModalText(`更新静态列表，新列表共有 ${count} 客户`);
    setShowModal(true);
  }

  function handleOpenCreateStaticListModal() {
    setModalDisableName(false);
    setModalList(null);
    setModalListType('STATIC');
    setModalText(`创建一共有 ${count} 个客户的列表`);
    setShowModal(true);
  }

  function handleModalSubmit({ name }) {
    if (modalList) {
      dispatch(CustomerLists.actions.update({
        data: { id: selected.id, filters: effectiveFilters },
        success() { setShowModal(false); }
      }));
    } else {
      const baseListId = (modalListType === 'STATIC' && selected.type === 'STATIC') ?
        selected.id : undefined;
      dispatch(CustomerLists.actions.create({
        name,
        type: modalListType,
        filters: effectiveFilters,
        baseListId,
        me,
        success() { setShowModal(false); }
      }));
    }
  }

  function handleModalCancel() {
    setShowModal(false);
  }

  function getLiveLists() {
    return lists.filter((list) => list.type === 'LIVE')
  }

  function getStaticLists() {
    return lists.filter((list) => list.type === 'STATIC')
  }

  function listToComponent(list) {
    const isSelected = selected && (selected.id === list.id);
    const pin = listPins.find((pin) => pin.referenceId === list.id);
    return (
      <List
        key={list.id}
        list={list}
        isSelected={isSelected}
        onSelectList={handleSelectList}
        pin={pin}
      />
    );
  }

  function handleTabChange(key) {
    setActiveTabKey(key);
    if (key === 'LIVE') {
      handleSelectList(getLiveLists()[0].id);
    } else {
      handleSelectList(getStaticLists()[0].id);
    }
  }

  function handleCollapse(e) {
    if (collapsed) setCollapsed(false);
    else setCollapsed(true);
  }

  const dispatch = useDispatch();

  const lists = useSelector(CustomerLists.selectors.data);
  const selected = useSelector(CustomerLists.selectors.selected);
  const hasAppliedFilters = useSelector(CustomerLists.selectors.hasAppliedFilters);
  const effectiveFilters = useSelector(CustomerLists.selectors.effectiveFilters);
  const me = useSelector(Me.selectors.data);
  const count = useSelector(Customers.selectors.count);
  const listPins = useSelector(UserPins.selectors.pinnedItems('customerLists'));

  const [showModal, setShowModal] = useState(false);
  const [modalListType, setModalListType] = useState(null);
  const [modalText, setModalText] = useState(null);
  const [modalList, setModalList] = useState(null);
  const [modalDisableName, setModalDisableName] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('LIVE');

  useEffect(() => {
    if (activeTabKey === 'STATIC' && getStaticLists().length === 0) {
      setActiveTabKey('LIVE');
    }
  }, [lists]);

  return (
    <div className={`lists-selection collapsed-${collapsed}`}>
      {
        showModal &&
        <ListModal
          type={modalListType}
          list={modalList}
          name={modalDisableName ? false : ''}
          text={modalText}
          onSubmit={handleModalSubmit}
          onCancel={handleModalCancel}
        />
      }
      <div>
        <div className="collapse-button" onClick={handleCollapse}>
          {collapsed ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
        </div>
        <Tabs onChange={handleTabChange} activeKey={activeTabKey}>
          <Tabs.TabPane key="LIVE" tab="筛选条件">
            <div className="lists-list">
              {
                getLiveLists().map(listToComponent)
              }
            </div>
            <div className="actions">
              <Button onClick={handleOpenCreateStaticListModal}>
                另存为静态列表
              </Button>
              <Button onClick={handleAddLiveList}>
                另存为
              </Button>
              <Button
                type="primary"
                disabled={isSaveDisabled()}
                onClick={handleSaveLiveListFilters}
              >
                保存
              </Button>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            key="STATIC"
            tab="静态列表"
            disabled={getStaticLists().length === 0}
          >
            <div className="lists-list">
              {
                getStaticLists().map(listToComponent)
              }
            </div>
            <div className="actions">
              <Button
                onClick={handleOpenCreateStaticListModal}
              >
                另存为
              </Button>
              <Button
                type="primary"
                disabled={isSaveDisabled()}
                onClick={handleOpenUpdateStaticListModal}
              >
                保存
              </Button>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
}

function List({
  list, isSelected, onSelectList, pin
}) {
  function isSystemOwned() {
    return !list.ownerId;
  }

  function handleInteracting() {
    setInteracting(true);
  }

  function handleCancelInteracting() {
    setInteracting(false);
  }

  function handleSelectList() {
    onSelectList(list.id);
  }

  function handleOpenDeleteModal() {
    setShowDeleteModal(true);
  }

  function handleDelete() {
    dispatch(CustomerLists.actions.delete({
      id: list.id
    }));
  }

  function handleCancelDelete() {
    setInteracting(false);
    setShowDeleteModal(false);
  }

  function handleOpenUpdateModal() {
    setShowUpdateModal(true);
  }

  function handleUpdate({ name }) {
    dispatch(CustomerLists.actions.update({
      data: { id: list.id, name },
      success() {
        setInteracting(false);
        setShowUpdateModal(false);
      }
    }));
  }

  function handleCancelUpdate() {
    setInteracting(false);
    setShowUpdateModal(false);
  }

  function handlePin() {
    dispatch(UserPins.actions.create({
      referenceTable: 'customerLists',
      referenceId: list.id
    }, () => {
      setInteracting(false);
    }));
  }

  function handleUnpin() {
    dispatch(UserPins.actions.delete(
      { id: pin.id },
      () => { setInteracting(false); }
    ));
  }

  function isActive() {
    return interacting || isSelected;
  }

  const [interacting, setInteracting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const dispatch = useDispatch();

  const className = classnames('list', { active: isActive() });
  let pinAction = { title: '关注', onClick: handlePin };
  if (pin) {
    pinAction = { title: '取消关注', onClick: handleUnpin };
  }

  return (
    <div
      key={list.id}
      className={className}
    >
      <div>{pin && <StarOutlined />}</div>
      <div onClick={handleSelectList}>{list.name}</div>
      <ListItemActions
        actions={[
          { name: '更改名称', onClick: handleOpenUpdateModal, disabled: isSystemOwned() },
          { name: pinAction.title, onClick: pinAction.onClick, disabled: isSystemOwned() },
          { name: '删除', onClick: handleOpenDeleteModal, disabled: isSystemOwned() }
        ]}
        onClick={handleInteracting}
        onCancel={handleCancelInteracting}
      />
      {
        showDeleteModal &&
        <DeleteModal
          type={list.type}
          name={list.name}
          onSubmit={handleDelete}
          onCancel={handleCancelDelete}
        />
      }
      {
        showUpdateModal &&
        <ListModal
          type={list.type}
          action={'UPDATE'}
          name={list.name}
          onSubmit={handleUpdate}
          onCancel={handleCancelUpdate}
        />
      }
    </div>
  );
}

export default ListsSelection;
