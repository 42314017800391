
/**
 * state format:
 * {
 *   data,
 *   spec,
 *   specVersion,
 *   config,
 *   notifications
 * }
 * 
 * data format:
 * {
 *   metrics: {
 *     <metric name>: [
 *       {
 *         datetime: <ISO 8601 date format>,
 *         value: <metric value>,
 *         splitValue: 'current' | 'past' | <dimension value> // TODO: better name?
 *       }  
 *     ]
 *   },
 *   dimensions: {
 *     <dimension name>: [
 *       {
 *         value: <dimension value>
 *         metricValue: <metric value>
 *         delta: <metric delta value>
 *       }
 *     ]
 *   }
 * }
 * 
 * spec format:
 * {
 *   timeFrame: 'CURRENT_MONTH' | 'RECENT_3_MONTHS' | 'RECENT_6_MONTHS'
 *   granularity: 'DAY' | 'MONTH'
 *   metrics: [<metric name>]
 *   dimensionTableMetric: <metric name>
 *   dimensions: {
 *     <dimension name>: {
 *       mode: 'SELECT' | 'EXCLUDE' | 'COMPARE'
 *       values: [<dimension value>]
 *     }
 *   }
 * }
 */

import API from 'adapters/api';

const fakeData = {};

fakeData.notifications = [
  /*
  {
    type: 'warning',
    message: '2021 年 1 月份，深圳 D 店，深圳 E 店，深圳 F 店转化率大幅度降低',
    spec: {} // TODO
  }
  */
];

const TimeSeriesExploration = {
  actions: {},
  selectors: {}
};

TimeSeriesExploration.selectors.spec = (state) => {
  return state.timeSeriesExploration.spec;
};

TimeSeriesExploration.selectors.specVersion = (state) => {
  return state.timeSeriesExploration.specVersion;
};

TimeSeriesExploration.selectors.data = (state) => {
  return state.timeSeriesExploration.data;
};

TimeSeriesExploration.selectors.config = (state) => {
  return state.timeSeriesExploration.config;
};

TimeSeriesExploration.selectors.notifications = (state) => {
  return state.timeSeriesExploration.notifications;
};

TimeSeriesExploration.selectors.comparisonDimensionName = (state) => {
  const { dimensions } = state.timeSeriesExploration.spec;
  const compareDimensionName = Object
    .keys(dimensions)
    .find((d) => dimensions[d].mode === 'COMPARE');
  return compareDimensionName;
};

TimeSeriesExploration.actions.init = ({ spec, config }) => {
  return {
    type: 'timeSeriesExplorationInit',
    spec,
    config
  };
};

TimeSeriesExploration.actions.query = () => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const { spec } = state.timeSeriesExploration;

      console.log('*** requesting with spec', spec);
      const { status, data } = await API.request({
        resource: 'analytics/composite',
        action: 'time-series',
        data: spec
      });
      console.log('*** received data', data);

      const processedData = {
        metrics: processMetricsData(data.timeSeries.metrics, spec),
        dimensions: data.timeSeries.dimensions
      };

      if (status < 300) {
        dispatch({
          type: 'timeSeriesExplorationDataUpdate',
          data: processedData
        });

        dispatch({
          type: 'timeSeriesExplorationSpecRemoveInvalid',
          data: processedData
        });
      }

      return { status, data: data.timeSeries };
    }
  }
};

function processMetricsData(data, spec) {
  return spec.metrics.reduce((all, curr) => {
    all[curr] = processMetricData(data[curr], spec);
    return all;
  }, {});
}

function processMetricData(data, spec) {
  const { granularity, dimensions, timeFrame } = spec;

  let compareDimensionValues = ['current', 'past'];
  Object.keys(dimensions).forEach((dimensionName) => {
    const dimensionSpec = spec.dimensions[dimensionName];
    if (dimensionSpec.mode === 'COMPARE') {
      compareDimensionValues = dimensionSpec.values;
    }
  });

  const dataDateIndexed = data.reduce((all, curr) => {
    const { timestamp, splitValue } = curr;
    if (all[timestamp]) {
      all[timestamp][splitValue] = curr;
    } else {
      all[timestamp] = {
        [splitValue]: curr
      };
    }
    return all;
  }, {});

  const fill = [];

  const fillDateStrings = getFillDateStrings(timeFrame, granularity);

  fillDateStrings.forEach((dateString) => {
    const presentSplitValues = Object.keys(dataDateIndexed[dateString] || {});
    const missingSplitValues = compareDimensionValues.filter((value) => {
      return !presentSplitValues.includes(value);
    });
    missingSplitValues.forEach((value) => {
      fill.push({
        value: 0,
        timestamp: dateString,
        splitValue: value
      });
    });
  });

  const sorted = [...data, ...fill].sort((a, b) => {
    const aNum = Number(a.timestamp.replace(/-/g, ''));
    const bNum = Number(b.timestamp.replace(/-/g, ''));
    return aNum - bNum;
  });

  return sorted;
}

function getFillDateStrings(timeFrame, granularity) {
  const fillDateStrings = [];

  const { start, end } = timeFrameStringToStartEndDate(timeFrame);

  const startDate = new Date(start);
  const endDate = new Date(end);

  while (endDate >= startDate) {
    const dateString = dateToString(startDate);
    if (granularity === 'DAY') {
      fillDateStrings.push(dateString);
      startDate.setDate(startDate.getDate() + 1);
    } else {
      fillDateStrings.push(dateString.substring(0, 7));
      startDate.setMonth(startDate.getMonth() + 1);
    }
  }

  return fillDateStrings;
}

function dateToString(date) {
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  if (month < 10) month = `0${month}`;
  if (day < 10) day = `0${day}`;

  return `${year}-${month}-${day}`;
}

function timeFrameStringToStartEndDate(timeFrameString) {
  let recentNMonths;
  if (timeFrameString === 'CURRENT_MONTH') {
    recentNMonths = 1;
  } else {
    recentNMonths = parseInt(timeFrameString.split('_')[1]);
  }

  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - recentNMonths + 1); // most recent 6 months
  startDate.setDate(1);
  const startDateString = `
    ${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}
  `.trim();

  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 1);
  const endDateString = `
    ${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}
  `.trim();

  return {
    start: startDateString,
    end: endDateString
  };
}

TimeSeriesExploration.actions.timeFrameUpdate = (timeFrame) => {
  return {
    type: 'timeSeriesExplorationTimeFrameUpdate',
    timeFrame
  };
};

TimeSeriesExploration.actions.granularityUpdate = (granularity) => {
  return {
    type: 'timeSeriesExplorationGranularityUpdate',
    granularity
  };
};

TimeSeriesExploration.actions.dimensionTableMetricUpdate = (metric) => {
  return {
    type: 'timeSeriesExplorationDimensionTableMetricUpdate',
    metric
  };
};

TimeSeriesExploration.actions.dimensionAddSelection = (name, value) => {
  return {
    type: 'timeSeriesExplorationDimensionAddSelection',
    name,
    value
  };
};

TimeSeriesExploration.actions.dimensionRemoveSelection = (name, value) => {
  return {
    type: 'timeSeriesExplorationDimensionRemoveSelection',
    name,
    value
  };
};

TimeSeriesExploration.actions.dimensionChangeMode = (name, mode) => {
  return {
    type: 'timeSeriesExplorationDimensionChangeMode',
    name,
    mode
  };
};

TimeSeriesExploration.actions.dimensionReset = (name) => {
  return {
    type: 'timeSeriesExplorationDimensionReset',
    name
  };
};

const reducers = {
  timeSeriesExploration(state, action) {
    if (state === undefined) return {
      notifications: fakeData.notifications,
      data: null,
      spec: null,
      specVersion: 0,
      config: null
    };

    if (action.type === 'timeSeriesExplorationInit') {
      return {
        ...state,
        notifications: fakeData.notifications,
        data: null,
        spec: action.spec,
        specVersion: state.specVersion + 1,
        config: action.config
      };
    } else if (action.type === 'timeSeriesExplorationSpecUpdate') {
      return {
        ...state,
        spec: action.spec,
        specVersion: state.specVersion + 1
      };
    } else if (action.type === 'timeSeriesExplorationSpecRemoveInvalid') {
      const { dimensions } = state.spec;
      const { data } = state;
      const newDimensions = Object.keys(dimensions).reduce((total, curr) => {
        const { mode, values } = dimensions[curr];
        const newValues = values.filter((val) => {
          const found = data.dimensions[curr].find((d) => d.value === val);
          return found !== undefined;
        });

        let newMode = mode;
        if (newMode === 'COMPARE' && newValues.length < 2) {
          newMode = 'SELECT';
        }
        total[curr] = {
          mode: newMode,
          values: newValues
        };
        return total;
      }, {});

      return {
        ...state,
        spec: {
          ...state.spec,
          dimensions: newDimensions
        }
      };
    } else if (action.type === 'timeSeriesExplorationDataUpdate') {
      return {
        ...state,
        data: action.data
      };
    } else if (action.type === 'timeSeriesExplorationTimeFrameUpdate') {
      return {
        ...state,
        spec: {
          ...state.spec,
          granularity: action.timeFrame === 'CURRENT_MONTH' && state.spec.granularity === 'MONTH' 
            ? 'DAY' : state.spec.granularity,
          timeFrame: action.timeFrame
        },
        specVersion: state.specVersion + 1
      };
    } else if (action.type === 'timeSeriesExplorationGranularityUpdate') {
      return {
        ...state,
        spec: {
          ...state.spec,
          granularity: action.granularity
        },
        specVersion: state.specVersion + 1
      };
    } else if (action.type === 'timeSeriesExplorationDimensionTableMetricUpdate') {
      return {
        ...state,
        spec: {
          ...state.spec,
          dimensionTableMetric: action.metric
        },
        specVersion: state.specVersion + 1
      };
    } else if (action.type === 'timeSeriesExplorationDimensionAddSelection') {
      const dimension = state.spec.dimensions[action.name];
      const newValues = [...dimension.values, action.value];
      const newDimension = {
        ...dimension,
        values: newValues
      };

      return {
        ...state,
        specVersion: state.specVersion + 1,
        spec: {
          ...state.spec,
          dimensions: {
            ...state.spec.dimensions,
            [action.name]: newDimension
          }
        }
      };
    } else if (action.type === 'timeSeriesExplorationDimensionRemoveSelection') {
      let { values, mode, ...rest } = state.spec.dimensions[action.name];
      values = values.filter((value) => value !== action.value);
      if (mode === 'COMPARE' && values.length < 2) mode = 'SELECT';

      const newDimension = {
        ...rest,
        values,
        mode
      };

      return {
        ...state,
        specVersion: state.specVersion + 1,
        spec: {
          ...state.spec,
          dimensions: {
            ...state.spec.dimensions,
            [action.name]: newDimension
          }
        }
      };
    } else if (action.type === 'timeSeriesExplorationDimensionChangeMode') {
      const dimension = state.spec.dimensions[action.name];

      return {
        ...state,
        specVersion: state.specVersion + 1,
        spec: {
          ...state.spec,
          dimensions: {
            ...state.spec.dimensions,
            [action.name]: { ...dimension, mode: action.mode }
          }
        }
      };
    } else if (action.type === 'timeSeriesExplorationDimensionReset') {
      return {
        ...state,
        specVersion: state.specVersion + 1,
        spec: {
          ...state.spec,
          dimensions: {
            ...state.spec.dimensions,
            [action.name]: { mode: 'SELECT', values: [] }
          }
        }
      };
    }

    return state;
  }
}

export { reducers };
export default TimeSeriesExploration;
