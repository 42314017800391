
import React from 'react';

const headers = ['', '当月'];
for (let i = 2; i <= 12; i++) {
  headers.push(`第 ${i} 个月`);
}

function CohortTable({
  data,
  splitField,
  aggregationType
}) {
  const processedData = processData(data || [], splitField);

  return (
    <div className="cohort">
      <div>{headers.map((header, i) => <div key={i}>{header}</div>)}</div>
      {
        Object.entries(processedData)
          .sort((a, b) => new Date(a[0]) - new Date(b[0]))
          .map(([key, val]) => {
            return (
              <div key={key}>
                {
                  headers.map((_, i) => {
                    if (i === 0) return <div>{key}</div>;
                    return <div className="cell">{formatValue(val[i - 1], key, aggregationType)}</div>;
                  })
                }
              </div>
            );
        })
      }
    </div>
  );
}

function processData(data, splitField) {
  const processedData = {};

  data
    .forEach((datum) => {
      const splitFieldValue = datum[splitField];
      const { monthsSinceBaseline, value } = datum;

      if (processedData[splitFieldValue]) {
        processedData[splitFieldValue][monthsSinceBaseline] = value;
      } else {
        processedData[splitFieldValue] = {
          [monthsSinceBaseline]: value
        };
      }
    });

  return processedData;
}

function formatValue(value, cohortValue, aggregationType) {
  if (value === undefined) return;
  let numVal = parseFloat(value);
  numVal = Math.round(numVal * 10) / 10;
  return numVal.toFixed(1);
}

export default CohortTable;
