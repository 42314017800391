
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button, Select
} from 'antd';

// import MetadataDomain from 'domains/metadata';
import AnalyticsDomain from 'domains/analytics';
import Panel from 'components/panel';
import CohortChart from './cohort-chart';
import CohortTable from './cohort-table';

import './index.scss';

const metrics = [
  {
    value: 'churn.COUNT',
    title: '流失数量'
  },
  {
    value: 'healthScores.AVG',
    title: '平均健康分'
  }
];

const cohorts = [
  {
    value: 'customers.channel',
    title: '客户.渠道'
  },
  {
    value: 'customers.nps',
    title: '客户.NPS'
  },
  {
    value: 'customers.registrationDateMonth',
    title: '客户.注册日期'
  },
  {
    value: 'customers.subscriptionDateMonth',
    title: '客户.订阅日期'
  }
];

const baselines = [
  {
    value: 'customers.registrationDateMonth',
    title: '客户.注册日期'
  },
  {
    value: 'customers.subscriptionDateMonth',
    title: '客户.订阅日期'
  }
];

/*
 * spec format:
 * {
 *   metricName: <String>,
 *   aggregation: {
 *     type: <aggregation type>
 *   },
 *   cohort: {
 *     dimensionName: <String>,
 *     dimensionFieldName: <String>
 *   },
 *   baseline: {
 *     dimensionName: <String>,
 *     dimensionFieldName: <String>
 *   }
 * }
 */

function ChurnCohorts() {
  function handleChangeBaseline(value) {
    setBaseline(value);
  }

  function handleChangeCohort(value) {
    setCohort(value);
  }

  function handleChangeMetric(value) {
    setMetric(value);
  }

  function getSpec(metric, cohort, baseline) {
    const [metricName, aggregationType] = metric.split('.');
    const [cohortDimensionName, cohortDimensionFieldName] = cohort.split('.');
    const [baselineDimensionName, baselineDimensionFieldName] = baseline.split('.');

    return {
      metricName,
      aggregation: {
        type: aggregationType
      },
      cohort: {
        dimensionName: cohortDimensionName,
        dimensionFieldName: cohortDimensionFieldName
      },
      baseline: {
        dimensionName: baselineDimensionName,
        dimensionFieldName: baselineDimensionFieldName
      }
    };
  }

  function handleSubmit() {
    const spec = getSpec(metric, cohort, baseline);
    setAppliedCohort(cohort);
    setAppliedBaseline(baseline);
    setAppliedMetric(metric);
    dispatch(AnalyticsDomain.actions.queryCohort(spec));
  }

  // const analyticsMetadata = useSelector(MetadataDomain.selectors.analytics);
  const dispatch = useDispatch();
  const [baseline, setBaseline] = useState(baselines[0].value);
  const [appliedBaseline, setAppliedBaseline] = useState(baseline);
  const [cohort, setCohort] = useState(cohorts[0].value);
  const [appliedCohort, setAppliedCohort] = useState(cohort);
  const [metric, setMetric] = useState(metrics[0].value);
  const [appliedMetric, setAppliedMetric] = useState(metric);
  const data = useSelector(
    AnalyticsDomain.selectors.cohort
  )[JSON.stringify(getSpec(appliedMetric, appliedCohort, appliedBaseline))];

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <div className="cohorts">
      <Panel>
        <div className="overall-control">
          <div className="options">
            <div>
              <div>指标选择</div>
              <Select value={metric} onChange={handleChangeMetric}>
                {
                  metrics.map((metric, i) => {
                    return (
                      <Select.Option key={i} value={metric.value}>
                        <p>{metric.title}</p>
                      </Select.Option>
                    );
                  })
                }
              </Select>
            </div>
            <div>
              <div>群组选择</div>
              <Select value={cohort} onChange={handleChangeCohort}>
                {
                  cohorts.map((cohort, i) => {
                    return (
                      <Select.Option key={i} value={cohort.value}>
                        <p>{cohort.title}</p>
                      </Select.Option>
                    );
                  })
                }
              </Select>
            </div>
            <div>
              <div>时间基准</div>
              <Select value={baseline} onChange={handleChangeBaseline}>
                {
                  baselines.map((baseline, i) => {
                    return (
                      <Select.Option key={i} value={baseline.value}>
                        <p>{baseline.title}</p>
                      </Select.Option>
                    );
                  })
                }
              </Select>
            </div>
          </div>
          <Button type="primary" onClick={handleSubmit}>
            <span>提交</span>
          </Button>
        </div>
      </Panel>
      <div style={{ height: 10 }}></div>
      <Panel>
        <div className="line-chart">
          <CohortChart data={data} splitField={appliedCohort} />
        </div>
      </Panel>
      <div style={{ height: 10 }}></div>
      <Panel title="群组">
        <CohortTable data={data} splitField={appliedCohort} />
      </Panel>
    </div>
  );
}

export default ChurnCohorts;
