
import React, { useEffect } from 'react';
import { Tabs, Result } from 'antd';

import ChurnPrediction from './churn-prediction';

import './index.scss';

function AIAssistant() {
  useEffect(() => {
    document.title = '云策智慧 - AI 助手';
  }, []);

  return (
    <div className="page-ai-assistant">
      <Tabs>
        <Tabs.TabPane key="decision-tree" tab="流失因素分析">
          <ChurnPrediction />
        </Tabs.TabPane>
        <Tabs.TabPane key="customer-metrics" tab="客户指标 AI 探索">
          <Result
            status="warning"
            title="抱歉，数据数据 AI 探索尚在开发中！"
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default AIAssistant;
