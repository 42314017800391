
import API from 'adapters/api';

/**
 * state structure:
 * {
 *   definitions: [
 *     {CustomerMetricDefinition}
 *   ],
 *   perCustomer: {
 *     [customerId]: [
 *       {
 *         name: <String>,
 *         value: <Number>
 *       }
 *     ]
 *   }
 * }
 */

const CustomerMetrics = {
  selectors: {},
  actions: {}
};

CustomerMetrics.selectors.definitions = (state) => state.customerMetrics.definitions;
CustomerMetrics.selectors.perCustomer = (state) => state.customerMetrics.perCustomer;
CustomerMetrics.selectors.overall = (state) => state.customerMetrics.overall;

CustomerMetrics.actions.readPerCustomer = (customerId) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `customer-metrics?customerId=${customerId}`,
        action: 'read'
      });

      if (status < 300) {
        const { metrics } = data;

        dispatch({
          type: 'customerMetricsPerCustomerRefreshData',
          customerId,
          data: metrics,
        });
      }

      return { status, data };
    }
  }
};

CustomerMetrics.actions.readOverall = () => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `customer-metrics?customerId=overall`,
        action: 'read'
      });

      if (status < 300) {
        const { metrics } = data;

        dispatch({
          type: 'customerMetricsOverallRefreshData',
          data: metrics,
        });
      }

      return { status, data };
    }
  }
};

CustomerMetrics.actions.createDefinition = ({
  definition, success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `customer-metric-definitions`,
        action: 'create',
        data: definition,
        params: { runDag: true }
      });

      if (status < 300) {
        dispatch({ 
          type: 'customerMetricDefinitionsAdd',
          data: { ...definition, ...data.customerMetricDefinition }
        });

        success && success();
      }

      return { status, data };
    }
  }
};

CustomerMetrics.actions.updateDefinition = ({
  definition, success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'customer-metric-definitions',
        action: 'update',
        data: definition,
        params: { runDag: true }
      });

      if (status < 300) {
        dispatch({ 
          type: 'customerMetricDefinitionsUpdate',
          id: definition.id,
          data: definition
        });

        success && success();
      }

      return { status, data };
    }
  }
};

CustomerMetrics.actions.deleteDefinition = ({
  id, success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'customer-metric-definitions',
        action: 'delete',
        data: { id }
      });

      if (status < 300) {
        dispatch({ 
          type: 'customerMetricDefinitionsRemove',
          id
        });

        success && success();
      }

      return { status, data };
    }
  }
};

CustomerMetrics.actions.recordManualMetric = ({
  metric, success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `customer-metrics`,
        action: 'create',
        data: metric
      });

      if (status < 300) {
        dispatch({ 
          type: 'customerMetricsPerCustomerUpdate',
          customerId: metric.customerId,
          data: metric
        });

        success && success();
      }

      return { status, data };
    }
  }
};

const reducers = {
  customerMetrics(state, action) {
    if (!state) return {};

    if (action.type === 'init') {
      return {
        ...state,
        perCustomer: {},
        overall: []
      };
    }

    if (action.type === 'customerMetricsPerCustomerRefreshData') {
      return {
        ...state,
        perCustomer: {
          ...state.perCustomer,
          [action.customerId]: action.data
        }
      };
    } else if (action.type === 'customerMetricsOverallRefreshData') {
      return {
        ...state,
        overall: action.data
      };
    } else if (action.type === 'customerMetricsPerCustomerUpdate') {
      const { customerId, data } = action;
      return {
        ...state,
        perCustomer: {
          ...state.perCustomer,
          [customerId]: state.perCustomer[customerId].map((metric) => {
            if (metric.id === data.id) return data;
            return metric;
          })
        }
      };
    } else if (action.type === 'customerMetricDefinitionsAdd') {
      return {
        ...state,
        definitions: [
          ...state.definitions,
          action.data
        ]
      }
    } else if (action.type === 'customerMetricDefinitionsUpdate') {
      return {
        ...state,
        definitions: state.definitions.map((record) => {
          if (record.id === action.id) return action.data;
          else return record;
        })
      }
    } else if (action.type === 'customerMetricDefinitionsRemove') {
      return {
        ...state,
        definitions: state.definitions.filter((record) => {
          return record.id !== action.id;
        })
      }
    }

    return state;
  }
}

export { reducers };
export default CustomerMetrics;
