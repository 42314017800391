
import React from 'react';
import {
  Button
} from 'antd';

import BaseDrawer from 'modals/base-drawer';

import './notification-drawer.scss';

function NotificationDrawer({
  notification,
  onCancel,
  onDelete,
}) {

  return (
    <BaseDrawer
      title={'消息详情'}
      onClose={onCancel}
    >
      <div className="drawer-notification">
        <div className='title'> {notification.title} </div>
        <div className='content'> {notification.message} </div>
        <div className='submit'>
          <Button onClick={onDelete} danger type='primary'> 
            删除 
          </Button>
        </div>
      </div>
    </BaseDrawer>
  );
}

export default NotificationDrawer;
