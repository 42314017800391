
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Row, Col
} from 'antd';
import moment from 'moment';

import Panel from 'components/panel';
import InPlaceEdit from 'components/in-place-edit';
import Metadata from 'domains/metadata';

function SubscriptionPanel({
  customer,
  onUpdate
}) {
  function getSelector(fieldName) {
    return Metadata.selectors.getComponentParamsSelector({
      collection: 'customers',
      fieldName,
      record: wrappedCustomer,
      onUpdate: onUpdateWrapper
    });
  }

  // React select does not like null
  // But empty string is not an unsigned int reference
  function onUpdateWrapper(field, value) {
    if (field === 'planId' && value === '') {
      onUpdate(field, null);
    } else {
      onUpdate(field, value);
    }
  }

  const wrappedCustomer = {
    ...customer,
    planId: customer.planId === null ? '' : customer.planId
  };

  const planParams = useSelector(getSelector('planId'));
  planParams.selectOptions = [
    { title: '无', value: '' },
    ...planParams.selectOptions
  ];

  const licenseParams = useSelector(getSelector('licenseCount'));
  const activeLicenseParams = useSelector(getSelector('activeLicenseCount'));

  // TODO: metadata service
  return (
    <div className="subscription-panel">
      <Panel title="订阅信息">
        <Row>
          <Col span={12}>
            <InPlaceEdit
              title="订阅状态"
              value={customer.status}
              type="select"
              selectOptions={[
                { value: '未转化', title: '未转化' },
                { value: '订阅中', title: '订阅中' },
                { value: '已流失', title: '已流失' }
              ]}
              onSubmit={(v) => onUpdate('status', v)}
            />
          </Col>
          <Col span={12}>
            <InPlaceEdit {...planParams} />
          </Col>
          <Col span={12}>
            <InPlaceEdit {...licenseParams} />
          </Col>
          <Col span={12}>
            <InPlaceEdit {...activeLicenseParams} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <InPlaceEdit
              title="注册日期"
              value={
                customer.registrationDate ?
                moment(customer.registrationDate) :
                null
              }
              type="date"
              onSubmit={(v) => onUpdate('registrationDate', v)}
            />            
          </Col>
          <Col span={12}>
            <InPlaceEdit
              title="订阅日期"
              value={
                customer.subscriptionDate ?
                moment(customer.subscriptionDate) :
                null
              }
              type="date"
              onSubmit={(v) => onUpdate('subscriptionDate', v)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <InPlaceEdit
              title="下次续费日期"
              value={
                customer.nextRenewalDate ?
                moment(customer.nextRenewalDate) :
                null
              }
              type="date"
              onSubmit={(v) => onUpdate('nextRenewalDate', v)}
            />
          </Col>
          <Col span={12}>
            <InPlaceEdit
              title="ARR"
              value={customer.arr}
              type="number"
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <InPlaceEdit
              title="获客渠道"
              value={customer.channel}
              type="text"
              onSubmit={(v) => onUpdate('channel', v)}
            />
          </Col>
        </Row>
      </Panel>
    </div>
  )
}

export default SubscriptionPanel;
