
import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  Checkbox,
  Table,
  Radio,
  Button
} from 'antd';

import './object-permissions.scss';

class ObjectPermissions extends React.Component {
  static propTypes = {
    object: PropTypes.object.isRequired,
  };

  state = {
    enabled: false
  };

  handleSwitch = (status) => {
    this.setState({ enabled: status });
  };

  renderCreateDelete() {
    return (
      <React.Fragment>
        <div className="divider"></div>
        <div className="section create-delete">
          <div>创建与删除</div>
          <Switch />
        </div>
      </React.Fragment>
    )
  }

  renderWorkflows(workflows) {
    if (!workflows) return;

    return (
      <React.Fragment>
        <div className="divider"></div>
        <div className="section">
          工作流
          <div className="workflows-list">
            {workflows.map((workflow) => {
              return (
                <div key={workflow.key} className="workflow-toggle">
                  <Checkbox />
                  {workflow.title}
                </div>
              );
            })}
          </div>
          <Button type="primary">提交</Button>
          <Button>重置</Button>
        </div>
      </React.Fragment>
    );
  }

  renderColumns(columns) {
    const columnsTableColumns = [
      {
        title: '字段',
        dataIndex: 'title',
        width: '20%'
      },
      {
        title: '所属与下级所属数据',
        width: '40%',
        render(text, record) {
          return (
            <Radio.Group defaultValue="read">
              <Radio value="none">不可见</Radio>
              <Radio value="read">可见</Radio>
              <Radio value="edit">可编辑</Radio>
            </Radio.Group>
          );
        }
      },
      {
        title: '非所属数据',
        width: '40%',
        render(text, record) {
          return (
            <Radio.Group defaultValue="read">
              <Radio value="none">不可见</Radio>
              <Radio value="read">可见</Radio>
              <Radio value="edit">可编辑</Radio>
            </Radio.Group>
          );
        }
      }
    ];

    return (
      <React.Fragment>
        <div className="divider"></div>
        <div className="section">
          字段
          <div className="columns-table">
            <Table
              dataSource={columns}
              rowKey="key"
              columns={columnsTableColumns}
              pagination={false}
            />
          </div>
          <Button type="primary">提交</Button>
          <Button>重置</Button>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { object } = this.props;
    const { enabled } = this.state;

    return (
      <div className="object-permissions">
        <div className="title-row">
          <Switch
            checked={enabled}
            onChange={this.handleSwitch}
          />
          <div className="title">{object.title}</div>
        </div>
        {enabled && this.renderCreateDelete()}
        {enabled && this.renderWorkflows(object.workflows)}
        {enabled && this.renderColumns(object.columns)}
      </div>
    );
  }
}

export default ObjectPermissions;
