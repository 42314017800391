
import React from 'react';
import { Alert, Button } from 'antd';
import { connect } from 'react-redux';

import './notifications.scss';

function Notifications({
  notifications
}) {
  function handleChangeSpec() {
    alert('not implemented');
  }

  return (
    <div className="notifications">
      {notifications.map((notification, i) => {
        let { type, message, spec } = notification;
        if (spec) {
          message = (
            <div>
              {message}
              <Button
                onClick={() => {
                  handleChangeSpec(spec)
                }}
                type="link"
              >
                点击显示
              </Button>
            </div>
          );
        }
        return (
          <Alert
            key={i}
            type={type}
            message={message}
          />
        )
      })}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    notifications: state.timeSeriesExploration.notifications
  };
}

export default connect(mapStateToProps)(Notifications);
