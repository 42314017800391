
import API from 'adapters/api';

import { url as authUrl } from 'adapters/auth';

const Auth = {};

Auth.signOut = async () => {
  await API.request({
    resource: 'user-sessions',
    action: 'sign-out'
  });

  document.cookie = 'sessionId=;path=/;domain=.yuncezhihui.local.com';
  setTimeout(() => {
    window.location.assign(authUrl);
  }, 100);
};

const reducers = {
  user(state, action) {
    if (state === undefined) return null;

    return state;
  },
  client(state, action) {
    if (state === undefined) return null;

    return state;
  }
};

export { reducers };
export default Auth;
