
import React, { useState, useEffect } from 'react';
import { Tag, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';

import Panel from 'components/panel';
import CustomersDomain from 'domains/customers';


function TagsPanel({
  customer
}) {
  // TODO: metadata service
  const dispatch = useDispatch();

  let inputRef = {};
  const [inputValue, setInputValue] = useState('');
  const [inputVisible, setInputVisible] = useState(false);

  useEffect(() => {
    dispatch(CustomersDomain.actions.getTags(customer));
  }, []);

  useEffect(() => {
    if (inputVisible) {
      inputRef.focus();
    }
  }, [inputVisible]);

  const handleDeleteEvent = (tag) => {
    dispatch(CustomersDomain.actions.deleteTag(customer, tag));
  }

  const handleInputChange = e => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (customer.tags.indexOf(inputValue) === -1) {
      dispatch(CustomersDomain.actions.createTag(customer, inputValue));
    }

    setInputVisible(false);
    setInputValue('');
  };

  const showInput = () => {
    setInputVisible(true);
  }

  const hideInput = () => {
    setInputVisible(false);
  }

  const tagsChild = (customer.tags ? customer.tags.map(tag => {
    const tagElem = (
      <Tag
        closable
        onClose={e => {
          e.preventDefault();
          handleDeleteEvent(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  }) : <> </>);

  return (
    <div className="tags-panel">
      <Panel title="标签">
        { customer.tags && tagsChild }
        {inputVisible && (
          <Input
            ref={input => inputRef = input }
            type="text"
            size="small"
            style={{ width: 78 }}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={hideInput}
            onPressEnter={handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag onClick={showInput} className="site-tag-plus">
            <PlusOutlined /> 添加新标签
          </Tag>
        )}
      </Panel>
    </div>
  )
}

export default TagsPanel;
