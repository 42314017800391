
import React from 'react';
import {
  Form, Input, Button, Select, Switch
} from 'antd';

import BaseDrawer from 'modals/base-drawer';

function NoteDrawer({
  onSubmit,
  onCancel
}) {
  return (
    <BaseDrawer
      title="创建笔记"
      onClose={onCancel}
    >
      <div>
        <NoteForm
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </div>
    </BaseDrawer>
  );
}

function NoteForm({
  onCancel,
  onSubmit
}) {
  const initialData = {
    category: '其他',
    content: '',
    pinned: false
  };

  const [form] = Form.useForm();

  const categoryField = (
    <div>
      <Form.Item
        name="category"
        label="分类"
      >
        <Select>
          <Select.Option value="拜访记录">拜访记录</Select.Option>
          <Select.Option value="分析">分析</Select.Option>
          <Select.Option value="其他">其他</Select.Option>
        </Select>
      </Form.Item>
    </div>
  );

  const contentField = (
    <div>
      <Form.Item
        name="content"
        label="正文"
      >
        <Input.TextArea
          rows={3}
        />
      </Form.Item>
    </div>
  );

  const pinnedField = (
    <div>
      <Form.Item
        name="pinned"
        label="置顶"
        valuePropName="checked"
      >
        <Switch
          checkedChildren="是"
          unCheckedChildren="否"
        />
      </Form.Item>
    </div>
  );

  return (
    <div>
      <Form
        name="note"
        initialValues={initialData}
        onFinish={onSubmit}
        form={form}
      >
        {categoryField}
        {contentField}
        {pinnedField}
        <div className="submit">
          <Form.Item noStyle>
            <Button
              type="primary"
              htmlType="submit"
            >
              确认
            </Button>
          </Form.Item>
          <Button onClick={onCancel}>取消</Button>
        </div>
      </Form>
    </div>
  );
}

export default NoteDrawer;
