 
import React from 'react';
import { Row, Col } from 'antd';

import Panel from 'components/panel';
import TicketsTable from 'components-advanced/tickets-table';

import ActivityLevelPanel from './activity-level-panel';
import NpsDistributionPanel from './nps-distribution-panel';
import HealthPanel from './health-panel';
import PinnedMetricsPanel from './pinned-metrics-panel';
import LeftPanel from './left-panel';
import WorkLogMetricPanels from './work-log-metric-panels';
import TicketsMetricPanels from './tickets-metric-panels';

import './index.scss';

function Dashboard({
  customer
}) {
  let {
    subscriptionDate, deploymentDate, onboardDate, initialValueDate
  } = customer;
  if (subscriptionDate) subscriptionDate = new Date(subscriptionDate);
  if (deploymentDate) deploymentDate = new Date(deploymentDate);
  if (onboardDate) onboardDate = new Date(onboardDate);
  if (initialValueDate) initialValueDate = new Date(initialValueDate);

  let timeToDeployment, timeToOnboard, timeToValue;
  if (subscriptionDate && deploymentDate) {
    const timeDiff = deploymentDate - subscriptionDate;
    const daysDiff = timeDiff / 1000 / 60 / 60 / 24;
    timeToDeployment = Math.ceil(daysDiff);
  }
  if (deploymentDate && onboardDate) {
    const timeDiff = onboardDate - deploymentDate;
    const daysDiff = timeDiff / 1000 / 60 / 60 / 24;
    timeToOnboard = Math.ceil(daysDiff);
  }
  if (onboardDate && initialValueDate) {
    const timeDiff = initialValueDate - onboardDate;
    const daysDiff = timeDiff / 1000 / 60 / 60 / 24;
    timeToValue = Math.ceil(daysDiff);
  }

  return (
    <div className="customer-dashboard">
      <div className="section-title">1. 核心信息</div>
      <Row gutter={[10, 10]}>
        <Col span={6}>
          <LeftPanel customer={customer} />
        </Col>
        <Col span={18}>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <ActivityLevelPanel customer={customer} />
            </Col>
            <Col span={24}>
              <HealthPanel customer={customer} />              
            </Col>
            <Col span={12}>
              <NpsDistributionPanel customer={customer} />
            </Col>
            <Col span={12}>
              <Panel title="合同日期到初始价值" style={{ height: 240 }} >
                <div className="onboarding-dates">
                  <div>
                    <div>合同到部署用时（天）：</div>
                    <div className={timeToDeployment ? '' : 'no-data'}>{timeToDeployment || '暂无数据'}</div>
                  </div>
                  <div>
                    <div>部署到完成引导用时（天）：</div>
                    <div className={timeToDeployment ? '' : 'no-data'}>{timeToOnboard || '暂无数据'}</div>
                  </div>
                  <div>
                    <div>引导到初始价值用时（天）：</div>
                    <div className={timeToDeployment ? '' : 'no-data'}>{timeToValue || '暂无数据'}</div>
                  </div>
                </div>
              </Panel>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="divider"></div>
      <div className="section-title">2. 置顶客户指标</div>
      <PinnedMetricsPanel customer={customer} />
      <div className="divider"></div>
      <div className="section-title">3. 工作记录</div>
      <WorkLogMetricPanels customer={customer} />
      <div className="divider"></div>
      <div className="section-title">4. 未完成 SOP</div>
      <TicketsMetricPanels customer={customer} />
      <div style={{ marginBottom: 10 }}></div>
      <TicketsTable
        customer={customer}
        titleRow={false}
        showStatuses={['NOT_STARTED', 'IN_PROGRESS']}
      />
    </div>
  );
}

export default Dashboard;
