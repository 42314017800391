
import React, { useState } from 'react';
import { Popover, Button } from 'antd';
import {
  MoreOutlined
} from '@ant-design/icons';

import './index.scss';

function ListItemActions({
  actions,
  onClick,
  onCancel
}) {
  function handleClick(e) {
    onClick && onClick();
    setVisible(true);
    e.stopPropagation();
  }

  const handleVisibleChange = (visible) => {
    if (!visible && onCancel) onCancel();
    setVisible(visible);
  };

  const content = (
    <div className="component-list-item-actions-content">
      {actions.map(({ name, onClick, disabled }, i) => {
        function handleClick(e) {
          setVisible(false);
          onClick();
          e.stopPropagation();
        }

        return (
          <p
            key={i}
            onClick={handleClick}
          >
            <Button
              type="text"
              disabled={disabled}
            >
              {name}
            </Button>
          </p>
        );
      })}
    </div>
  );

  const [visible, setVisible] = useState(false);

  return (
    <div
      className="component-list-item-actions"
      onClick={handleClick}
    >
      <Popover
        visible={visible}
        placement="leftTop"
        content={content}
        trigger="click"
        arrowPointAtCenter
        onVisibleChange={handleVisibleChange}
      >
        <div className="inner">
          <MoreOutlined />
        </div>
      </Popover>
    </div>
  );
}

export default ListItemActions;
