
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Pagination from "@mui/material/Pagination";
import { Tooltip } from 'antd';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import {
  MoreOutlined
} from '@ant-design/icons';

import Panel from 'components/panel';
import NotificationsDomain from 'domains/notifications';
import { formatTime } from 'utils/time';
import NotificationDrawer from './notification-drawer';
import DeletionModal from './deletion-modal';

import './notification-tab.scss';

function NotificationsTab({ type }) {
  const PAGE_SIZE = 20;

  function handleInteracting(id) {
    setInteractingId(id);
  }

  function handleChangePage(event, page) {
    dispatch(NotificationsDomain.actions.readList({ type, page, limit: PAGE_SIZE }));
  }

  const dispatch = useDispatch();

  const notifications = useSelector(NotificationsDomain.selectors.data)[type];
  const notificationsCount = useSelector(NotificationsDomain.selectors.count)[type];

  const [interactingId, setInteractingId] = useState(null);

  useEffect(() => {
    dispatch(NotificationsDomain.actions.readList({ type, limit: PAGE_SIZE }));
  }, []);

  const notificationTable = (
    <div className="notifications-table">
      <div className={classnames('header', { archived: type === 'archived' })}>
        <div></div> {/** action buttons */}
        <div>创建时间</div>
        <div>来源</div>
        <div>客户</div>
        <div>SOP</div>
        <div>标题</div>
        <div>正文</div>
        <div></div>
      </div>
      <div>
        {notifications.map((notification) => {
          return (
            <Notification
              key={notification.id}
              type={type}
              notification={notification}
              interacting={notification.id === interactingId}
              onInteracting={handleInteracting}
            />
          );
        })}
      </div>
    </div>
  );

  const zeroStateTable = (
    <div className="zero-state">
      <span>暂无{typeToTitle[type]}消息</span>
    </div>
  );

  return (
    <div className="notifications">
      <Panel>
        {
          notifications.length === 0 ?
            zeroStateTable :
            notificationTable
        }
        {
          notifications.length !== 0 &&
          <div className="pagination">
            <Pagination
              count={notificationsCount === undefined ? 0 : Math.ceil(notificationsCount / PAGE_SIZE)}
              variant="outlined"
              color="primary"
              shape="rounded"
              onChange={handleChangePage}
            />
          </div>
        }
      </Panel>
    </div>
  );
}

const typeToTitle = {
  archived: '已归档',
  unarchived: '未归档'
};

function Notification({
  type,
  notification,
  interacting,
  onInteracting
}) {
  function handleShowDeletionModal() {
    setShowDeletionModal(true);
  }

  function handleHideDeletionModal() {
    setShowDeletionModal(false);
    onInteracting(null);
  }

  function handleHideModal() {
    setShowModal(false);
    onInteracting(null);
  }

  function handleShowModal() {
    setShowModal(true);
  }

  function handleShowDetails(notificationId) {
    onInteracting(notificationId);
    handleShowModal();
  }

  function handleDelete() {
    dispatch(NotificationsDomain.actions.delete({
      id: notification.id,
      type,
      success: () => {
        handleHideDeletionModal();
        handleHideModal();
      }
    }));
  }

  function updateStatus(newStatus) {
    if (notification.status !== newStatus) {
      dispatch(NotificationsDomain.actions.update({
        notification: {
          status: newStatus,
          id: notification.id
        },
        type,
        success: handleHideModal
      }));
    }
  }


  function unarchive() {
    updateStatus('read');
  }

  function onArchive() {
    updateStatus('archived');
  }

  function markAsRead() {
    updateStatus('read');
  }
  function markAsUnread() {
    updateStatus('unread');
  }

  function getArchiveButton() {
    if (type === 'unarchived') {
      return {
        text: '归档',
        action: onArchive,
        icon: <ArchiveOutlinedIcon />
      }
    } else if (type === 'archived') {
      return {
        text: '解除归档',
        action: unarchive,
        icon: <UnarchiveOutlinedIcon />
      }
    }
  }

  function getReadButton() {
    if (notification.status === 'read') {
      return {
        text: '标为未读',
        action: markAsUnread,
        icon: <MarkEmailUnreadOutlinedIcon />
      }
    } else { // unread
      return {
        text: '标为已读',
        action: markAsRead,
        icon: <MarkEmailReadOutlinedIcon />
      }
    }
  }

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  const customerPath = notification.customerId ? `/customers-management/${notification.customerId}/summary` : undefined;
  const ticketPath = notification.ticketId ? `/ticket/${notification.ticketId}` : undefined;

  return (
    <div className={classnames('notification', { interacting }, { unread: notification.status === 'unread' }, { read: notification.status !== 'unread' }, { archived: type === 'archived' })}>
      <div className='action-buttons'>
        {
          type === 'unarchived' &&
          <Tooltip title={getReadButton().text} mouseEnterDelay={0.8}>
            <div>
              <div
                onClick={getReadButton().action}
                className='action-button'
              >
                {getReadButton().icon}
              </div>
            </div>
          </Tooltip>
        }
        <Tooltip title={getArchiveButton().text} mouseEnterDelay={0.8}>
          <div>
            <div
              onClick={getArchiveButton().action}
              className='action-button'
            >
              {getArchiveButton().icon}
            </div>
          </div>
        </Tooltip>
      </div>
      <div className='created-at'>
        {formatTime(notification.createdAt)}
      </div>
      <div className="category">
        {notification.category}
      </div>
      <div className='customer-link'>
        {customerPath !== undefined && <Link to={customerPath} className={classnames('link', { disabled: customerPath === undefined })}>客户</Link>}
      </div>
      <div className='ticket-link'>
        {ticketPath !== undefined && <Link to={ticketPath} className='link'>SOP</Link>}
      </div>
      <div className="title">
        {notification.title}
      </div>
      <div className="message">
        {notification.message}
      </div>

      <div className="actions">
        <div>
          <div
            onClick={() => handleShowDetails(notification.id)}
            className='action-button-2'
          >
            <MoreOutlined />
          </div>
        </div>
      </div>
      {
        showModal &&
        <NotificationDrawer
          notification={notification}
          onCancel={handleHideModal}
          onDelete={handleShowDeletionModal}
          getArchiveButton={getArchiveButton}
        />
      }
      {
        showDeletionModal &&
        <DeletionModal
          notification={notification}
          onSubmit={handleDelete}
          onCancel={handleHideDeletionModal}
        />
      }
    </div>
  );
}

export default NotificationsTab;
