
const Plans = {};

const reducers = {
  plans(state, action) {
    if (state === undefined) return null;

    return state;
  }
}

export { reducers };
export default Plans;
