
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from 'antd';

import FeedbackDomain from 'domains/feedback';
import FeedbackCard from 'components/feedback-card';
import Panel from 'components/panel';

import './index.scss';

function Feedback({ customer }) {
  function handleFeedbackTypeChange(type) {
    setFeedbackType(type);
  }

  const { id } = customer;

  const dispatch = useDispatch();
  const feedback = useSelector(FeedbackDomain.selectors.perCustomer)[id];

  const [feedbackType, setFeedbackType] = useState('all');

  useEffect(() => {
    dispatch(FeedbackDomain.actions.perCustomer(id));
  }, []);

  const filteredFeedback = filterFeedback(feedback || [], feedbackType);

  const filter = (
    <div className="filter">
      <div>类型</div>
      <div className="select">
        <Select value={feedbackType} onChange={handleFeedbackTypeChange}>
          <Select.Option value="all">全部</Select.Option>
          <Select.Option value="surveyEntry">反馈问题回答</Select.Option>
          <Select.Option value="NPS">NPS</Select.Option>
          <Select.Option value="complaint">投诉</Select.Option>
          <Select.Option value="suggestion">建议</Select.Option>
          <Select.Option value="others">其他</Select.Option>
        </Select>
      </div>
    </div>
  );

  return (
    <div className="tab-feedback">
      <Panel>
        {filter}
        <div className="feedback-content">
          {filteredFeedback.map((oneFeedback, i) => {
            return (
              <FeedbackCard
                key={i}
                timestamp={oneFeedback.timestamp.substring(0, 19).replace('T', ' ')}
                type={oneFeedback.type}
                customer={{ id, name: customer.name }}
                response={oneFeedback}
              />
            );
          })}
          {
            feedback &&
            filteredFeedback.length === 0 &&
            <div>-- 暂无数据 --</div>
          }
          {!feedback && <div>-- 加载中 --</div>}
        </div>
      </Panel>
    </div>
  );
}

function filterFeedback(feedback, type) {
  if (type === 'all') return feedback;

  return feedback.filter((oneFeedback) => oneFeedback.type === type);
}

export default Feedback;
