
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Row, Col
} from 'antd';

import Panel from 'components/panel';
import InPlaceEdit from 'components/in-place-edit';
import MetadataDomain from 'domains/metadata';

function CustomFormulaePanel({
  customer,
  onUpdate
}) {
  const fieldsMetadata = useSelector(MetadataDomain.selectors.fieldsMetadata);
  const allData = useSelector(MetadataDomain.selectors.allData);

  const customerCustomFormulaeMetadata = Object.keys(fieldsMetadata.customers).filter((key) => {
    const fieldMetadata = fieldsMetadata.customers[key];
    return fieldMetadata.isFormula;
  }).map((key) => {
    return {
      ...fieldsMetadata.customers[key],
      name: key
    };
  });

  return (
    <div className="custom-formulae-panel">
      <Panel title="自定义公式">
        <Row>
          {customerCustomFormulaeMetadata.map((fieldMetadata, i) => {
            const params = MetadataDomain.getComponentParams({
              collection: 'customers',
              fieldName: fieldMetadata.name,
              record: customer,
              onUpdate,
              allData,
              fieldsMetadata
            });

            return (
              <Col span={12} key={i}>
                <InPlaceEdit {...params} />
              </Col>
            );
          })}
          {
            customerCustomFormulaeMetadata.length === 0 &&
            <div style={{ color: '#C0C0C0' }}>暂无自定义公式</div>
          }
        </Row>
      </Panel>
    </div>
  )
}

export default CustomFormulaePanel;
