
import React from 'react';
import {
  Form, Input, Button, Select, Row, Col
} from 'antd';
import { useSelector } from 'react-redux';

import BaseDrawer from 'modals/base-drawer';
import UsersDomain from 'domains/users';
import MeDomain from 'domains/me';
import MetadataDomain from 'domains/metadata';

const { Option } = Select;

function CustomerDrawer({
  onSubmit,
  onCancel
}) {
  return (
    <BaseDrawer
      title={'手动添加新客户'}
      onClose={onCancel}
    >
      <CustomerForm
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </BaseDrawer>
  );
}

function CustomerForm({
  onSubmit,
  onCancel
}) {

  const users = useSelector(UsersDomain.selectors.data);
  const me = useSelector(MeDomain.selectors.data);
  const client = useSelector(MetadataDomain.selectors.client);
  const clientType = client.type;

  const initialData = {
    assigneeId: me.id,
  };

  const labelCol= {span: 8}

  const nameField = (
    <div>
      <Form.Item
        name="name"
        label="名称"
        rules={[
          { required: true, message: '请输入客户名称' }
        ]}
        labelCol={labelCol}
      >
        <Input placeholder="请输入客户名称" />
      </Form.Item>
    </div>
  );

  const assigneeIdField = (
    <div>
      <Form.Item
        name="assigneeId"
        label="负责员工"
        rules = {[{ required: true, message: '请选择负责员工' }]}
        labelCol={labelCol}
      >
        <Select placeholder="负责员工">
          {users.map((user) => {
            return <Option
              key={user.id}
              value={user.id}
            >
              {user.name}
            </Option>
          })}
        </Select>
      </Form.Item>
    </div>
  );

  const statusField = (
    <div>
      <Form.Item
        name="status"
        label="客户状态"
        rules={[
          { required: true, message: '请选择客户状态' }
        ]}
        labelCol={labelCol}
      >
        <Select placeholder="客户状态" style={{ width: '100%' }}>
          <Option key='未转化' value='未转化'>未转化</Option>
          <Option key='订阅中' value='订阅中'>订阅中</Option>
          <Option key='已流失' value='已流失'>已流失</Option>
        </Select>
      </Form.Item>
    </div>
  );

  const provinceField = (
    <div>
      <Form.Item
        name="province"
        label="省"
        labelCol={labelCol}
      >
        <Input placeholder="省" />
      </Form.Item>
    </div>
  );

  const cityField = (
    <div>
      <Form.Item
        name="city"
        label="市"
        labelCol={labelCol}
      >
        <Input placeholder="市" />
      </Form.Item>
    </div>
  );

  const districtField = (
    <div>
      <Form.Item
        name="district"
        label="区"
        labelCol={labelCol}
      >
        <Input placeholder="区" />
      </Form.Item>
    </div>
  );

  const streetField = (
    <div>
      <Form.Item
        name="street"
        label="街道"
        labelCol={labelCol}
      >
        <Input placeholder="街道" />
      </Form.Item>
    </div>
  );

  const regionField = (
    <div>
      <Form.Item
        name="region"
        label="地域"
        labelCol={labelCol}
      >
        <Input placeholder="请输入客户地域" />
      </Form.Item>
    </div>
  );

  const industryField = (
    <div>
      <Form.Item
        name="industry"
        label="行业"
        labelCol={labelCol}
      >
        <Input placeholder="请输入客户行业" />
      </Form.Item>
    </div>
  );

  const channelField = (
    <div>
      <Form.Item
        name="channel"
        label="获客渠道"
        labelCol={labelCol}
      >
        <Input placeholder="获客渠道" />
      </Form.Item>
    </div>
  );


  const b2bOnlyFields = (
    <Row gutter={30}>
      <Col span={12}>{regionField}</Col>
      <Col span={12}>{provinceField}</Col>
      <Col span={12}>{cityField}</Col>
      <Col span={12}>{districtField}</Col>
      <Col span={12}>{streetField}</Col>
      <Col span={12}>{industryField}</Col>
    </Row>
  )

  return (
    <div>
      <Form
        labelAlign='right'
        name="customer"
        initialValues={initialData}
        onFinish={onSubmit}
      >

        <Row gutter={30}>
          <Col span={12}>{nameField}</Col>
          <Col span={12}>{assigneeIdField}</Col>
        </Row>
        <Row gutter={30}>
          <Col span={12}>{statusField}</Col>
          <Col span={12}>{channelField} </Col>
        </Row>
        {clientType === 'b2b' && b2bOnlyFields}
       
        <div className="submit">
          <Form.Item noStyle>
            <Button
              type="primary"
              htmlType="submit"
            >
              确认
            </Button>
          </Form.Item>
          <Button onClick={onCancel}>取消</Button>
        </div>
      </Form>
    </div>
  );
}

export default CustomerDrawer;