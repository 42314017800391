
import React, { useState } from 'react';
import {
  Row, Col, Form, Input, Button, Select, Switch, Radio, InputNumber, Cascader
} from 'antd';
import { useSelector } from 'react-redux';
import {
  MinusOutlined
} from '@ant-design/icons';

import BaseDrawer from 'modals/base-drawer';
import MetadataDomain from 'domains/metadata';
import CustomerMetricsDomain from 'domains/customer-metrics';
import CustomerListsDomain from 'domains/customer-lists';
import UsersDomain from 'domains/users';

import './alert-rule-drawer.scss';

const { Option } = Select;
const { TextArea } = Input;

function AlertRuleModal({
  alertRule,
  onSubmit,
  onCancel
}) {
  const actionTitle = alertRule ? '更新' : '创建';

  return (
    <BaseDrawer
      title={`${actionTitle}报警规则`}
      onClose={onCancel}
    >
      <div className="modal-alert-rule-create-update">
        <AlertRuleForm
          alertRule={alertRule}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </div>
    </BaseDrawer>
  );
}

function AlertRuleForm({
  alertRule,
  onCancel,
  onSubmit
}) {
  function handleSubmit(result) {
    result.conditionsJson = [...conditions];
    if (type === "METRIC") result.customerMetricDefinitionId = result.conditionsJson[0].key;
    onSubmit(result);
  }

  function handleSetConditions(conditions) {
    setConditions(conditions);

    form.setFieldsValue({ key: undefined });
    form.setFieldsValue({ operator: undefined });
    form.setFieldsValue({ value: undefined });

    const formConditions = {
      key: {},
      operator: {},
      value: {}
    };

    conditions.forEach((condition, index) => {
      Object.keys(condition).forEach((key) => {
        formConditions[key][index] = condition[key];
      })
    });

    form.setFieldsValue(formConditions);
  }

  function onTypeChange(e) {
    setType(e.target.value);
    const defaultConditions = e.target.value === 'EVENT' ? [] : [{}]
    setShowAddConditionButton(e.target.value === 'EVENT' ? false : true)
    handleSetConditions(defaultConditions);
  }

  function onEventChange(v) {
    setEvent(v);

    if (events &&
      events[v] &&
      events[v].details &&
      Object.keys(events[v].details).filter((detail) => events[v].details[detail].filterable).length > 0
    ) {
      handleSetConditions([{}]);
      setShowAddConditionButton(true);
    }
    else {
      handleSetConditions([]);
      setShowAddConditionButton(false);
    }
  }

  function onAddConditionButtonClick() {
    const newCondition = {};
    if (type === 'METRIC') newCondition.key = conditions[0].key;

    const newConditions = [
      ...conditions,
      newCondition
    ];
    handleSetConditions(newConditions);
  }

  function onKeyChange(v, index) {
    const newConditions = [...conditions].map((c, i) => {
      if (i === index) c.key = v;
      return c
    });
    if (type === 'METRIC') {
      newConditions.forEach((newCondition) => newCondition.key = newConditions[0].key)
    }
    handleSetConditions(newConditions);
  }

  function onOperatorChange(v, index) {
    const newConditions = [...conditions].map((c, i) => {
      if (i === index) c.operator = v;
      return c
    });
    handleSetConditions(newConditions);
  }

  function onValueChange(v, index) {
    const newConditions = [...conditions].map((c, i) => {
      if (i === index) c.value = v;
      return c
    });
    handleSetConditions(newConditions);
  }

  function onDeleteCondition(index) {
    const newConditions = conditions.filter((c, i) => i !== index);
    handleSetConditions(newConditions);
  }

  const customerLists = useSelector(CustomerListsDomain.selectors.data);
  const fieldsMetadata = useSelector(MetadataDomain.selectors.fieldsMetadata);
  const { events, customers, users } = fieldsMetadata;
  const allUsers = useSelector(UsersDomain.selectors.data);

  const initialData = alertRule || {
    customerListId: customerLists[0].id,
    type: 'METRIC',
    receiver: 'ALL',
    message: '',
    isEnabled: true,
    shouldTicket: false
  };
  if (initialData.conditionsJson) {
    initialData.key = {};
    initialData.operator = {};
    initialData.value = {};

    initialData.conditionsJson.forEach((condition, index) => {
      initialData.key[index] = condition.key;
      initialData.operator[index] = condition.operator;
      initialData.value[index] = condition.value;
    })
  }

  const [type, setType] = useState(initialData.type);

  const defaultConditions = type === 'EVENT' ? [] : [{}];
  const conditionsJson = initialData.conditionsJson || defaultConditions;

  const [conditions, setConditions] = useState(conditionsJson);

  const [form] = Form.useForm();

  const [event, setEvent] = useState(initialData.eventName);
  const [message, setMessage] = useState(initialData.message);

  const [showAddConditionButton, setShowAddConditionButton] = useState(conditions.length > 0);

  const eventDetails = events && events[event] && events[event].details;

  const customerListIdField = (
    <div>
      <Form.Item
        name="customerListId"
        rules={[
          { required: true, message: '请选择报警客户群体' }
        ]}
      >
        <Select>
          {customerLists.map((customerList) => (
            <Option
              key={customerList.name}
              value={customerList.id}
            >
              {customerList.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );

  const typeField = (
    <div>
      <Form.Item
        name="type"
        label="类型"
        rules={[
          { required: true, message: `请选择报警类型` }
        ]}
      >
        <Radio.Group onChange={onTypeChange}>
          <Radio value="METRIC">指标</Radio>
          <Radio value="EVENT">事件</Radio>
        </Radio.Group>
      </Form.Item>
    </div>
  );

  const eventNameField = (
    <div>
      <Form.Item
        name="eventName"
        label="名称"
        rules={[
          { required: true, message: '请选择事件名称' }
        ]}
      >
        <Select onChange={onEventChange}>
          {Object.keys(events).map((event) => {
            const eventName = events[event].title;
            return (
              <Option
                key={event}
                value={event}
              >
                {eventName}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </div>
  );

  const addConditionField = (
    <div className='add-condition-button'>
      <Form.Item>
        <Button
          onClick={onAddConditionButtonClick}
        >
          添加条件
        </Button>
      </Form.Item>
    </div>
  );

  const titleField = (
    <div>
      <Form.Item
        name="title"
        label="标题"
        rules={[
          { required: true, message: `请输入报警标题` }
        ]}
      >
        <Input placeholder="请输入报警标题" />
      </Form.Item>
    </div>
  );

  const templateOptions = [
    {
      name: '客户',
      children: Object.values(customers).map((customer) => {
        return {
          name: customer.title
        }
      })
    },
    {
      name: '员工',
      children: Object.values(users).map((user) => {
        return {
          name: user.title
        }
      })
    },
    {
      name: '事件',
      children: [
        {
          name: '时间'
        },
        ...(eventDetails ? Object.keys(eventDetails).map((detail) => ({ name: eventDetails[detail].title })) : [])
      ]
    }
  ];

  const messageField = (
    <div>
      <Form.Item
        name="message"
        label="正文"
        rules={[
          { required: true, message: `请输入报警信息` }
        ]}
      >
        <TextArea
          rows={4}
          placeholder="${客户.名称}，在${事件.时间}，发生了 XYZ" // eslint-disable-line
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
      </Form.Item>
    </div>
  );

  function onTemplateChange(value) {
    const newMessage = message + `\${${value.join('.')}}`;
    setMessage(newMessage);
    form.setFieldsValue({
      message: newMessage
    });
  }

  const templateField = (
    <div className="template">
      <Form.Item
        name="templates"
      >
        <Cascader
          fieldNames={{ label: 'name', value: 'name', children: 'children' }}
          options={templateOptions}
          onChange={onTemplateChange}
        >
          <Button>添加参数</Button>
        </Cascader>
      </Form.Item>
    </div>
  )

  const mechanismsField = (
    <div>
      <Form.Item
        name="mechanisms"
        label="通道"
        rules={[
          { required: true, message: `请选择报警机制` }
        ]}
      >
        <Select
          mode="multiple"
          placeholder="请选择报警机制"
          allowClear
        >
          <Option value="IN_APP">APP</Option>
          <Option value="WECHAT">微信</Option>
          <Option value="MESSAGE">短信</Option>
          <Option value="EMAIL">邮件</Option>
        </Select>
      </Form.Item>
    </div>
  );

  const receiverField = (
    <div>
      <Form.Item
        name="receiver"
        label="接收"
        rules={[
          { required: true, message: `请选择报警接收人` }
        ]}
      >
        <Select
          defaultValue="ALL"
        >
          {[
            { value: 'ALL', title: '所有人' },
            { value: 'ASSIGNEE', title: '负责员工' },
            { value: 'SELF', title: '自己' },
          ].map((option, index) => {
            return <Option key={option.value} value={option.value}>{option.title}</Option>;
          })}
        </Select>
      </Form.Item>
    </div>
  );

  const additionalReceiversField = (
    <div className="additionalReceivers">
      <Form.Item
        name="additionalReceivers"
        label="抄送"
      >
        <Select
          mode="multiple"
          placeholder="请选择指定报警接收员工"
          allowClear
        >
          {allUsers.map((user) => {
            return <Option key={user.id} value={user.id}>{user.name}</Option>;
          })}
        </Select>
      </Form.Item>
    </div>
  );

  const isEnabledField = (
    <div>
      <div className="is-enabled">是否开启报警</div>
      <Form.Item
        name="isEnabled"
      >
        <Switch
          checkedChildren="激活"
          unCheckedChildren="未激活"
          defaultChecked={alertRule ? alertRule.isEnabled : true}
        />
      </Form.Item>
    </div>
  );

  const shouldTicketField = (
    <div>
      <div className="should-ticket">是否生成 SOP</div>
      <Form.Item
        name="shouldTicket"
      >
        <Switch
          checkedChildren="是"
          unCheckedChildren="否"
          defaultChecked={alertRule ? alertRule.shouldTicket : false}
        />
      </Form.Item>
    </div>
  );

  return (
    <div className="alert-rule-form">
      <Form
        name="AlertRule"
        initialValues={initialData}
        onFinish={handleSubmit}
        form={form}
      >
        <div className="sub-title">1. 客户群体</div>
        {customerListIdField}
        <div className="divider"></div>
        <div className="sub-title">2. 报警条件</div>
        {typeField}
        {type === 'EVENT' && eventNameField}
        {conditions.map((condition, index) => {
          return <AlertRuleCondition
            index={index}
            type={type}
            eventDetails={eventDetails}
            onKeyChange={onKeyChange}
            onOperatorChange={onOperatorChange}
            onValueChange={onValueChange}
            onDeleteCondition={onDeleteCondition}
            isDisabled={conditions.length < 2}
          />
        })}
        {showAddConditionButton && addConditionField}
        <div className="divider"></div>
        <div className="sub-title">3. 报警信息</div>
        {titleField}
        {messageField}
        {templateField}
        <div className="divider"></div>
        <div className="sub-title">4. 报警处理</div>
        {mechanismsField}
        {receiverField}
        {additionalReceiversField}
        <Row>
          <Col span={8}>
            {isEnabledField}
          </Col>
          <Col span={8}>
            {shouldTicketField}
          </Col>
        </Row>
        <div className="divider"></div>
        <div className="submit">
          <Form.Item noStyle>
            <Button
              type="primary"
              htmlType="submit"
            >
              确认
            </Button>
          </Form.Item>
          <Button onClick={onCancel}>取消</Button>
        </div>
      </Form>
    </div>
  );
}

function AlertRuleCondition({
  index,
  type,
  eventDetails,
  onKeyChange,
  onOperatorChange,
  onValueChange,
  onDeleteCondition,
  isDisabled
}) {
  function handleDeleteCondition() {
    onDeleteCondition(index);
  }

  function handleEventChange(v) {
    setEventDetail(v);
    onKeyChange(v, index);
  }

  function handleMetricChange(v) {
    onKeyChange(v, index);
  }

  function handleOperatorChange(v) {
    onOperatorChange(v, index);
  }

  function handleValueChange(v) {
    onValueChange(v, index);
  }

  const customerMetricDefinitions = useSelector(CustomerMetricsDomain.selectors.definitions);

  const [eventDetail, setEventDetail] = useState(null);

  const details = eventDetails || {};

  const eventDetailsTitleField = (
    <div className={index === 0 ? '' : 'key'}>
      <Form.Item
        name={['key', index.toString()]}
        label={index === 0 ? '细节' : ''}
        rules={[
          { required: true, message: '请选择事件细节' }
        ]}
      >
        <Select
          onSelect={handleEventChange}
        >
          {
            Object.keys(details)
              .filter((detail) => details[detail].filterable)
              .map((detail) => {
                const title = details[detail].title;
                return <Option key={detail} value={detail}>{title}</Option>;
              })
          }
        </Select>
      </Form.Item>
    </div>
  );

  const customerMetricDefinitionIdField = (
    <div className={index === 0 ? '' : 'key'}>
      <Form.Item
        name={['key', index.toString()]}
        label={index === 0 ? '指标' : ''}
        rules={[
          { required: true, message: '请选择指标' }
        ]}
      >
        <Select
          onSelect={handleMetricChange}
          disabled={index > 0}
        >
          {customerMetricDefinitions.map((customerMetricDefinition) => (
            <Option value={customerMetricDefinition.id}>
              {customerMetricDefinition.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );

  const operatorField = (
    <div>
      <Form.Item
        name={['operator', index.toString()]}
        rules={[
          { required: true, message: '请选择报警标准' }
        ]}
      >
        <Select
          onSelect={handleOperatorChange}
        >
          <Option key="GREATER" value=">">{'>'}</Option>
          <Option key="GREATER_EQUAL" value=">=">{'>='}</Option>
          <Option key="EQUAL" value="=">=</Option>
          <Option key="LESS_EQUAL" value="<=">{'<='}</Option>
          <Option key="LESS" value="<">{'<'}</Option>
        </Select>
      </Form.Item>
    </div>
  );

  const numericValueField = (
    <div>
      <Form.Item
        name={['value', index.toString()]}
        rules={[
          { required: true, message: '请输入数字' }
        ]}
      >
        <InputNumber
          onChange={handleValueChange}
        />
      </Form.Item>
    </div>
  );

  const stringValueField = (
    <div>
      <Form.Item
        name={['value', index.toString()]}
        rules={[
          { required: true, message: '请选择报警标准' }
        ]}
      >
        <Input
          onChange={handleValueChange}
        />
      </Form.Item>
    </div>
  );

  const minusButtonField = (
    <Button
      className="minus-button"
      shape="circle"
      icon={<MinusOutlined />}
      onClick={handleDeleteCondition}
      disabled={isDisabled}
    />
  );

  const keyField = type === 'EVENT' ? eventDetailsTitleField : customerMetricDefinitionIdField;
  const valueField = details[eventDetail] && details[eventDetail].type === 'STRING' ?
    stringValueField : numericValueField;

  return (
    <div className="condition">
      <Row>
        <Col span={12}>
          {keyField}
        </Col>
        <Col span={5}>
          {operatorField}
        </Col>
        <Col span={5}>
          {valueField}
        </Col>
        <Col span={2}>
          {minusButtonField}
        </Col>
      </Row>
    </div>
  );
}

export default AlertRuleModal;