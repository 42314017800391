
import React from 'react';

import Panel from 'components/panel';
import BulletChart from 'components/bullet-chart';
import DistributionBar from 'components/distribution-bar';
import SparklineChart from 'components/sparkline-chart';
import { formatNumber, formatPercent } from 'utils/number';

import themeColor from 'theme/_color.scss';
import './index.scss';

function MetricPanel({
  title,
  text,
  color,
  delta,
  deltaMeaning,
  trend,
  trendType = 'bar',
  goal,
  distribution,
  height,
  actions,
  meaning
}) {
  let textToDisplay = text;
  let colorToDisplay = color;
  if (text === undefined || text === null) {
    textToDisplay = '无数据';
    colorToDisplay = '#d0d0d0';
  } else if (!isNaN(text)) {
    textToDisplay = formatNumber(text);
  }

  if (meaning === 'good') {
    colorToDisplay = themeColor.healthGood;
  } else if (meaning === 'medium') {
    colorToDisplay = themeColor.healthMedium;
  } else if (meaning === 'bad') {
    colorToDisplay = themeColor.healthBad;
  } else if (meaning === 'noData') {
    colorToDisplay = '#d0d0d0';
  }

  let deltaColor = 'neutral', deltaDisplay = '';
  if (delta !== null && delta !== undefined) {
    deltaDisplay = formatPercent(delta);
    if (delta > 0) deltaColor = 'good';
    if (delta < 0) deltaColor = 'bad';
  }
  if (deltaMeaning) deltaColor = deltaMeaning;

  let goodPercent, mediumPercent, badPercent;
  let noDataPercent, neutralPercent, percentText;
  if (distribution) {
    const { good = 0, medium = 0, bad = 0, neutral, noData = 0 } = distribution;
    const total = good + medium + bad + noData;

    if (neutral !== undefined) {
      const total = neutral + bad;
      if (total === 0) {
        percentText = '0% | 0%';
      } else {
        badPercent = (bad / total * 100).toFixed(0);
        neutralPercent = (neutral / total * 100).toFixed(0);

        percentText = `${neutralPercent}% | ${badPercent}%`;
      }
    } else {
      if (total === 0) {
        percentText = '';
      } else {
        goodPercent = (good / total * 100).toFixed(0);
        mediumPercent = (medium / total * 100).toFixed(0);
        badPercent = (bad / total * 100).toFixed(0);
        noDataPercent = (noData / total * 100).toFixed(0);
  
        percentText = `${goodPercent}% | ${mediumPercent}% | ${badPercent}%`;
        if (noData) {
          percentText += ` | ${noDataPercent}%`;
        }
      }
    }
  }

  return (
    <div className="component-metric-panel">
      <Panel title={title} actions={actions} style={{ height, paddingTop: 5 }}>
        <div className="metric-content">
          <div style={{ color: colorToDisplay }}>{textToDisplay}</div>
          {
            (delta !== null && delta !== undefined) &&
            <div className={`delta ${deltaColor}`}>比上月 {deltaDisplay}</div>
          }
        </div>
        {
          goal &&
          <div className="metric-goal">
            <BulletChart goal={goal} />
          </div>
        }
        {
          trend &&
          <div className="trend">
            <div>过去六个月：</div>
            <div><SparklineChart data={trend} type={trendType} /></div>
          </div>
        }
        {
          distribution &&
          <div className="distribution">
            <div>{percentText}</div>
            <div><DistributionBar data={distribution} /></div>
          </div>
        }
      </Panel>
    </div>
  );
}

export default MetricPanel;
