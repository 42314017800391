
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Tooltip } from 'antd';
import { ZoomInOutlined } from '@ant-design/icons';

import MachineLearningDomain from 'domains/machine-learning';
import { formatTime } from 'utils/time'
import DecisionTree from 'components/decision-tree-chart';
import Panel from 'components/panel';

import './index.scss';

function ChurnPrediction() {
  const dispatch = useDispatch();
  const decisionTree = useSelector(MachineLearningDomain.selectors.decisionTree);
  const customersStatusChangeSnapshot = useSelector(
    MachineLearningDomain.selectors.customersStatusChangeSnapshot
  );

  useEffect(() => {
    dispatch(MachineLearningDomain.actions.getDecisionTree());
    dispatch(MachineLearningDomain.actions.getCustomersStatusChangeSnapshot());
  }, []);

  if (!decisionTree.decisionTree) {
    return <div>暂无数据</div>;
  }

  let filteredSnapshot = [];
  const seenIds = {};
  customersStatusChangeSnapshot.forEach((datum) => {
    if (seenIds[datum.customerId]) return;
    seenIds[datum.customerId] = true;
    filteredSnapshot.push(datum);
  });

  return (
    <div>
      <Panel style={{ height: 630 }}>
        <div className="decision-tree-panel-content">
          <div>
            <div className="header">
              <div>
                <span>数据量：</span><span>{decisionTree.sampleSize}</span>
              </div>
              <div>
                <span>生成日期：</span><span>{formatTime(decisionTree.createdAt)}</span>
              </div>
            </div>
            <DecisionTree
              data={decisionTree.visualizedTree}
            />
          </div>
          <div><div className="divider"></div></div>
          <div>
            <Table
              dataSource={filteredSnapshot}
              columns={columns}
              rowKey="customerId"
              pagination={false}
            />
          </div>
        </div>
      </Panel>
    </div>
  );
}

const columns = [
  {
    title: '客户 ID',
    width: 10,
    dataIndex: 'customerId'
  },
  {
    title: '结果',
    width: 10,
    dataIndex: 'result'
  },
  {
    title: '快照信息',
    width: 10,
    render(_, record) {
      const parsed = JSON.parse(record.metricsJson);
      const content = (
        <pre style={{ padding: '15px 25px', paddingRight: 30 }}>
          {JSON.stringify(parsed, null, 2)}
        </pre>
      );

      return (
        <div>
          <Tooltip color="blue" trigger="click" title={content}>
            <ZoomInOutlined />
          </Tooltip>
        </div>
      );
    }
  }
];

export default ChurnPrediction;
