
import React, { useEffect, useRef } from 'react';
import { Chart } from '@antv/g2';

import './index.scss';

/**
 * 
 * @param data: [{ timestamp: '2020-01-01', value: 2000 }, ...]
 * @param color: line color
 */

function TrendChart({
  data,
  annotation,
  name = '指标',
  healthBackground
}) {
  function createChart(container, data) {
    if (data.length === 0) return;
  
    const chart = new Chart({
      container,
      autoFit: true
    });

    chart.data(data);

    chart
      .line()
      .position('timestamp*value')
      .color('#7E92B5')
      .tooltip('timestamp*value', (timestamp, value) => {
        return {
          name,
          value: formatValue(value)
        };
      });

    chart.axis('timestamp', {
      label: {
        type: 'time',
        formatter: formatDate
      },
      line: false,
      tickLine: false
    });

    chart.axis('value', {
      label: {
        formatter: formatValue
      },
      grid: {
        line: {
          style: {
            lineDash: [5, 5]
          }
        }
      }
    });

    let dataForScaleCalculation = data;
    if (annotation) {
      dataForScaleCalculation = [...dataForScaleCalculation, { value: annotation }];
    }
    let { ticks, minMax, monoZero } = calculateScale(dataForScaleCalculation);

    if (annotation !== undefined && data.length > 1) {
      chart.annotation().line({
        start: ['min', annotation],
        end: ['max', annotation],
        style: {
          stroke: '#ff4d4f',
          lineWidth: 1,
          lineDash: [3, 5],
        },
        text: {
          position: 'end',
          style: {
            fill: '#ff4d4f',
            fontSize: 11,
            fontWeight: 'normal',
          },
          content: '目标',
          offsetX: 5,
          offsetY: 7
        }
      });
    }

    let { min, max } = minMax;
    if (monoZero) {
      max = 100;
      ticks = [0, 25, 50, 75, 100];
    };

    chart.scale('value', {
      ticks,
      min,
      max
    });

    if (healthBackground) {
      chart.annotation().region({
        start: ['min', min],
        end: ['max', 50],
        style: {
          fill: 'red',
          fillOpacity: 0.1,
        }
      });
  
      chart.annotation().region({
        start: ['min', 50],
        end: ['max', 70],
        style: {
          fill: 'yellow',
          fillOpacity: 0.1,
        }
      });
  
      chart.annotation().region({
        start: ['min', 70],
        end: ['max', 100],
        style: {
          fill: 'green',
          fillOpacity: 0.1,
        }
      });
    }

    chart.scale('timestamp', {
      range: [0, 1]
    });
  
    chart.render();
  }

  const chartContainer = useRef(null);

  useEffect(() => {
    chartContainer.current.innerHTML = '';
    createChart(chartContainer.current, data);
  }, [data]);

  return (
    <div className="component-trend-chart" ref={chartContainer}></div>
  );
}

function formatDate(dateString) {
  const [, month, day] = dateString.split('-');
  if (day) {
    return `${month}/${day}`;
  } else {
    return `${month}月`
  }
}

function calculateScale(data) {
  const { min, max } = getMinMax(data);
  
  if (min === max) {
    return {
      ticks: [min - 5, min - 2.5, min, min + 2.5, min + 5],
      minMax: {
        max: min + 5,
        min: min - 5
      },
      monoZero: min === 0
    };
  }

  const diff = max - min;
  const lowerMid = min + diff / 4;
  const mid = min + diff / 2;
  const upperMid = min + diff / 4 * 3;

  const ticks = [min, lowerMid, mid, upperMid, max];
  const minMax = {
    min,
    max
  };

  return { ticks, minMax };
}

function getMinMax(data) {
  let min = Infinity;
  let max = -Infinity;
  for (let i = 0; i < data.length; i++) {
    const { value } = data[i];
    if (value > max) max = value;
    if (value < min) min = value;
  }
  return { min, max };
}

function formatValue(value) {
  const parsed = parseFloat(value);
  if (parsed > 10000) {
    const scaledNumber = (Math.round(value / 1000) / 10).toFixed(1);
    return `${scaledNumber}w`;
  } else {
    const decimal = parseInt(value) === value ? 0 : 1;
    return `${parsed.toFixed(decimal)}`;
  }
}

export default TrendChart;
