
import React from 'react';
import {
  Table,
  Tooltip
} from 'antd';
import { Link } from 'react-router-dom';

import Panel from 'components/panel';

import './current-month-renewal-list.scss';

function CurrentMonthRenewalList() {
  const columns = getColumns();

  return (
    <Panel title="本月续费客户列表">
      <Table
        dataSource={customers}
        rowKey="id"
        columns={columns}
        pagination={{
          current: 1,
          total: 40,
          pageSize: 20,
          showTotal(total, range) {
            return (
              <div className="total">
                {`总共 ${total} 项，显示第 ${range[0]}-${range[1]}`}
              </div>
            );
          },
          showSizeChanger: false,
          position: ['bottomLeft']
        }}
      />
    </Panel>
  );
}

function getColumns() {
  let columns = [];
  columns = columns.concat([
    {
      title: '',
      dataIndex: 'churnPrediction',
      width: '1%',
      render(text, record) {
        if (record.churnPrediction === '流失') {
          const churnDecisionTreeLink = 
            `/customers-management/${record.id}/churn-decision-tree`;
          return (
            <Tooltip title="有流失风险">
              <Link to={churnDecisionTreeLink}>
                <div className='dot'> </div>
              </Link>
            </Tooltip>
          )
        }
      }
    },
    {
      title: '名称',
      dataIndex: 'name',
      width: '15%',
      render(text, record) {
        const path = [
          '/customers-management/',
          `${record.id}/`,
          'summary'
        ].join('');

        return (
          <Link to={path}>{record.name}</Link>
        )
      }
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: '10%'
    },
    {
      title: '区域',
      dataIndex: 'region',
      width: '10%'
    },
    {
      title: '行业',
      dataIndex: 'industry',
      width: '10%'
    },
    {
      title: '产品',
      dataIndex: 'plan',
      width: '15%'
    },
    {
      title: '续费日期',
      dataIndex: 'nextRenewalDate',
      width: '15%',
      render(text, record) {
        if (record.nextRenewalDate) {
          return record.nextRenewalDate.slice(0, 10);
        } else {
          return record.nextRenewalDate;
        }
      }
    },
    {
      title: '总付款',
      dataIndex: 'totalPayment',
      width: '15%'
    },
    {
      title: '健康分',
      dataIndex: 'healthScore',
      width: '15%',
      render(text) {
        const num = parseInt(text);
        let className = 'good';
        if (num < 50) {
          className = 'bad';
        } else if (num < 80) {
          className = 'medium';
        }
        return <div className={className}>{text}</div>
      }
    }
  ]);

  return columns;
}

const customers = generateFakeData();
function generateFakeData() {
  const fakeData = [];

  for (let i = 0; i < 40; i++) {
    const churnPrediction = Math.floor(Math.random() * 10) === 0;
    const status = [
      '订阅中', '订阅中', '订阅中', '已流失'
    ][Math.floor(Math.random() * 4)];
    let randomDay = Math.floor(Math.random() * 31) + 1;
    if (randomDay < 10) randomDay = `0${randomDay}`;
    let healthScore = Math.floor(Math.random() * 140) + 1
    if (healthScore > 100) healthScore -= 40;

    fakeData.push({
      id: i,
      churnPrediction,
      name: `客户-${i}`,
      status,
      region: '华南',
      industry: '制造业',
      plan: '标准版',
      nextRenewalDate: `10-${randomDay}`,
      totalPayment: 500000,
      healthScore
    });
  }

  return fakeData;
}

export default CurrentMonthRenewalList;
