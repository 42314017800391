
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Panel from 'components/panel';
import CustomFormulaeDomain from 'domains/custom-fomulae';

import ObjectSelection from './object-selection';
import ObjectManagement from './object-management';
import FieldsManagement from './fields-management';

import './index.scss';

function OperationalMetadataManagement() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CustomFormulaeDomain.actions.readList());
  }, []);

  const [selectedObjectId, setSelectedObjectId] = useState(1);

  const objectMetadata = objectsMetadata.find(
    (metadata) => metadata.id === selectedObjectId
  );

  return (
    <Panel style={{ height: '100%' }}>
      <div className="operational-metadata-management">
        <ObjectSelection
          objectsMetadata={objectsMetadata}
          selectedObjectId={selectedObjectId}
          setSelectedObjectId={setSelectedObjectId}
        />
        <div className="divider"></div>
        <div>
          <ObjectManagement
            objectMetadata={objectMetadata}
          />
          <FieldsManagement
            selectedObjectId={selectedObjectId}
          />
        </div>
      </div>
    </Panel>
  );
}

// HACK, hardcoded for now
const objectsMetadata = [
  {
    id: 1,
    objectName: 'customers',
    title: '客户',
    description: '客户对象数据模型，包括默认字段，自定义字段，和自定义公式'
  },
  {
    id: 2,
    objectName: 'workLogs',
    title: '工作记录',
    description: '工作记录对象'
  }
];

export default OperationalMetadataManagement;
