
import { url as authUrl } from 'adapters/auth';

const api = store => next => async (action) => {
  if (
    action.type !== 'api' && // deprecated, use query or mutation
    action.type !== 'query' &&
    action.type !== 'mutation'
  ) return next(action);

  store.dispatch({ type: 'startSpin' });

  const result = await action.request(
    store.getState(), store.dispatch
  );

  const { status, data } = result;

  store.dispatch({ type: 'stopSpin' });

  action.before && action.before(store.dispatch);
  
  let success = false;
  let message;

  if (!status) {
    message = '无法连接到服务器，请稍后再试'
  } else if (status >= 400) {
    if (status === 401) {
      window.location.assign(authUrl);
    }
    message = data.message || '未知错误';
  } else {
    success = true;
    message = data.message;
  }

  if (action.type === 'mutation' || !success) {
    store.dispatch({
      type: 'alert',
      alert: {
        type: success ? 'success' : 'error',
        message
      }
    });

    // HACK START, TODO: use thunk middleware or something
    // also could be buggy in race conditions
    let lastId;
    setTimeout(() => {
      const alertsData = store.getState().alert.data;
      const length = alertsData.length;
      lastId = alertsData[length - 1].id;
    }, 1);
    
    setTimeout(() => {
      store.dispatch({
        type: 'closeAlert',
        id: lastId
      });
    }, 10000);
    // HACK END
  }

  success && action.success && action.success(data, store.dispatch);
  action.after && action.after(store.dispatch);
}

const middlewares = [api];

export { middlewares };
