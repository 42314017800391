
import React from 'react';
import {
  Table
} from 'antd';
import { Link } from 'react-router-dom';

import ChartPanel from 'components/chart-panel';

function RecentChurnList() {
  const columns = getColumns();

  return (
    <ChartPanel title="近期流失客户列表" customHeight={928}>
      <div style={{ paddingLeft: 20 }}>
        <Table
          dataSource={customers}
          rowKey="id"
          columns={columns}
          pagination={{
            current: 1,
            total: 25,
            pageSize: 18,
            showTotal(total, range) {
              return (
                <div className="total">
                  {`总共 ${total} 项，显示第 ${range[0]}-${range[1]}`}
                </div>
              );
            },
            showSizeChanger: false,
            position: ['bottomLeft']
          }}
        />
      </div>
    </ChartPanel>
  );
}

function getColumns() {
  let columns = [];
  columns = columns.concat([
    {
      title: '名称',
      dataIndex: 'name',
      width: '20%',
      render(text, record) {
        const path = [
          '/customers-management/',
          `${record.id}/`,
          'summary'
        ].join('');

        return (
          <Link to={path}>{record.name}</Link>
        )
      }
    },
    {
      title: '流失日期',
      dataIndex: 'nextRenewalDate',
      width: '30%',
      render(text, record) {
        if (record.nextRenewalDate) {
          return record.nextRenewalDate.slice(0, 10);
        } else {
          return record.nextRenewalDate;
        }
      }
    },
    {
      title: '流失原因',
      width: '50%',
      render() {
        return Math.random() > 0.5 ? '未知' : '测试原因';
      }
    }
  ]);

  return columns;
}

const customers = generateFakeData();
function generateFakeData() {
  const fakeData = [];

  for (let i = 0; i < 40; i++) {
    const churnPrediction = Math.floor(Math.random() * 10) > 3;
    const status = '已流失';
    let randomMonth = Math.floor(Math.random() * 5) + 1;
    if (randomMonth < 10) randomMonth = `0${randomMonth}`;
    let randomDay = Math.floor(Math.random() * 31) + 1;
    if (randomDay < 10) randomDay = `0${randomDay}`;
    let healthScore = Math.floor(Math.random() * 140) + 1
    if (healthScore > 100) healthScore -= 40;

    fakeData.push({
      id: i,
      churnPrediction,
      name: `客户-${i}`,
      status,
      region: '华南',
      industry: '制造业',
      plan: '标准 plan',
      nextRenewalDate: `2022-${randomMonth}-${randomDay}`,
      totalPayment: 500000,
      healthScore
    });
  }

  return fakeData;
}

export default RecentChurnList;
