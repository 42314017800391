
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form,
  Input,
  Button
} from 'antd';
import {
  UserOutlined
} from '@ant-design/icons';

import Rbac from 'domains/rbac';
import BaseModal from '../base-modal';

import './index.scss';

class RolesCreate extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  render() {
    const { onCancel, onSubmit } = this.props;

    return (
      <BaseModal title="创建角色">
        <RolesCreateForm
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </BaseModal>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onCancel() {
      dispatch({ type: 'closeModal' });
    },
    onSubmit(role) {
      dispatch({
        type: 'api',
        request: () => Rbac.createRole(role),
        success(data) {
          dispatch({ type: 'rolesCreate', role: data.role })
          dispatch({ type: 'closeModal' });
        }
      })
    }
  };
}

function RolesCreateForm({
  onCancel,
  onSubmit,
}) {
  const nameField = (
    <div>
      <div className="label">名称</div>
      <Form.Item
        name="name"
        rules={[
          { required: true, message: `请输入角色名称` }
        ]}
      >
        <Input
          prefix={<UserOutlined />}
        />
      </Form.Item>
    </div>
  );

  const descriptionField = (
    <div>
      <div className="label">描述</div>
      <Form.Item
        name="description"
      >
        <Input />
      </Form.Item>
    </div>
  );

  return (
    <div className="roles-create-form">
      <Form
        name="RolesCreate"
        initialValues={{ status: 'active' }}
        onFinish={onSubmit}
      >
        {nameField}
        {descriptionField}
        <div className="submit">
          <Form.Item noStyle>
            <Button
              type="primary"
              htmlType="submit"
            >
              提交
            </Button>             
          </Form.Item>
          <Button onClick={onCancel}>取消</Button>
        </div>
      </Form>
    </div>
  );
}

export default connect(
  null,
  mapDispatchToProps
)(RolesCreate);
