
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import Pagination from "@mui/material/Pagination";

import Panel from 'components/panel';
import MetricPanel from 'components/metric-panel';
import NotificationsDomain from 'domains/notifications';
import Me from 'domains/me';
import UserMetricsDomain from 'domains/user-metrics';
import AggregateCustomerMetricsDomain from 'domains/aggregate-customer-metrics';
import PinnedListsPanel from './pinned-lists-panel';

import './summary.scss';

const PAGE_SIZE = 5;

function Summary() {
  const type = 'unread';

  const dispatch = useDispatch();

  const notifications = useSelector(NotificationsDomain.selectors.data)[type];
  const notificationsCount = useSelector(NotificationsDomain.selectors.count)[type];
  const me = useSelector(Me.selectors.data);
  const userMetrics = useSelector(UserMetricsDomain.selectors.perUser)[me.id];
  const aggregateCustomerMetrics = useSelector(
    AggregateCustomerMetricsDomain.selectors.getForUserSelector(me.id)
  );

  useEffect(() => {
    dispatch(NotificationsDomain.actions.readList({ type, limit: PAGE_SIZE }));
    dispatch(AggregateCustomerMetricsDomain.actions.getForUser(me.id));
    dispatch(UserMetricsDomain.actions.readPerUser(me.id));
  }, []);


  function handleChangePage(event, page) {
    dispatch(NotificationsDomain.actions.readList({ type, page, limit: PAGE_SIZE }));
  }

  return (
    <div className="summary">
      <div>
        <div className="section-title">1. 未读信息</div>
        <Panel>
          {
            !notifications &&
            <div>-- 加载中 --</div>
          }
          {
            notifications &&
            <div className="notifications-count">
              <div>总共 {notificationsCount} 条未读消息</div>
              <div><Link to="/notifications">全部消息</Link></div>
            </div>
          }
          {
            notifications && notifications.length > 0 &&
            <div>
              <div className="notifications-table">
                <div>
                  <div>客户</div><div>SOP</div><div>标题</div><div>正文</div>
                </div>
                <div style={{ height: 0, borderBottom: '1px solid #E0E0E0' }}></div>
                <div>
                  {notifications.map((notification, i) => {
                    const { customerId, ticketId, title, message } = notification;

                    const customerPath = `/customers-management/${notification.customerId}/summary`;
                    const ticketPath = `/ticket/${notification.ticketId}`;
                    const customerLink = customerId ?
                      <Link to={customerPath} className='link'>客户</Link> :
                      '';
                    const ticketLink = ticketId ?
                      <Link to={ticketPath} className='link'>SOP</Link> :
                      '';

                    return (
                      <div key={i}>
                        <div>{customerLink}</div>
                        <div>{ticketLink}</div>
                        <div>{title}</div>
                        <div>{message}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="pagination">
                <Pagination
                  count={notificationsCount === undefined ? 0 : Math.ceil(notificationsCount / PAGE_SIZE)}
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </div>
            </div>
          }
        </Panel>
      </div>
      <div>
        <div className="section-title">2. 我的客户指标</div>
        <Row gutter={[10, 10]}>
          {
            aggregateCustomerMetrics && aggregateCustomerMetrics.map(metric => {
              return (
                <Col key={metric.title} span={6}>
                  <MetricPanel
                    title={metric.title}
                    text={metric.value}
                    delta={metric.delta}
                    deltaMeaning={metric.deltaMeaning}
                    goal={metric.goal}
                    trend={metric.trend}
                    meaning={metric.meaning}
                    distribution={metric.distribution}
                    height={110}
                  />
                </Col>
              )
            })
          }
        </Row>
      </div>
      <div>
        <div className="section-title">3. 我的工作指标</div>
        <Row gutter={[10, 10]}>
          {
            userMetrics && userMetrics.map(metric => {
              return (
                <Col key={metric.title} span={6}>
                  <MetricPanel
                    title={metric.title}
                    text={metric.value}
                    goal={metric.goal}
                    trend={metric.trend}
                    distribution={metric.distribution}
                    meaning={metric.meaning}
                    height={110}
                  />
                </Col>
              )
            })
          }
        </Row>
      </div>
      <div>
        <div className="section-title">4. 我收藏的客户列表</div>
        <PinnedListsPanel />
      </div>
    </div>
  );
}

export default Summary;
