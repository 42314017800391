
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button, Switch
} from 'antd';
import {
  DownOutlined, UpOutlined
} from '@ant-design/icons';
import classnames from 'classnames';

import Panel from 'components/panel';
import SurveysDomain from 'domains/surveys';
import SurveyModal from './survey-modal';
import DeletionModal from './deletion-modal';
import ListItemActions from 'components/list-item-actions';
import SurveyAnalytics from './survey-analytics';

import './index.scss';

function Surveys() {
  function handleHideModal() {
    setShowModal(false);
  }

  function handleShowModal() {
    setShowModal(true);
  }

  function handleCreateSurvey(v) {
    dispatch(SurveysDomain.actions.create({
      survey: v,
      success: handleHideModal
    }));
  }

  const dispatch = useDispatch();
  const surveys = useSelector(SurveysDomain.selectors.data);
  const [showModal, setShowModal] = useState(false);
  const [interactingId, setInteractingId] = useState(null);
  const [analyticSurveyId, setAnalyticServeyId] = useState(null);

  useEffect(() => {
    dispatch(SurveysDomain.actions.readList());
  }, []);

  const surveyTable = (
    <>
      <div className="header">
        <div className='arrow-header'></div>
        <div>状态</div>
        <div>问题</div>
        <div>选项</div>
        <div></div>
      </div>
      <div>
        {surveys.map((survey, i) => {
          return (
            <Survey 
              key={i}
              survey={survey}
              interacting={survey.id === interactingId}
              onInteracting={setInteractingId}
              analyzing={survey.id === analyticSurveyId}
              onAnalyzing={setAnalyticServeyId}
            />
          );
        })}
      </div>
    </>
  );

  const surveyTableZeroState = (
    <div>尚未添加反馈问题，请点击下方按键添加</div>
  );

  return (
    <div className="surveys">
      <Panel>
        <div className="overall-actions">
          <Button
            type="primary"
            onClick={handleShowModal}
          >
            创建反馈问题
          </Button>
        </div>
        <div className="surveys-table">
          {
            surveys.length === 0 ?
            surveyTableZeroState :
            surveyTable
          }
        </div>
      </Panel>
      {
        showModal &&
        <SurveyModal
          onSubmit={handleCreateSurvey}
          onCancel={handleHideModal}
        />
      }
    </div>
  );
}

function Survey({
  survey,
  interacting,
  onInteracting,
  analyzing,
  onAnalyzing
}) {
  function handleShowDeletionModal() {
    setShowDeletionModal(true);
  }

  function handleHideDeletionModal() {
    setShowDeletionModal(false);
    onInteracting(null);
  }

  function handleHideModal() {
    setShowModal(false);
    onInteracting(null);
  }

  function handleShowModal() {
    setShowModal(true);
  }

  function handleSurveyClicked() {
    if (!analyzing) {
      onAnalyzing(survey.id);
    } else {
      onAnalyzing(null);
    }
  }

  function handleDelete() {
    dispatch(SurveysDomain.actions.delete({
      id: survey.id,
      success: handleHideDeletionModal
    }));
  }

  function handleUpdate(values) {
    if (survey.status !== 'init') {
      delete values.choices;
    }

    dispatch(SurveysDomain.actions.update({
      survey: {
        id: survey.id,
        ...values
      },
      success: handleHideModal
    }));
  }

  function handleStatusUpdate(v) {
    const status = v === true ? 'enabled' : 'disabled';
    handleUpdate({
      id: survey.id,
      status
    });
  }

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  return (
    <div className='survey-row'>
      <div className={classnames('survey', { interacting }, { 'show-analytics': analyzing })} onClick={handleSurveyClicked}>
        { analyzing && <UpOutlined /> }
        { !analyzing && <DownOutlined />}
        <div>
          <div onClick={(e) => e.stopPropagation()}>
            <Switch
              checkedChildren="激活"
              unCheckedChildren="未激活"
              defaultChecked={survey.status === 'enabled'}
              onChange={handleStatusUpdate}
            />
          </div>
        </div>
        <div className="question">
          {survey.question}
        </div>
        <div className="choices">
          {survey.choices.join('，')}
        </div>
        <div className="actions" onClick={(e) => e.stopPropagation()}>
          <ListItemActions
            actions={[
              { name: '更改', onClick: handleShowModal },
              { name: '删除', onClick: handleShowDeletionModal }
            ]}
            onClick={() => onInteracting(survey.id)}
            onCancel={() => onInteracting(null)}
          />
        </div>
      </div>
      {
        showModal &&
        <SurveyModal
          survey={survey}
          onSubmit={handleUpdate}
          onCancel={handleHideModal}
        />
      }
      {
        showDeletionModal &&
        <DeletionModal
          survey={survey}
          onSubmit={handleDelete}
          onCancel={handleHideDeletionModal}
        />
      }
      {
        analyzing &&
        <SurveyAnalytics
          activeSurveyId={survey.id} 
        />
      }
    </div>
  );
}

export default Surveys;
