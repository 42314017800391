
import React, { useEffect, useRef } from 'react';
import { Chart } from '@antv/g2';

import { formatNumber } from 'utils/number';

function YTDChart({
  data
}) {
  const chartContainer = useRef(null);

  useEffect(() => {
    const chart = new Chart({
      container: chartContainer.current,
      autoFit: true
    });

    chart.data(data);
    
    chart
      .line()
      .position('timestamp*value')
      .color('#7E92B5')
      .tooltip('timestamp*value', (timestamp, value) => {
        return {
          name: '累计',
          value: formatNumber(value)
        };
      });

    chart.scale({
      value: {
        min: 0,
        max: 40000000,
        tickCount: 5
      },
      date: {
        formatter: formatDate
      }
    });

    chart.axis('timestamp', {
      line: false,
      tickLine: false
    });

    chart.axis('value', {
      label: {
        formatter: formatNumber
      }
    });

    chart.annotation().line({
      start: ['min', 31000000],
      end: ['max', 31000000],
      style: {
        stroke: '#006d75',
        lineWidth: 2,
        lineDash: [10, 10],
      },
      text: {
        position: 'start',
        style: {
          fill: '#006d75',
          fontSize: 15,
          fontWeight: 'normal',
        },
        content: '年度目标续约 3100w',
        offsetY: -5,
      },
    });

    chart.render();
  }, []);

  return (
    <div style={{ height: '100%', width: '100%' }} ref={chartContainer}></div>
  );
}

function formatDate(dateString) {
  return `${dateString.split('-')[1]}月`;
}

export default YTDChart;
