
import React, { useEffect, useRef } from 'react';
import { Chart } from '@antv/g2';

import { calculateScale } from 'utils/chart';
import { formatNumber } from 'utils/number';

import './index.scss';

function SparklineChart({
  data,
  type = 'line'
}) {
  function createChart(container, data) {
    const chart = new Chart({
      container,
      autoFit: true
    });

    chart.data(data);

    if (type === 'line') {
      chart.line().position('timestamp*value');

      chart.scale('timestamp', {
        range: [0.15, 0.85]
      });

      let [max, min, dataMax, dataMin] = calculateScale({ data, buffer: [0.2, 0.2] });
      if (dataMax === 0 && dataMin === 0) {
        min = 0; max = 1;
      }

      chart.scale({
        value: { min, max, formatter: formatNumber },
      });

      chart.axis('value', {
        grid: null,
        label: false
      });
    } else if (type === 'bar') {
      chart.interval().position('timestamp*value');

      const [max] = calculateScale({ data, buffer: [0.2, 0.2] });

      chart.scale({
        value: { min: 0, max }
      });

      chart.axis('value', false);
    }

    chart.axis('timestamp', {
      label: false
    });

    chart.tooltip({
      position: 'left',
      offset: 20,
      showCrosshairs: true
    });

    chart.render();
  }

  const chartContainer = useRef(null);

  useEffect(() => {
    createChart(chartContainer.current, data);
  }, []);

  return (
    <div className="component-sparkline-chart" ref={chartContainer}></div>
  );
}

export default SparklineChart;
