
import API from 'adapters/api';

/**
 * state structure:
 * {
 *   data: {
 *     answersDistribution: [],
 *     responseRate: [],
 *     responseTimeline: []
 *   }
 * }
 */

const SurveyAnalytics = {
  selectors: {},
  actions: {}
};

SurveyAnalytics.selectors.data = (state) => {
  return state.surveyAnalytics.perSurveyId;
};

SurveyAnalytics.actions.read = ({ id }) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `surveys/${id}/analytics`,
        action: 'read',
      });

      if (status < 300) {
        const { result } = data;
        const answersTimeline = result.answersTimeline;
        const distinctAnswers = [...new Set(answersTimeline.map(entry => entry.answer))];
        const distinctDates = [...new Set(answersTimeline.map(entry => entry.date))];
        const newAnswersTimeline = [];
        for (const date of distinctDates) {
          for (const answer of distinctAnswers) {
            const entry = answersTimeline.filter(entry => entry.date === date && entry.answer === answer);
            if (entry.length < 1) {
              newAnswersTimeline.push({ count: 0, answer, date});
            } else {
              newAnswersTimeline.push(entry[0]);
            }
          }
        }
        result.answersTimeline = newAnswersTimeline;

        dispatch({
          type: 'surveyAnalyticsRefreshData',
          id,
          data: result
        });
      }

      return { status, data };
    }
  }
};

const reducers = {
  surveyAnalytics(state, action) {
    if (state === undefined) return { perSurveyId: {} };

    if (action.type === 'surveyAnalyticsRefreshData') {
      return {
        ...state,
        perSurveyId: {
          [action.id]: action.data
        }
      };
    }
    return state;
  }
}

export { reducers };
export default SurveyAnalytics;
