
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button
} from 'antd';

import Breadcrumb from 'components/breadcrumb';
import RolesTable from './roles-table';

import './index.scss';

class Permissions extends React.Component {
  static propTypes = {
    roles: PropTypes.array.isRequired,
    onOpenRolesCreateModal: PropTypes.func.isRequired,
    onOpenRolesDeleteModal: PropTypes.func.isRequired
  };

  render() {
    const {
      roles,
      onOpenRolesCreateModal,
      onOpenRolesDeleteModal
    } = this.props;

    return (
      <div className="page-roles">
        <Breadcrumb
          navArray={[
            { content: '权限管理' }
          ]}
        />
        <RolesTable
          roles={roles}
          onOpenRolesDeleteModal={onOpenRolesDeleteModal}
        />
        <Button
          type="primary"
          onClick={onOpenRolesCreateModal}
        >
          创建角色
        </Button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    roles: state.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onOpenRolesCreateModal() {
      dispatch({
        type: 'modal',
        name: 'RolesCreate'
      });
    },
    onOpenRolesDeleteModal(role) {
      dispatch({
        type: 'modal',
        name: 'RolesDelete',
        context: { role }
      })
    },
  };
}

const Connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Permissions);

export default Connected;
