
import API from 'adapters/api';

const Stores = {};

Stores.create = async (store) => {
  return await API.request({
    resource: 'stores',
    action: 'create',
    data: store
  });
};

Stores.update = async (store) => {
  return await API.request({
    resource: 'stores',
    action: 'update',
    data: store
  });
};

Stores.delete = async (id) => {
  return await API.request({
    resource: 'stores',
    action: 'delete',
    data: { id }
  });
};

const reducers = {
  stores(state, action) {
    if (state === undefined) return null;

    if (action.type === 'storesCreate') {
      return [
        ...state,
        action.store
      ];
    } else if (action.type === 'storesUpdate') {
      return state.map((store) => {
        if (store.id === action.store.id) {
          return Object.assign({}, store, action.store);
        } else {
          return store;
        }
      });
    } else if (action.type === 'storesDelete') {
      return state.filter((store) => store.id !== action.id);
    }

    return state;
  }
}

export { reducers };
export default Stores;
