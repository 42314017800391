
import React from 'react';
import {
  Button
} from 'antd';

import BaseModal from 'modals/base-modal';

function CustomersDelete({
  customer,
  onSubmit,
  onCancel
}) {
  return (
    <BaseModal
      title={`确定删除客户${customer.name}？`}
      onCancelOverride={onCancel}
    >
      <CustomersDeleteForm
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </BaseModal>
  );
}

function CustomersDeleteForm({
  onCancel,
  onSubmit
}) {
  return (
    <div className="customers-delete-form submit">
      <Button
        type="primary"
        danger
        onClick={onSubmit}
      >
        提交
      </Button>
      <Button onClick={onCancel}>取消</Button>
    </div>
  );
}

export default CustomersDelete;
