
import React, { useRef, useState, useEffect } from 'react';
import { Chart } from '@antv/g2';

import ChartPanel from 'components/chart-panel';

const data = [
  { type: '原因 1', value: 103 },
  { type: '原因 2', value: 85 },
  { type: '原因 3', value: 34 },
  { type: '原因 4', value: 34 },
  { type: '原因 5', value: 34 },
  { type: '原因 6', value: 34 },
  { type: '原因 7', value: 34 },
  { type: '未知', value: 200 }
];

function TopReasonsTable() {
  const [choice, setChoice] = useState('total');
  const handleToggle = function(event, value) {
    setChoice(value);
  }

  return (
    <ChartPanel title="Top 流失原因">
      <BarChart data={data.sort((a, b) => a.value - b.value)} />
    </ChartPanel>
  );
}

function BarChart({
  data
}) {
  useEffect(() => {
    const chart = new Chart({
      container: chartContainer.current,
      autoFit: true,
      height: 230,
    });

    chart.scale({
      value: {
        max: 300,
        min: 0
      },
    });

    chart.data(data);

    chart.axis('type', {
      title: null,
      tickLine: null,
      line: null,
    });

    chart.axis('value', {
      label: null,
      grid: null,
      title: null
    });

    chart.legend(false);

    chart.coordinate().transpose();

    chart
      .interval()
      .position('type*value')
      .size(26)
      .label('value', {
        style: {
          fill: '#8d8d8d',
        },
        offset: 10,
      });

    chart.interaction('active-region');

    chart.render();
  }, []);

  const chartContainer = useRef(null);

  return <div ref={chartContainer}></div>;
}

export default TopReasonsTable;
