
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Panel from 'components/panel';
import AnalyticsDomain from 'domains/analytics';
import ColumnChart from 'components/column-chart';

import themeColor from 'theme/_color.scss';
import './nps-distribution-panel.scss';

function NpsDistributionPanel({
  customer
}) {
  const perCustomer = useSelector(AnalyticsDomain.selectors.perCustomer)[customer.id] || {};

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AnalyticsDomain.actions.customerNpsDistribution(customer.id));
  }, []);

  const data = perCustomer.customerNpsDistribution;

  const { nps } = customer;
  let npsColor = themeColor.healthGood;
  if (nps < 7) npsColor = themeColor.healthBad;
  else if (nps < 9) npsColor = themeColor.healthMedium;

  return (
    <div className="nps-distribution-panel">
      <Panel title="NPS" style={{ height: 240 }}>
        {!data && <div>加载中</div>}
        {
          data && data.length === 0 &&
          <div>暂无数据</div>
        }
        {
          data && data.length !== 0 &&
          <div className="content">
            <div className="score" style={{ color: npsColor }}>{nps}</div>
            <div className="chart">
              <ColumnChart data={data} />
            </div>
          </div>
        }
      </Panel>
    </div>
  );
}

export default NpsDistributionPanel;
