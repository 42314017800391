
import React, { useState } from 'react';

import ChartPanel from 'components/chart-panel';
import WaterfallChart from 'components/waterfall-chart';
import ChartToggleButtons from 'components/chart-toggle-button';

function ArrTrend({ arrTrendData }) {
  const [choice, setChoice] = useState('total');
  const handleToggle = function(event, value) {
    if (value !== null) {
      setChoice(value);
    }
  }
  const data = arrTrendData[choice];
  const toggleButtons = (
    <ChartToggleButtons 
      value={choice} 
      onChange={handleToggle}
      choices={{
        total:'总共',
        superAnnual: '旗舰版',
        annual: '标准版'
      }}
    />
  );

  return (
    <ChartPanel
      title="ARR 趋势" 
      actions={toggleButtons}
    >
      <WaterfallChart
        name="arr-trend"
        data={data}
        valueName="ARR"
      />
    </ChartPanel>
  );
}

export default ArrTrend;
