
import React from 'react';
import {
  Form, Input, Button
} from 'antd';

import BaseModal from 'modals/base-modal';

import './list-modal.scss';

function ListModal({
  type,
  list,
  name,
  text,
  onCancel,
  onSubmit
}) {
  let typeTitle = {
    LIVE: '筛选条件',
    STATIC: '静态列表'
  }[type];

  const actionTitle = list ? '更新' : '创建';

  if (list) typeTitle = `${typeTitle}，${list.name}`;

  return (
    <BaseModal
      title={`${actionTitle}${typeTitle}`}
      onCancelOverride={onCancel}
    >
      <div className="modal-list-create-update">
        {text && <p>{text}</p>}
        <NameForm
          name={name}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </div>
    </BaseModal>
  );
}

function NameForm({
  name,
  onCancel,
  onSubmit
}) {
  const nameField = (
    <div>
      <div className="label">名称</div>
      <Form.Item
        name="name"
        rules={[
          { required: true, message: `请输入名称` }
        ]}
      >
        <Input />
      </Form.Item>
    </div>
  );

  return (
    <div className="customer-lists-name-form">
      <Form
        name="CustomerLists"
        initialValues={{ name }}
        onFinish={onSubmit}
      >
        {name !== false && nameField}
        <div className="submit">
          <Form.Item noStyle>
            <Button
              type="primary"
              htmlType="submit"
            >
              提交
            </Button>             
          </Form.Item>
          <Button onClick={onCancel}>取消</Button>
        </div>
      </Form>
    </div>
  );
}

export default ListModal;
