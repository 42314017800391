
import API from 'adapters/api';

/**
 * state structure:
 * {
 *   perCustomer: {
 *     [customerId]: [
 *       {
 *         id: <String>,
 *         userId: <Number>,
 *         userName: <String>,
 *         createdAt: <Datetime>,
 *         customerId: <Number>,
 *         customerName: <String>
 *       }
 *     ]
 *   },
 *   perUser: {
 *     [userId]: [
 *       {
 *         id: <String>,
 *         userId: <Number>,
 *         userName: <String>,
 *         createdAt: <Datetime>,
 *         customerId: <Number>,
 *         customerName: <String>
 *       }
 *     ]
 *   }
 * }
 */

const UserActivities = {
  selectors: {},
  actions: {}
};

UserActivities.selectors.perUser = (state) => state.userActivities.perUser;
UserActivities.selectors.perCustomer = (state) => state.userActivities.perCustomer;

UserActivities.actions.create = (customerId, customerName, userActivity) => {
  return { 
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `/customers/${customerId}/user-activities`,
        action: 'create',
        data: userActivity
      });

      if (status < 300) {
        const activity = {
          ...userActivity,
          userName: state.user.name,
          userId: state.user.id,
          id: data.userActivity.id,
          customerName
        };

        dispatch({
          type: 'addActivity',
          customerId,
          userId: state.user.id,
          activity
        });
      }

      return { status, data };
    }
  };
}

UserActivities.actions.readPerCustomer = (customerId) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `customers/${customerId}/user-activities`,
        action: 'read'
      });
     
      if (status < 300) {
        const { userActivities } = data;

        dispatch({
          type: 'userActivitiesPerCustomerRefreshData',
          customerId,
          data: userActivities,
        });
      }

      return { status, data };
    }
  }
};

UserActivities.actions.readPerUser = (userId) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `users/${userId}/user-activities`,
        action: 'read'
      });
     
      if (status < 300) {
        const { userActivities } = data;

        dispatch({
          type: 'userActivitiesPerUserRefreshData',
          userId,
          data: userActivities,
        });
      }

      return { status, data };
    }
  }
};

const reducers = {
  userActivities(state, action) {
    if (!state) return {};

    if (action.type === 'init') {
      return {
        ...state,
        perCustomer: {},
        perUser: {}
      };
    }

    if (action.type === 'userActivitiesPerUserRefreshData') {
      return {
        ...state,
        perUser: {
          ...state.perUser,
          [action.userId]: action.data
        }
      };
    } else if (action.type === 'userActivitiesPerCustomerRefreshData') {
      return {
        ...state,
        perCustomer: {
          ...state.perCustomer,
          [action.customerId]: action.data
        }
      };
    } else if (action.type === 'addActivity') {
      return {
        ...state,
        perCustomer: {
          ...state.perCustomer,
          [action.customerId]: [...(state.perCustomer[action.customerId] || []), action.activity]
        },
        perUser: {
          ...state.perUser,
          [action.userId]: [...(state.perUser[action.userId] || []), action.activity]
        }
      };
    }

    return state;
  }
}

export { reducers };
export default UserActivities;
