
import React from 'react';
import { connect } from 'react-redux';

import DashboardDomain from 'domains/time-series-exploration';
import DimensionTable from './dimension-table';

function DimensionContainer({
  name,
  title,
  spec,
  data,
  comparisonDimensionName,
  onChangeMode,
  onHideDimension,
  onResetDimension,
  onAddSelection,
  onRemoveSelection
}) {
  const dimensionSpec = spec.dimensions[name];
  const mode = dimensionSpec.mode;
  const selected = dimensionSpec.values;

  let compareLocked = false;
  if (comparisonDimensionName && comparisonDimensionName !== name) {
    compareLocked = true;
  }

  function handleResetDimension() {
    onResetDimension(name);
  }

  function handleChangeMode(mode) {
    onChangeMode(name, mode);
  }

  function handleAddSelection(value) {
    onAddSelection(name, value);
  }

  function handleRemoveSelection(value) {
    onRemoveSelection(name, value);
  }

  function handleHideDimension() {
    onHideDimension(name);
  }

  return (
    <DimensionTable
      key={title}
      name={name}
      title={title}
      data={data.dimensions[name]}
      mode={mode}
      selected={selected}
      compareLocked={compareLocked}
      onHideDimension={handleHideDimension}
      onChangeMode={handleChangeMode}
      onResetDimension={handleResetDimension}
      onAddSelection={handleAddSelection}
      onRemoveSelection={handleRemoveSelection}
    />
  );
}

function mapStateToProps(state) {
  return {
    spec: DashboardDomain.selectors.spec(state),
    data: DashboardDomain.selectors.data(state),
    comparisonDimensionName: DashboardDomain.selectors.comparisonDimensionName(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onResetDimension(name) {
      dispatch(DashboardDomain.actions.dimensionReset(name));
    },
    onChangeMode(name, mode) {
      dispatch(DashboardDomain.actions.dimensionChangeMode(name, mode));
    },
    onAddSelection(name, value) {
      dispatch(DashboardDomain.actions.dimensionAddSelection(name, value));
    },
    onRemoveSelection(name, value) {
      dispatch(DashboardDomain.actions.dimensionRemoveSelection(name, value));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DimensionContainer);
