
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import RingStats from 'components/ring-stats'
import { formatNumber } from 'utils/number';
import CustomersDomain from 'domains/customers';
import Panel from 'components/panel';

import './index.scss';

function HealthDistributionPanel() {
  const [stats, setStats] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    function success(stats) {
      setStats(stats);
    }
    dispatch(CustomersDomain.actions.getAdHocListStats(null, null, success));
  }, []);

  if (!stats) return <div>加载中...</div>;

  const {
    avgHealth, healthDistribution,
    customerCount, totalArr
  } = stats;
  let { good, medium, bad } = healthDistribution;
  if (!good) good = { count: 0, percent: 0 };
  if (!medium) medium = { count: 0, percent: 0 };
  if (!bad) bad = { count: 0, percent: 0 };

  return (
    <Panel title="整体健康分布">
      <div className="component-advanced-health-distribution-panel">
        <div>
          <RingStats
            title="平均健康分"  
            text={avgHealth}
            distribution={healthDistribution}
          />
        </div>
        <div></div>
        <div>
          <div></div>
          <div>
            <div>客户总数：{customerCount}</div>
            <div>总共 ARR：{formatNumber(totalArr)}</div>
            <div className="health good">健康客户数量：{good.count}</div>
            <div className="health medium">中等客户数量：{medium.count}</div>
            <div className="health bad">风险客户数量：{bad.count}</div>
          </div>
          <div></div>
        </div>
      </div>
    </Panel>
  );
}

export default HealthDistributionPanel;
