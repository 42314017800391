
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button, Switch
} from 'antd';
import Pagination from "@mui/material/Pagination";

import Panel from 'components/panel';
import AlertRulesDomain from 'domains/alert-rules';
import AlertRuleDrawer from './alert-rule-drawer';
import DeletionModal from './deletion-modal';
import CustomerMetricsDomain from 'domains/customer-metrics';
import CustomerListsDomain from 'domains/customer-lists';
import MetadataDomain from 'domains/metadata';
import ListItemActions from 'components/list-item-actions';

import './index.scss';

function AlertRules() {
  function handleHideDrawer() {
    setShowDrawer(false);
  }

  function handleShowDrawer() {
    setShowDrawer(true);
  }

  function handleCreateAlertRule(v) {
    dispatch(AlertRulesDomain.actions.create({
      alertRule: v,
      success: handleHideDrawer
    }));
  }

  function handleChangePage(event, value) {
    dispatch(AlertRulesDomain.actions.readList(value));
  }

  const dispatch = useDispatch();

  const alertRules = useSelector(AlertRulesDomain.selectors.data);
  const alertRulesCount = useSelector(AlertRulesDomain.selectors.count);

  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    dispatch(AlertRulesDomain.actions.readList());

    document.title = '云策智慧 - 自动报警';
  }, []);

  const alertRuleTable = (
    <>
      <div className="header">
        <div>是否开启报警</div>
        <div>是否触发 SOP</div>
        <div>报警描述</div>
        <div></div>
      </div>
      <div>
        {alertRules.map((alertRule, i) => {
          return (
            <AlertRule
              key={i}
              alertRule={alertRule}
            />
          );
        })}
      </div>
    </>
  );

  const alertRuleTableZeroState = (
    <div>尚未添加报警，请点击下方按键添加</div>
  );

  return (
    <div className="page-alerts-management">
      <div className="overall-actions">
        <Button
          type="primary"
          onClick={handleShowDrawer}
        >
          创建报警
        </Button>
      </div>
      <Panel>
        <div className="alert-rules-table">
          {
            alertRules.length === 0 ?
              alertRuleTableZeroState :
              alertRuleTable
          }
        </div>
        <div className="pagination">
          <Pagination
            count={Math.ceil(alertRulesCount / 20)}
            variant="outlined"
            color="primary"
            shape="rounded"
            onChange={handleChangePage}
          />
        </div>
      </Panel>
      {
        showDrawer &&
        <AlertRuleDrawer
          onSubmit={handleCreateAlertRule}
          onCancel={handleHideDrawer}
        />
      }
    </div>
  );
}

function AlertRule({
  alertRule
}) {
  function handleShowDeletionModal() {
    setShowDeletionModal(true);
  }

  function handleHideDeletionModal() {
    setShowDeletionModal(false);
  }

  function handleHideDrawer() {
    setShowDrawer(false);
  }

  function handleShowDrawer() {
    setShowDrawer(true);
  }

  function handleDelete() {
    dispatch(AlertRulesDomain.actions.delete({
      id: alertRule.id,
      success: handleHideDeletionModal
    }));
  }

  function handleUpdate(values) {
    setIsEnabled(values.isEnabled);
    setShouldTicket(values.shouldTicket);
    dispatch(AlertRulesDomain.actions.update({
      alertRule: {
        ...values,
        id: alertRule.id
      },
      success: handleHideDrawer
    }));
  }

  function handleIsEnabledUpdate(v) {
    handleUpdate({
      id: alertRule.id,
      isEnabled: v,
      shouldTicket
    });
  }

  function handleShouldTicketUpdate(v) {
    handleUpdate({
      id: alertRule.id,
      shouldTicket: v,
      isEnabled
    });
  }

  const dispatch = useDispatch();

  const [showDrawer, setShowDrawer] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [isEnabled, setIsEnabled] = useState(alertRule.isEnabled);
  const [shouldTicket, setShouldTicket] = useState(alertRule.shouldTicket);

  const fieldsMetadata = useSelector(MetadataDomain.selectors.fieldsMetadata);
  const { events } = fieldsMetadata;
  const customerLists = useSelector(CustomerListsDomain.selectors.data);
  const customerList = customerLists.find(list => list.id === alertRule.customerListId);
  const customerMetricDefinitions = useSelector(CustomerMetricsDomain.selectors.definitions);
  const customerMetricDefinition = customerMetricDefinitions.find(
    (definition) => definition.id === alertRule.customerMetricDefinitionId
  );
  const eventTitle = events[alertRule.eventName] && events[alertRule.eventName].title;
  const alertDescription = alertRule.type === 'EVENT' ?
    `${customerList.name} - ${eventTitle}` :
    `${customerList.name} - ${customerMetricDefinition.name}`;

  return (
    <div className="alert-rule">
      <div>
        <Switch
          checkedChildren="激活"
          unCheckedChildren="未激活"
          checked={isEnabled}
          onChange={handleIsEnabledUpdate}
        />
      </div>
      <div>
        <Switch
          checkedChildren="是"
          unCheckedChildren="否"
          checked={shouldTicket}
          onChange={handleShouldTicketUpdate}
        />
      </div>
      <div className="description">
        {alertDescription}
      </div>
      <div className="actions">
        <ListItemActions
          actions={[
            { name: '修改', onClick: handleShowDrawer },
            { name: '删除', onClick: handleShowDeletionModal }
          ]}
        />
      </div>
      {
        showDrawer &&
        <AlertRuleDrawer
          alertRule={alertRule}
          onSubmit={handleUpdate}
          onCancel={handleHideDrawer}
        />
      }
      {
        showDeletionModal &&
        <DeletionModal
          alertDescription={alertDescription}
          onSubmit={handleDelete}
          onCancel={handleHideDeletionModal}
        />
      }
    </div>
  );
}

export default AlertRules;
