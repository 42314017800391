
import React from 'react';
import PropTypes from 'prop-types';
import {
  Radio,
  Button,
  Col
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  EllipsisOutlined
} from '@ant-design/icons';
import classNames from 'classnames/bind';

import { formatNumber, formatPercent } from 'utils/number';
import RemoveModal from 'modals/dimensions-remove';

import './dimension-table.scss';

class DimensionTable extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    mode: PropTypes.string.isRequired,
    selected: PropTypes.array,
    compareLocked: PropTypes.bool.isRequired,
    onChangeMode: PropTypes.func.isRequired,
    onHideDimension: PropTypes.func.isRequired,
    onResetDimension: PropTypes.func.isRequired,
    onAddSelection: PropTypes.func.isRequired,
    onRemoveSelection: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selected: []
  };

  state = {
    showRemoveModal: false,
    hover: false,
    sortColumn: 'metricValue', // value | metricValue | delta | percentDelta
    sortDirection: 'down', // up | down
    maximized: false
  };

  processData(datum) {
    const previous = datum.metricValue - datum.delta;
    const percentDelta = previous === 0 ? null :
      datum.delta / previous;

    return {
      ...datum,
      percentDelta
    };
  }

  comparison = (a, b) => {
    const { sortColumn, sortDirection } = this.state;

    const aVal = a[sortColumn];
    const bVal = b[sortColumn];

    const direction = sortDirection === 'up' ? 1 : -1;

    let comparison;
    if (aVal > bVal) {
      comparison = 1
    } else {
      comparison = -1;
    }

    return comparison * direction;
  };

  handleMouseEnter = () => {
    this.setState({ hover: true });
  };

  handleMouseLeave = () => {
    this.setState({ hover: false });
  };

  handleSortChange = (column) => {
    const { sortColumn, sortDirection } = this.state;
    
    if (column === sortColumn) {
      if (sortDirection === 'up') this.setState({ sortDirection: 'down' });
      else this.setState({ sortDirection: 'up' });
    } else {
      this.setState({
        sortColumn: column,
        sortDirection: 'down'
      });
    }
  };

  handleModeChange = (e) => {
    const { onChangeMode } = this.props;
    onChangeMode(e.target.value);
  };

  handleShowRemoveModal = () => {
    this.setState({ showRemoveModal: true });
  };

  handleRemove = () => {
    const { onHideDimension, onResetDimension } = this.props;
    onHideDimension();
    onResetDimension();
  };

  handleToggleMaximize = () => {
    this.setState({ maximized: !this.state.maximized });
  };

  handleSelectionClick = (value, isSelected) => {
    const {
      onAddSelection,
      onRemoveSelection
    } = this.props;

    if (isSelected) {
      onRemoveSelection(value);
    } else {
      onAddSelection(value);
    }
  };

  renderRemoveModal = () => {
    return (
      <RemoveModal
        name={this.props.title}
        onCancel={() => this.setState({ showRemoveModal: false })}
        onSubmit={this.handleRemove}
      />
    );
  };

  renderHeaderRow = () => {
    const { title } = this.props;
    const { sortColumn, sortDirection } = this.state;

    const columns = ['', 'value', 'metricValue', 'delta', 'percentDelta'];

    const columnNames = {
      value: title,
      metricValue: '指标',
      delta: '增减',
      percentDelta: '%'
    };

    const cells = columns.map((column) => {
      return (
        <div
          key={column}
          onClick={() => this.handleSortChange(column)}
        >
          <div>{columnNames[column]}</div>
          {
            sortColumn === column &&
            <div>
              {
                sortDirection === 'down' ?
                <CaretDownOutlined /> :
                <CaretUpOutlined />
              }
            </div>
          }
        </div>
      );
    });

    return (
      <div className="row header-row">
        {cells}
      </div>
    );
  };

  renderRow = (datum) => {
    const {
      selected,
      mode
    } = this.props;

    const {
      value,
      metricValue,
      delta,
      percentDelta
    } = datum;

    const selectionIndex = selected.indexOf(value);
    const isSelected = selectionIndex !== -1;

    let rowClassName = `row mode-${mode}`;
    if (isSelected) {
      rowClassName = `${rowClassName} selected selection-${selectionIndex}`;
    } else if (selected.length > 0 && !isSelected) {
      rowClassName = `${rowClassName} deselected`;
    }

    let percentDeltaClassName = 'percent-delta';
    if (percentDelta > 0) {
      percentDeltaClassName = `${percentDeltaClassName} positive`;
    } else if (percentDelta < 0) {
      percentDeltaClassName = `${percentDeltaClassName} negative`;
    }

    return (
      <div
        key={value}
        className={rowClassName}
        onClick={() => {
          this.handleSelectionClick(value, isSelected)
        }}
      >
        <div className="select-status">
          {
            isSelected &&
            (mode === 'EXCLUDE' ? <CloseOutlined /> : <CheckOutlined />)
          }
        </div>
        <div className="title">{value}</div>
        <div>{formatNumber(metricValue)}</div>
        <div>{formatNumber(delta)}</div>
        <div className={percentDeltaClassName}>
          {
            percentDelta === null ? '-' :
            formatPercent(percentDelta)
          }
        </div>
      </div>
    );
  };

  renderControlFooter() {
    const { selected, mode, compareLocked } = this.props;

    const compareEnabled = (
      !compareLocked &&
      selected.length >= 2
    )
    return (
      <div className="control-footer">
        <div className="mode">
          <Radio.Group
            onChange={this.handleModeChange}
            value={mode}
          >
            <Radio.Button value="SELECT">选择</Radio.Button>
            <Radio.Button value="EXCLUDE">排除</Radio.Button>
            <Radio.Button value="COMPARE" disabled={!compareEnabled}>比较</Radio.Button>
          </Radio.Group>
        </div>
        <Button
          type="link"
          onClick={this.handleShowRemoveModal}
        >
          <CloseOutlined />
        </Button>
      </div>
    );
  }

  renderControlFooterPlaceholder() {
    return (
      <div className="control-footer"></div>
    );
  }

  render() {
    let { data, selected } = this.props;
    let { maximized } = this.state;

    data = data
      .map(this.processData)
      .sort(this.comparison);
    
    const shownData = maximized ? data : data.slice(0, 7);
    const shownDataSelectionCount = shownData.filter((d) => selected.indexOf(d.value) !== -1).length;
    const selectionCount = selected.length;

    const className = classNames(
      'dimension-table',
      'section',
      { 'maximized': maximized }
    );

    const span = maximized ? 24 : 12;

    return (
      <Col span={span}>
        <div
          className={className}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {this.state.showRemoveModal && this.renderRemoveModal()}
          <div className="data-table">
            {this.renderHeaderRow()}
            {shownData.map(this.renderRow)}
            {
              data.length >= 8 &&
              <div className="row" onClick={this.handleToggleMaximize}>
                <div></div>
                <div>
                  {
                    maximized &&
                    <Button type="link" style={{ paddingLeft: 0, border: 'none' }}>
                      缩小
                    </Button>
                  }
                  {
                    !maximized &&
                    <Button type="link" style={{ paddingLeft: 0, border: 'none' }}>
                      <EllipsisOutlined />
                      {shownDataSelectionCount !== selectionCount && (`   共选中 ${selectionCount} 个维度值`)}
                    </Button>
                  }
                </div>
              </div>
            }
          </div>
          {
            this.state.hover &&
            this.renderControlFooter()
          }
          {
            !this.state.hover &&
            this.renderControlFooterPlaceholder()
          }
        </div>
      </Col>
    );
  }
}

export default DimensionTable;
