
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Button, Tooltip } from 'antd';
import classnames from 'classnames';

import ListItemActions from 'components/list-item-actions';
import CustomersDomain from 'domains/customers';
import DeleteModal from '../customer-modals/delete';
import Panel from 'components/panel';
import MetadataDomain from 'domains/metadata';

import themeColor from 'theme/_color.scss';
import './index.scss';

function CustomersTable({
  page,
  handleChangePage,
  handleClickCustomerCreation
}) {
  function getSelector(fieldName) {
    return MetadataDomain.selectors.getComponentParamsSelector({
      collection: 'customers',
      fieldName,
      record: {},
      onUpdate: () => {}
    });
  }

  function handleInteracting(id) {
    setInteractingId(id);
  }

  function handleOpenDeleteModal() {
    setShowDeleteModal(true);
  }

  function handleDelete() {
    dispatch(
      CustomersDomain.actions.delete({
        id: interactingId,
        success: handleDeleteCancel
      })
    );
  }

  function handleDeleteCancel() {
    setShowDeleteModal(false);
    setInteractingId(null);
  }

  const dispatch = useDispatch();

  const count = useSelector(CustomersDomain.selectors.count);
  const customers = useSelector(CustomersDomain.selectors.data);
  const client = useSelector(MetadataDomain.selectors.client);

  const [interactingId, setInteractingId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const selector = MetadataDomain.selectors.getComponentParamsSelector({
    collection: 'customers',
    fieldName: 'planId',
    record: {},
    onUpdate: () => {}
  });
  const planParams = useSelector(selector);
  const planOptions = [
    { title: '无', value: null },
    ...planParams.selectOptions
  ];
  const assignedEmployeeParams = useSelector(getSelector('assignedEmployeeId'));
  const assignedEmployeeOptions = [
    { title: '无', value: null },
    ...assignedEmployeeParams.selectOptions
  ];

  const columns = getColumns({
    clientType: client.type,
    interactingId,
    onInteracting: handleInteracting,
    onOpenDeleteModal: handleOpenDeleteModal,
    planOptions,
    assignedEmployeeOptions
  });

  return (
    <div className="customers-table">
      <Panel>
        <div className="overall-actions">
          <Button
            type="primary"
            onClick={handleClickCustomerCreation}
          >
            手动创建客户
          </Button>
        </div>
        <Table
          dataSource={customers}
          rowKey="id"
          columns={columns}
          pagination={{
            current: page,
            total: count,
            pageSize: 20,
            showTotal(total, range) {
              return (
                <div className="total">
                  {`总共 ${total} 项，显示第 ${range[0]}-${range[1]}`}
                </div>
              );
            },
            showSizeChanger: false,
            position: ['bottomLeft'],
            onChange: handleChangePage
          }}
        />
        {
          showDeleteModal &&
          <DeleteModal
            customer={customers.find((customer) => customer.id === interactingId)}
            onSubmit={handleDelete}
            onCancel={handleDeleteCancel}
          />
        }
      </Panel>
    </div>
  );
}

function getColumns({
  clientType,
  interactingId,
  onInteracting,
  onOpenDeleteModal,
  planOptions,
  assignedEmployeeOptions
}) {
  let columns = [];
  columns = columns.concat([
    {
      title: '',
      dataIndex: 'churnPrediction',
      width: '15px',
      render(text, record) {
        if (record.churnPrediction === '流失') {
          const churnDecisionTreeLink = 
            `/customers-management/${record.id}/churn-decision-tree`;
          return (
            <Tooltip title="有流失风险">
              <Link to={churnDecisionTreeLink}>
                <div className='dot'> </div>
              </Link>
            </Tooltip>
          )
        }
      }
    },
    {
      title: '团队',
      dataIndex: 'name',
      render(text, record) {
        const path = [
          '/customers-management/',
          `${record.id}/`,
          'summary'
        ].join('');

        return (
          <Link to={path}>{record.name}</Link>
        )
      }
    },
    {
      title: '健康',
      dataIndex: 'healthScore',
      render(text, record) {
        let health = 'bad';
        if (text >= 70) health = 'good';
        else if (text >= 40) health = 'medium';
        if (isNaN(text) || text === null) health = 'noData';

        const color = {
          good: themeColor.healthGood,
          medium: themeColor.healthMedium,
          bad: themeColor.healthBad,
          noData: '#BFBFBF'
        }[health];

        return (
          <div style={{ padding: '1px 3px', textAlign: 'center', backgroundColor: color, color: 'white' }}>
            {text.toFixed(0)}
          </div>
        );
      }
    }
  ]);

  columns = columns.concat([
    {
      title: '版本',
      dataIndex: 'planId',
      render(text, record) {
        if (!text) return '';
        const option = planOptions.find((option) => option.value === text);
        return option.title;
      }
    },
    {
      title: '状态',
      dataIndex: 'status'
    },
    {
      title: '区域',
      dataIndex: 'region'
    },
    {
      title: '客户级别',
      dataIndex: 'custom_string_1'
    },
    {
      title: 'ARR',
      dataIndex: 'arr'
    },
    {
      title: '续费日期',
      dataIndex: 'nextRenewalDate',
      render(text, record) {
        if (record.nextRenewalDate) {
          return record.nextRenewalDate.slice(0, 10);
        } else {
          return '无';
        }
      }
    },
    {
      title: 'CS 负责人',
      dataIndex: 'assignedEmployeeId',
      render(text, record) {
        if (!text) return '';
        const option = assignedEmployeeOptions.find((option) => option.value === text);
        return option.title;
      }
    },
    {
      title: '行业',
      dataIndex: 'industry'
    },
    {
      title: '人数',
      dataIndex: 'licenseCount'
    },
    {
      title: '激活人数',
      dataIndex: 'activeLicenseCount'
    },
    {
      title: '激活人数分布',
      dataIndex: 'custom_string_3'
    },
    {
      title: '激活率',
      dataIndex: 'custom_number_7'
    }
  ]);

  columns = columns.concat([
    {
      render: (text, record) => {
        const className = classnames(
          'actions',
          { interacting: record.id === interactingId }
        );
        return (
          <div className={className}>
            <ListItemActions
              actions={[
                { name: '删除', onClick: onOpenDeleteModal }
              ]}
              onClick={() => onInteracting(record.id)}
            />
          </div>
        );
      },
    }
  ]);

  return columns;
}

export default CustomersTable;
