
import React, { useState } from 'react';

import ChartPanel from 'components/chart-panel';
import DistributionTrendChart from 'components/distribution-trend-chart';

import ChartToggleButtons from 'components/chart-toggle-button';

function GRRChart({ grrData }) {
  const [choice, setChoice] = useState('total');
  const handleToggle = function(event, value) {
    setChoice(value);
  }

  const data = grrData[choice];

  const toggleButtons = (
    <ChartToggleButtons 
      value={choice} 
      onChange={handleToggle}
      choices={{
        total: '总共',
        superAnnual: '旗舰版',
        annual: '标准版'
      }}
    />
  );

  return (
    <ChartPanel 
      title="GRR"
      actions={toggleButtons}>
      <DistributionTrendChart
        type="line"
        valueType="percent"
        data={data}
        valueName="value"
        colorMapper={() => '#7E92B5'}
      />
    </ChartPanel>
  );
}

export default GRRChart;
