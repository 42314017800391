
import React, { useEffect, useRef } from 'react';
import { Chart } from '@antv/g2';

import themeColor from 'theme/_color.scss';
import './renewal-numbers.scss';

function RenewalNumbers() {
  const chartContainer = useRef(null);

  useEffect(() => {
    chartContainer.current.innerHTML = '';
    createChart(chartContainer.current);
  }, []);

  function createChart(container) {
    const chart = new Chart({
      container,
      autoFit: true,
      height: 200
    });

    chart.data(fakeData);

    chart.tooltip({
      showMarkers: false
    });

    chart.coordinate().transpose();
    chart
      .interval()
      .position('label*value')
      .color('label', (label) => {
        return {
          '已续费': themeColor.success,
          '已流失': themeColor.failure,
          '尚未到期': '#C0C0C0'
        }[label];
      });
    
    chart.tooltip({
      shared: true,
      marker: false
    });

    chart.interaction('active-region');

    chart.render();
  }

  return (
    <div className="renewal-numbers" ref={chartContainer}></div>
  );
}

const fakeData = [
  { value: 5, label: '已流失' },
  { value: 20, label: '尚未到期' },
  { value: 15, label: '已续费' }
]

export default RenewalNumbers;
