
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Select
} from 'antd';

import MetadataDomain from 'domains/metadata';
import CustomersDomain from 'domains/customers';
import Panel from 'components/panel';
import HealthDistributionPanel from 'components-advanced/health-distribution-panel';

import JourneyMap from './journey-map';
import HealthModelEditor from './health-model-editor';

import './index.scss';

const CUSTOMERS_OBJECT_ID = 1;

const HARD_CODED_JOURNEY_NODES = [
  {
    id: 'start',
    type: 'end',
    label: '订阅'
  },
  {
    id: '初始客户',
    label: '初始客户'
  },
  {
    id: '维护客户',
    label: '维护客户'
  },
  {
    id: 'end',
    type: 'end',
    label: '结束'
  }
];

const HARD_CODED_JOURNEY_EDGES = [
  {
    source: 'start',
    target: '初始客户'
  },
  {
    source: '初始客户',
    target: '维护客户'
  },
  {
    source: '维护客户',
    target: 'end'
  }
];

function HealthModels() {
  function handleCategorySelection(value) {
    setCategory(value);
    setJourneyStage('初始客户');
  }

  function handleJourneyStageSelection(value) {
    setJourneyStage(value);
  }

  const fieldsMetadataSelector = MetadataDomain.getFieldsMetadataSelector({
    objectId: CUSTOMERS_OBJECT_ID
  });
  const fieldsMetadata = useSelector(fieldsMetadataSelector);
  const categoryOptions = fieldsMetadata.category.options;

  const [category, setCategory] = useState(categoryOptions[0].value);
  const [journeyStage, setJourneyStage] = useState('初始客户');
  const [stats, setStats] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = '云策智慧 - 健康模型';
  }, []);

  useEffect(() => {
    function success(stats) {
      setStats(stats);
    }
    dispatch(CustomersDomain.actions.getAdHocListStats(category, journeyStage, success));
  }, [category, journeyStage]);

  return (
    <div className="models">
      <Panel>
        <div className="model-selection">
          <div>
            <div className="category-selection">
              <div className="title">客户分组：</div>
              <div>
                <Select
                  defaultValue={category}
                  onChange={handleCategorySelection}
                  style={{ width: 150 }}
                >
                  {categoryOptions.map(({ value, title }) => 
                    <Select.Option key={value} value={value}>{title}</Select.Option>
                  )}
                </Select>
              </div>
            </div>
            <div className="journey-stage-selection">
              <div className="title">历程阶段：</div>
              <JourneyMap
                nodes={HARD_CODED_JOURNEY_NODES}
                edges={HARD_CODED_JOURNEY_EDGES}
                onSelectNode={handleJourneyStageSelection}
                selectedNodeId={journeyStage}
              />
            </div>
          </div>
          <div className="divider"></div>
          <div className="stats">
            <HealthDistributionPanel stats={stats} />
          </div>
        </div>
      </Panel>
      <div style={{ height: 10 }}></div>
      <HealthModelEditor
        category={category}
        journeyStage={journeyStage}
      />
    </div>
  );
}

export default HealthModels;
