
import API from 'adapters/api';

/**
 * state structure:
 * {
 *   data: [
 *     {CustomFormula}
 *   ]
 * }
 */

const CustomFormulae = {
  selectors: {},
  actions: {}
};

CustomFormulae.selectors.data = (state) => state.customFormulae.data;

CustomFormulae.actions.readList = () => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'custom-formulae',
        action: 'readList'
      });

      if (status < 300) {
        const { customFormulae } = data;

        dispatch({
          type: 'customFormulaeRefreshData',
          data: customFormulae
        });
      }

      return { status, data };
    }
  }
};

CustomFormulae.actions.create = ({
  customFormula, success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'custom-formulae',
        action: 'create',
        data: customFormula
      });

      if (status < 300) {
        dispatch({
          type: 'customFormulaeAdd',
          data: data.customFormula
        });

        dispatch({
          type: 'customFieldsCreate',
          data: { type: customFormula.type, isFormula: true, title: data.customFormula.name, name: data.customFormula.customKey }
        });

        success && success();
      }

      return { status, data };
    }
  }
};

CustomFormulae.actions.update = ({
  customFormula,
  success
}) => {

  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'custom-formulae',
        action: 'update',
        data: customFormula
      });

      if (status < 300) {
        dispatch({
          type: 'customFormulaeUpdate',
          data: customFormula
        });

        dispatch({
          type: 'customFieldsUpdate',
          data: {
            name: customFormula.fieldName,
            title: customFormula.name,
            type: 'FORMULA',
            isFormula: true
          }
        });

        success && success();
      }

      return { status, data };
    }
  }
};

CustomFormulae.actions.delete = ({
  id,
  name,
  success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'custom-formulae',
        action: 'delete',
        data: { id }
      });

      if (status < 300) {
        dispatch({
          type: 'customFormulaeRemove',
          data: { id }
        });

        dispatch({
          type: 'customFieldsRemove',
          data: { name }
        });

        success && success();
      }

      return { status, data };
    }
  }
};

const reducers = {
  customFormulae(state, action) {
    if (state === undefined) return { data: [] };

    if (action.type === 'customFormulaeAdd') {
      return {
        ...state,
        data: [
          ...state.data,
          action.data
        ]
      };
    } else if (action.type === 'customFormulaeRefreshData') {
      return {
        ...state,
        data: action.data
      };
    } else if (action.type === 'customFormulaeUpdate') {
      return {
        ...state,
        data: state.data.map((datum) => {
          if (datum.id === action.data.id) {
            return {
              ...datum,
              ...action.data
            };
          }
          return datum;
        })
      }
    } else if (action.type === 'customFormulaeRemove') {
      return {
        ...state,
        data: state.data.filter((datum) => datum.id !== action.data.id)
      };
    }

    return state;
  }
}

export { reducers };
export default CustomFormulae;
