
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button, Row, Col
} from 'antd';

import CustomerMetricsDomain from 'domains/customer-metrics';
import Definition from './definition';
import Drawer from './drawer';

import './index.scss';

function CustomerMetricDefinitions() {
  function handleHideDrawer() {
    setShowDrawer(false);
  }

  function handleShowDrawer() {
    setShowDrawer(true);
  }

  function handleCreate(v) {
    if (!v.logic) v.logic = {};
    dispatch(CustomerMetricsDomain.actions.createDefinition({
      definition: v,
      success: handleHideDrawer
    }));
  }

  const dispatch = useDispatch();

  const customerMetricDefinitions = useSelector(CustomerMetricsDomain.selectors.definitions);
  const metrics = useSelector(CustomerMetricsDomain.selectors.overall);

  const metricsByDefinitionId = (metrics || []).reduce((total, curr) => {
    total[curr.id] = curr.timeSeries;
    return total;
  }, {});

  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    dispatch(CustomerMetricsDomain.actions.readOverall());
  }, []);

  const pinnedDefinitions = customerMetricDefinitions.filter((definition) => definition.pinned);
  const unPinnedDefinitions = customerMetricDefinitions.filter((definition) => !definition.pinned);
  const unpinnedNoSectionDefinitions = [];
  const unPinnedDefinitionsBySection = {};
  unPinnedDefinitions.forEach((definition) => {
    const { section } = definition;

    if (section === undefined || section === null) {
      unpinnedNoSectionDefinitions.push(definition);
      return;
    }

    if (!unPinnedDefinitionsBySection[section]) {
      unPinnedDefinitionsBySection[section] = [];
    }
    unPinnedDefinitionsBySection[section].push(definition);
  });

  const pinnedDefinitionsContent = (
    <Row gutter={[10, 10]}>
      {pinnedDefinitions.map(renderDefinition)}
    </Row>
  );
  const unpinnedNoSectionDefinitionsContent = (
    <Row gutter={[10, 10]}>
      {unpinnedNoSectionDefinitions.map(renderDefinition)}
    </Row>
  );

  function renderDefinition(definition, i) {
    const metrics = metricsByDefinitionId[definition.id];
    return (
      <Col key={i} span={8}>
        <Definition
          definition={definition}
          metrics={metrics}
        />
      </Col>
    );
  }

  return (
    <div className="customer-metric-definitions">
      <div className="create">
        <Button
          type="primary"
          onClick={handleShowDrawer}
        >
          创建自定义指标
        </Button>
      </div>
      <div className="definitions-table">
        {
          pinnedDefinitions.length > 0 &&
          <>
            <div className="divider pinned">置顶指标</div>
            {pinnedDefinitionsContent}
          </>
        }
        {
          Object.keys(unPinnedDefinitionsBySection)
            .sort()
            .map((section, i) => {
              const definitions = unPinnedDefinitionsBySection[section];
              const definitionsContent = (
                <Row key={`${section}-content`} gutter={[10, 10]}>
                  {definitions.map(renderDefinition)}
                </Row>
              );
              return (
                <div key={i}>
                  <div className="divider">{section}</div>
                  {definitionsContent}
                </div>
              );
            })
        }
        {
          unpinnedNoSectionDefinitions.length > 0 &&
          <>
            <div className="divider">无分类指标</div>
            {unpinnedNoSectionDefinitionsContent}
          </>
        }
      </div>
      {
        showDrawer &&
        <Drawer
          onSubmit={handleCreate}
          onCancel={handleHideDrawer}
        />
      }
    </div>
  );
}

export default CustomerMetricDefinitions;
