
import React from 'react';
import {
  Button
} from 'antd';

import BaseModal from 'modals/base-modal';

function DeleteModal({
  type,
  name,
  onCancel,
  onSubmit
}) {
  const typeTitle = {
    LIVE: '筛选条件',
    STATIC: '客户清单'
  }[type];

  return (
    <BaseModal
      title={`确定删除${typeTitle}${name}`}
      onCancelOverride={onCancel}
    >
      <div className='submit'>
        <Button type="primary" danger onClick={onSubmit}>
          确定
        </Button>
        <Button onClick={onCancel}>
          取消
        </Button>
      </div>
    </BaseModal>
  );
}

export default DeleteModal;
