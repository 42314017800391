
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Pagination from "@mui/material/Pagination";

import EventsDomain from 'domains/events';
import EventsLog from 'components/events-log';
import Panel from 'components/panel';

import FilterPanel from './filter-panel';
import ActivityLevel from './activity-level';

import './index.scss';

function CustomersMonitoring() {
  function handleChangePage(_, page) {
    const offset = (page - 1) * limit;
    search(offset, limit, filters);
  }

  function handleChangeFilter(filters) {
    const offset = 0;
    setFilters(filters);
    search(offset, limit, filters);
  }

  function search(offset, limit, filters) {
    dispatch(EventsDomain.actions.overall(
      {
        ...filters,
        subType: filters.customerEventType,
        name: filters.eventName
      },
      { offset, limit }
    ));
  }

  const initialEndDate = moment().format('YYYY-MM-DD');
  const initialStartDate = moment().subtract(14, 'day').format('YYYY-MM-DD');

  const dispatch = useDispatch();

  const overall = useSelector(EventsDomain.selectors.overall);
  let events = [], count = 0, countByDate = [];
  if (overall) {
    events = overall.data;
    count = overall.count;
    countByDate = overall.countByDate;
  }

  const [filters, setFilters] = useState({
    endDate: initialEndDate,
    startDate: initialStartDate,
    customerEventType: null,
    eventName: null,
    customerName: null,
    customerUserName: null
  });

  const limit = 20;

  const { endDate, startDate } = filters;
  const chartTitle = `${startDate} - ${endDate}`;
  let filledTimeSeries = [];
  if (countByDate && countByDate.length > 0) {
    filledTimeSeries = getActivityLevelData({
      startDate, endDate, countByDate
    });
  }

  useEffect(() => {
    document.title = '云策智慧 - 客户监控';
  }, []);

  return (
    <div className="page-customers-monitoring">
      <div className="filter-panel">
        <FilterPanel
          filters={filters}
          setFilters={handleChangeFilter}
        />
      </div>
      <div className="content">
        <div className="activity-level">
          <ActivityLevel
            title={chartTitle}
            data={filledTimeSeries}
          />
        </div>
        <div className="events">
          <Panel>
            {
              events && events.length > 0 &&
              <div className="pagination">
                <Pagination
                  count={count ? Math.ceil(count / limit) : count}
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </div>
            }
            <EventsLog
              events={events}
            />
          </Panel>
        </div>
      </div>
    </div>
  );
}

function getActivityLevelData({ startDate, endDate, countByDate }) {
  const countByDateObj = countByDate.reduce((total, curr) => {
    const dateStr = moment(curr.timestamp).format('YYYY-MM-DD'); 
    total[dateStr] = curr.value;
    return total;
  }, {});

  const startDateMoment = moment(startDate);
  const endDateMoment = moment(endDate);
  const diff = endDateMoment.diff(startDateMoment);
  const diffDays = Math.floor(diff / 1000 / 60 / 60 / 24);
  
  const data = [];
  for (let i = 0; i <= diffDays; i++) {
    const dateStr = startDateMoment.format('YYYY-MM-DD');
    data.push({
      timestamp: dateStr,
      value: countByDateObj[dateStr] || 0
    });
    startDateMoment.add(1, 'day');
  }

  return data;
}

export default CustomersMonitoring;
