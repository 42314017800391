
import React from 'react';

import Panel from 'components/panel';

import Cell from './cell';

import './index.scss';

function Matrix() {
  return (
    <div className="matrix">
      <Panel title="健康矩阵概况">
        <div className="matrix-content">
          <div className="header-row">
            <div className="column-header"></div>
            <div>初始客户</div>
            <div>维护客户</div>
          </div>
          <div className="data-row">
            <div className="column-header">KA</div>
            <Cell category="KA" journeyStage="初始客户" />
            <Cell category="KA" journeyStage="维护客户" />
          </div>
          <div className="data-row">
            <div className="column-header">MM</div>
            <Cell category="MM" journeyStage="初始客户" />
            <Cell category="MM" journeyStage="维护客户" />
          </div>
          <div className="data-row">
            <div className="column-header">SMB</div>
            <Cell category="SMB" journeyStage="初始客户" />
            <Cell category="SMB" journeyStage="维护客户" />
          </div>
        </div>
      </Panel>
    </div>
  );
}

export default Matrix;
