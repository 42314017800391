
import React, { useState } from 'react';
import { Select } from 'antd';

import FeedbackDomain from 'domains/feedback';
import Panel from 'components/panel';
import Feedback from '../feedback';

import './index.scss';

function FeedbackGeneral() {
  const [category, setCategory] = useState('all-but-complaint');

  return (
    <div className="general">
      <Panel>
        <div className="select">
          <Select size={'large'} value={category} onChange={setCategory}  style={{ width: '200px' }}>
            <Select.Option value="all-but-complaint">
              全部
            </Select.Option>
            <Select.Option value="suggestion">
              意见与建议
            </Select.Option>
            <Select.Option value="shoutout">
              表扬
            </Select.Option>
            <Select.Option value="other">
              其他
            </Select.Option>
          </Select>
        </div>
        <Feedback
          selector={FeedbackDomain.selectors.generalFeedback}
          category={category}
        />
      </Panel>
    </div>
  );
}

export default FeedbackGeneral;
