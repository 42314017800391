
import React from 'react';
import {
  Button
} from 'antd';

import BaseModal from 'modals/base-modal';

import './deletion-modal.scss';

function DeletionModal({
  ticket,
  onCancel,
  onSubmit
}) {
  return (
    <BaseModal
      title={'确定删除 SOP？'}
      onCancelOverride={onCancel}
    >
      <div className="modal-tickets-deletion">
        <div className="title">
          <div>{ticket.title}</div>
        </div>
        <div className="message">
          <div>{ticket.message}</div>
        </div>
        <div></div>
        <div className="submit">
          <Button type="primary" danger onClick={onSubmit}>
            确定
          </Button>
          <Button onClick={onCancel}>
            取消
          </Button>
        </div>
      </div>
    </BaseModal >
  );
}

export default DeletionModal;
