
import React, { useEffect } from 'react';

import TicketsTable from 'components-advanced/tickets-table';

function Tickets() {
  useEffect(() => {
    document.title = '云策智慧 - SOP 管理';
  }, []);

  return (
    <div className='page-tickets'>
      <TicketsTable />
    </div >
  );
}

export default Tickets;
