
import React from 'react';
import {
  Row, Col, Alert
} from 'antd';

import HealthDistributionPanel from 'components-advanced/health-distribution-panel';
import DashboardHeader from 'components/dashboard-header';
import MetricPanel from 'components/metric-panel';
import NDRChart from 'analytics/ndr-chart';
import SubscriptionChangeTable from 'analytics/subscription-change-table';
import ArrTrendChart from 'analytics/arr-trend-chart';
import LogoRetentionChart from 'analytics/logo-retention-chart';
import {
  arrData,
  ndrData,
  grrData,
  subscriptionChangeData,
  arrChangeData,
  logoRetentionData
} from 'analytics/fake-data';
import {
  membershipSummary,
  arrSummary
} from 'analytics/fake-data-summary';

import YTDRenewalToGoal from './ytd-renewal-to-goal';
import GRRChart from './grr-chart';
import SubscriptionChangeTrend from './subscription-change-trend';

function Summary() {
  return (
    <div className="summary-dashboard">
      <Alert
        message="本页面包括未完成功能，以及测试数据！"
        type="error"
      />
      <div style={{ height: 15 }}></div>
      <DashboardHeader
        title="概况"
        onTimeFrameSelect={() => {}}
        timeFrameString="RECENT_6_MONTHS"
      />
      <Row gutter={10}>
        <Col span={6}>
          <MetricPanel
            title="总客户数"
            text={membershipSummary.totalCount}
            delta={membershipSummary.delta}
          />
        </Col>
        <Col span={6}>
          <MetricPanel
            title="ARR"
            text={arrSummary.totalCount}
            delta={arrSummary.delta}
          />
        </Col>
        <Col span={6}>
          <MetricPanel
            title="平均健康分"
            text={68}
            color={'#faad14'}
            delta={-0.03}
          />
        </Col>
        <Col span={6}>
          <MetricPanel
            title="NPS"
            text={21}
            color={'#52c41a'}
            delta={0.05}
          />
        </Col>
      </Row>
      <div style={{ height: 10 }}></div>
      <Row gutter={[10, 10]}>
        <Col span={12}>
          <HealthDistributionPanel />
        </Col>
        <Col span={12}>
          <YTDRenewalToGoal />
        </Col>
        <Col span={8}>
          <NDRChart 
            ndrData={ndrData}
          />
        </Col>
        <Col span={8}>
          <GRRChart
            grrData={grrData}
          />
        </Col>
        <Col span={8}>
          <LogoRetentionChart
            logoRetentionData={logoRetentionData}
          />
        </Col>
        <Col span={8}>
          <ArrTrendChart 
            arrTrendData={arrData}
          />
        </Col>
        <Col span={8}>
          <SubscriptionChangeTrend
            arrData={arrData}
          />
        </Col>
        <Col span={8}>
          <SubscriptionChangeTable
            subscriptionChangeData={subscriptionChangeData}
            arrChangeData={arrChangeData}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Summary;
