
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import classnames from 'classnames';

import UsersDomain from 'domains/users';
import CustomerNotesDomain from 'domains/customer-notes';
import ListItemActions from 'components/list-item-actions';
import InPlaceEdit from 'components/in-place-edit';
import Panel from 'components/panel';
import NoteDrawer from './note-drawer';
import NotesDelete from './notes-delete-modal';

import './index.scss';

function Notes({
  customer
}) {
  function handleHideDrawer() {
    setShowDrawer(false);
  }

  function handleShowDrawer() {
    setShowDrawer(true);
  }

  function handleCreateNote(values) {
    dispatch(
      CustomerNotesDomain.actions.createNote(
        {
          ...values,
          customerId: customer.id
        },
        handleHideDrawer
      )
    );
  }

  function handleDelete(note) {
    dispatch(CustomerNotesDomain.actions.delete(note));
    setShowDeleteModal(false);
  }

  function handleDeleteCancel() {
    setShowDeleteModal(false);
  }

  function onDelete(note) {
    setShowDeleteModal(true);
    setInteractingNote(note);
  }

  const { id } = customer;

  const dispatch = useDispatch();
  const [showDrawer, setShowDrawer] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [interactingNote, setInteractingNote] = useState(null);
  const notes = useSelector(CustomerNotesDomain.selectors.perCustomer)[id];

  const pinnedNotes = (notes || []).filter((note) => note.pinned).sort(sortNotes);
  const unpinnedNotes = (notes || []).filter((note) => !note.pinned).sort(sortNotes);

  useEffect(() => {
    if (!notes) {
      dispatch(CustomerNotesDomain.actions.getNotes(id));
    }
  }, []);

  return (
    <div className="notes-panel">
      <Button
        type="primary"
        onClick={handleShowDrawer}
      >
        创建笔记
      </Button>
      {
        showDrawer &&
        <NoteDrawer
          onSubmit={handleCreateNote}
          onCancel={handleHideDrawer}
        />
      }
      <div style={{ height: 10 }}></div>
      {
        notes &&
        notes.length === 0 &&
        <div>-- 暂无笔记 --</div>
      }
      {!notes && <div>-- 加载中 --</div>}
      {
        notes &&
        <>
          {pinnedNotes.map((note, i) => {
            return <Note key={i} note={note} onDelete={onDelete}/>
          })}
          {
            pinnedNotes.length > 0 && unpinnedNotes.length > 0 &&
            <div className="divider"></div>
          }
          {unpinnedNotes.map((note, i) => {
            return <Note key={i} note={note} onDelete={onDelete}/>
          })}
        </>
      } 
      {
        showDeleteModal && 
        <NotesDelete
          note = {interactingNote}
          handleDelete = {handleDelete}
          onCancel = {handleDeleteCancel}
        />
      }
    </div>
  )
}

function Note({
  note,
  onDelete
}) {
  function handleUpdate(field, value) {
    dispatch(CustomerNotesDomain.actions.update({
      ...note,
      [field]: value
    }));
  };

  function handlePin() {
    dispatch(CustomerNotesDomain.actions.update({
      ...note,
      pinned: !note.pinned
    }));
  }

  const {
    content, category, pinned,
    createdById, createdAt,
    updatedById, updatedAt
  } = note;

  const actions = [
    { name: pinned ? '取消置顶' : '置顶', onClick: handlePin },
    { name: '删除', onClick: () => onDelete(note) }
  ];

  const dispatch = useDispatch();
  const users = useSelector(UsersDomain.selectors.data);

  const createdBy = users.find((user) => user.id === createdById);
  const updatedBy = users.find((user) => user.id === updatedById);

  const className = classnames('note', { pinned });

  return (
    <div className={className}>
      <Panel>
        <div className="top-row">
          <div>
            <span>创建：</span>
            <span>{createdBy.name}</span>
            <span>，</span>
            <span>{createdAt.substring(0, 19).replace('T', ' ')}</span>
          </div>
          <div>
            <span>最后更新：</span>
            <span>{updatedBy.name}</span>
            <span>，</span>
            <span>{updatedAt.substring(0, 19).replace('T', ' ')}</span>
          </div>
          <div>
            <ListItemActions
              actions={actions}
              onClick={() => {}}
              onCancel={() => {}}
            />
          </div>
        </div>
        <div className="mid-row">
          <InPlaceEdit
            title="类型"
            type="select"
            value={category}
            selectOptions={[
              { title: '拜访记录', value: '拜访记录' },
              { title: '分析', value: '分析' },
              { title: '其他', value: '其他' }
            ]}
            displayMode="horizontal"
            onSubmit={(v) => handleUpdate('category', v)}
          />
        </div>
        <div className="bottom-row">
          <InPlaceEdit
            type="textarea"
            value={content}
            onSubmit={(v) => handleUpdate('content', v)}
          />
        </div>
      </Panel>
    </div>
  );
}

function sortNotes(a, b) {
  return new Date(b.updatedAt) - new Date(a.updatedAt);
}

export default Notes;
