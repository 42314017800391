
import React from 'react';
import { useSelector } from 'react-redux';

import MetadataDomain from 'domains/metadata';
import Panel from 'components/panel';
import UsersDomain from 'domains/users';
import CustomerUsersDomain from 'domains/customer-users';

import './left-panel.scss';

function LeftPanel({ customer }) {
  const client = useSelector(MetadataDomain.selectors.client);

  const users = useSelector(UsersDomain.selectors.data);

  const customerUsers = useSelector(CustomerUsersDomain.selectors.perCustomer)[customer.id];

  const customerContacts = customerUsers.filter((user) => {
    return user.tags && (user.tags.includes('联系人'));
  });

  const user = users.find((user) => {
    return user.id === customer.assignedEmployeeId;
  });

  return (
    <div className="left-panel">
      <Panel style={{ height: '100%' }}>
        <div className="title">基本信息</div>
        <div className="info">
          <div>
            <div>名称：</div>
            <div>{customer.name}</div>
          </div>
          <div>
            <div>负责员工：</div>
            <div>{user && user.name}</div>
          </div>
          <div>
            <div>地址：</div>
            <div>
              {customer.country}
              {customer.province}{customer.city}
              {customer.district}{customer.street}
            </div>
          </div>
          <div>
            <div>历程阶段：</div>
            <div>{customer.journeyStage}</div>
          </div>
          <div>
            <div>NPS</div>
            <div>{customer.nps}</div>
          </div>
          <div>
            <div>ARR</div>
            <div>{customer.arr}</div>
          </div>
          {
            client.type === 'b2b' &&
            <>
              <div>
                <div>行业：</div>
                <div>{customer.industry}</div>
              </div>
              <div>
                <div>区域：</div>
                <div>{customer.region}</div>
              </div>
              <div>
                <div>分类：</div>
                <div>{customer.category}</div>
              </div>
            </>
          }
          {
            client.type === 'b2c' &&
            <>
              <div>
                <div>性别：</div>
                <div>{customer.gender}</div>
              </div>
              <div>
                <div>生日：</div>
                <div>{customer.birthday}</div>
              </div>
              <div>
                <div>email：</div>
                <div>{customer.email}</div>
              </div>
              <div>
                <div>mobile</div>
                <div>{customer.mobile}</div>
              </div>
            </>
          }
        </div>
        <div className="title">订阅信息</div>
        <div className="info">
          <div>
            <div>状态：</div>
            <div>{customer.status}</div>
          </div>
          <div>
            <div>产品：</div>
            <div>{customer.planId}</div>
          </div>
          <div>
            <div>注册日期：</div>
            <div>
              {
                customer.registrationDate ?
                customer.registrationDate.substring(0, 10) :
                ''
              }
            </div>
          </div>
          <div>
            <div>订阅日期：</div>
            <div>
              {
                customer.subscriptionDate ?
                customer.subscriptionDate.substring(0, 10) :
                ''
              }
            </div>
          </div>
          <div>
            <div>续费日期：</div>
            <div>
              {
                customer.nextRenewalDate ?
                customer.nextRenewalDate.substring(0, 10)
                : ''
              }
            </div>
          </div>
        </div>
        {
          client.type === 'b2b' &&
          <>
            <div className="title">联系人</div>
            <div>
              {
                customerContacts.length > 0 &&
                customerContacts.map((user) => (
                  <div key={user.id}>
                    <span>{user.title}</span>
                    <span>，</span>
                    <span>{user.name}</span>
                  </div>
                ))
              }
              {
                customerContacts.length === 0 &&
                <div>暂无联系人</div>
              }
            </div>
          </>
        }
      </Panel>
    </div>
  );
}

export default LeftPanel;
