
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';

import Panel from 'components/panel';
import HealthDomain from 'domains/health';
import HealthOverall from 'analytics/health-overall';
import BreakdownChart from 'components/breakdown-chart';

function HealthPanel({
  customer
}) {
  const { id } = customer;

  const modelWithScores = useSelector(HealthDomain.selectors.getModelWithScoresSelector(customer));
  const scoresTrend = useSelector(HealthDomain.selectors.trend)[id];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(HealthDomain.actions.queryScoresMap(id));
    dispatch(HealthDomain.actions.queryTrend(id));
  }, []);

  const loading = !modelWithScores || !scoresTrend;

  return (
    <div className="health-panel">
      <Row gutter={10}>
        <Col span={12}>
          <Panel title="健康总分" style={{ height: 240 }}>
            {
              loading &&
              <div>加载中</div>}
            {
              !loading &&
              <div className="chart">
                <HealthOverall
                  modelWithScores={modelWithScores}
                  overallScoresTrend={scoresTrend.filter((datum) => {
                    return datum.name === '总分';
                  })}
                />
              </div>
            }
          </Panel>
        </Col>
        <Col span={12}>
          <Panel title="健康类别分" style={{ height: 240 }}>
            {!modelWithScores && <div>加载中</div>}
            {
              modelWithScores &&
              <div className="chart">
                <BreakdownChart
                  score={modelWithScores.score}
                  categoryScores={modelWithScores.children.map((datum) => {
                    return {
                      ...datum,
                      entity: 'self'
                    };
                  })}
                />
              </div>
            }
          </Panel>
        </Col>
      </Row>
    </div>
  );
}

export default HealthPanel;
