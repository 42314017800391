
import React from 'react';

import Panel from 'components/panel';
import RenewalNumbers from './renewal-numbers';
import RenewalResults from './renewal-results';

import './index.scss';

function Summary() {
  return (
    <div className="summary">
      <Panel title="本月概况" style={{ height: 290 }}>
        <div className="summary-content">
          <div>
            <div className="title">本月应续费</div>
            <div className="content">40</div>
            <div className="title">本月目标</div>
            <div className="content">36</div>
          </div>
          <div>
            <div className="title">本月续费状态</div>
            <RenewalNumbers />
          </div>
          <div>
            <div className="title">本月进度</div>
            <RenewalResults />
          </div>
        </div>
      </Panel>
    </div>
  );
}

export default Summary;
