
import API from 'adapters/api';

/**
 * state structure:
 * {
 *   data: {
 *     'archived': [
 *       {Notification}
 *     ],
 *     'unarchived': [
 *       {Notification}
 *     ]
 *   },
 *   count: {
 *     'archived': {number}
 *     'unarchived': {number}
 *     'unread': 0 | 1
 *   } // TODO: count goes with data
 * }
 */

const Notifications = {
  selectors: {},
  actions: {}
};

Notifications.selectors.data = (state) => state.notifications.data;
Notifications.selectors.count = (state) => state.notifications.count;
Notifications.selectors.unreadCount = (state) => state.notifications.unreadCount;

const notificationTypeToStatus = {
  archived: ['archived'],
  unarchived: ['read', 'unread'],
  unread: ['unread']
}

function sortNotifications(data) {
  for (const type in data) {
    const notifications = data[type];
    notifications.sort(
      (n1, n2) => {
        const d1 = new Date(n1.createdAt);
        const d2 = new Date(n2.createdAt);
        const diff = d2.getTime() - d1.getTime()
        if (diff !== 0) {
          return diff;
        } else {
          return n2.id - n1.id;
        }
      } 
    )
  }
}

Notifications.actions.readList = ({ 
  type, 
  page = 1, 
  limit = 1, 
}) => {
  const offset = (page - 1) * limit;

  const params = {
    offset,
    limit,
    status: notificationTypeToStatus[type]
  };

  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'notifications',
        action: 'readList',
        params
      });

      if (status < 300) {
        const { notifications } = data;
        dispatch({
          type: 'notificationsRefreshData',
          notificationType: type,
          data: notifications.data,
          count: notifications.count
        });
      }

      return { status, data };
    }
  }
};

Notifications.actions.update = ({
  notification,
  type,
  success
}) => {
return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'notifications',
        action: 'update',
        data: notification
      });

      if (status < 300) {
        dispatch({
          type: 'notificationsUpdate',
          notificationType: type,
          data: notification
        });

        success && success();
      }

      return { status, data };
    }
  }
};

Notifications.actions.delete = ({
  id,
  type,
  success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'notifications',
        action: 'delete',
        data: { id }
      });

      if (status < 300) {
        dispatch({
          type: 'notificationsRemove',
          notificationType: type,
          data: { id }
        });

        success && success();
      }

      return { status, data };
    }
  }
};

const reducers = {
  notifications(state, action) {
    if (state === undefined) return {
      data: { archived: [], unarchived:[] },
      count: { archived: [], unarchived:[] }
    };

   if (action.type === 'notificationsRefreshData') {
      const newData = {...state.data};
      newData[action.notificationType] = action.data;
      const newCount = {...state.count};
      newCount[action.notificationType] = action.count;
      sortNotifications(newData);
      return {
        ...state,
        data: newData,
        count: newCount
      };
    } else if (action.type === 'notificationsUpdate') {
      const newData = {...state.data};
      const oldNotification = newData[action.notificationType]
        .find((datum) => datum.id === action.data.id);
      const updatedNotification = {...oldNotification, ...action.data};
      
      // remove the notification from old type
      newData[action.notificationType] = newData[action.notificationType].filter(
        (datum) => datum.id !== action.data.id);

      // add notification to new type
      let updatedNotificationType;
      if (notificationTypeToStatus['unarchived'].includes(updatedNotification.status)) {
        updatedNotificationType = 'unarchived';
      } else if (notificationTypeToStatus['archived'].includes(updatedNotification.status)) {
        updatedNotificationType = 'archived';
      }
      if (updatedNotificationType) {
        newData[updatedNotificationType] = [...newData[updatedNotificationType], updatedNotification];
      }

      sortNotifications(newData);
      return {
        ...state,
        data: newData
      }
    } else if (action.type === 'notificationsRemove') {
      const newData = {...state.data};
      newData[action.notificationType] = state.data[action.notificationType]
        .filter((datum) => datum.id !== action.data.id);
      sortNotifications(newData);
      return {
        ...state,
        data: newData
      };
    }

    return state;
  }
}

export { reducers };
export default Notifications;
