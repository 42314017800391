
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Table, Tag } from 'antd';

import CustomersDomain from 'domains/customers';
import CustomerUsersDomain from 'domains/customer-users';
import Panel from 'components/panel';
import ListItemActions from 'components/list-item-actions';
import Metadata from 'domains/metadata';
import InPlaceEdit from 'components/in-place-edit';

import CustomerUserDrawer from './customer-user-drawer';
import CustomerUserDeleteModal from './delete-modal';

import './index.scss';

function CustomerUsers({ customer }) {
  function getSelector(fieldName) {
    return Metadata.selectors.getComponentParamsSelector({
      collection: 'customers',
      fieldName,
      record: customer,
      onUpdate: (v) => {
        CustomersDomain.actions.update({
          id: customer.id,
          [fieldName]: v
        });
      }
    });
  }

  const licenseCountParams = useSelector(getSelector('licenseCount'));
  const activeLicenseCountParams = useSelector(getSelector('activeLicenseCount'));

  const { id, licenseCount, activeLicenseCount } = customer;

  const customerUsers = useSelector(CustomerUsersDomain.selectors.perCustomer)[id];

  const dispatch = useDispatch();
  const [showCreationDrawer, setShowCreationDrawer] = useState(false);
  const [showUpdateDrawer, setShowUpdateDrawer] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCustomerUser, setSelectedCustomerUser] = useState(null);

  function handleShowCreationDrawer() {
    setShowCreationDrawer(true);
  }

  function handleShowUpdateDrawer(customerUser) {
    setSelectedCustomerUser(customerUser);
    setShowUpdateDrawer(true);
  }

  function handleHidePopups() {
    setSelectedCustomerUser(null);
    setShowCreationDrawer(false);
    setShowUpdateDrawer(false);
    setShowDeleteModal(false);
  }

  function handleCreate(customerUser) {
    customerUser.customerId = id;
    dispatch(
      CustomerUsersDomain.actions.create(
        customerUser, 
        handleHidePopups
      )
    );
  }

  function handleUpdate(record) {
    const customerUser = {...selectedCustomerUser, ...record};
    dispatch(
      CustomerUsersDomain.actions.update(
        customerUser,
        handleHidePopups
      )
    )
  }

  function handleDelete() {
    dispatch(
      CustomerUsersDomain.actions.delete(
        selectedCustomerUser,
        handleHidePopups
      )
    )
  }

  function handleShowDeleteModal(customerUser) {
    setSelectedCustomerUser(customerUser);
    setShowDeleteModal(true);
  }

  return (
    <div className="tab-customer-users">
      <Panel>
        <div className="actions">
          <Button
            type="primary"
            onClick={handleShowCreationDrawer}
          >
            添加客户员工
          </Button>
          {
            licenseCount !== undefined && licenseCount !== null &&
            <InPlaceEdit {...licenseCountParams} displayMode="horizontal" />
          }
          {
            activeLicenseCount !== undefined && licenseCount !== null &&
            <InPlaceEdit {...activeLicenseCountParams} displayMode="horizontal" />
          }
        </div>
        <div className='table'>
          <Table
            dataSource={customerUsers}
            rowKey="id"
            columns={getColumns(handleShowUpdateDrawer, handleShowDeleteModal)}
            pagination={false}
          />
        </div>
        {
          showCreationDrawer &&
          <CustomerUserDrawer
            onSubmit={handleCreate}
            onCancel={handleHidePopups}
          />
        }
        {
          showUpdateDrawer &&
          <CustomerUserDrawer
            initialData={selectedCustomerUser}
            onSubmit={handleUpdate}
            onCancel={handleHidePopups}
          />
        }
        {
          showDeleteModal &&
          <CustomerUserDeleteModal
            customerUser={selectedCustomerUser}
            onSubmit={handleDelete}
            onCancel={handleHidePopups}
          />
        }
      </Panel>
    </div>
  );
}

function getColumns(handleShowDrawer, handleShowDeleteModal) {
  return [
    {
      title: '名称',
      width: '10%',
      dataIndex: 'name'
    },
    {
      title: '职称',
      dataIndex: 'title',
      width: '10%'
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: '10%',
      render(text, record) {
        return (
          <div className={record.status}>
            {
              record.status === 'ACTIVE' ?
              <Tag color="green">激活</Tag> :
              <Tag color="gray">未激活</Tag>
            }
          </div>
        )
      }
    },
    {
      title: '标签',
      dataIndex: 'tags',
      width: '10%',
      render: tags => (
        <>
          {(tags || []).map(tag => {
            return (
              <Tag key={tag}>
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: '手机',
      dataIndex: 'mobile',
      width: '10%'
    },
    {
      title: '电子邮箱',
      dataIndex: 'email',
      width: '10%'
    },
    {
      title: '',
      dataIndex: '',
      width: '1%',
      render: (text, record) => {
        return (
        <div>
          <ListItemActions
            actions={[
              { name: '修改', onClick: () => handleShowDrawer(record) },
              { name: '删除', onClick: () => handleShowDeleteModal(record) }
            ]}
            onClick={() => {}}
            onCancel={() => {}}
          />
        </div>
        )
      }
    }
  ];
}

export default CustomerUsers;
