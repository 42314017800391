
import React , { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Pagination } from 'antd';

import FeedbackDomain from 'domains/feedback';
import FeedbackCard from 'components/feedback-card';

function Feedback({
  selector,
  category
}) {
  const PAGE_SIZE = 10;

  const dispatch = useDispatch();

  const { data, count } = useSelector(selector);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(FeedbackDomain.actions.readList(category, page, PAGE_SIZE));
  }, [page, category]);

  const feedbackInfo = (
    <>
      {data.map((feedback, i) => {
        return (
          <div key={feedback.id} style={{ marginBottom: 25 }}>
            <FeedbackCard
              timestamp={feedback.createdAt.substring(0, 19).replace('T', ' ')}
              type={feedback.category}
              customer={{ id: feedback.customer.id, name: feedback.customer.name }}
              response={feedback}
            /> 
          </div>
        );
      })}
    </>
  )

  const feedbackZeroState = (
    <div>尚未见反馈</div>
  );

  return (
    <div>
      <div>
        { 
          data !== undefined ? 
          feedbackInfo : feedbackZeroState
        }
      </div>
      <Pagination
        showTotal={(total, range) => {
          return (
            <div className="total">
              {`总共 ${total} 项，显示第 ${range[0]}-${range[1]}`}
            </div>
          );
        }}
        onChange={(page, pageSize) => { 
          setPage(page);
        }}
        total={count}
        pageSize={PAGE_SIZE}
        current={page}
      />
    </div>
  );
}


export default Feedback;
