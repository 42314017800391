
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Descriptions
} from 'antd';

import Breadcrumb from 'components/breadcrumb';
import AppPermissions from './app-permissions';
import ObjectPermissions from './object-permissions';

import './index.scss';

const managementPages = [
  {
    key: 'sales-performance',
    title: '销售管理 - 销售业绩'
  },
  {
    key: 'membership-cards',
    title: '销售管理 - 会员卡'
  },
  {
    key: 'customers-dashboard',
    title: '客户 - 客户数据展示'
  },
  {
    key: 'customers-management',
    title: '客户 - 客户管理'
  },
  {
    key: 'stores',
    title: '门店与员工 - 门店管理'
  },
  {
    key: 'employees',
    title: '门店与员工 - 员工管理'
  },
  {
    key: 'commissions',
    title: '门店与员工 - 提成管理'
  },
  {
    key: 'permissions'  ,
    title: '门店与员工 - 权限管理'
  }
];

const apps = [
  {
    key: 'management',
    title: '管理端'
  },
  {
    key: 'trainer',
    title: '教练端'
  }
];

const trainerPages = [
  {
    key: 'sales-planning',
    title: '销售'
  },
  {
    key: 'customers',
    title: '会员'
  },
  {
    key: 'commissions',
    title: '提成'
  },
  {
    key: 'profile',
    title: '个人'
  }
];

const pages = {
  management: managementPages,
  trainer: trainerPages
};

const objects = [
  {
    key: 'stores',
    title: '门店',
    columns: [
      {
        key: 'status',
        title: '开业状态'
      },
      {
        key: 'name',
        title: '名称'
      },
      {
        key: 'province',
        title: '省'
      },
      {
        key: 'city',
        title: '市'
      },
      {
        key: 'district',
        title: '区'
      },
      {
        key: 'street',
        title: '详细地址'
      },
      {
        key: 'managerId',
        title: '店长'
      },
      {
        key: 'superiorManagerId',
        title: '区域经理'
      }
    ]
  },
  {
    key: 'users',
    title: '员工',
    columns: [
      {
        key: 'name',
        title: '名称'
      }
    ]
  },
  {
    key: 'roles',
    title: '角色',
    columns: [
      {
        key: 'name',
        title: '名称'
      }
    ]
  },
  {
    key: 'customers',
    title: '客户',
    workflows: [
      {
        key: 'Meituan Validation',
        title: '美团验券'
      }
    ],
    columns: [
      {
        key: 'name',
        title: '名称'
      }
    ]
  },
  {
    key: 'purchases',
    title: '购买',
    columns: [
      {
        key: 'amount',
        title: '金额'
      },
      {
        key: 'type',
        title: '类别'
      },
      {
        key: 'sales-rep',
        title: '转化教练'
      },
      {
        key: 'referer',
        title: '介绍人'
      }
    ]
  }
];

class Role extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired
  };

  getRole(roles, id) {
    return roles.find((role) => role.id === id);
  };

  render() {
    const { match, roles } = this.props;
    const { id } = match.params;
    const role = this.getRole(roles, parseInt(id));
  
    return (
      <div className="page-role">
        <Breadcrumb
          navArray={[
            { content: '权限管理', link: '/permissions' },
            { content: role.name }
          ]}
        />
        <div className="basic-info">
          <div className="title">基本信息</div>
          <Descriptions column={1} bordered>
            <Descriptions.Item label="名称" labelStyle={{ width: 200 }}>{role.name}</Descriptions.Item>
            <Descriptions.Item label="描述">{role.description}</Descriptions.Item>
          </Descriptions>
        </div>
        <div className="apps-permissions">
          <div className="title">应用权限</div>
          {apps.map((app) => {
            return (
              <AppPermissions
                key={app.key}
                app={app}
                pages={pages[app.key]}
              />
            );
          })}
        </div>
        <div className="objects-permissions">
          <div className="title">信息权限</div>
          {objects.map((object) => {
            return (
              <ObjectPermissions
                key={object.key}
                object={object}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    roles: state.roles
  };
}

const Connected = connect(
  mapStateToProps,
  null
)(Role);

export default withRouter(Connected);
