
import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import {
  Tabs
} from 'antd';

import OperationalMetadataManagement from './operational-metadata-management';

import './index.scss';

function Setup({ match }) {
  function handleChangeTab(key) {
    const redirectUrl = `/setup/${key}`
    history.push(redirectUrl);
    setActiveTab(key);
  }

  const { tab } = match.params;

  const history = useHistory();
  const [activeTab, setActiveTab] = useState(tab);

  useEffect(() => {
    document.title = '云策智慧 - 设置';
  }, []);

  return (
    <div className="page-setup">
      <Tabs activeKey={activeTab} onChange={handleChangeTab}>
        <Tabs.TabPane key="operational-metadata-management" tab="数据搭建">
          <OperationalMetadataManagement />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default withRouter(Setup);
