
import React from 'react';
import {
  Row, Col
} from 'antd';

import Panel from 'components/panel';
import InPlaceEdit from 'components/in-place-edit';

function SocialMediaPanel({
  customer
}) {
  // TODO: metadata service
  return (
    <div className="social-media-panel">
      <Panel title="社交网络信息">
        <Row>
          <Col span={12}>
            <InPlaceEdit
              title="关联微信账号"
              value={customer.weChatId}
              type="workflow"
            />
          </Col>
          
        </Row>
        <Row>
        <Col span={12}>
            <InPlaceEdit
              title="是否关注微信服务号"
              value={customer.weChatSubscribed}
              type="readonly"
            />
          </Col>
          <Col span={12}>
            <InPlaceEdit
              title="微信小程序已激活"
              value={customer.weChatMiniAppActivated}
              type="readonly"
            />            
          </Col>
        </Row>
      </Panel>
    </div>
  )
}

export default SocialMediaPanel;
