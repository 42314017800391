
import axios from 'axios';
import env from 'env';

const {
  api: {
    rootURL
  }
} = env;

axios.defaults.withCredentials = true;

const API = {};

const cache = {};

function constructMethod(action) {
  const actionToMethod = {
    create: 'POST',
    readList: 'GET',
    read: 'GET',
    update: 'POST',
    delete: 'POST'
  };

  const method = actionToMethod[action];

  return method === undefined ?
    'POST' :
    method;
}

function constructPath(action, id) {
  const actionToPath = {
    create: '',
    readList: '',
    read: id ? id : '',
    update: id ? id : 'update',
    delete: id ? `${id}/delete` : 'delete',
    customize: ''
  };

  const path = actionToPath[action];

  return path === undefined ?
    action :
    path;
}

function isCacheExpired(cacheResult) {
  const now = new Date();
  const diff = now - cacheResult.timestamp;

  return (diff / 1000 / 60) > 30;
}

API.request = async ({ resource, action, data, params, methodOverride, skipCache }) => {
  const method = methodOverride || constructMethod(action);
  const path = constructPath(action, data && data.id);
  const url = `${rootURL}/${resource}${path === '' ? '' : `/${path}`}`;

  const requestParams = {
    method,
    url,
    data,
    params
  };

  if (method === 'GET' || resource.match(/^analytics/)) {
    const key = JSON.stringify(requestParams);
    const cacheResult = cache[key];

    if (!skipCache && cacheResult && !isCacheExpired(cacheResult)) {
      return {
        status: 200,
        data: cacheResult.data
      };
    }
  }

  try {
    const result = await axios(requestParams);

    if (method === 'GET' || resource.match(/^analytics/)) {
      const key = JSON.stringify(requestParams);
      cache[key] = {
        timestamp: new Date(),
        data: result.data
      };
    }

    return {
      status: result.status,
      data: result.data
    };
  } catch (e) {
    if (!e.response) return {
      error: {
        message: '无法连接到服务器，请稍后再试'
      }
    }

    return {
      status: e.response.status,
      data: e.response.data
    };
  }
};

export default API;
