
import React from 'react';

import OverallTrendChart from 'components/overall-trend-chart';
import ScoreIndicator from 'components/score-indicator';

import './index.scss';

function HealthOverall({
  modelWithScores,
  overallScoresTrend,
  avgOverallScoresTrend
}) {
  return (
    <div className="component-health-overall">
      <ScoreIndicator
        score={modelWithScores.score}
        trend={overallScoresTrend}
      />
      <OverallTrendChart
        name="overall"
        trend={overallScoresTrend}
        comparisonTrend={avgOverallScoresTrend}
      />
    </div>
  );
}

export default HealthOverall;
