
import API from 'adapters/api';

import AnalyticsDomain from 'domains/analytics';

// TODO: get rid of scoresMap entirely

/**
 * state structure:
 * {
 *   models: {
 *     [`${category}-${journeyStage}`]: {model}
 *   },
 *   scoresMap: {
 *     <customerId>: [{
 *       name: {String},
 *       value: {Number}
 *     }]
 *   },
 *   avgScoresMap: [{ name: {String}, value: {Number} }],
 *   trend: {
 *     <customerId>: [{
 *       timestamp: {String},
 *       name: {String},
 *       value: {Number}
 *     }]
 *   },
 *   avgTrend: [{
 *     timestamp: {String},
 *     name: {String},
 *     value: {Number}
 *   }]
 * }
 */

const Health = {
  selectors: {},
  actions: {}
};

const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 5); // most recent 6 months
  startDate.setDate(1);
  const startDateString = `
    ${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}
  `.trim();

  const endDate = new Date();
  endDate.setDate(endDate.getDate() + 1);
  const endDateString = `
    ${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}
  `.trim();

Health.selectors.models = (state) => {
  return state.health.models;
};

Health.selectors.getModelSelector = (customer) => {
  const { category, journeyStage } = customer;
  return (state) => state.health.models[`${category}-${journeyStage}`];
};

Health.selectors.getModelWithScoresSelector = (customer) => {
  const { id, category, journeyStage } = customer;
  return (state) => {
    const model = state.health.models[`${category}-${journeyStage}`];
    const scoresMap = state.health.scoresMap[id];
    if (!model || !scoresMap) return;
    return getHealthModelWithScores(model, scoresMap);
  };
};

Health.selectors.getModelWithAvgScoresSelector = (customer) => {
  const { category, journeyStage } = customer;
  return (state) => {
    const model = state.health.models[`${category}-${journeyStage}`];
    const avgScoresMap = state.health.avgScoresMap;
    if (!model || !avgScoresMap) return;
    return getHealthModelWithScores(model, avgScoresMap);
  };
};

Health.selectors.trend = (state) => state.health.trend;

Health.selectors.avgTrend = (state) => state.health.avgTrend;

Health.actions.updateModel = (
  { category, journeyStage, model },
  success
) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'health-models',
        action: 'update',
        data: { category, journeyStage, model }
      });

      if (status < 300) {
        dispatch({ 
          type: 'healthModelsUpdate',
          category,
          journeyStage,
          model
        });

        success && success();
      }

      return { status, data };
    }
  };  
};

Health.actions.queryTrend = (customerId) => {
  const params = {
    metricName: 'healthScores',
    aggregation: {
      type: 'AVG'
    },
    selectedDimensions: {
      name: []
    },
    timeFrame: {
      start: startDateString,
      end: endDateString,
      granularity: 'MONTH'
    }
  };

  if (customerId !== 'avg') {
    params.filter = {
      dimensionName: 'customerId',
      operator: 'EQUAL',
      value: customerId
    }
  }

  return AnalyticsDomain.actions.queryMetric(
    params,
    (dispatch, results) => {
      if (customerId === 'avg') {
        dispatch({
          type: 'healthAvgTrendRefresh',
          data: results
        });
      } else {
        dispatch({
          type: 'healthTrendRefresh',
          customerId,
          data: results
        });
      }
    }
  );
};

// temporary, as query-metrics currently does not support bins
// hack parameters
Health.actions.queryDistributionTrend = (success, conditions = {}) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'analytics/custom',
        action: 'health-distribution',
        data: { conditions }
      });

      if (status < 300) {
        success && success(data);
      }

      return { status, data };
    }
  };  
};

// HACK
Health.actions.healthAvgScoresMap = (conditions, success) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'analytics/custom',
        action: 'health-avg-scores-map',
        data: { conditions }
      });

      if (status < 300) {
        success && success(data.scoresMap);
      }

      return { status, data };
    }
  };  
};

Health.actions.queryScoresMap = (customerId) => {
  const params = {
    dimensionName: 'healthScores',
    aggregation: {
      type: 'AVG',
      fieldName: 'value'
    },
    selection: ['name']
  };

  if (customerId !== 'avg') {
    params.filters = [
      {
        fieldName: 'customer_id',
        operator: 'EQUAL',
        value: customerId
      }
    ];
  }

  return AnalyticsDomain.actions.queryDimension(
    params,
    (dispatch, results) => {
      const scoresMap = results.reduce((obj, curr) => {
        obj[curr.name] = curr.value;
        return obj;
      }, {});

      if (customerId === 'avg') {
        dispatch({
          type: 'healthAvgScoresMapRefresh',
          data: scoresMap
        });
      } else {
        dispatch({
          type: 'healthScoresMapRefresh',
          customerId,
          data: scoresMap
        });
      }
    }
  );
};

const reducers = {
  health(state, action) {
    if (state === undefined) return {
      models: {},
      scoresMap: {},
      avgScoresMap: [],
      trend: {},
      avgTrend: []
    };

    if (action.type === 'init') {
      return {
        ...state,
        scoresMap: {},
        avgScoresMap: [],
        trend: {},
        avgTrend: []
      };
    } else if (action.type === 'healthTrendRefresh') {
      const { customerId, data } = action;

      return {
        ...state,
        trend: {
          ...state.trend,
          [customerId]: data
        }
      };
    } else if (action.type === 'healthAvgTrendRefresh') {
      return {
        ...state,
        avgTrend: action.data
      };
    } else if (action.type === 'healthAvgScoresMapRefresh') {
      return {
        ...state,
        avgScoresMap: action.data
      };
    } else if (action.type === 'healthScoresMapRefresh') {
      const { customerId, data } = action;

      return {
        ...state,
        scoresMap: {
          ...state.scoresMap,
          [customerId]: data
        }
      };
    } else if (action.type === 'healthModelsUpdate') {
      const { category, journeyStage, model } = action;

      return {
        ...state,
        models: {
          ...state.models,
          [`${category}-${journeyStage}`]: model
        }
      }
    }

    return state;
  }
}

Health.findNode = (node, name) => {
  if (node.name === name) return node;
  if (node.children) {
    for (let i = 0; i < node.children.length; i++) {
      const child = node.children[i];
      const result = Health.findNode(child, name);
      if (result) return result;
    }
    return false;
  } else {
    return false;
  }
};

Health.scoreToHealth = (score) => {
  let health = 'good'
  if (score < 50) health = 'bad';
  else if (score < 80) health = 'medium';
  return health;
};

function getHealthModelWithScores(model, scoresMap) {
  const healthModelWithScores = {
    name: '总分',
    score: scoresMap['总分'],
    children: []
  };

  model.children.forEach((node) => {
    const nodeWithScore = {
      ...node,
      score: scoresMap[node.name]
    };

    if (node.children) {
      const children = [];
      node.children.forEach((node) => {
        children.push({
          ...node,
          score: scoresMap[node.name]
        });
      });
      nodeWithScore.children = children;
    }

    healthModelWithScores.children.push(nodeWithScore);
  });

  return healthModelWithScores;
};

export { reducers };
export default Health;
