
import React, { useState } from 'react';

import ChartPanel from 'components/chart-panel';
import DistributionTrendChart from 'components/distribution-trend-chart';

import ChartToggleButtons from 'components/chart-toggle-button';

function ChurnTrendChart({ churnTrendData }) {
  const [choice, setChoice] = useState('logo');
  const handleToggle = function(event, value) {
    setChoice(value);
  }

  const data = churnTrendData[choice];

  const toggleButtons = (
    <ChartToggleButtons 
      value={choice} 
      onChange={handleToggle}
      choices={{
        logo: 'logo',
        logoPercent: 'logo %',
        arr: 'ARR（Gross）',
        arrPercent: 'ARR（Gross） %'
      }}
    />
  );

  return (
    <ChartPanel 
      title="流失趋势"
      actions={toggleButtons}>
      <DistributionTrendChart
        name="churn-trend"
        type="line"
        valueType={choice === 'logo' || choice === 'arr' ? 'number' : 'percent'}
        data={data}
        valueName="value"
        colorMapper={() => '#7E92B5'}
      />
    </ChartPanel>
  );
}

export default ChurnTrendChart;
