
import React from 'react';

import FeedbackDomain from 'domains/feedback';
import Feedback from '../feedback';
import Panel from 'components/panel';

function FeedbackGeneral() {
  return (
    <Panel>
      <Feedback
        selector={FeedbackDomain.selectors.complaint}
        category="complaint"
      />
    </Panel>
  );
}

export default FeedbackGeneral;
