
import API from 'adapters/api';

const Metadata = {
  selectors: {},
  actions: {}
};

Metadata.getSelector = (type, object) => {
  return (state) => (state.metadata[type][object]);
};


// HACK, hardcoded for now
const objectsMetadata = [
  {
    id: 1,
    objectName: 'customers',
    title: '客户',
    description: '客户对象'
  },
  {
    id: 2,
    objectName: 'workLogs',
    title: '工作记录',
    description: '工作记录对象'
  }
];

Metadata.getFieldsMetadataSelector = ({
  objectId
}) => {
  const objectName = objectsMetadata.find((metadata) => {
    return metadata.id === objectId;
  }).objectName;

  return (state) => {
    const clientType = state.metadata.client.type;
    const unfiltered = state.metadata.fields[objectName];

    return Object.keys(unfiltered).filter((fieldName) => {
      return (
        !unfiltered[fieldName].applicableTo ||
        unfiltered[fieldName].applicableTo.includes(clientType)
      );
    }).reduce((total, curr) => {
      total[curr] = unfiltered[curr];
      return total;
    }, {});
  };
};

Metadata.selectors.client = (state) => {
  return state.metadata.client;
};

Metadata.selectors.customFields = (state) => {
  return state.metadata.customFields;
}

Metadata.selectors.analytics = (state) => {
  return state.metadata.analytics;
};

Metadata.selectors.allData = (state) => {
  return {
    users: state.users,
    plans: state.plans,
    stores: state.stores
  };
};

Metadata.selectors.fieldsMetadata = (state) => {
  return state.metadata.fields;
};

Metadata.selectors.getComponentParamsSelector = ({
  collection,
  fieldName,
  record,
  onUpdate
}) => {
  return (state) => {
    const allData = Metadata.selectors.allData(state);
    const fieldsMetadata = state.metadata.fields;

    return Metadata.getComponentParams({
      collection, fieldName, record, onUpdate,
      allData, fieldsMetadata
    });
  };
};

Metadata.getComponentParams = ({
  collection,
  fieldName,
  record,
  onUpdate,
  allData,
  fieldsMetadata
}) => {
  const fieldMetadata = fieldsMetadata[collection][fieldName];

  if (fieldMetadata.isFormula) {
    return {
      title: fieldMetadata.title,
      value: record[fieldName],
      type: 'readonly'
    };
  }

  if (fieldMetadata.type === 'STRING') {
    return {
      title: fieldMetadata.title,
      value: record[fieldName],
      type: 'text',
      onSubmit: (v) => onUpdate(fieldName, v)
    };
  }

  if (fieldMetadata.type === 'NUMBER') {
    return {
      title: fieldMetadata.title,
      value: record[fieldName],
      type: 'number',
      onSubmit: (v) => onUpdate(fieldName, v)
    };
  }

  if (fieldMetadata.type === 'DATE') {
    return {
      title: fieldMetadata.title,
      value: record[fieldName],
      type: 'date',
      onSubmit: (v) => onUpdate(fieldName, v)
    };
  }

  if (fieldMetadata.type === 'REFERENCE') {
    const { target, targetTitleFieldName } = fieldMetadata;
    const targetTitleFieldMetadata = fieldsMetadata[target][targetTitleFieldName];

    const options = allData[fieldMetadata.target].map((record) => {
      const title = Metadata.getFieldValue(
        record,
        fieldMetadata.targetTitleFieldName,
        targetTitleFieldMetadata
      );
      return {
        value: record[fieldMetadata.targetFieldName],
        title
      }
    });

    return {
      title: fieldMetadata.title,
      value: record[fieldName],
      type: 'select',
      selectOptions: options,
      onSubmit: (v) => onUpdate(fieldName, v)
    }
  }
};

Metadata.actions.deleteCustomField = ({
  name,
  success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const { status, data } = await API.request({
        resource: 'custom-fields',
        action: 'delete',
        data: { name }
      });

      if (status < 300) {
        dispatch({
          type: 'customFieldsRemove',
          data: { name }
        });

        success && success();
      }

      return { status, data };
    }
  };
};

Metadata.actions.updateCustomField = ({
  customField,
  success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const { status, data } = await API.request({
        resource: 'custom-fields',
        action: 'update',
        data: customField
      });

      if (status < 300) {
        dispatch({
          type: 'customFieldsUpdate',
          data: customField
        });

        success && success();
      }

      return { status, data };
    }
  };
};

Metadata.actions.createCustomField = ({
  customField,
  success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const { status, data } = await API.request({
        resource: 'custom-fields',
        action: 'create',
        data: customField
      });

      if (status < 300) {
        const name = Object.keys(data.customField)[0];
        dispatch({
          type: 'customFieldsCreate',
          data: { ...customField, name }
        });

        success && success();
      }

      return { status, data };
    }
  };
};

const reducers = {
  metadata(state, action) {
    if (state === undefined) return { fields: {} };

    if (action.type === 'customFieldsRemove') {
      const { name } = action.data;

      const { [name]: removedField, ...otherFields } = state.fields.customers;

      return {
        ...state,
        fields: {
          ...state.fields,
          customers: otherFields
        }
      };
    } else if (action.type === 'customFieldsUpdate') {
      const { name } = action.data;

      return {
        ...state,
        fields: {
          ...state.fields,
          customers: {
            ...state.fields.customers,
            [name]: action.data
          }
        }
      };
    } else if (action.type === 'customFieldsCreate') {
      const customField = action.data;

      return {
        ...state,
        fields: {
          ...state.fields,
          customers: {
            ...state.fields.customers,
            [customField.name]: customField
          }
        }
      };
    }

    return state;
  }
};

Metadata.getFieldValue = (record, fieldName, metadata) => {
  if (!metadata) return record[fieldName];

  if (metadata.type === 'COMPOSITE') {
    return metadata.fieldNames.map((fieldName) => {
      return record[fieldName];
    }).join('');
  }

  return record[fieldName];
};

export { reducers };
export default Metadata;
