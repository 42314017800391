
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomerMetricsDomain from 'domains/customer-metrics';
import MachineLearningDomain from 'domains/machine-learning';
import MetadataDomain from 'domains/metadata';
import DecisionTree from 'components/decision-tree-chart';
import Panel from 'components/panel';

import './index.scss';

function ChurnDecisionTree({ customer }) {
  const dispatch = useDispatch();

  const decisionTree = useSelector(MachineLearningDomain.selectors.decisionTree);
  const customerMetricsDefinitions = useSelector(CustomerMetricsDomain.selectors.definitions);
  const customerMetrics = useSelector(CustomerMetricsDomain.selectors.perCustomer)[customer.id];
  const customFields = useSelector(MetadataDomain.selectors.customFields);

  const visualizedTree = MachineLearningDomain.applyDecisionTreeForCustomer(
    decisionTree.decisionTree,
    customerMetricsDefinitions,
    customFields.customers,
    customer,
    customerMetrics);

  useEffect(() => {
    dispatch(MachineLearningDomain.actions.getDecisionTree());
    dispatch(CustomerMetricsDomain.actions.readPerCustomer(customer.id));
  }, []);  

  return (
    <div className="decision-tree">
      {
        (!visualizedTree || visualizedTree === {}) &&
        <div>暂无数据</div>
      }
      {
        visualizedTree &&
        <Panel style={{ height: '100%' }}>
          <div className="decision-tree-inner">
            <DecisionTree
              data={visualizedTree}
            />
          </div>
        </Panel>
      }
    </div>
  );
}

export default ChurnDecisionTree;
