
import React from 'react';
import {
  Button
} from 'antd';

import BaseModal from 'modals/base-modal';

function DeleteModal({
  customFormula,
  customFormulaeMap,
  onCancel,
  onSubmit
}) {
  return (
    <BaseModal
      title={`确定删除自定义计算公式：${customFormula.title}`}
      onCancelOverride={onCancel}
    >
      <div className='custom-formula-deletion-modal'>
        <div className="content">
          {customFormulaeMap[customFormula.fieldName]}
        </div>
        <div className='submit'>
          <Button type="primary" danger onClick={onSubmit}>
            确定
          </Button>
          <Button onClick={onCancel}>
            取消
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}

export default DeleteModal;
