
import API from 'adapters/api';

/**
 * state structure:
 * {
 *   data: [
 *     {AlertRule}
 *   ]
 * }
 */

const limit = 20; // changing this is not an important feature right now

const AlertRules = {
  selectors: {},
  actions: {}
};

AlertRules.selectors.data = (state) => state.alertRules.data;
AlertRules.selectors.count = (state) => state.alertRules.count;

AlertRules.actions.readList = (page = 1) => {
  const offset = (page - 1) * limit;
  const params = {
    offset,
    limit
  };

  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'alert-rules',
        action: 'readList',
        params
      });

      if (status < 300) {
        const { alertRules } = data;

        dispatch({
          type: 'alertRulesRefreshData',
          data: alertRules.data,
          count: alertRules.count
        });
      }

      return { status, data };
    }
  }
};

AlertRules.actions.create = ({
  alertRule, success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'alert-rules',
        action: 'create',
        data: alertRule
      });

      if (status < 300) {
        const { id } = data.alertRule;

        dispatch({
          type: 'alertRulesAdd',
          data: { ...alertRule, id }
        });

        success && success();
      }

      return { status, data };
    }
  }
};

AlertRules.actions.update = ({
  alertRule,
  success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'alert-rules',
        action: 'update',
        data: alertRule
      });

      if (status < 300) {
        dispatch({
          type: 'alertRulesUpdate',
          data: alertRule
        });

        success && success();
      }

      return { status, data };
    }
  }
};

AlertRules.actions.delete = ({
  id,
  success
}) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'alert-rules',
        action: 'delete',
        data: { id }
      });

      if (status < 300) {
        dispatch({
          type: 'alertRulesRemove',
          data: { id }
        });

        success && success();
      }

      return { status, data };
    }
  }
};

const reducers = {
  alertRules(state, action) {
    if (state === undefined) return { data: [], count: 0 };

    if (action.type === 'alertRulesAdd') {
      return {
        ...state,
        data: [
          ...state.data,
          action.data
        ],
        count: state.count + 1
      };
    } else if (action.type === 'alertRulesRefreshData') {
      return {
        ...state,
        data: action.data,
        count: action.count
      };
    } else if (action.type === 'alertRulesUpdate') {
      return {
        ...state,
        data: state.data.map((datum) => {
          if (datum.id === action.data.id) {
            return {
              ...datum,
              ...action.data
            };
          }
          return datum;
        })
      }
    } else if (action.type === 'alertRulesRemove') {
      return {
        ...state,
        data: state.data.filter((datum) => datum.id !== action.data.id)
      };
    }

    return state;
  }
}

export { reducers };
export default AlertRules;
