
import React, { useEffect, useRef } from 'react';
import { Chart } from '@antv/g2';

import './metric-chart.scss';

function formatValue(value) {
  if (value === undefined) return;
  const numVal = parseFloat(value);
  if (numVal > 10000) {
    return (numVal / 10000).toFixed(1) + 'w';
  } else {
    return numVal.toFixed(1);
  }
}

function MetricChart({
  title,
  data,
  type = "line",
  colorMapper
}) {
  const chartContainer = useRef(null);

  useEffect(() => {
    chartContainer.current.innerHTML = '';
    createChart({
      container: chartContainer.current,
      data,
      type,
      colorMapper
    });
  }, [data]);

  function createChart({
    container, data, type, colorMapper
  }) {
    if (data.length < 1) return;

    const chart = new Chart({
      container,
      autoFit: true,
      padding: [10, 5, 20, 55]
    });

    chart.axis('inProgressValue', false);
    chart.axis('value', {
      tickLine: null,
      grid: {
        line: {
          style: {
            stroke: '#EAEAEA'
          }
        }
      }
    });

    chart.axis('timestamp', {
      tickLine: null
    });

    chart.legend(false);

    // give last and second last current splitValue special treatment
    // skip if too many data points
    let processedData = data;
    if (data.length < 50) { 
      processedData = processData(data);
    }
    chart.data(processedData);

    // consistent scales
    const { min, max } = getMinMax(data);  
  
    chart.scale({
      value: {
        min,
        max,
        tickCount: 4,
        formatter: formatValue
      },
      inProgressValue: {
        min,
        max,
        tickCount: 4,
        formatter: formatValue
      },
      timestamp: {
        range: [0.04, 0.94],
        tickCount: 5
      }
    });
    
    chart.tooltip({
      showCrosshairs: true,
      shared: true
    });


    chart
      .line()
      .position('timestamp*value')
      .color('splitValue', colorMapper)
      .style('splitValue', (val) => {
        if (val === 'past') {
          return {
            opacity: 0.5
          };
        }
      });
    
    chart
      .line()
      .position('timestamp*inProgressValue')
      .color('splitValue', colorMapper)
      .style({ lineDash: [5, 10] });

    chart.render();

    return chart;
  };

  return (
    <div className="metric-chart">
      <div className="title">{title}</div>
      <div className="chart" ref={chartContainer}></div>
    </div>
  );
}

function getMinMax(data) {
  let allValues = data.map((datum) => datum.value);
  if (data[0].past !== undefined) {
    allValues = allValues.concat(data.map((datum) => datum.past));
  }
  const maxValue = Math.max(...allValues);
  const minValue = Math.min(...allValues);

  const diff = maxValue - minValue;
  let lower = minValue - diff * 0.2;
  if (lower < 0) lower = 0;
  const upper = maxValue + diff * 0.3;

  return {
    min: lower,
    max: upper
  };
}

function processData(data) {
  const sampleDimension = data[0].splitValue;
  const sampleDimensionData = data.filter((datum) => datum.splitValue === sampleDimension);
  const len = sampleDimensionData.length;

  if (len < 2) return data;

  const currentDate = sampleDimensionData[len - 1].timestamp;
  const beforeCurrentDate = sampleDimensionData[len - 2].timestamp;

  const current = data.filter((datum) => {
    return (
      datum.timestamp === currentDate &&
      datum.splitValue !== 'past'
    );
  });
  const beforeCurrent = data.filter((datum) => {
    return (
      datum.timestamp === beforeCurrentDate &&
      datum.splitValue !== 'past'
    );
  });
  const finishedData = data.filter((datum) => {
    return (
      datum.timestamp !== currentDate ||
      datum.splitValue === 'past'
    );
  });

  const inProgressData = [...current, ...beforeCurrent].map((datum) => {
    const { value, ...rest } = datum;
    return {
      inProgressValue: value,
      ...rest
    }
  });

  const processedData = [...finishedData, ...inProgressData];

  return processedData.map((datum) => {
    return {
      ...datum,
      splitValue: splitValueToTitle(datum.splitValue)
    };
  });
}

function splitValueToTitle(splitValue) {
  const currentPastMapping = {
    current: '当前',
    past: '过去'
  };

  if (currentPastMapping[splitValue]) return currentPastMapping[splitValue];

  return splitValue;
}

export default MetricChart;
