
import React, { useEffect, useRef } from 'react';
import { Chart } from '@antv/g2';

import Panel from 'components/panel';

import './index.scss';

function DistributionPanel({
  title,
  data,
  height
}) {
  function createChart(container, data) {
    const chart = new Chart({
      container,
      autoFit: true
    });

    chart.data(data);

    chart.scale('percent', {
      formatter: (val) => {
        return `${Math.round(val * 100)}%`
      }
    });

    chart.coordinate('theta', {
      radius: 0.75,
      innerRadius: 0.8,
    });

    chart
      .interval()
      .adjust('stack')
      .position('percent')
      .color('category')

    
    chart.legend('category', {
      position: 'right'
    });

    chart.tooltip({
      position: 'right',
      offset: 20
    });

    chart.interaction('element-active');
    
    chart.render();
  }

  const chartContainer = useRef(null);

  useEffect(() => {
    createChart(chartContainer.current, data);
  }, []);

  return (
    <div className="component-distribution-panel">
      <Panel title={title} style={{ height }}>
        <div className="metric-content" ref={chartContainer}></div>
      </Panel>
    </div>
  );
}

export default DistributionPanel;
