
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Breadcrumb from 'components/breadcrumb';
import TicketsDomain from 'domains/tickets';
import TicketCommentDomain from 'domains/ticket-comments';
import { history } from 'domains/history';
import ListItemActions from 'components/list-item-actions';
import TicketDetails from './ticket-details';
import DeletionModal from 'components-advanced/tickets-table/deletion-modal';

import './index.scss';

function Ticket({
  match
}) {
  function handleShowDeletionModal() {
    setShowDeletionModal(true);
  }

  function handleHideDeletionModal() {
    setShowDeletionModal(false);
  }

  function handleGoToTickets() {
    history.push('/tickets');
  }

  function updateTicket(values) {
    dispatch(TicketsDomain.actions.update({
      ticket: {
        ...values,
        id: ticketId
      }
    }));
  }

  function deleteTicket() {
    dispatch(TicketsDomain.actions.delete({
      id: parseInt(id),
      success: handleGoToTickets
    }));
  }

  function addComment(comment) {
    if (!!comment) {
      dispatch(TicketCommentDomain.actions.create({
        ticketComment: {
          ticketId,
          comment
        }
      }));
    }
  }

  function getTicket(tickets, id) {
    return tickets.find((ticket) => ticket.id === id);
  };

  const dispatch = useDispatch();
  const comments = useSelector(TicketCommentDomain.selectors.data);
  const tickets = useSelector(TicketsDomain.selectors.data);
  const [showDeletionModal, setShowDeletionModal] = useState(false);

  const { id } = match.params;
  const ticketId = parseInt(id);
  const ticket = getTicket(tickets, ticketId);

  useEffect(() => {
    dispatch(TicketCommentDomain.actions.readList(ticketId));

    if (!ticket) {
      dispatch(TicketsDomain.actions.read(ticketId));
    }
  }, []);

  if (!ticket) return <div>加载中。。。</div>;

  return (
    <div className="page-ticket">
      <div>
        <Breadcrumb
          navArray={[
            { content: 'SOP 列表', link: '/tickets' },
            { content: `${ticket.id}` }
          ]}
        />
        <div className="delete-ticket-details">
          <ListItemActions
            actions={[
              { name: '删除', onClick: handleShowDeletionModal }
            ]}
          />
        </div>
      </div>
      <div className='content'>
        <TicketDetails
          ticket={ticket}
          comments={comments}
          onUpdate={updateTicket}
          onDelete={deleteTicket}
          onAddComment={addComment}
        />
      </div>
      {
        showDeletionModal &&
        <DeletionModal
          ticket={ticket}
          onSubmit={deleteTicket}
          onCancel={handleHideDeletionModal}
        />
      }
    </div>
  );
}

export default withRouter(Ticket);
