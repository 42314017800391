
function formatPercent(decimal) {
  const percent = Math.round(decimal * 1000) / 10;
  if (percent >= 0) {
    return `+ ${percent.toFixed(1)}%`;
  } else {
    return `- ${Math.abs(percent).toFixed(1)}%`;
  }
}

function formatNumber(num) {
  let number = parseFloat(num);

  if (number === 0) return number;

  const decimalPoints = Number.isInteger(num) ? 0 : 1;

  const magnitude = Math.log10(Math.abs(number));
  if (magnitude <= 4) return number.toFixed(decimalPoints);
  return `${(Math.round(number / 1000) / 10).toFixed(1)}w`;
}

export {
  formatPercent,
  formatNumber
};
