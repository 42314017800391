
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Select, Input, Button
} from 'antd';
import {
  PlusOutlined,
  MinusCircleOutlined
} from '@ant-design/icons';

import MetadataDomain from 'domains/metadata';

import './if-else-formula-field.scss';

function IfElseFormulaField({
  logic,
  setLogic
}) {
  function handleAddConditionOutcome() {
    const rest = [...logic];
    const last = rest.pop();

    const newConditionOutcome = {
      condition: {
        type: 'ATTRIBUTE',
        fieldName: 'nps',
        operator: '>',
        value: 0
      },
      outcome: 0
    };

    const newLogic = [
      ...rest,
      newConditionOutcome,
      last
    ];

    setLogic(newLogic);
  }

  function getConditionOutcomeRemoveHandler(index) {
    return () => {
      setLogic(logic.filter((_, i) => i !== index));
    };
  }

  function getLogicUpdateHandler(index, part, key) {
    return (value) => {
      let outcomeValue;
      if (part === 'outcome') {
        outcomeValue = value.target.value;
      }
      const newLogic = logic.map((conditionOutcome, i) => {
        if (i !== index) return conditionOutcome;

        const defaultOperator = 'EQUAL';

        let newPart;
        if (key === 'type') {
          if (value === 'ATTRIBUTE') {
            newPart = {
              type: 'ATTRIBUTE',
              fieldName: 'nps',
              operator: defaultOperator,
              value: 0
            };
          } else {
            newPart = {
              type: 'FIXED',
              value: 0
            };
          }
        } else {
          let newValue = value;
          if (typeof value === 'object' && value !== null) {
            newValue = value.target.value;
          }

          newPart = {
            ...conditionOutcome[part],
            [key]: newValue
          };
        }

        // TODO: refactor
        if (part === 'outcome') newPart = outcomeValue;

        return {
          ...conditionOutcome,
          [part]: newPart
        };
      });

      setLogic(newLogic);
    };
  }

  const fieldsMetadata = useSelector(MetadataDomain.getSelector('fields', 'customers'));

  const fieldsMetadataByCategory = Object.keys(fieldsMetadata).reduce((total, curr) => {
    const fieldMetadata = fieldsMetadata[curr];
    fieldMetadata.fieldName = curr; // put key inside val body

    let { category } = fieldMetadata;
    if (!category) category = '其他';

    if (total[category]) {
      total[category].push(fieldMetadata);
    } else {
      total[category] = [fieldMetadata];
    }
    return total;
  }, {});

  const selectSearchOptions = {
    showSearch: true,
    filterOption: (input, option) => {
      if (!option.children) return false;
      return option.children.includes(input);
    }
  };

  const selectAttributeOptions = (
    Object.keys(fieldsMetadataByCategory).map((category) => {
      return (
        <Select.OptGroup key={category} label={category}>
          {
            fieldsMetadataByCategory[category]
              .filter((fieldMetadata) => {
                return (
                  !fieldMetadata.isFormula &&
                  fieldMetadata.type === 'NUMBER' &&
                  (
                    !fieldMetadata.applicableTo ||
                    fieldMetadata.applicableTo.includes('b2b')
                  )
                );
              })
              .map((fieldMetadata) => {
                const { fieldName, title } = fieldMetadata;
                return (
                  <Select.Option key={fieldName} value={fieldName}>
                    {title}
                  </Select.Option>
                );
              })
          }
        </Select.OptGroup>
      );
    })
  );

  function renderConditionOutcomeFields({ condition, outcome }, i) {
    return (
      <div key={i} className="condition-outcome">
        <div>{i + 1}.</div>
        <div>
          <div className="condition">
            <div>如果：</div>
            <Input.Group compact>
              <Select
                value={condition.type}
                disabled={true}
                style={{ width: condition.type === 'ELSE' ? '100%' : 80 }}
                showArrow={false}
              >
                {
                  condition.type === 'ELSE' ?
                    <Select.Option value="ELSE">其他</Select.Option> :
                    <Select.Option value="ATTRIBUTE">字段</Select.Option>
                }
              </Select>
              {
                condition.type === 'ATTRIBUTE' &&
                <>
                  <Select
                    value={condition.fieldName}
                    onChange={getLogicUpdateHandler(i, 'condition', 'fieldName')}
                    style={{ width: 200 }}
                    {...selectSearchOptions}
                    showArrow={false}
                  >
                    {selectAttributeOptions}
                  </Select>
                  <Select
                    className="operator"
                    value={condition.operator}
                    onChange={getLogicUpdateHandler(i, 'condition', 'operator')}
                    style={{ width: 80 }}
                    showArrow={false}
                  >
                    <Select.Option value="===">{'等于'}</Select.Option>
                    <Select.Option value="!==">{'不等于'}</Select.Option>
                    <Select.Option value=">">{'大于'}</Select.Option>
                    <Select.Option value=">=">{'大等于'}</Select.Option>
                    <Select.Option value="<">{'小于'}</Select.Option>
                    <Select.Option value="<=">{'小等于'}</Select.Option>
                  </Select>
                  <div style={{ width: 'calc(100% - 357px)' }}>
                    <Input
                      value={condition.value}
                      onChange={getLogicUpdateHandler(i, 'condition', 'value')}
                      style={{ width: '100%', height: 32 }}
                    />
                  </div>
                </>
              }
            </Input.Group>
          </div>
          <div className="outcome">
            <div>则：</div>
            <div style={{ width: '100%' }}>
              <Input
                value={outcome}
                onChange={getLogicUpdateHandler(i, 'outcome', 'value')}
                style={{ width: '100%', height: 32 }}
              />
            </div>
          </div>
        </div>
        {
          (i === logic.length - 1) ?
          <div className="deletion-button disabled">
            <MinusCircleOutlined />
          </div> :
          <div
            className="deletion-button"
            onClick={getConditionOutcomeRemoveHandler(i)}
          >
            <MinusCircleOutlined />
          </div>
        }
      </div>
    );
  }

  return (
    <div className="if-else-formula-field">
      {logic.map(renderConditionOutcomeFields)}
      <Button
        icon={<PlusOutlined />}
        onClick={handleAddConditionOutcome}
        disabled={logic.length > 5}
      >
        添加
      </Button>
    </div>
  );
}

export default IfElseFormulaField;
