
import React, { useState, useEffect }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button
} from 'antd';

import HealthDomain from 'domains/health';
import Panel from 'components/panel';

import NodeDetails from './node-details';
import HealthModelGraph from './health-model-graph';

import './health-model-editor.scss';

function HealthModel({
  category,
  journeyStage
}) {
  function handleEdit() {
    setMode('EDIT');
  }

  function handleSave() {
    dispatch(
      HealthDomain.actions.updateModel(
        { category, journeyStage, model: toSaveModel(localModel) },
        () => {
          setDirtyCount(0);
          setMode('READ');
        }
      )
    );
  }

  function handleCancel() {
    setDirtyCount(0);
    setLocalModel(processedModel);
    setMode('READ');
  }

  function handleChangeDirtyCount(delta) {
    setDirtyCount(dirtyCount + delta);
  }

  function handleUpdateNode(name, data) {
    const updatedModel = getUpdatedModel(localModel, name, data);
    setLocalModel(updatedModel);
  }

  function handleDeleteNode(name) {
    const updatedModel = getModelAfterDeleteNode(localModel, name);
    setLocalModel(updatedModel);
  }

  function getUpdatedModel(node, name, data) {
    if (node.name === name) {
      return {
        ...node,
        ...data,
        edited: true
      };
    }

    if (!node.children) return node;

    return {
      ...node,
      children: node.children.map(
        (node) => getUpdatedModel(node, name, data)
      )
    };
  }

  function getModelAfterDeleteNode(node, name) {
    if (!node.children) return node;

    const match = node.children.find((node) => node.name === name);

    if (match) {
      return {
        ...node,
        children: node.children.filter((node) => node.name !== name)
      };
    } else {
      return {
        ...node,
        children: node.children.map(
          (node) => getModelAfterDeleteNode(node, name)
        )
      };
    }
  }

  const models = useSelector(HealthDomain.selectors.models);
  const model = models[`${category}-${journeyStage}`];

  const dispatch = useDispatch();

  const [mode, setMode] = useState('READ');
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [dirtyCount, setDirtyCount] = useState(0);

  const processedModel = {
    ...model,
    id: `${model.name}-${Math.floor(Math.random() * 10000)}`,
    label: model.name
  };
  processedModel.children = processedModel.children.map((node) => {
    const { name, children } = node;
    return {
      ...node,
      edited: false,
      id: `${name}-${Math.floor(Math.random() * 10000)}`,
      label: name,
      children: children ? children.map((node) => {
        const { name } = node;
        return {
          ...node,
          edited: false,
          id: `${name}-${Math.floor(Math.random() * 10000)}`,
          label: name
        };
      }) : undefined
    };
  });

  const [localModel, setLocalModel] = useState(processedModel);
  const found = findNodeById(localModel, selectedNodeId);

  let selectedNode, depth;
  if (found) {
    selectedNode = found.node;
    depth = found.depth;
  }

  useEffect(() => {
    setLocalModel(processedModel);
  }, [model]);

  let actions;
  if (mode === 'READ') {
    actions = (
      <div className="actions">
        <Button type="primary" onClick={handleEdit}>编辑</Button>
      </div>
    );
  } else {
    actions = (
      <div className="actions">
        <Button type="primary" onClick={handleSave}>保存</Button>
        <Button onClick={handleCancel}>取消</Button>
      </div>
    );
  }

  return (
    <Panel>
      <div className="health-model-editor">
        {actions}
        <div className="editing-pane">
          <div>
            <HealthModelGraph
              model={localModel}
              selectedNodeId={selectedNodeId}
              setSelectedNodeId={setSelectedNodeId}
              overallMode={mode}
              dirtyCount={dirtyCount}
              setDirtyCount={setDirtyCount}
            />
          </div>
          <div className="divider"></div>
          <div>
            {!selectedNode && <div className="node-details">请先选择模型节点</div>}
            {
              selectedNode &&
              <NodeDetails
                node={selectedNode}
                depth={depth}
                mode={mode}
                onChangeDirtyCount={handleChangeDirtyCount}
                onUpdateNode={handleUpdateNode}
                onDeleteNode={handleDeleteNode}
              />
            }
          </div>
        </div>
      </div>
    </Panel>
  );
}

function findNodeById(node, id, depth = 1) {
  if (node.id === id) return { node, depth };

  const { children } = node;
  if (!children) return false;

  for (let i = 0; i < children.length; i++) {
    const result = findNodeById(children[i], id, depth + 1);
    if (result) return result;
  }
}

function toSaveModel(model) {
  return {
    name: model.name,
    children: model.children.map((node) => {
      const saveNode = {
        name: node.name,
        weight: node.weight
      };

      if (node.formula) saveNode.formula = node.formula;

      if (node.children) {
        saveNode.children = node.children.map((node) => {
          return {
            name: node.name,
            weight: node.weight,
            formula: node.formula
          };
        });
      }

      return saveNode;
    })
  };
}

export default HealthModel;
