

import React, { useEffect, useRef } from 'react';
import { Chart } from '@antv/g2';

function CohortChart({
  data, splitField
}) {
  function createChart() {
    const chart = new Chart({
      container: chartContainer.current,
      autoFit: true,
      height: 235
    });

    if (!data) return;

    chart.data(data);
    
    chart
      .line()
      .position('monthsSinceBaseline*value')
      .color(splitField);
    
    chart.tooltip({
      showCrosshairs: true,
      shared: true
    });

    chart.scale({
      value: {
        formatter: formatValue,
        min: 0
      },
      monthsSinceBaseline: {
        range: [0.04, 0.94]
      }
    });

    chart.axis('value', {
      grid: null,
      title: {
        text: '指标',
        autoRotate: false,
        spacing: 20
      }
    });

    chart.axis('monthsSinceBaseline', {
      title: {
        text: '距离基准日期已过（月）'
      }
    });

    chart.legend({
      position: 'right'
    });
    
    chart.render();
  }

  const chartContainer = useRef(null);

  useEffect(() => {
    chartContainer.current.innerHTML = '';
    createChart();
  }, [data]);

  return (
    <div ref={chartContainer}></div>
  );
}

function formatValue(value) {
  if (value === undefined) return;
  let numVal = parseFloat(value);
  numVal = Math.round(numVal * 10) / 10;
  return numVal.toFixed(1);
}

export default CohortChart;
