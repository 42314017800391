
import React, { useEffect, useRef } from 'react';
import G6 from '@antv/g6';
import { isObject } from '@antv/util';

import themeColor from 'theme/_color.scss';
import './index.scss';

function DecisionTree({
  data
}) {
  const chartContainer = useRef(null);
  const colorMap = {
    '预测续费': themeColor.healthGood,
    '预测流失': themeColor.healthBad
  };

  function getColor(predictChurn) {
    return predictChurn ? themeColor.healthBad : themeColor.healthGood;
  }

  useEffect(() => {
    createChart(data, chartContainer);
  }, []);

  function createChart(data, chartContainer) {
    G6.registerNode(
      'round-rect',
      {
        drawShape: function drawShape(cfg, group) {
          const predictChurn = cfg.predictChurn;
          const isInValidPath = predictChurn !== undefined;
          const attributeValue = cfg.attributeValue;
          let labelLength = cfg.label.length;
          let stroke = cfg.style.stroke;
          if (isInValidPath) {
            stroke = getColor(predictChurn);
          }
          if (attributeValue !== undefined) {
            if (attributeValue.toString().length > labelLength) {
              labelLength = attributeValue.toString().length;
            }
          }
          // adjust width by label length
          const width = labelLength * 7 + 40;

          const rect = group.addShape('rect', {
            attrs: {
              x: -width / 2,
              y: -15,
              width,
              height: attributeValue === undefined ? 30 : 50,
              radius: 15,
              stroke: stroke,
              lineWidth: isInValidPath ? 2 : 1.2,
              fillOpacity: 1,
            },
            name: 'rect-shape',
          });
          if (attributeValue !== undefined) {
            group.addShape('text', {
              attrs: {
                x: 0,
                y: 22,
                text: attributeValue.toString(),
                fontSize: 12,
                fill: '#000',
                fontWeight: 'bold',
                textAlign: 'center'
              },
              name: 'attribute-value',
            });
          }

          return rect;
        },
        getAnchorPoints: function getAnchorPoints() {
          return [
            [0, 0.5],
            [1, 0.5],
          ];
        },
        update: function update(cfg, item) {
          const group = item.getContainer();
          const children = group.get('children');
          const node = children[0];

          const stroke = cfg.style.stroke;

          if (stroke) {
            node.attr('stroke', colorMap[cfg.label] || stroke,);
          }
        },
      },
      'single-node',
    );
    
    G6.registerEdge('fund-polyline', {
      itemType: 'edge',
      draw: function draw(cfg, group) {
        const startPoint = cfg.startPoint;
        const endPoint = cfg.endPoint;
        let stroke = cfg.style.stroke;

        const Ydiff = endPoint.y - startPoint.y;
        const slope = Ydiff !== 0 ? Math.min(500 / Math.abs(Ydiff), 20) : 0;
        const cpOffset = slope > 15 ? 0 : 16;
        const offset = Ydiff < 0 ? cpOffset : -cpOffset;
        
        const line1EndPoint = {
          x: startPoint.x + slope,
          y: endPoint.y + offset,
        };
        const line2StartPoint = {
          x: line1EndPoint.x + cpOffset,
          y: endPoint.y,
        };
    
        // 控制点坐标
        const controlPoint = {
          x:
            ((line1EndPoint.x - startPoint.x) * (endPoint.y - startPoint.y)) /
              (line1EndPoint.y - startPoint.y) +
            startPoint.x,
          y: endPoint.y,
        };
    
        let path = [
          ['M', startPoint.x, startPoint.y],
          ['L', line1EndPoint.x, line1EndPoint.y],
          ['Q', controlPoint.x, controlPoint.y, line2StartPoint.x, line2StartPoint.y],
          ['L', endPoint.x, endPoint.y],
        ];
    
        if (Math.abs(Ydiff) <= 5) {
          path = [
            ['M', startPoint.x, startPoint.y],
            ['L', endPoint.x, endPoint.y],
          ];
        }
    
        const endArrow = cfg?.style && cfg.style.endArrow ? cfg.style.endArrow : false;
        if (isObject(endArrow)) endArrow.fill = stroke;
        
        const isInValidPath = cfg.predictChurn !== undefined;
        if (isInValidPath) {
          stroke = getColor(cfg.predictChurn);
        }

        const line = group.addShape('path', {
          attrs: {
            path,
            stroke: stroke,
            lineWidth: isInValidPath ? 2 : 1.2,
            endArrow,
          },
          name: 'path-shape',
        });
    
        const labelLeftOffset = 0;
        const labelTopOffset = 8;

        function getEdgeString() {
          if (cfg.data) {
            if (Array.isArray(cfg.data.value) && cfg.data.value.length > 1) {
              return (`${cfg.data.condition} [${cfg.data.value}]`);
            } else {
              return (`${cfg.data.condition} ${cfg.data.value}`)
            }
          }
          return '';
        }
        // condition
        group.addShape('text', {
          attrs: {
            text: getEdgeString(),
            x: line2StartPoint.x + labelLeftOffset,
            y: endPoint.y - labelTopOffset - 5,
            fontSize: 10,
            textAlign: 'left',
            textBaseline: 'middle',
            fill: '#000000D9',
          },
          name: 'text-shape-condition',
        });
        
        return line;
      },
    });

    const graph = new G6.Graph({
      container: chartContainer.current,
      fitView: true,
      layout: {
        type: 'dagre',
        rankdir: 'LR',
        nodesep: 30, // y-distance between nodes
        ranksepFunc: (node) => {  // x-distance between nodes
          if (node.parentAttributeValues !== undefined || node.attributeValues !== undefined) {
            const leftWidth = node.parentAttributeValues !== undefined ? node.parentAttributeValues.toString().length : 0;
            const rightWidth = node.attributeValues !== undefined ? node.attributeValues.toString().length : 0;

            return (leftWidth + rightWidth) * 3 + 30
          }
          return 20;
        }
      },
      modes: {
        default: ['drag-canvas'],
      },
      defaultNode: {
        type: 'round-rect',
        labelCfg: {
          style: {
            fill: '#000000A6',
            fontSize: 10,
          },
        },
        style: {
          stroke: '#C0C0C0',
          width: 120,
        },
      },
      defaultEdge: {
        type: 'fund-polyline',
        style: {
          stroke: '#C0C0C0',
          width: 150,
        },
      },
    });
    
    graph.data(data);
    graph.render();
    
    const edges = graph.getEdges();
    edges.forEach(function (edge) {
      const line = edge.getKeyShape();
      const stroke = line.attr('stroke');
      const targetNode = edge.getTarget();
      targetNode.update({
        style: {
          stroke,
        },
      });
    });
    graph.paint();
  }

  return (
    <div className="component-decision-tree-chart" ref={chartContainer}></div>
  );
}

export default DecisionTree;
