
import React from 'react';
import {
  Table,
  Button,
  Tag
} from 'antd';

import './users-table.scss';

function UsersTable({
  users,
  roles,
  usersRoles,
  onUsersUpdate,
  onOpenUsersDeleteModal
}) {
  function findRoles(userId) {
    let result = usersRoles
      .filter((userRole) => (userRole.userId === userId))

    result = result.map((userRole) => (
      roles.find((role) => (role.id === userRole.roleId))
    ));

    return result;
  }

  const columns = [
    {
      title: '姓名',
      width: '10%',
      render(text, record) {
        return record.name;
      }
    },
    {
      title: '职称',
      dataIndex: 'title',
      width: '10%'
    },
    {
      title: '类型',
      dataIndex: 'type',
      width: '10%',
      render(text) {
        return {
          regular: '普通',
          admin: '管理员',
          'super admin': '总管理员'
        }[text];
      }
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: '10%',
      render(text) {
        return (
          <div className={text}>
            {text === 'active' ? '在职' : '停职'}
          </div>
        )
      }
    },
    {
      title: '角色',
      render(text, record) {
        return (
          <div>
            {findRoles(record.id).map((role) => {
              return <Tag key={role.id}>{role.name}</Tag>
            })}
          </div>
        )
      }
    },
    {
      width: '25%',
      render: (text, record) => {
        return (
          <div className="actions">
            <Button
              onClick={() => onUsersUpdate({
                id: record.id,
                status: record.status === 'active' ? 'inactive' : 'active'
              })}
            >
              {record.status === 'active' ? '停职' : '复职'}
            </Button>
            <Button
              danger
              onClick={() => onOpenUsersDeleteModal(record)}
            >
              删除
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="users-table">
      <div className="title">员工列表</div>
      <Table
        dataSource={users}
        rowKey="id"
        columns={columns}
        bordered
        pagination={{ position: ['bottomLeft'] }}
      />
    </div>
  );
}

export default UsersTable;
