
import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import TimeSeriesExplorationDomain from 'domains/time-series-exploration';
import MetadataDomain from 'domains/metadata';
import Notifications from './notifications';
import MetricsArea from './metrics-area';
import DimensionsArea from './dimensions-area';
import DashboardHeader from 'components/dashboard-header';

import './index.scss';

const dimensionFieldToTitle = {
  customers: {
    nps: 'NPS',
    channel: '渠道',
    industry: '行业',
    region: '区域',
    category: '类型',
    gender: '性别',
    intent: '意向'
  }
};

const dashboardConfig = {
  metrics: [
    {
      name: 'subscriptions.COUNT',
      title: '订阅数量'
    },
    {
      name: 'subscriptions.SUM',
      title: '订阅金额'
    },
    {
      name: 'renewals.COUNT',
      title: '续费数量'
    },
    {
      name: 'renewals.SUM',
      title: '续费金额'
    },
    {
      name: 'healthScores.AVG',
      title: '平均健康分'
    }
  ]
};

const dimensionFieldNames = [
  'channel', 'industry', 'region', 'category'
];

function TimeSeriesExploration() {
  function handleTimeFrameSelect(v) {
    dispatch(TimeSeriesExplorationDomain.actions.timeFrameUpdate(v.key));
  }

  const dispatch = useDispatch();
  const clientMetadata = useSelector(MetadataDomain.selectors.client);
  const analyticsMetadata = useSelector(MetadataDomain.selectors.analytics);
  const spec = useSelector(TimeSeriesExplorationDomain.selectors.spec);
  const specVersion = useSelector(TimeSeriesExplorationDomain.selectors.specVersion);
  const data = useSelector(TimeSeriesExplorationDomain.selectors.data);

  const customersDimensionFields = analyticsMetadata.dimensions.customers.fields;

  dashboardConfig.dimensions = dimensionFieldNames.map((fieldName) => {
    const fieldMetadata = customersDimensionFields[fieldName];
    if (
      fieldMetadata.applicableTo &&
      !fieldMetadata.applicableTo.includes(clientMetadata.type)
    ) {
      return false;
    }

    return {
      name: `customers.${fieldName}`,
      title: dimensionFieldToTitle.customers[fieldName]
    }
  }).filter((d) => !!d);

  const dimensions = dashboardConfig.dimensions.reduce((total, curr) => {
    total[curr.name] = { mode: 'SELECT', values: [] };
    return total;
  }, {});

  useEffect(() => {
    dispatch(TimeSeriesExplorationDomain.actions.init({
      spec: {
        timeFrame: 'RECENT_6_MONTHS',
        granularity: 'MONTH',
        dimensionTableMetric: dashboardConfig.metrics[0].name,
        metrics: dashboardConfig.metrics.map((metric) => metric.name),
        dimensions
      },
      config: dashboardConfig
    }));
  }, []);

  useEffect(() => {
    if (spec !== null) {
      dispatch(TimeSeriesExplorationDomain.actions.query());
    }
  }, [specVersion]);

  if (!data) return <div>--- 加载中 ---</div>;

  return (
    <div className="sales-performance">
      {false && <Notifications />}
      <DashboardHeader
        mode="left"
        onTimeFrameSelect={handleTimeFrameSelect}
        timeFrameString={spec.timeFrame}
      />
      <Row gutter={10}>
        <Col span={12}>
          <MetricsArea />
        </Col>
        <Col span={12}>
          <DimensionsArea />
        </Col>
      </Row>
    </div>
  );
}

export default TimeSeriesExploration;
