
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Panel from 'components/panel';
import AnalyticsDomain from 'domains/analytics';
import TrendChart from 'components/trend-chart';

function ActivityLevelPanel({
  customer
}) {
  const perCustomer = useSelector(AnalyticsDomain.selectors.perCustomer)[customer.id] || {};

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AnalyticsDomain.actions.customerActivityLevel(customer.id));
  }, []);

  const data = perCustomer.customerActivityLevel;

  return (
    <div className="activity-level-panel">
      <Panel title="活跃度" style={{ height: 240 }}>
        {!data && <div>加载中</div>}
        {
          data && data.length === 0 &&
          <div>暂无数据</div>
        }
        {
          data && data.length !== 0 &&
          <div className="chart">
            <TrendChart
              data={data}
            />
          </div>
        }
      </Panel>
    </div>
  );
}

export default ActivityLevelPanel;
