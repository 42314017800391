
import API from 'adapters/api';

const Users = {
  selectors: {}
};

Users.selectors.data = (state) => state.users;

Users.create = async (user) => {
  return await API.request({
    resource: 'users',
    action: 'create',
    data: user
  });
};

Users.update = async (user) => {
  return await API.request({
    resource: 'users',
    action: 'update',
    data: user
  });
};

Users.delete = async (id) => {
  return await API.request({
    resource: 'users',
    action: 'delete',
    data: { id }
  });
};

const reducers = {
  users(state, action) {
    if (state === undefined) return null;

    if (action.type === 'usersCreate') {
      return [
        ...state,
        action.user
      ];
    } else if (action.type === 'usersUpdate') {
      return state.map((user) => {
        if (user.id === action.user.id) {
          return Object.assign({}, user, action.user);
        } else {
          return user;
        }
      });
    } else if (action.type === 'usersDelete') {
      return state.filter((user) => user.id !== action.id);
    }

    return state;
  }
}

export { reducers };
export default Users;
