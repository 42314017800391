
import React, { useState, useEffect } from 'react';
import { withRouter, useHistory, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Tabs,
  Tooltip,
} from 'antd';

import Breadcrumb from 'components/breadcrumb';
import Dashboard from './dashboard';
import BasicInfo from './basic-info';
import Metrics from './metrics';
import History from './history';
import HealthScoreDetails from './health-score-details';
import Notes from './notes';
import CustomersDomain from 'domains/customers';
import Feedback from './feedback';
import TicketsTable from 'components-advanced/tickets-table';
import CustomerUsers from './customer-users';
import CustomerChurnDecisionTree from './decision-tree';
import MetadataDomain from 'domains/metadata';
import CustomerUsersDomain from 'domains/customer-users';
import UserActivities from './user-activities';

import './index.scss';

function Customer({
  match
}) {
  function handleChangeTab(key) {
    const redirectUrl = `/customers-management/${id}/${key}`
    history.push(redirectUrl);
    setActiveTab(key);
  }

  function getCustomer(customers, id) {
    return customers.find((customer) => customer.id === id);
  };

  const { id, tab } = match.params;

  const dispatch = useDispatch();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(tab);
  const customers = useSelector(CustomersDomain.selectors.data);
  const client = useSelector(MetadataDomain.selectors.client);
  const customerUsers = useSelector(CustomerUsersDomain.selectors.perCustomer)[id];

  const customer = getCustomer(customers, parseInt(id));

  useEffect(() => {
    dispatch(CustomersDomain.actions.read(id));

    if (!customerUsers) {
      dispatch(CustomerUsersDomain.actions.getList(id));
    }

    document.title = '云策智慧 - 客户 360°';
  }, []);

  if (!customer || !customerUsers) return <div>加载中。。。</div>;

  const churnDecisionTreeLink = `/customers-management/${id}/churn-decision-tree`;
  
  const redDotForChurn = (
    <Tooltip title="有流失风险">
      <Link to={churnDecisionTreeLink}>
        <div className='red-dot'> </div>
      </Link>
    </Tooltip>
  )

  return (
    <div className="page-customer">
      <Breadcrumb
        navArray={[
          { content: '客户管理', link: '/customers-management' },
          { 
            content: (
              <div className='customer-name'>
                <span>{customer.name}</span>
                {
                  customer.churnPrediction === '流失' && 
                  redDotForChurn
                }
              </div>
            )
          }
        ]}
      />
      <div className="content">
        <Tabs activeKey={activeTab} onChange={handleChangeTab}>
          <Tabs.TabPane key="summary" tab="看板">
            <Dashboard
              customer={customer}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="basic-info" tab="信息">
            <BasicInfo
              customer={customer}
            />
          </Tabs.TabPane>
          {
            client.type === 'b2b' &&
            <Tabs.TabPane key="customer-users" tab="客户员工">
              <CustomerUsers customer={customer} />
            </Tabs.TabPane>
          }
          <Tabs.TabPane key="health-score-details" tab="健康模型">
            <HealthScoreDetails
              customer={customer}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="churn-decision-tree" tab="流失预测">
            <CustomerChurnDecisionTree
              customer={customer}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="metrics" tab="其他指标">
            <Metrics
              customer={customer}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="tickets" tab="SOP">
            <TicketsTable customer={customer} />
          </Tabs.TabPane>
          <Tabs.TabPane key="feedback" tab="反馈">
            <Feedback
              customer={customer}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="user-events" tab="工作记录">
            <UserActivities
              customer={customer}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="history" tab="历史">
            <History
              customer={customer}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="notes" tab="笔记">
            <Notes
              customer={customer}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default withRouter(Customer);
