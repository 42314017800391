
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import Customers from 'domains/customers';
import CustomerLists from 'domains/customer-lists';
import ListsSelection from './lists-selection';
import Filters from './filters';
import CustomersTable from './customers-table';
import CustomerDrawer from './customer-drawers';

import './index.scss';

function CustomersManagement() {
  function handleChangePage(page) {
    setPage(page);
  }

  function handleShowDrawer() {
    setShowDrawer(true);
  }

  function handleHideDrawer() {
    setShowDrawer(false);
  }

  function handleCreateCustomer(customer) {
    dispatch(Customers.actions.create(customer, handleHideDrawer));
  }

  const dispatch = useDispatch();
  const selectedList = useSelector(CustomerLists.selectors.selected);
  const appliedFilters = useSelector(CustomerLists.selectors.appliedFilters);

  const [page, setPage] = useState(1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const { search } = useLocation();
  const queryStringObj = queryString.parse(search);

  useEffect(() => {
    const { customerListId } = queryStringObj;
    if (customerListId !== undefined) {
      dispatch(CustomerLists.actions.select(
        parseInt(customerListId))
      );
    }

    document.title = '云策智慧 - 客户 360°';
  }, []);

  useEffect(() => {
    const baseListId = (selectedList && selectedList.type === 'STATIC') ?
      selectedList.id : undefined;
    dispatch(
      Customers.actions.readList(
        page, appliedFilters, baseListId
      )
    );
  }, [page, appliedFilters, selectedList]);

  useEffect(() => {
    setPage(1);
  }, [selectedList]);

  return (
    <div className={`page-customers-management collapsed-${collapsed}`}>
      <ListsSelection
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />
      <div className="content">
        <Filters />
        <div style={{ height: 10 }}></div>
        <CustomersTable
          page={page}
          handleChangePage={handleChangePage}
          handleClickCustomerCreation={handleShowDrawer}
        />
      </div>
      {
        showDrawer &&
        <CustomerDrawer
          onSubmit={handleCreateCustomer}
          onCancel={handleHideDrawer}
        />
      }
    </div>
  );
}

export default CustomersManagement;
