
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form,
  Input,
  Select,
  Button,
  Row, Col
} from 'antd';
import {
  MailOutlined,
  PhoneOutlined
} from '@ant-design/icons';

import CustomersDomain from 'domains/customers';
import BaseModal from '../base-modal';

import './index.scss';

class CustomersCreate extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
  };

  render() {
    const { onCancel, onSubmit } = this.props;

    return (
      <BaseModal title="添加客户">
        <CustomersCreateForm
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </BaseModal>
    )
  }
}

function mapStateToProps(state) {
  return {
    customers: state.customers
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onCancel() {
      dispatch({ type: 'closeModal' });
    },
    onSubmit(customer) {
      dispatch({
        type: 'api',
        request: () => CustomersDomain.create(customer),
        success(data) {
          dispatch({
            type: 'customersCreate',
            customer: { ...customer, id: data.id }
          });
          dispatch({ type: 'closeModal' });
        }
      })
    }
  };
}

function CustomersCreateForm({
  onCancel,
  onSubmit
}) {
  const nameField = (
    <div>
      <div className="label">名</div>
      <Form.Item
        name="name"
        rules={[
          { required: true, message: `请输入名称` }
        ]}
      >
        <Input
          placeholder={"名称"}
        />
      </Form.Item>
    </div>
  );

  const statusField = (
    <div>
      <div className="label">状态</div>
      <Form.Item
        name="status"
      >
        <Select>
          <Select.Option value="active">
            订阅中
          </Select.Option>
          <Select.Option value="inactive">
            非订阅中
          </Select.Option>
        </Select>
      </Form.Item>
    </div>
  );

  const emailField = (
    <div>
      <div className="label">电子邮件</div>
      <Form.Item
        name="email"
        rules={[
          { type: 'email', message: '电子邮件格式错误' }
        ]}
      >
        <Input
          placeholder={"电子邮件"}
          prefix={<MailOutlined />}
        />
      </Form.Item>
    </div>
  );

  const mobileField = (
    <div>
      <div className="label">手机号</div>
      <Form.Item
        name="mobile"
        rules={[
          { type: 'number', message: '手机号格式错误' }
        ]}
      >
        <Input
          placeholder={"手机号"}
          prefix={<PhoneOutlined />}
        />
      </Form.Item>
    </div>
  );

  return (
    <div className="customers-create-form">
      <Form
        name="CustomersCreate"
        initialValues={
          {
            status: 'active',
            type: 'regular'
          }
        }
        onFinish={onSubmit}
      >
        <Row gutter={10}>
          <Col span={8}>{nameField}</Col>
          <Col span={8}>{statusField}</Col>
        </Row>
        <Row gutter={10}>
          <Col span={12}>{emailField}</Col>
          <Col span={12}>{mobileField}</Col>
        </Row>
        <div className="submit">
          <Form.Item noStyle>
            <Button
              type="primary"
              htmlType="submit"
            >
              提交
            </Button>             
          </Form.Item>
          <Button onClick={onCancel}>取消</Button>
        </div>
      </Form>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomersCreate);
