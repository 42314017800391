
import React from 'react';
import {
  Button, Menu, Dropdown
} from 'antd';
import { DownOutlined } from '@ant-design/icons';

import './index.scss';

function DashboardHeader({
  mode,
  title,
  listString = 'ALL_CUSTOMERS',
  onListSelect
}) {
  const listDropdown = (
    <Menu onClick={onListSelect}>
      <Menu.Item key="ALL_CUSTOMERS">
        <p>所有客户</p>
      </Menu.Item>
      <Menu.Item key="MY_CUSTOMERS">
        <p>我的客户</p>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="component-dashboard-header">
      {
        mode !== 'left' &&
        <div className="title">{title}</div>
      }
      <div className="actions">
        <Dropdown overlay={listDropdown} trigger={['click']}>
          <Button>
            <span>{listToTitle[listString]}</span><DownOutlined />
          </Button>
        </Dropdown>
      </div>
    </div>
  );
}

const listToTitle = {
  ALL_CUSTOMERS: '所有客户',
  MY_CUSTOMERS: '我的客户'
};

export default DashboardHeader;
