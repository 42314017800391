
import React, { useEffect, useRef } from 'react';
import { Chart } from '@antv/g2';

import themeColor from 'theme/_color.scss';

function RenewalResults() {
  const chartContainer = useRef(null);

  useEffect(() => {
    chartContainer.current.innerHTML = '';
    createChart(chartContainer.current);
  }, []);

  function createChart(container) {
    const chart = new Chart({
      container,
      autoFit: true,
      height: 200
    });

    chart.data(fakeData);
    chart.scale('value', {
      min: 0,
      max: 15,
      nice: true,
      ticks: [0, 5, 10, 15]
    });

    chart.tooltip({
      showMarkers: false
    });

    chart
      .interval()
      .position('timestamp*value')
      .color('label', (label) => {
        return {
          '健康': themeColor.healthGoodFuture,
          '中等': themeColor.healthMediumFuture,
          '危险': themeColor.healthBadFuture,
          '已续费': themeColor.success,
          '已流失': themeColor.failure
        }[label];
      })
      .adjust('stack');
  
    const today = new Date();
    const month = today.getMonth() + 1;
    const day = today.getDate();
   
    chart.annotation().region({
      start: ['min', 'min'],
      end: [`2022-${month}-${day}`, 'max']
    });
    
    chart.tooltip({
      shared: true,
      marker: false
    });

    chart.interaction('active-region');

    chart.render();
  }

  return (
    <div ref={chartContainer}></div>
  );
}

const fakeData = generateFakeData();
function generateFakeData() {
  const fakeData = [];

  const today = new Date();
  const month = today.getMonth() + 1;
  const day = today.getDate();

  for (let i = 1; i <= day; i++) {
    const day = i < 10 ? `0${i}` : i;
    const timestamp = `2022-${month}-${day}`;

    let churnedCount = Math.floor(Math.random() * 11) - 7;
    if (churnedCount < 0) churnedCount = 0;
    let renewedCount = Math.floor(Math.random() * 11) - 6;
    if (renewedCount < 0) renewedCount = 0;

    fakeData.push({
      timestamp,
      value: churnedCount,
      label: '已流失'
    });

    fakeData.push({
      timestamp,
      value: renewedCount,
      label: '已续费'
    });
  }

  for (let i = day + 1; i <= 30; i++) {
    const timestamp = `2022-${month}-${i}`;

    const healthLabels = [
      '健康',
      '中等',
      '危险'
    ];

    healthLabels.forEach((label) => {
      let value = Math.floor(Math.random() * 12) - 8;
      if (value < 0) value = 0;
      fakeData.push({
        timestamp,
        value,
        label
      });
    });
  }

  return fakeData;
}

export default RenewalResults;
