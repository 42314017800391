
import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Tabs } from 'antd';

import Summary from './summary';
import Retention from './retention';
import Churn from './churn';

import './index.scss';

function CustomersDashboards({
  match
}) {
  function handleChangeTab(key) {
    const redirectUrl = `/customers-dashboards/${key}`
    history.push(redirectUrl);
    setActiveTab(key);
  }

  const { tab } = match.params;

  const history = useHistory();
  const [activeTab, setActiveTab] = useState(tab);

  useEffect(() => {
    document.title = '云策智慧 - 客户数据看板';
  }, []);

  return (
    <div className="page-customers-dashboards">
      <div className="content">
        <Tabs activeKey={activeTab} onChange={handleChangeTab}>
          <Tabs.TabPane key="summary" tab="概况">
            <Summary />
          </Tabs.TabPane>
          <Tabs.TabPane key="retention" tab="续费管理">
            <Retention />
          </Tabs.TabPane>
          <Tabs.TabPane key="churn" tab="流失分析">
            <Churn />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default withRouter(CustomersDashboards);
