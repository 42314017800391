
import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import {
  Tabs
} from 'antd';

import Dashboard from './dashboard';
import Models from './models';

function CustomersHealth({ match }) {
  function handleChangeTab(key) {
    const redirectUrl = `/customers-health/${key}`
    history.push(redirectUrl);
    setActiveTab(key);
  }

  const { tab } = match.params;

  const history = useHistory();
  const [activeTab, setActiveTab] = useState(tab);

  useEffect(() => {
    document.title = '云策智慧 - 客户健康';
  }, []);

  return (
    <div className="page-customers-health">
      <Tabs activeKey={activeTab} onChange={handleChangeTab}>
        <Tabs.TabPane key="dashboard" tab="整体看板">
          <Dashboard />
        </Tabs.TabPane>
        <Tabs.TabPane key="models" tab="模型设置">
          <Models />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default withRouter(CustomersHealth);
