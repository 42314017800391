
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Row, Col
} from 'antd';

import Metadata from 'domains/metadata';
import Panel from 'components/panel';
import InPlaceEdit from 'components/in-place-edit';

function InfoPanel({
  customer,
  onUpdate
}) {
  function getSelector(fieldName) {
    return Metadata.selectors.getComponentParamsSelector({
      collection: 'customers',
      fieldName,
      record: customer,
      onUpdate
    });
  }

  const assignedEmployeeParams = useSelector(getSelector('assignedEmployeeId'));
  const nameParams = useSelector(getSelector('name'));
  const countryParams = useSelector(getSelector('country'));
  const provinceParams = useSelector(getSelector('province'));
  const cityParams = useSelector(getSelector('city'));
  const districtParams = useSelector(getSelector('district'));
  const streetParams = useSelector(getSelector('street'));

  assignedEmployeeParams.selectOptions.push({ title: '', value: null });

  return (
    <div className="info-panel">
      <Panel title="基本信息">
        <Row>
          <Col span={12}>
            <InPlaceEdit {...nameParams}/>
          </Col>
          <Col span={12}>
            <InPlaceEdit {...assignedEmployeeParams}/>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <InPlaceEdit {...countryParams}/>
          </Col>
          <Col span={12}>
            <InPlaceEdit {...provinceParams}/>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <InPlaceEdit {...cityParams}/>
          </Col>
          <Col span={12}>
            <InPlaceEdit {...districtParams}/>
          </Col>
          <Col span={12}>
            <InPlaceEdit {...streetParams}/>
          </Col>
        </Row>
      </Panel>
    </div>
  )
}

export default InfoPanel;
