
import React, { useEffect, useState } from 'react';
import { Chart, registerShape } from '@antv/g2';
import { calculateScale } from 'utils/chart'
import { formatNumber } from 'utils/number'
import { trendUp, trendDown }  from 'theme/_color.scss';

registerShape('interval', 'rangeMovedLeft', {
  draw(cfg, group) {
    const points = this.parsePoints(cfg.points);
    const leftX = points[0].x;
    const topY = points[0].y;
    const bottomY = points[1].y;

    const size = 10;

    const polygon = group.addShape('path', {
      attrs: {
        ...cfg.defaultStyle,
        path: [
          ['M', leftX - size / 2, topY],
          ['L', leftX - size / 2, bottomY],
          ['L', leftX + size / 2, bottomY],
          ['L', leftX + size / 2, topY],
        ],
        fill: cfg.color
      },
    });
  
    return polygon;
  },
});

registerShape('interval', 'rangeMovedRight', {
  draw(cfg, group) {
    const points = this.parsePoints(cfg.points);
    const leftX = points[0].x;
    const topY = points[0].y;
    const bottomY = points[1].y;

    const size = 10;

    const polygon = group.addShape('path', {
      attrs: {
        ...cfg.defaultStyle,
        path: [
          ['M', leftX + size / 2, topY],
          ['L', leftX + size / 2, bottomY],
          ['L', leftX + size * 1.5, bottomY],
          ['L', leftX + size * 1.5, topY],
        ],
        fill: cfg.color
      },
    });
  
    return polygon;
  },
});


function colorMap(value, time, timestamps) {
  const lastDate = timestamps.at(-1);
  const alpha = time === lastDate ? '57' : 'BB';
  if (value === '增加') return trendUp + alpha;
  return trendDown + alpha;
}

function WaterfallChart({
  name,
  data,
  valueName,
}) {
  const className =  `chart-waterfall-${name}`;

  const colorMapper = (value, time) => {
    return colorMap(value, time, data.map(datum => datum.timestamp));
  }

  const [max, min] = calculateScale({ data, key: valueName });
  
  const commonScale = {
    min,
    max,
    tickCount: 3
  };

  const [savedChart, setChart] = useState(null);

  function formatDate(dateString) {
    return `${dateString.split('-')[1]}月`;
  }

  useEffect(() => {
    if (savedChart !== null) {
      savedChart.destroy();
    }
    const chart = new Chart({
      container: document.querySelector(`.${className}`),
      autoFit: true,
      height: 245
    });

    setChart(chart);

    const lineView = chart.createView();

    lineView
      .data(data)
      .line()
      .color('#7E92B5')
      .position(`timestamp*${valueName}`)
      .tooltip(`timestamp*${valueName}`, (timestamp, value) => {
        return {
          name: valueName,
          value: formatNumber(value)
        }
      });
    
    lineView.scale({
      [valueName]: commonScale,
      date: {
        formatter: formatDate
      }
    });

    lineView.axis(valueName, {
      label: {
        formatter: formatNumber
      }
    });

    const addedBarView = chart.createView();
    addedBarView.data(data.map((datum) => {
      return { ...datum, type: '增加' };
    }));
    
    addedBarView
      .interval()
      .shape('rangeMovedLeft')
      .size(10)
      .color('type*timestamp', colorMapper)
      .position('timestamp*增加')
      .tooltip('timestamp*增加', (timestamp, increase) => {
        return {
          name: '增加',
          value: formatNumber(increase[1] - increase[0])
        }
      });

    addedBarView.scale({
      增加: commonScale,
      date: {
        formatter: formatDate
      }
    });

    addedBarView.axis('增加', {
      label: {
        formatter: formatNumber
      },
      grid: null
    });

    const subtractedBarView = chart.createView();

    subtractedBarView.data(data.map((datum) => {
      return { ...datum, type: '减少' }
    }));

    subtractedBarView
      .interval()
      .shape('rangeMovedRight')
      .color('type*timestamp', colorMapper)
      .size(10)
      .position('timestamp*减少')
      .tooltip('timestamp*减少', (timestamp, decrease) => {
        return {
          name: '减少',
          value: formatNumber(decrease[1] - decrease[0])
        }
      });;

    subtractedBarView.scale({
      减少: commonScale,
      date: {
        formatter: formatDate
      }
    });

    subtractedBarView.axis('减少', {
      label: {
        formatter: formatNumber
      },
      grid: null
    });

    lineView.axis('timestamp', {
      tickLine: null, line: null
    });
    addedBarView.axis('timestamp', {
      tickLine: null, line: null
    });
    subtractedBarView.axis('timestamp', {
      tickLine: null, line: null
    });

    chart.tooltip({
      showMarkers: false,
      shared: true,
      showCrosshairs: false
    });

    lineView.tooltip({
      showMarkers: true
    });

    chart.legend({
      custom: true,
      items: [
        {
          name: '增加', value: '增加', 
          marker: { symbol: 'square', style: { fill: colorMapper('增加') } }
        },
        {
          name: '减少', value: '减少',
          marker: { symbol: 'square', style: { fill: colorMapper('减少') } }
        }
      ],
    });

    chart.render();
  }, [data]);

  return (
    <div className={className}></div>
  );
}

export default WaterfallChart;
