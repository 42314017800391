
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Radio,
  Menu,
  Dropdown,
  Button,
  Row
} from 'antd';
import {
  PlusOutlined
} from '@ant-design/icons';

import DashboardDomain from 'domains/time-series-exploration';
import DimensionContainer from './dimension-container';

import './index.scss';

function DimensionsArea({
  spec,
  config,
  onMetricUpdate
}) {
  const [
    shownDimensions,
    setShownDimensions
  ] = useState(config.dimensions.map((d) => d.name));

  function handleMetricUpdate(e) {
    onMetricUpdate(e.target.value);
  }

  return (
    <section className="dimensions-area">
      <div className="overall-control">
        <Radio.Group
          value={spec.dimensionTableMetric}
          onChange={handleMetricUpdate}
        >
          {config.metrics.map((metric) => {
            return (
              <Radio.Button
                key={metric.name}
                value={metric.name}
              >
                {metric.title}
              </Radio.Button>
            )
          })}
        </Radio.Group>
        {renderAddDimension({
          config,
          shownDimensions,
          setShownDimensions
        })}
      </div>
      <div className="dimensions-tables">
        {renderDimensionTables({
          shownDimensions,
          setShownDimensions,
          config
        })}
      </div>
    </section>
  );
}

function renderAddDimension({
  config,
  shownDimensions,
  setShownDimensions
}) {
  const dimensions = config.dimensions;
  const diff = dimensions.filter(d => !shownDimensions.includes(d.name))

  const menu = (
    <Menu
      onClick={handleAddDimension}
    >
      {
        diff.map((dimension) => (
          <Menu.Item key={dimension.name}>
            {dimension.title}
          </Menu.Item>
        ))
      }
    </Menu>
  );

  function handleAddDimension(name) {
    setShownDimensions([...shownDimensions, name.key]);
  }

  return (
    <Dropdown
      overlay={menu}
      disabled={diff.length === 0}
    >
      <Button
        type="link"
      >
        <PlusOutlined /> 添加维度
      </Button>
    </Dropdown>
  );
}

function renderDimensionTables({
  config,
  shownDimensions,
  setShownDimensions
}) {
  function handleHideDimension(dimension) {
    const result = shownDimensions.filter((d) => d !== dimension);
    setShownDimensions(result);
  }

  return (
    <Row gutter={[10, 10]}>
      {
        shownDimensions.map((name) => {
          const title = config.dimensions.find((d) => (
            d.name === name
          )).title;

          return (
            <DimensionContainer
              key={name}
              name={name}
              title={title}
              onHideDimension={handleHideDimension}
            />
          );
        })
      }
    </Row>
  );
}

function mapStateToProps(state) {
  return {
    spec: DashboardDomain.selectors.spec(state),
    config: DashboardDomain.selectors.config(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onMetricUpdate(metric) {
      dispatch(DashboardDomain.actions.dimensionTableMetricUpdate(metric))
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DimensionsArea);
