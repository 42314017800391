
import React from 'react';
import PropTypes from 'prop-types';

import {
  Drawer,
} from 'antd';

import './index.scss';

class BaseDrawer extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired
  };

  render() {
    const {
      title,
      onClose,
      children
    } = this.props;

    return (
      <Drawer
        width={640}
        closable={false}
        onClose={onClose}
        visible={true}
        zIndex={700} // smaller than modal's default (1000)
        getContainer={() => document.querySelector('#app')}
      >
        {title && <div className="title">{title}</div>}
        <div className="drawer-body">
          {children}
        </div>
      </Drawer>
    );
  }
}

export default BaseDrawer;
