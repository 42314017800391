
import React from 'react';
import Card from '@mui/material/Card';

import './index.scss';

function Panel({
  title,
  actions,
  children,
  style = {}
}) {
  return (
    <Card variant="outlined" style={{ minHeight: '100%' }}>
      <div
        className="component-panel"
        style={style}
      >
        {
          title &&
          <div className="title">
            <div>{title}</div>
            {actions && <div>{actions}</div>}
          </div>
        }
        <div className="content">{children}</div>
      </div>
    </Card>
  );
}

export default Panel;
