
import React from 'react';
import {
  Button
} from 'antd';

import BaseModal from 'modals/base-modal';

import './deletion-modal.scss';

function DeletionModal({
  alertDescription,
  onCancel,
  onSubmit,
}) {
  return (
    <BaseModal
      title={`确定删除报警规则？`}
      onCancelOverride={onCancel}
    >
      <div className="modal-alert-rules-deletion">
        <div className="content">
          <div>{alertDescription}</div>
        </div>
        <div className="submit">
          <Button
            type="primary"
            onClick={onSubmit}
            danger
          >
            删除
          </Button>
          <Button onClick={onCancel}>
            取消
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}

export default DeletionModal;
