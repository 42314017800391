
import React from 'react';

import DistributionTrendChart from 'components/distribution-trend-chart';
import ChartPanel from 'components/chart-panel';

const topReasonsDistributionTrendData = [
  { timestamp: '2021-10', health: '原因1', count: 1030, percent: 35 },
  { timestamp: '2021-09', health: '原因1', count: 660, percent: 40 },
  { timestamp: '2021-08', health: '原因1', count: 1170, percent: 35 },
  { timestamp: '2021-07', health: '原因1', count: 1000, percent: 40 },
  { timestamp: '2021-06', health: '原因1', count: 1000, percent: 35 },
  { timestamp: '2021-05', health: '原因1', count: 1000, percent: 40 },
  { timestamp: '2021-10', health: '原因2', count: 540, percent: 30 },
  { timestamp: '2021-09', health: '原因2', count: 1120, percent: 20 },
  { timestamp: '2021-08', health: '原因2', count: 460, percent: 30 },
  { timestamp: '2021-07', health: '原因2', count: 400, percent: 20 },
  { timestamp: '2021-06', health: '原因2', count: 400, percent: 30 },
  { timestamp: '2021-05', health: '原因2', count: 400, percent: 20 },
  { timestamp: '2021-10', health: '原因3', count: 350, percent: 35 },
  { timestamp: '2021-09', health: '原因3', count: 300, percent: 40 },
  { timestamp: '2021-08', health: '原因3', count: 300, percent: 35 },
  { timestamp: '2021-07', health: '原因3', count: 300, percent: 40 },
  { timestamp: '2021-06', health: '原因3', count: 300, percent: 35 },
  { timestamp: '2021-05', health: '原因3', count: 300, percent: 40 },
  { timestamp: '2021-04', health: '原因1', count: 1030, percent: 35 },
  { timestamp: '2021-03', health: '原因1', count: 660, percent: 40 },
  { timestamp: '2021-02', health: '原因1', count: 1170, percent: 35 },
  { timestamp: '2021-01', health: '原因1', count: 1000, percent: 40 },
  { timestamp: '2020-12', health: '原因1', count: 1000, percent: 35 },
  { timestamp: '2020-11', health: '原因1', count: 1000, percent: 40 },
  { timestamp: '2021-04', health: '原因2', count: 540, percent: 30 },
  { timestamp: '2021-03', health: '原因2', count: 1120, percent: 20 },
  { timestamp: '2021-02', health: '原因2', count: 460, percent: 30 },
  { timestamp: '2021-01', health: '原因2', count: 400, percent: 20 },
  { timestamp: '2020-12', health: '原因2', count: 400, percent: 30 },
  { timestamp: '2020-11', health: '原因2', count: 400, percent: 20 },
  { timestamp: '2021-04', health: '原因3', count: 350, percent: 35 },
  { timestamp: '2021-03', health: '原因3', count: 300, percent: 40 },
  { timestamp: '2021-02', health: '原因3', count: 300, percent: 35 },
  { timestamp: '2021-01', health: '原因3', count: 300, percent: 40 },
  { timestamp: '2020-12', health: '原因3', count: 300, percent: 35 },
  { timestamp: '2020-11', health: '原因3', count: 300, percent: 40 }
].sort((a, b) => a.timestamp.replace('-', '') - b.timestamp.replace('-', ''));

function npsColorMapping(value, timestamp) {
  const alpha = timestamp === '2021-10' ? 0.35 : 1;
  if (value === '原因1') return `rgba(19, 194, 194, ${alpha})`;
  if (value === '原因2') return `rgba(191, 191, 191, ${alpha})`;
  return `rgba(255, 122, 69, ${alpha})`;
}

function TopReasonsTrendChart() {
  return (
    <ChartPanel title="Top 3 流失原因趋势">
      <DistributionTrendChart
        name="top-reasons-trend"
        data={topReasonsDistributionTrendData}
        valueName="count"
        colorMapper={npsColorMapping}
        type="area"
      />
    </ChartPanel>
  );
}

export default TopReasonsTrendChart;
