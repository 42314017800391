
import React from 'react';
import { Row, Col, Alert } from 'antd';

import DashboardHeader from 'components/dashboard-header';
import ChurnTrendChart from 'analytics/churn-trend-chart';
import TopReasonsChart from './top-reasons-table';
import ChurnReasonsTable from './churn-reasons-trend-chart';
import {
  churnTrendData
} from 'analytics/fake-data';
import ChartPanel from 'components/chart-panel';
import RecentChurnList from './recent-churn-list';

import './index.scss';

function Churn() {
  return (
    <div className="churn-dashboard">
      <Alert
        message="本页面包括未完成功能，以及测试数据！"
        type="error"
      />
      <div style={{ height: 15 }}></div>
      <DashboardHeader
        title="流失分析"
        onTimeFrameSelect={() => {}}
        timeFrameString="RECENT_6_MONTHS"
      />
      <Row gutter={10}>
        <Col span={12}>
          <ChurnTrendChart
            churnTrendData={churnTrendData}
          />
        </Col>
        <Col span={12}>
          <ChurnReasonsTable />
        </Col>
      </Row>
      <div style={{ height: 10 }}></div>
      <Row gutter={10}>
        <Col span={12}>
          <TopReasonsChart />
          <div style={{ height: 10 }}></div>
          <div>
            <ChartPanel title="健康模型 Precision-Recall">
              <div className="table-list">
                <div className="precision-recall">
                  <div>
                    <div></div>
                    <div>高健康分</div>
                    <div>低健康分</div>
                  </div>
                  <div>
                    <div>未流失</div>
                    <div>753</div>
                    <div>22</div>
                  </div>
                  <div>
                    <div>流失</div>
                    <div>3</div>
                    <div>67</div>
                  </div>
                </div>
                <div className="precision-recall">
                  <div>
                    <div></div>
                    <div>预测的流失</div>
                    <div>实际的流失</div>
                  </div>
                  <div>
                    <div>正确</div>
                    <div>75.3%</div>
                    <div>95.7%</div>
                  </div>
                  <div>
                    <div>错误</div>
                    <div>24.7%</div>
                    <div>4.3%</div>
                  </div>
                </div>
              </div>
            </ChartPanel>
          </div>
        </Col>
        <Col span={12}>
          <RecentChurnList />
        </Col>
      </Row>
    </div>
  );
}

export default Churn;
