
import React from 'react';
import { Row, Col } from 'antd';

import HealthDistributionPanel from 'components-advanced/health-distribution-panel';
import HealthDistributionTrendPanel from 'components-advanced/health-distribution-trend-panel';

import Matrix from './matrix';

import './index.scss';

function Dashboard() {
  return (
    <div className="dashboard">
      <Row gutter={[10, 10]}>
        <Col span={12}>
          <HealthDistributionPanel />
        </Col>
        <Col span={12}>
          <HealthDistributionTrendPanel />
        </Col>
        <Col span={24}>
          <Matrix />
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
