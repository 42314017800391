
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button
} from 'antd';
import {
  PlusOutlined,
} from '@ant-design/icons';

import Panel from 'components/panel';
import Metadata from 'domains/metadata';
import CustomerLists from 'domains/customer-lists';
import Filter from './filter';

import './index.scss';

function Filters() {
  function handleApplyFilters() {
    dispatch(CustomerLists.actions.applyFilters());
  }

  function handleResetFilters() {
    setResetDisabled(true);
    dispatch(CustomerLists.actions.resetFilters());
  }

  function handleAddFilter() {
    setResetDisabled(false);
    dispatch(CustomerLists.actions.addFilter());
  }

  function handleDeleteFilter(i) {
    setDisabled(false);
    setResetDisabled(false);
    dispatch(CustomerLists.actions.removeFilter(i));
  }

  function handleChangeFilter(i, newFilter) {
    setDisabled(false);
    setResetDisabled(false);
    dispatch(CustomerLists.actions.changeFilter(i, newFilter));
  }

  function isDeleteDisabled() {
    return displayedFilters.length === 1;
  }

  const dispatch = useDispatch();

  const displayedFilters = useSelector(CustomerLists.selectors.displayedFilters);

  const [disabled, setDisabled] = useState(true);
  const [resetDisabled, setResetDisabled] = useState(true);

  const metadata = useSelector(Metadata.getSelector('fields', 'customers'));

  return (
    <div className="filters">
      <Panel>
        {displayedFilters.map((filter, i) => (
          <Filter
            key={i}
            filter={filter}
            index={i}
            metadata={metadata}
            isDeleteDisabled={isDeleteDisabled()}
            onChangeFilter={handleChangeFilter}
            onDeleteFilter={handleDeleteFilter}
          />
        ))}
        <div className="actions">
          <div>
            <Button
              shape="circle"
              icon={<PlusOutlined />} onClick={handleAddFilter}
            />
          </div>
          <div>
            <Button
              onClick={handleResetFilters}
              disabled={resetDisabled}
            >
              重置
            </Button>
            <Button
              type="primary"
              onClick={handleApplyFilters}
              disabled={disabled}
            >
              应用
            </Button>
          </div>
        </div>
      </Panel>
    </div>
  );
}

export default Filters;
