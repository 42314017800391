
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Input,
  Select,
  Button,
  DatePicker
} from 'antd';
import moment from 'moment';
import {
  MinusOutlined
} from '@ant-design/icons';

import Metadata from 'domains/metadata';

const operators = {
  null: { title: '' },
  EQUAL: { title: '等于' },
  NOT_EQUAL: { title: '不等于' },
  GREATER: { title: '>' },
  GREATER_EQUAL: { title: '>=' },
  LESS: { title: '<' },
  LESS_EQUAL: { title: '<=' },
  EXISTS: { title: '有数值' },
  NOT_EXISTS: { title: '无数值' },
  IS_TRUE: { title: '是' },
  IS_FALSE: { title: '否' },
  INCLUDE: { title: '包含' },
  EXCLUDE: { title: '不包含' },
  IS_CURRENT_USER_ID: { title: '属于当前员工' }
};

const equalityOperatorNames = ['EQUAL', 'NOT_EQUAL'];
const existenceOperatorNames = ['EXISTS', 'NOT_EXISTS'];
const comparisonOperatorNames = ['GREATER', 'GREATER_EQUAL', 'LESS', 'LESS_EQUAL'];

const fieldTypeToOperatorNames = {
  null: [],
  NUMBER: [...equalityOperatorNames, ...existenceOperatorNames, ...comparisonOperatorNames],
  DATE: [...equalityOperatorNames, ...existenceOperatorNames, ...comparisonOperatorNames],
  FORMULA: [...equalityOperatorNames, ...existenceOperatorNames, ...comparisonOperatorNames],
  REFERENCE: [...equalityOperatorNames, ...existenceOperatorNames],
  SELECT: equalityOperatorNames,
  BOOLEAN: ['EQUAL'],
  ENUM: ['INCLUDE', 'EXCLUDE']
};

function getFilterInput({
  fieldName,
  operatorName,
  allData,
  fieldsMetadata
}) {
  if (
    !operatorName ||
    (operatorName === 'EXISTS' || operatorName === 'NOT_EXISTS') ||
    operatorName === 'IS_CURRENT_USER_ID'
  ) {
    return () => (<Input disabled />);
  }

  const fieldMetadata = fieldsMetadata.customers[fieldName];

  if (fieldMetadata.type === 'BOOLEAN') {
    return ({ value, onChange }) => {
      return (
        <Select defaultValue={value} onChange={onChange}>
          <Select.Option key={1} value={true}>
            是
          </Select.Option>
          <Select.Option key={0} value={false}>
            否
          </Select.Option>
        </Select>
      );
    };
  }

  if (fieldMetadata.type === 'REFERENCE') {
    const valuesTitlesArray = allData[fieldMetadata.target].map((datum) => {
      const title = Metadata.getFieldValue(
        datum,
        fieldMetadata.targetTitleFieldName,
        fieldsMetadata[fieldMetadata.target][fieldMetadata.targetTitleFieldName]
      );
      const value = datum[fieldMetadata.targetFieldName];
      return { value, title }
    });

    return ({ value, onChange }) => (
      <Select
        defaultValue={value}
        onChange={onChange}
        showSearch
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {valuesTitlesArray.map(({ value, title }, i) => {
          return (
            <Select.Option key={i} value={value}>
              {title}
            </Select.Option>
          );
        })}
      </Select>
    );
  }

  if (fieldMetadata.type === 'SELECT') {
    return ({ value, onChange }) => (
      <Select
        defaultValue={value}
        onChange={onChange}
      >
        {fieldMetadata.options.map((option, i) => {
          const { value, title } = option;
          return (
            <Select.Option key={i} value={value}>
              {title}
            </Select.Option>
          );
        })}
      </Select>
    );
  }

  if (fieldMetadata.type === 'DATE') {
    return DatePicker;
  }

  return Input;
}

function Filter({
  filter,
  index,
  metadata,
  isDeleteDisabled,
  onChangeFilter,
  onDeleteFilter
}) {
  function getPossibleOperatorNames(fieldName) {
    const field = metadata[fieldName];
    if (field === undefined || fieldTypeToOperatorNames[field.type] === undefined) {
      return equalityOperatorNames;
    } else if (fieldName === 'assignedEmployeeId') {
      return fieldTypeToOperatorNames[field.type].concat(['IS_CURRENT_USER_ID']);
    } else {
      return fieldTypeToOperatorNames[field.type];
    }
  }

  function handleFieldChange(newFieldName) {
    const possibleOperatorsNames = getPossibleOperatorNames(newFieldName);
    const field = metadata[newFieldName];
    let newFilter;
    if (newFieldName === 'none') {
      newFilter = { fieldName: 'none' };
    } else {
      let value = '';
      if (field.type === 'BOOLEAN') value = true;
      else if (field.type === 'DATE') value = moment();

      newFilter = {
        operatorName: possibleOperatorsNames[0],
        fieldName: newFieldName,
        value
      };
    }

    onChangeFilter(index, newFilter);
  }

  function handleOperatorChange(newOperatorName) {
    onChangeFilter(
      index,
      {
        ...filter,
        operatorName: newOperatorName
      }
    );
  }

  function handleValueChange(e) {
    let value = e;
    if (e.target) value = e.target.value;

    onChangeFilter(
      index,
      {
        ...filter,
        value
      }
    );
  }

  function handleDeleteFilter() {
    onDeleteFilter(index);
  }

  function renderFieldOption(name) {
    return (
      <Select.Option key={name} value={name}>
        {metadata[name].title}
      </Select.Option>
    );
  }

  function renderOperatorOption(name) {
    return (
      <Select.Option key={name} value={name}>
        {operators[name].title}
      </Select.Option>
    )
  }


  const allData = useSelector(Metadata.selectors.allData);
  const fieldsMetadata = useSelector(Metadata.selectors.fieldsMetadata);
  const clientType = useSelector(Metadata.selectors.client).type;

  const possibleOperatorsNames = getPossibleOperatorNames(filter.fieldName);
  let metadataGroups = Object.keys(metadata)
    .map(field => metadata[field].category)
    .filter((value, index, self) => self.indexOf(value) === index && value !== undefined);
  metadataGroups.push('others'); // “其他” should be the last group
  metadataGroups = metadataGroups.reduce((total, curr) => {
    const relevantFields = getRelevantFields(curr);
    if (relevantFields.length > 0) {
      total.push({
        name: curr,
        children: relevantFields
      });
    }
    return total;
  }, []);

  function getRelevantFields(group) {
    return Object.keys(metadata)
      .filter((fieldName) => {
        return metadata[fieldName].filterable !== false
          && (
            metadata[fieldName].applicableTo === undefined ||
            metadata[fieldName].applicableTo.includes(clientType)
          )
          && (
            metadata[fieldName].category === group ||
            (group === 'others' && !metadata[fieldName].category)
          )
      })
  }

  const ValueInput = getFilterInput({
    fieldName: filter.fieldName,
    operatorName: filter.operatorName,
    allData,
    fieldsMetadata
  });

  const fieldMetadata = fieldsMetadata.customers[filter.fieldName];
  const valueParam = (filter.fieldName !== 'none' && fieldMetadata.type === 'DATE') ?
    moment(filter.value) :
    filter.value;

  return (
    <div className="filter">
      <Button
        shape="circle"
        icon={<MinusOutlined />}
        onClick={handleDeleteFilter}
        disabled={isDeleteDisabled}
      />
      <Select
        value={filter.fieldName}
        onChange={handleFieldChange}
        showSearch
        filterOption={(input, option) => {
          if (!option.children) return false;
          return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
      >
        <Select.Option value='none' key='none'>
          无
        </Select.Option>
        {
          metadataGroups.map(({ name, children }) =>
            <Select.OptGroup
              label={name === 'others' ? '其他' : name}              
              key={name}
            >
              {children.map(renderFieldOption)}
            </Select.OptGroup>
          )
        }
      </Select>
      <Select
        value={filter.operatorName}
        onChange={handleOperatorChange}
        disabled={filter.fieldName === 'none'}
      >
        {
          possibleOperatorsNames.map(renderOperatorOption)
        }
      </Select>
      <ValueInput
        value={valueParam}
        onChange={handleValueChange}
      />
    </div>
  );
}

export default Filter;
