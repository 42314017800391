
import API from 'adapters/api';

// TODO: this is a temporary design

/**
 * state structure:
 * {
 *   metrics: {
 *     key: <data>
 *   },
 *   dimensions: {
 *     key: <data>
 *   },
 *   perCustomer: {
 *     [customerId]: {
 *       customerActivityLevel: [
 *         { timestamp, value }
 *       ]
 *     }
 *   }
 * }
 */

const Analytics = {
  selectors: {},
  actions: {}
};

Analytics.selectors.metrics = (state) => state.analytics.metrics;
Analytics.selectors.dimensions = (state) => state.analytics.dimensions;
Analytics.selectors.cohort = (state) => state.analytics.cohort;
Analytics.selectors.perCustomer = (state) => state.analytics.perCustomer;

Analytics.actions.customerActivityLevel = (customerId) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'analytics/custom/customer-activity-level',
        action: 'read',
        data: { customerId },
        methodOverride: 'POST'
      });

      if (status < 300) {
        dispatch({
          type: 'analyticsCustomerActivityLevelRefresh',
          customerId,
          data: data.customerActivityLevel
        });
      }

      return { status, data };
    }
  };
};

Analytics.actions.customerNpsDistribution = (customerId) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'analytics/custom/customer-nps-distribution',
        action: 'read',
        data: { customerId },
        methodOverride: 'POST'
      });

      if (status < 300) {
        dispatch({
          type: 'analyticsCustomerNpsDistributionRefresh',
          customerId,
          data: data.customerNpsDistribution
        });
      }

      return { status, data };
    }
  };
};

Analytics.actions.queryMetric = ({
  metricName,
  aggregation,
  selectedDimensions,
  timeFrame,
  filter
}, success) => {
  const metricParams = {
    metricName,
    aggregation,
    selectedDimensions,
    timeFrame,
    filter
  };

  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'analytics/base/metric',
        action: 'read',
        data: metricParams,
        methodOverride: 'POST'
      });

      if (status < 300) {
        dispatch({
          type: 'analyticsMetricRefresh',
          key: JSON.stringify(metricParams),
          data: data.metric
        });

        success && success(dispatch, data.metric);
      }

      return { status, data };
    }
  }
};

Analytics.actions.queryDimension = ({
  dimensionName,
  aggregation,
  selection,
  filters
}, success) => {
  const dimensionParams = {
    dimensionName,
    aggregation,
    selection,
    filters
  };

  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'analytics/base/dimension',
        action: 'read',
        data: dimensionParams,
        methodOverride: 'POST'
      });

      if (status < 300) {
        dispatch({
          type: 'analyticsDimensionRefresh',
          key: JSON.stringify(dimensionParams),
          data: data.dimension
        });

        success && success(dispatch, data.dimension);
      }

      return { status, data };
    }
  }
};

Analytics.actions.queryCohort = (spec) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'analytics/composite/cohort',
        action: 'read',
        data: spec,
        methodOverride: 'POST'
      });

      if (status < 300) {
        dispatch({
          type: 'analyticsCohortRefresh',
          key: JSON.stringify(spec),
          data: data.cohort
        });
      }

      return { status, data };
    }
  }
};

const reducers = {
  analytics(state, action) {
    if (state === undefined) return {
      metrics: {},
      dimensions: {},
      cohort: {},
      perCustomer: {}
    };

    if (action.type === 'analyticsCustomerActivityLevelRefresh') {
      const perCustomer = state.perCustomer[action.customerId] || {};

      return {
        ...state,
        perCustomer: {
          ...state.perCustomer,
          [action.customerId]: {
            ...perCustomer,
            customerActivityLevel: action.data
          }
        }
      };
    } else if (action.type === 'analyticsCustomerNpsDistributionRefresh') {
      const perCustomer = state.perCustomer[action.customerId] || {};

      return {
        ...state,
        perCustomer: {
          ...state.perCustomer,
          [action.customerId]: {
            ...perCustomer,
            customerNpsDistribution: action.data
          }
        }
      };
    } else if (action.type === 'analyticsMetricRefresh') {
      const { key, data } = action;

      return {
        ...state,
        metrics: {
          ...state.metrics,
          [key]: data
        }
      };
    } else if (action.type === 'analyticsDimensionRefresh') {
      const { key, data } = action;

      return {
        ...state,
        dimensions: {
          ...state.dimensions,
          [key]: data
        }
      };
    } else if (action.type === 'analyticsCohortRefresh') {
      const { key, data } = action;

      return {
        ...state,
        cohort: {
          ...state.cohort,
          [key]: data
        }
      };
    }

    return state;
  }
}

export { reducers };
export default Analytics;
