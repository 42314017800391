
import React from 'react';
import {
  Row, Col
} from 'antd';
import moment from 'moment';

import Panel from 'components/panel';
import InPlaceEdit from 'components/in-place-edit';

function ProfilePanel({
  customer,
  onUpdate
}) {
  // TODO: metadata service
  // HACK: fixed journey stages for now
  return (
    <div className="profile-panel">
      <Panel title="更多资料">
        <Row>
          <Col span={12}>
            <InPlaceEdit
              title="业务结果"
              value={customer.outcome}
              type="number"
              onSubmit={(v) => onUpdate('outcome', v)}
            />
          </Col>
          <Col span={12}>
            <InPlaceEdit
              title="历程阶段"
              value={customer.journeyStage}
              type="select"
              selectOptions={[
                { value: '初始客户', title: '初始客户' },
                { value: '维护客户', title: '维护客户' }
              ]}
              onSubmit={(v) => onUpdate('journeyStage', v)}
            />
          </Col>
          <Col span={12}>
            <InPlaceEdit
              title="部署日期"
              value={
                customer.deploymentDate ?
                moment(customer.deploymentDate) :
                null
              }
              type="date"
              onSubmit={(v) => onUpdate('deploymentDate', v)}
            />
          </Col>
          <Col span={12}>
            <InPlaceEdit
              title="引导完成日期"
              value={
                customer.onboardDate ?
                moment(customer.onboardDate) :
                null
              }
              type="date"
              onSubmit={(v) => onUpdate('onboardDate', v)}
            />
          </Col>
          <Col span={12}>
            <InPlaceEdit
              title="初始价值日期"
              value={
                customer.initialValueDate ?
                moment(customer.initialValueDate) :
                null
              }
              type="date"
              onSubmit={(v) => onUpdate('initialValueDate', v)}
            />
          </Col>
        </Row>
      </Panel>
    </div>
  )
}

export default ProfilePanel;
