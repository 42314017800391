
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker } from 'antd';
import moment from 'moment';

import UserActivitiesDomain from 'domains/user-activities';
import UserActivityCard from 'components/user-activity-card';

import './work-log.scss';

function WorkLog({
  user
}) {
  function onMonthChange(date) {
    setYear(date.year());
    setMonth(date.month() + 1);
  }

  const [year, setYear] = useState((new Date()).getFullYear());
  const [month, setMonth] = useState((new Date()).getMonth() + 1);

  const dispatch = useDispatch();
  const activities = useSelector(UserActivitiesDomain.selectors.perUser)[user.id] || [];

  useEffect(() => {
    dispatch(UserActivitiesDomain.actions.readPerUser(user.id));
  }, []);

  const monthActivities = activities.filter(({ activityTime }) => {
    const [activityYear, activityMonth] = activityTime.substring(0, 7).split('-');
    return (
      parseInt(activityYear) === year &&
      parseInt(activityMonth) === month
    );
  });

  let renderedLog;
  if (monthActivities.length === 0) {
    renderedLog = <div>当月无工作记录</div>;
  } else {
    renderedLog = monthActivities.map((activity) => {
      return (
        <UserActivityCard
          key={activity.id}
          activity={activity}
        />
      );
    });
  }

  return (
    <div className="work-log">
      <DatePicker
        value={moment(`${year}/${month}`)}
        onChange={onMonthChange} picker="month"
      />
      {renderedLog}
    </div>
  )
}

export default WorkLog;
