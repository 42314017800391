
import React from 'react';
import {
  Form, Input, Button, Select
} from 'antd';

import BaseDrawer from 'modals/base-drawer';

function CustomFieldDrawer({
  customField,
  onSubmit,
  onCancel
}) {
  const actionTitle = customField ? '更新' : '创建';

  return (
    <BaseDrawer
      title={`${actionTitle}自定义字段`}
      onClose={onCancel}
    >
      <div className="drawer-custom-field-create-update">
        <CustomFieldForm
          customField={customField}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </div>
    </BaseDrawer>
  );
}

function CustomFieldForm({
  customField,
  onSubmit,
  onCancel
}) {
  const titleField = (
    <div>
      <div className="field-name">名称</div>
      <Form.Item
        name="title"
        rules={[
          { required: true, message: `请输入名称` }
        ]}
      >
        <Input />
      </Form.Item>
    </div>
  );

  const typeField = (
    <div>
      <div className="field-name">类型</div>
      <Form.Item
        name="type"
        rules={[
          { required: true, message: `请选择类型` }
        ]}
      >
        <Select
          disabled={customField}
        >
          <Select.Option value="STRING">
            文字
          </Select.Option>
          <Select.Option value="NUMBER">
            数字
          </Select.Option>
          <Select.Option value="DATE">
            日期
          </Select.Option>
        </Select>
      </Form.Item>
    </div>
  );

  const fieldNameField = (
    <div>
      <div className="field-name">API 名称</div>
      <Form.Item
        name="fieldName"
      >
        <Input
          disabled
        />
      </Form.Item>
    </div>
  );

  return (
    <div className="custom-field-form">
      <Form
        name="CustomField"
        initialValues={customField || { type: 'STRING', title: '' }}
        onFinish={onSubmit}
      >
        {titleField}
        {typeField}
        {customField && fieldNameField}
        <div className="submit">
          <Form.Item noStyle>
            <Button
              type="primary"
              htmlType="submit"
            >
              确认
            </Button>
          </Form.Item>
          <Button onClick={onCancel}>取消</Button>
        </div>
      </Form>
    </div>
  );
}

export default CustomFieldDrawer;