
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Modal,
} from 'antd';

import './index.scss';

class WrappedModal extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onCancelOverride: PropTypes.func,
    children: PropTypes.node.isRequired,
    title: PropTypes.string
  };

  render() {
    const {
      title,
      children,
      onCancel,
      onCancelOverride
    } = this.props;

    const modalCancel = onCancelOverride ? onCancelOverride : onCancel;

    return (
      <Modal
        visible={true}
        closable={false}
        onCancel={modalCancel}
        footer={null}
        getContainer={() => document.querySelector('#app')}
      >
        {title && <div className="title">{title}</div>}
        <div className="modal-body">
          {children}
        </div>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onCancel() {
      dispatch({ type: 'closeModal' });
    }
  }
}

export default connect(
  null,
  mapDispatchToProps
)(WrappedModal);
