
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import HealthDomain from 'domains/health';
import DistributionTrendChart from 'components/distribution-trend-chart';
import ChartToggleButtons from 'components/chart-toggle-button';
import ChartPanel from 'components/chart-panel';

import { healthGood, healthMedium, healthBad }  from 'theme/_color.scss';
import './index.scss';

function DistributionTrendPanel() {
  const handleToggle = function(event, value) {
    setChoice(value);
  }

  const [distributionTrend, setDistributionTrend] = useState(null);
  const [choice, setChoice] = useState('count');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(HealthDomain.actions.queryDistributionTrend((data) => {
      setDistributionTrend(data.distributionTrend);
    }));
  }, []);

  const toggleButtons = (
    <ChartToggleButtons 
      value={choice} 
      onChange={handleToggle}
      choices={{
        count: '数量',
        percent: '比例'
      }}
    />
  );

  return (
    <div className="component-advanced-health-distribution-trend-panel">
      <ChartPanel
        title="客户数量趋势"
        actions={toggleButtons}
        customHeight={296}
      >
        {
          !distributionTrend &&
          <div>加载中...</div>
        }
        {
          distributionTrend &&
          <div className="distribution-trend-panel-content">
            <DistributionTrendChart
              data={distributionTrend}
              valueName={choice}
              colorMapper={colorMapper}
            />
          </div>
        }
      </ChartPanel>
    </div>
  );
}

function colorMapper(health) {
  const alpha = 'FF';
  if (health === '良好') return healthGood + alpha;
  if (health === '中等') return healthMedium + alpha;
  return healthBad + alpha;
}

export default DistributionTrendPanel;
