
import React from 'react';
import { Link } from 'react-router-dom';

import './index.scss'

const typeToTitle = {
  'other': '其他',
  'suggestion': '意见与建议',
  'shoutout': '表扬',
  'complaint': '投诉'
};

function FeedbackCard({
  timestamp,
  type,
  customer, // { id, name }
  response // { question, answer } | { title, content }
}) {
  let content = <div>无信息</div>;
  if (type === 'surveyEntry') {
    content = (
      <div>
        <div>问题：{response.question}</div>
        <div>回答：{response.answer}</div>
      </div>
    );
  } else {
    content = (
      <div>
        <div>反馈编号: {response.id}</div>
        <div>标题：{response.title}</div>
        <div>正文：{response.content}</div>
      </div>
    );
  }

  return (
    <div className={`component-feedback-card type-${type}`}>
      <div>
        <div className="type">类型：{typeToTitle[type]}</div>
        <div>时间：{timestamp}</div>
        {
          customer.id &&
          <div className="link">客户：<Link to={idToLink(customer.id)}>{customer.name}</Link></div>
        }
        {
          !customer.id &&
          <div>客户：{customer.name}</div>
        }
      </div>
      <div>
        {content}
      </div>
    </div>
  );
}

function idToLink(id) {
  return `/customers-management/${id}`;
}

export default FeedbackCard;
