
import API from 'adapters/api';

/**
 * state structure:
 * {
 *   perUser: {
 *     [userId]: [
 *       {
 *         name: <String>,
 *         value: <Number>,
 *         goal: {target: <Number>, range: <Number>, measure: <Number> }
 *       }
 *     ]
 *   },
 *   forCustomer: {
 *     [customerId]: {
 *       [type]: {
 *         title: <String>,
 *         latest: <YYYY-MM-DD>,
 *         trend: [
 *           { timestamp, type, value }
 *         ]
 *       }
 *     }
 *   }
 * }
 */

const UserMetrics = {
  selectors: {},
  actions: {}
};

UserMetrics.selectors.perUser = (state) => state.userMetrics.perUser;
UserMetrics.getForCustomerSelector = (customerId) => {
  return (state) => state.userMetrics.forCustomer[customerId];
};

UserMetrics.actions.readPerUser = (userId) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `users/${userId}/metrics`,
        action: 'read'
      });

      if (status < 300) {
        const { metrics } = data;

        dispatch({
          type: 'userMetricsRefreshData',
          userId,
          data: metrics,
        });
      }

      return { status, data };
    }
  }
};

UserMetrics.actions.readForCustomer = (customerId) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `users-customer-metrics/${customerId}`,
        action: 'read'
      });

      if (status < 300) {
        const { metrics } = data;

        dispatch({
          type: 'userMetricsForCustomerRefreshData',
          customerId,
          data: metrics,
        });
      }

      return { status, data };
    }
  }
};

const reducers = {
  userMetrics(state, action) {
    if (!state) return {};

    if (action.type === 'init') {
      return {
        ...state,
        perUser: {},
        forCustomer: {}
      };
    }

    if (action.type === 'userMetricsRefreshData') {
      return {
        ...state,
        perUser: {
          ...state.perUser,
          [action.userId]: action.data
        }
      };
    } else if (action.type === 'userMetricsForCustomerRefreshData') {
      return {
        ...state,
        forCustomer: {
          ...state.forCustomer,
          [action.customerId]: action.data
        }
      };
    }

    return state;
  }
}

export { reducers };
export default UserMetrics;
