
import React, { useState } from 'react';
import classnames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import {
  Dropdown, Row, Col
} from 'antd';
import Paper from '@mui/material/Paper';

import './index.scss';

function NavMenu({ location, menuItemsData }) {
  const activePathRoot = `/${location.pathname.split('/')[1]}`;

  return (
    <div className="component-nav-menu">
      {
        menuItemsData.map((menuItemData, i) => {
          const active = isActive(activePathRoot, menuItemData);
          return (
            <MenuItem
              {...menuItemData}
              active={active}
              key={i}
            />
          );
        })
      }
    </div>
  );
}

function MenuItem({ name, icon, url, children, active }) {
  const [viewingChildren, setViewingChildren] = useState(false);

  const className = classnames(
    'menu-item',
    { active: active || viewingChildren },
    { 'icon-only': name === undefined }
  );

  function handleMenuOpenClose(isOpen) {
    setViewingChildren(isOpen);
  }

  if (url) {
    return (
      <Link to={url}>
        <div className={className}>
          {icon && <div className="icon">{icon}</div>}
          {name && <div>{name}</div>}
        </div>
      </Link>
    );
  }

  const dropdownMenu = (
    <Paper>
      <div
        className="component-nav-menu-dropdown"
        onClick={() => handleMenuOpenClose(false)}
      >
        <Row>
          {children.map(({ name, icon, url, description }) => {
            return (
              <Col key={url} span={12}>
                <Link to={url}>
                  <div className="menu-item">
                    <div className="title-row">
                      <div className="icon">{icon}</div>
                      <div>{name}</div>
                    </div>
                    <div className="description">{description}</div>
                  </div>
                </Link>
              </Col>
            );
          })}
        </Row>
      </div>
    </Paper>
  );

  return (
    <Dropdown
      overlay={dropdownMenu}
      trigger={['click']}
      visible={viewingChildren}
      onVisibleChange={handleMenuOpenClose}
    >
      <div className={className}>
        {icon && <div className="icon">{icon}</div>}
        <div>{name}</div>
      </div>
    </Dropdown>
  );
}

function isActive(activePathRoot, menuItemData) {
  let urls = [];
  if (menuItemData.url) {
    urls = [menuItemData.url];
  } else {
    urls = menuItemData.children.map((child) => child.url);
  }

  return urls.some(
    (url) => url.match(new RegExp(`^${activePathRoot}`))
  );
}

export default withRouter(NavMenu);
