
function extractSummary(trendData, key) {
  const lastRecord = trendData[trendData.length - 1];
  const totalCount = lastRecord === undefined ? 0 : lastRecord[key];
  const secondToLastRecord = trendData[trendData.length - 2];
  let delta = 0;
  if (secondToLastRecord !== undefined) {
    delta = totalCount / secondToLastRecord[key] - 1;
  }

  const trend = trendData.map(record => { 
    return { timestamp: record.timestamp, value: record[key]}
  });

  return {
    totalCount,
    delta,
    trend
  }
}

function extractMultiSummary(trendData, key, weights) {
  const orderedDates = trendData
    .map(record => record.timestamp)
    .sort((a, b) => a.replace('-', '') - b.replace('-', ''))
    .filter((v, index, self) => self.indexOf(v) === index);

  const lastDate = orderedDates.at(-1);
  const secondToLastDate = orderedDates.at(-2);

  function computeAvgHealth(timestamp) {
    let sum = 0;
    let countSum = 0;
    for (const bucket in weights) {
      const count = trendData.find(record => record.timestamp === timestamp && record[key] === bucket).count;
      const value = count * weights[bucket];
      countSum += count;
      sum += value;
    }
    return Math.floor(sum / countSum);
  }
  
  const avgHealth = computeAvgHealth(lastDate);
  let delta = 0;
  if (secondToLastDate !== undefined) {
    const lastAvgHealth = computeAvgHealth(secondToLastDate);
    delta = avgHealth / lastAvgHealth - 1;
  }

  const trend = orderedDates.map(timestamp => ({ timestamp, value: computeAvgHealth(timestamp) }));
  return {
    avgHealth,
    delta,
    trend
  }
}

function extractNpsSummary(npsTrendData) {
  const orderedDates = npsTrendData
    .map(record => record.timestamp)
    .sort((a, b) => a.replace('-', '') - b.replace('-', ''))
    .filter((v, index, self) => self.indexOf(v) === index);

  const lastDate = orderedDates.at(-1);
  const secondToLastDate = orderedDates.at(-2);

  function computeNpsRecommendationRatio(timestamp) {
    const recommendationCount = npsTrendData.find(record => record.timestamp === timestamp && record.health === '会推荐').count;
    const totalCount = npsTrendData
      .filter(record => record.timestamp === timestamp)
      .map(record => record.count)
      .reduce((prev, cur) => prev + cur);
    
    return Math.floor(recommendationCount / totalCount * 100);
  }
  
  const recommendationRatio = computeNpsRecommendationRatio(lastDate);
  let delta = 0;
  if (secondToLastDate !== undefined) {
    const lastRecommendationRatio = computeNpsRecommendationRatio(secondToLastDate);
    delta = recommendationRatio / lastRecommendationRatio - 1;
  }

  const trend = orderedDates.map(timestamp => ({ timestamp, value: computeNpsRecommendationRatio(timestamp) }));
  return {
    recommendationRatio,
    delta,
    trend
  }
}

// TODO: move this to domain
// TODO: this function modifies params, refactor into pure function
function insertRatioTrend(trendData) {
  function computeRatioTrend(trend) {
    return trend.map(record => {
      const timestamp = record.timestamp;
      const sumOfDay = trend.filter(r => r.timestamp === timestamp).reduce((prev, cur) => prev + cur.count, 0);
      return {
        ...record,
        count: Math.round(record.count / sumOfDay * 100)
      };
    });
  }
  
  trendData.percent = computeRatioTrend(trendData.total);
  trendData.arrPercent = computeRatioTrend(trendData.arr);
}

export {
  extractSummary,
  extractMultiSummary,
  extractNpsSummary,
  insertRatioTrend
};
