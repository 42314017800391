
function formatTime(datetime) {
  const [date, time] = datetime.split('T');
  return date + ' ' + time.slice(0,8);
}

function formatDate(datetime) {
  if (!datetime) return '';
  const [date,] = datetime.split('T');
  return date;
}

export {
  formatTime,
  formatDate
}
