
import React from 'react';
import {
  Button
} from 'antd';

import BaseModal from 'modals/base-modal';

import './deletion-modal.scss';

function DeletionModal({
  survey,
  onCancel,
  onSubmit
}) {
  const status = {
    init: '初始',
    enabled: '激活',
    disabled: '未激活'
  }[survey.status];

  return (
    <BaseModal
      title={`确定删除反馈问题？`}
      onCancelOverride={onCancel}
    >
      <div className="modal-surveys-deletion">
        <div className="content">
          <div>问题：{survey.question}</div>
          <div>状态：{status}</div>
        </div>
        <div className="submit">
          <Button type="primary" danger onClick={onSubmit}>
            确定
          </Button>
          <Button onClick={onCancel}>
            取消
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}

export default DeletionModal;
