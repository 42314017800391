
import React, { useEffect, useRef } from 'react';
import { Chart } from '@antv/g2';

import themeColor from 'theme/_color.scss';
import './index.scss';

function RingStats({
  title, text, distribution
}) {
  function createChart(container, title, text, data) {
    const chart = new Chart({
      container,
      autoFit: true
    });

    chart.data(data);

    chart.scale('percent', {
      formatter: (val) => {
        return `${Math.round(val)}%`;
      }
    });

    chart.coordinate('theta', {
      radius: 0.75,
      innerRadius: 0.85,
    });

    chart
      .interval()
      .adjust('stack')
      .position('percent')
      .color('category', (val) => {
        let color = themeColor.healthGood;
        if (val === '危险') {
          color = themeColor.healthBad;
        } else if (val === '亚健康') {
          color = themeColor.healthMedium;
        } else if (val === '无数据') {
          color = '#D0D0D0';
        }
        return color;
      })
      .label('percent', (percent) => {
        return {
          offset: 20,
          fontSize: 32,
          content: (data) => {
            return `${data.category}: ${Math.round(percent)}%`;
          },
        };
      });

    let fontSize = 54;
    let color = themeColor.healthGood;
    if (text === '暂无数据') {
      color = '#D0D0D0';
      fontSize = 26;
    } else if (text < 50) {
      color = themeColor.healthBad;
    } else if (text < 80) {
      color = themeColor.healthMedium;
    }

    chart
      .annotation()
      .text({
        position: ['50%', '50%'],
        content: title,
        style: {
          fontSize: 20,
          fill: '#8c8c8c',
          textAlign: 'center',
        },
        offsetY: -25,
      })
      .text({
        position: ['50%', '50%'],
        content: text,
        style: {
          fontSize,
          fill: color,
          textAlign: 'center',
        },
        offsetX: 0,
        offsetY: 25,
      })

    chart.legend(false);

    chart.tooltip({
      marker: false
    });

    chart.render();
  }

  let data = Object.keys(distribution).map((category) => {
    return {
      category: {
        good: '健康',
        medium: '亚健康',
        bad: '危险'
      }[category],
      percent: distribution[category].percent
    };
  });
  if (data.length === 0) {
    data = [{ category: '无数据', percent: 100 }];
  }

  const displayText = text ? text : '暂无数据';

  const containerRef = useRef(null);

  useEffect(() => {
    containerRef.current.innerHTML = '';
    createChart(containerRef.current, title, displayText, data);
  }, [text, distribution]);

  return <div className="component-ring-stats" ref={containerRef}></div>;
}

export default RingStats;
