
import React from 'react';
import {
  ArrowUpOutlined,
  ArrowDownOutlined
} from '@ant-design/icons';

import './index.scss';

function ScoreIndicator({
  score,
  trend,
  size = 'large'
}) {
  const direction = getDirection(trend);
  let directionArrow;
  if (direction === 'up') {
    directionArrow = <ArrowUpOutlined />;
  } else if (direction === 'down') {
    directionArrow = <ArrowDownOutlined />;
  } else {
    directionArrow = <div>=</div>;
  }

  let health = 'good';
  if (score < 50) {
    health = 'bad';
  } else if (score < 80) {
    health = 'medium';
  }

  return (
    <div className={`score-indicator ${size}`}>
      <div className={`number ${health}`}>{Math.round(score)}</div>
      <div className={`arrow ${direction}`}>{directionArrow}</div>
    </div>
  );
}

function getDirection(trend) {
  const len = trend.length;
  if (len < 2) return 'neutral';

  const last = trend[len - 1].value;
  const first = trend[0].value;

  if (last > first) {
    return 'up';
  } else if (last < first) {
    return 'down';
  } else {
    return 'neutral';
  }
}

export default ScoreIndicator;
