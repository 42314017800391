
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button
} from 'antd';

import { history } from 'domains/history';
import CustomersDomain from 'domains/customers';
import BaseModal from '../base-modal';

class CustomersDelete extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    customer: PropTypes.object.isRequired,
    redirect: PropTypes.bool
  };

  handleSubmit = () => {
    const { customer, onSubmit, redirect } = this.props;
    onSubmit(customer.id, redirect);
  };

  render() {
    const {
      onCancel,
      customer
    } = this.props;

    return (
      <BaseModal title={`确定删除客户${customer.name}？`}>
        <CustomersDeleteForm
          onCancel={onCancel}
          onSubmit={this.handleSubmit}
        />
      </BaseModal>
    )
  }
}

function mapStateToProps(state) {
  const { customer, redirect } = state.modal.context;

  return {
    customer,
    redirect
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onCancel() {
      dispatch({ type: 'closeModal' });
    },
    onSubmit(id, redirect) {
      dispatch({
        type: 'api',
        request: () => CustomersDomain.delete(id),
        success() {
          dispatch({ type: 'closeModal' });
          dispatch({
            type: 'customersDelete',
            id
          });
          if (redirect) history.push('/customers');
        }
      });
    }
  };
}

function CustomersDeleteForm({
  onCancel,
  onSubmit
}) {
  return (
    <div className="customers-delete-form">
      <Button
        type="primary"
        htmlType="submit"
        onClick={onSubmit}
      >
        提交
      </Button>
    <Button onClick={onCancel}>取消</Button>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomersDelete);
