
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form,
  Input,
  Select,
  Button,
  Row, Col
} from 'antd';
import {
  ShopOutlined
} from '@ant-design/icons';

import Stores from 'domains/stores';
import BaseModal from '../base-modal';

import './index.scss';

const Option = Select.Option;

class StoresCreate extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired
  };

  render() {
    const { onCancel, onSubmit, users } = this.props;

    return (
      <BaseModal title="创建门店">
        <StoresCreateForm
          onCancel={onCancel}
          onSubmit={onSubmit}
          users={users}
        />
      </BaseModal>
    )
  }
}

function mapStateToProps(state) {
  return {
    users: state.users
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onCancel() {
      dispatch({ type: 'closeModal' });
    },
    onSubmit(store) {
      dispatch({
        type: 'api',
        request: () => Stores.create(store),
        success(data) {
          dispatch({ type: 'storesCreate', store: data.store })
          dispatch({ type: 'closeModal' });
        }
      })
    }
  };
}

function StoresCreateForm({
  onCancel,
  onSubmit,
  users
}) {
  const nameField = (
    <div>
      <div className="label">名称</div>
      <Form.Item
        name="name"
        rules={[
          { required: true, message: `请输入门店名称` }
        ]}
      >
        <Input
          prefix={<ShopOutlined />}
        />
      </Form.Item>
    </div>
  );

  const statusField = (
    <div>
      <div className="label">状态</div>
      <Form.Item
        name="status"
      >
        <Select>
          <Select.Option value="active">
            开业状态
          </Select.Option>
          <Select.Option value="inactive">
            未开业状态
          </Select.Option>
        </Select>
      </Form.Item>
    </div>
  );

  const superiorManagerField = (
    <div>
      <div className="label">所属区域经理</div>
      <Form.Item
        name="superiorManagerId"
      >
        <Select
        >
          {users.map((user) => (
            <Option value={user.id}>{user.name}</Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );

  const managerField = (
    <div>
      <div className="label">店长</div>
      <Form.Item
        name="managerId"
      >
        <Select
        >
          {users.map((user) => (
            <Option value={user.id}>{user.name}</Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );

  const provinceField = (
    <div>
      <div className="label">省</div>
      <Form.Item
        name="province"
        rules={[
          { required: true, message: `请输入省` }
        ]}
      >
        <Input />
      </Form.Item>
    </div>
  );

  const cityField = (
    <div>
      <div className="label">市</div>
      <Form.Item
        name="city"
        rules={[
          { required: true, message: `请输入市` }
        ]}
      >
        <Input />
      </Form.Item>
    </div>
  );

  const districtField = (
    <div>
      <div className="label">区</div>
      <Form.Item
        name="district"
        rules={[
          { required: true, message: `请输入区` }
        ]}
      >
        <Input />
      </Form.Item>
    </div>
  );

  const streetField = (
    <div>
      <div className="label">详细地址</div>
      <Form.Item
        name="street"
        rules={[
          { required: true, message: `请输入详细地址` }
        ]}
      >
        <Input />
      </Form.Item>
    </div>
  );

  return (
    <div className="stores-create-form">
      <Form
        name="StoresCreate"
        initialValues={{ status: 'active' }}
        onFinish={onSubmit}
      >
        {nameField}
        {statusField}
        {superiorManagerField}
        {managerField}
        <Row gutter={10}>
          <Col span={8}>{provinceField}</Col>
          <Col span={8}>{cityField}</Col>
          <Col span={8}>{districtField}</Col>
        </Row>
        {streetField}
        <div className="submit">
          <Form.Item noStyle>
            <Button
              type="primary"
              htmlType="submit"
            >
              提交
            </Button>             
          </Form.Item>
          <Button onClick={onCancel}>取消</Button>
        </div>
      </Form>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoresCreate);
