
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, DatePicker, Select, Input, Button } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';

import MetadataDomain from 'domains/metadata';
import UserActivitiesDomain from 'domains/user-activities';

moment.locale('zh-cn');
const { TextArea } = Input;
const { Option } = Select;
const { Item } = Form;

function CreateUserActivity({
  customer
}) {
  function onFinish(value) {
    value.activityTime = value.datetime.format("YYYY-MM-DD HH:mm:ss");
    dispatch(UserActivitiesDomain.actions.create(customer.id, customer.name, value));
  }

  const dispatch = useDispatch();

  const selector = MetadataDomain.getFieldsMetadataSelector({ objectId: 2 });
  const fieldsMetadata = useSelector(selector);

  return (
    <div className='create-activity'>
      <Form
        name="create-activity"
        labelCol={{ flex: '110px' }}
        onFinish={onFinish}
        initialValues={{
          type: '拜访'
        }}
        colon={false}
      >
        <Item label="时间" name="datetime" rules={[{ required: true }]}>
          <DatePicker style={{ width: 200 }} showTime={{ format: 'HH:mm' }} />
        </Item>
        <Item label="类别" name="type" rules={[{ required: true }]}>
          <Select style={{ width: 200 }}>
            {
              fieldsMetadata.type.options.map(({ value, title }) => {
                return <Option key={value} value={value}>{title}</Option>
              })
            }
          </Select>
        </Item>
        <Item label="标题" name="title" rules={[{ required: true }]}>
          <Input />
        </Item>
        <Item label="内容" name="content" rules={[{ required: true }]}>
          <TextArea rows={6} maxLength={1000} showCount/>
        </Item>
        <Item label=" ">
          <Button type="primary" htmlType="submit">
            添加记录
          </Button>
        </Item>
      </Form>
    </div>
  )
}

export default CreateUserActivity;
