
import React from 'react';

import Panel from 'components/panel';
import TrendChart from 'components/trend-chart';

import './index.scss';

function ActivityLevel({
  title,
  data
}) {
  let content;

  if (!data || data.length === 0) {
    content = ''
  } else {
    content = (
      <TrendChart
        name="事件数量"
        data={data}
      />
    );
  }

  return (
    <Panel title={title} style={{ height: 240 }}>
      <div className="activity-level-chart">
        {content}
      </div>
    </Panel>
  );
}

export default ActivityLevel;
