
import API from 'adapters/api';

const Rbac = {};

Rbac.createRole = async (role) => {
  return await API.request({
    resource: 'roles',
    action: 'create',
    data: role
  });
};

Rbac.updateRole = async (role) => {
  return await API.request({
    resource: 'roles',
    action: 'update',
    data: role
  });
};

Rbac.deleteRole = async (id) => {
  return await API.request({
    resource: 'roles',
    action: 'delete',
    data: { id }
  });
};

const reducers = {
  usersRoles(state, action) {
    if (state === undefined) return null;

    if (action.type === 'usersRolesAdd') {
      return [
        ...state,
        action.userRole
      ];
    } else if (action.type === 'usersRolesRemove') {
      return state.filter((userRole) => {
        return (
          userRole.userId !== action.userId &&
          userRole.roleId !== action.roleId
        );
      });
    }

    return state;
  },
  roles(state, action) {
    if (state === undefined) return null;

    if (action.type === 'rolesCreate') {
      return [
        ...state,
        action.role
      ];
    } else if (action.type === 'rolesUpdate') {
      return state.map((role) => {
        if (role.id === action.role.id) {
          return Object.assign({}, role, action.role);
        } else {
          return role;
        }
      });
    } else if (action.type === 'rolesDelete') {
      return state.filter((role) => role.id !== action.id);
    }

    return state;
  }
};

export { reducers };
export default Rbac;
