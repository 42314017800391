
import React from 'react';

import './object-management.scss';

function ObjectManagement({
  objectMetadata
}) {
  const { objectName, title, description } = objectMetadata;

  return (
    <div className="object-management">
      <div className="title">{title}</div>
      <div className="object-name">API 名称：{objectName}</div>
      <div className="description">{description}</div>
    </div>
  );
}

export default ObjectManagement;
