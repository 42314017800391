 
import API from 'adapters/api';

/**
 * state structure:
 * {
 *   forUser: {
 *     [userId]: [
 *       {
 *         name: <String>,
 *         value: <Number>
 *       }
 *     ]
 *   }
 * }
 */

const AggregateCustomerMetrics = {
  selectors: {},
  actions: {}
};

AggregateCustomerMetrics.selectors.getForUserSelector = (userId) => {
  return (state) => state.aggregateCustomerMetrics.forUser[userId];
};

AggregateCustomerMetrics.actions.getForUser = (userId) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `aggregate-customer-metrics/for-user`,
        action: 'read',
        params: { userId }
      });

      if (status < 300) {
        const { aggregateCustomerMetrics } = data;

        dispatch({
          type: 'aggregateCustomerMetricsForUserRefreshData',
          userId,
          data: aggregateCustomerMetrics,
        });
      }

      return { status, data };
    }
  }
};

const reducers = {
  aggregateCustomerMetrics(state, action) {
    if (!state) return {};

    if (action.type === 'init') {
      return {
        ...state,
        forUser: {}
      };
    }

    if (action.type === 'aggregateCustomerMetricsForUserRefreshData') {
      return {
        ...state,
        forUser: {
          ...state.forUser,
          [action.userId]: action.data
        }
      };
    }

    return state;
  }
}

export { reducers };
export default AggregateCustomerMetrics;
