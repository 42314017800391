
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd';
import classnames from 'classnames';

import CustomerMetricsDomain from 'domains/customer-metrics';
import MetricPanel from 'components/metric-panel';
import ListItemActions from 'components/list-item-actions';
import ManualMetricModal from './manual-metric-modal';

import './index.scss';

function Metrics({
  customer
}) {
  const dispatch = useDispatch();

  const metrics = useSelector(CustomerMetricsDomain.selectors.perCustomer)[customer.id];
  const metricDefinitions = useSelector(CustomerMetricsDomain.selectors.definitions);

  const pinnedDefinitions = (metricDefinitions || []).filter((definition) => definition.pinned);
  const unpinnedDefinitions = (metricDefinitions || []).filter((definition) => !definition.pinned);
  const unpinnedNoSectionDefinitions = [];
  const unPinnedDefinitionsBySection = {};
  unpinnedDefinitions.forEach((definition) => {
    const { section } = definition;

    if (section === undefined || section === null) {
      unpinnedNoSectionDefinitions.push(definition);
      return;
    }

    if (!unPinnedDefinitionsBySection[section]) {
      unPinnedDefinitionsBySection[section] = [];
    }
    unPinnedDefinitionsBySection[section].push(definition);
  });

  useEffect(() => {
    dispatch(CustomerMetricsDomain.actions.readPerCustomer(customer.id));
  }, []);

  function renderMetric(metricDefinition) {
    const matchedMetric = metrics.find(metric => metric.definitionId === metricDefinition.id);

    return (
      <MetricPanelContainer
        key={metricDefinition.id}
        customer={customer}
        definition={metricDefinition}
        metric={matchedMetric}
      />
    );
  }

  return (
    <div className="metrics">
      {
        metrics && pinnedDefinitions.length > 0 &&
        <>
          <div className="divider">置顶指标</div>
          {
            <Row gutter={[10, 10]}>
              {pinnedDefinitions.map(renderMetric)}
            </Row>
          }
        </>
      }
      {
        metrics &&
        Object.keys(unPinnedDefinitionsBySection)
          .sort()
          .map((section, i) => {
            const definitions = unPinnedDefinitionsBySection[section];
            const definitionsContent = (
              <Row gutter={[10, 10]}>
                {definitions.map(renderMetric)}
              </Row>
            );
            return (
              <div key={i}>
                <div className="divider">{section}</div>
                {definitionsContent}
              </div>
            );
          })
      }
      {
        metrics && unpinnedNoSectionDefinitions.length > 0 &&
        <>
          <div className="divider">无分类指标</div>
          {
            <Row gutter={[10, 10]}>
              {unpinnedNoSectionDefinitions.map(renderMetric)}
            </Row>
          }
        </>
      }
    </div>
  );
}

function MetricPanelContainer({
  customer, definition, metric
}) {
  function handleShowModal() {
    setShowModal(true);
  }

  function handleHideModal() {
    setShowModal(false);
  }

  function handleSubmit(v) {
    dispatch(CustomerMetricsDomain.actions.recordManualMetric({
      metric: v,
      success: handleHideModal
    }));
  }

  let value = undefined;
  if (metric) {
    value = metric.value;
  }

  let trend = value ? [{
    timestamp: (new Date()).toISOString().substring(0, 10),
    value
  }] : [];
  if (metric && metric.timeSeries && metric.timeSeries.length > 0) {
    trend = metric.timeSeries.map((datum) => {
      return {
        ...datum,
        value: parseFloat(datum.value)
      };
    });
  }

  const { name, goal } = definition;

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  let actions = null;
  if (definition.type === 'MANUAL') {
    actions = (
      <ListItemActions
        actions={[
          { name: '手动记录', onClick: handleShowModal }
        ]}
      />
    )
  }

  const className = classnames(
    'definition',
    { pinned: definition.pinned }
  );

  return (
    <Col span={6}>
      <div className={className}>
        <MetricPanel
          title={name}
          text={value}
          actions={actions}
          trend={trend}
          trendType="line"
          annotation={goal}
        />
        {
          showModal &&
          <ManualMetricModal
            customerId={customer.id}
            definition={definition}
            onCancel={handleHideModal}
            onSubmit={handleSubmit}
          />
        }
      </div>
    </Col>
  )
}

export default Metrics;
