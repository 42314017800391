
import API from 'adapters/api';

/**
 * state structure:
 * {
 *   perCustomer: {
 *     [customerId]: [
 *       {Note}
 *     ]
 *   }
 * }
 */

const CustomerNotes = {
  selectors: {},
  actions: {}
};

CustomerNotes.selectors.perCustomer = (state) => state.customerNotes.perCustomer;

CustomerNotes.actions.createNote = (note, success) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `customer-notes`,
        action: 'create',
        data: note
      });

      if (status < 300) {
        dispatch({ 
          type: 'notesPerCustomerAdd',
          customerId: note.customerId,
          data: { ...note, ...data.customerNote }
        });

        success && success();
      }

      return { status, data };
    }
  };
};

CustomerNotes.actions.getNotes = (customerId) => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `customer-notes?customerId=${customerId}`,
        action: 'read'
      });

      if (status < 300) {
        dispatch({ 
          type: 'notesPerCustomerRefershData',
          customerId,
          data: data.customerNotes
        });
      }

      return { status, data };
    }
  };
};

CustomerNotes.actions.delete = (note, success) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `customer-notes`,
        action: 'delete',
        data: { id: note.id }
      });

      if (status < 300) {
        dispatch({ 
          type: 'notesPerCustomerRemove',
          customerId: note.customerId,
          id: note.id
        });

        success && success();
      }

      return { status, data };
    }
  };
};

CustomerNotes.actions.update = (note, success) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `customer-notes`,
        action: 'update',
        data: note
      });

      if (status < 300) {
        dispatch({ 
          type: 'notesPerCustomerUpdate',
          data: note
        });

        success && success();
      }

      return { status, data };
    }
  };
};

const reducers = {
  customerNotes(state, action) {
    if (state === undefined) return {
      perCustomer: {}
    };

    if (action.type === 'notesPerCustomerRefershData') {
      return {
        ...state,
        perCustomer: {
          ...state.perCustomer,
          [action.customerId]: action.data
        }
      };
    } else if (action.type === 'notesPerCustomerAdd') {
      return {
        ...state,
        perCustomer: {
          ...state.perCustomer,
          [action.customerId]: [
            ...state.perCustomer[action.customerId],
            action.data
          ]
        }
      }
    } else if (action.type === 'notesPerCustomerRemove') {
      return {
        ...state,
        perCustomer: {
          ...state.perCustomer,
          [action.customerId]: state.perCustomer[action.customerId].filter((note) => {
            return note.id !== action.id
          })
        }
      }
    } else if (action.type === 'notesPerCustomerUpdate') {
      return {
        ...state,
        perCustomer: {
          ...state.perCustomer,
          [action.data.customerId]: state.perCustomer[action.data.customerId].map((note) => {
            if (note.id === action.data.id) {
              return { ...note, ...action.data };
            } else {
              return note;
            }
          })
        }
      }
    }

    return state;
  }
}

export { reducers };
export default CustomerNotes;
