
const UI = {};

const reducers = {
  spin(state, action) {
    if (state === undefined) return 0;
  
    if (action.type === 'startSpin') {
      return state + 1;
    } else if (action.type === 'stopSpin') {
      return state - 1;
    }
  
    return state;
  },
  modal(state, action) {
    if (state === undefined) return null;
  
    if (action.type === 'modal') {
      return {
        name: action.name,
        context: action.context || {}
      }
    } else if (action.type === 'closeModal') {
      return null;
    }
  
    return state;
  },
  alert(state, action) {
    if (state === undefined) return { idCounter: 0, data: [] };
  
    if (action.type === 'alert') {
      const id = state.idCounter;

      return {
        ...state,
        idCounter: id + 1,
        data: [...state.data, {
          id,
          type: action.alert.type,
          message: action.alert.message
        }]
      };
    } else if (action.type === 'closeAlert') {
      return {
        ...state,
        data: state.data.filter((datum) => datum.id !== action.id)
      };
    } else if (action.type === 'closeAllAlert') {
      return {
        ...state,
        data: []
      };
    }
   
    return state;
  }
};

export { reducers };
export default UI;
