
import React from 'react';
import {
  Row, Col
} from 'antd';

import Panel from 'components/panel';
import InPlaceEdit from 'components/in-place-edit';

function CompanyInfoPanel({
  customer,
  onUpdate
}) {
  return (
    <div className="company-info-panel">
      <Panel title="企业信息">
        <Row>
          <Col span={12}>
            <InPlaceEdit
              title="行业"
              value={customer.industry}
              type="text"
              onSubmit={(v) => onUpdate('industry', v)}
            />
          </Col>
          <Col span={12}>
            <InPlaceEdit
              title="分类"
              value={customer.category}
              type="text"
              onSubmit={(v) => onUpdate('category', v)}
            />
          </Col>
          <Col span={12}>
            <InPlaceEdit
              title="地区"
              value={customer.region}
              type="text"
              onSubmit={(v) => onUpdate('region', v)}
            />
          </Col>
        </Row>
      </Panel>
    </div>
  )
}

export default CompanyInfoPanel;
