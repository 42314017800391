
import API from 'adapters/api';

/**
 * Sample Input
{
    "type": "condition",
    "attribute": "",
    "condition": "",
    "value": "Root",
    "children": [
        {
            "type": "condition",
            "attribute": "渠道",
            "condition": "in",
            "value": [
                "SEM",
                "Retargeting"
            ],
            "children": [
                {
                    "type": "result",
                    "value": "Predict: 续费"
                }
            ]
        },
        {
            "type": "condition",
            "attribute": "渠道",
            "condition": "not in",
            "value": [
                "SEM",
                "Retargeting"
            ],
            "children": [
                {
                    "type": "condition",
                    "attribute": "3",
                    "condition": "<=",
                    "value": "1.5",
                    "children": [
                        {
                            "type": "result",
                            "value": "Predict: 流失"
                        }
                    ]
                },
                {
                    "type": "condition",
                    "attribute": "3",
                    "condition": ">",
                    "value": "1.5",
                    "children": [
                        {
                            "type": "result",
                            "value": "Predict: 续费"
                        }
                    ]
                }
            ]
        }
    ]
}
  Sample Output:
  {
    nodes: 
    [
        {
            "id": "1",
            "label": "渠道"
        },
        {
            "id": "2",
            "label": "Predict: 续费"
        },
        {
            "id": "4",
            "label": "近 7 天使用功能 A 次数"
        },
        {
            "id": "5",
            "label": "Predict: 流失"
        },
        {
            "id": "7",
            "label": "Predict: 续费"
        }
    ],
    edges:
    [
        {
            "source": "1",
            "target": "2",
            "data": {
                "attribute": "渠道",
                "condition": "in {SEM,Retargeting}"
            }
        },
        {
            "source": "1",
            "target": "4",
            "data": {
                "attribute": "渠道",
                "condition": "not in {SEM,Retargeting}"
            }
        },
        {
            "source": "4",
            "target": "5",
            "data": {
                "attribute": "近 7 天使用功能 A 次数",
                "condition": "<= 0.5"
            }
        },
        {
            "source": "4",
            "target": "7",
            "data": {
                "attribute": "近 7 天使用功能 A 次数",
                "condition": "> 0.5"
            }
        }
    ]
  }
*/

function extractAttributeValue(attribute, customer, customerMetrics) {
  if (!customer || !customerMetrics) {
    return undefined;
  }
  let attributeValue;
  if (attribute.type === 'name') {
    attributeValue = customer[attribute.value];
    if (attributeValue === null) {
      attributeValue = '无数值';
    }
  } else if (attribute.type === 'id') {
    const customAttribute = customerMetrics.find(metric => metric.definitionId === attribute.value);
    if (customAttribute) {
      attributeValue = customAttribute.value;
    }
  }
  return attributeValue;
}

function validateAttributeCondition(attribute, condition, value, customer, customerMetrics) {
  const attributeValue = extractAttributeValue(attribute, customer, customerMetrics);

  if (attributeValue !== undefined) {
    if (condition === 'in') {
      return value.includes(attributeValue);
    } else if (condition === 'not in') {
      return !value.includes(attributeValue);
    } else if (condition === '>') {
      return attributeValue > parseFloat(value);
    } else if (condition === '<') {
      return attributeValue < parseFloat(value);
    } else if (condition === '>=') {
      return attributeValue >= parseFloat(value);
    } else if (condition === '<=') {
      return attributeValue <= parseFloat(value);
    }
  } else {
    return false;
  }
}

function attributeToString(customerMetricsDefinitions, attribute) {
  if (attribute.type === 'id') {      
    return customerMetricsDefinitions
      .find(definition => definition.id === attribute.value).name;
  }
  return attribute.value;
}

const CUSTOMER_ATTRIBUTE_NAME_MAPPING = {
  'channel': '渠道',
  'category': '类别',
  'nps': 'nps'
  // TODO: add more
}

const CONDITION_NAME_MAPPING = {
  'in': {
    'multi': '属于', 
    'single': '等于'
  },
  'not in': {
    'multi': '不属于',
    'single': '不等于'
  },
  '>=': {
    'multi': '≥',
    'single': '≥'
  },
  '<=': {
    'multi': '≤',
    'single': '≤'
  }
}

function getCustomFieldName(label, customFieldsMetadata) {
  for (const metaDataKey in customFieldsMetadata) {
    const metaData = customFieldsMetadata[metaDataKey];
    if (metaData[label] !== undefined) {
      return metaData[label]['title'];
    }
  }

  return undefined;
}

function translate(nodes, edges, customeFieldsMetadata) {
  const translatedNodes = nodes.map(node => {
    let label = node.label;
    if (CUSTOMER_ATTRIBUTE_NAME_MAPPING[label] !== undefined) {
      label = CUSTOMER_ATTRIBUTE_NAME_MAPPING[label];
    } else {
      const customFieldLabel = getCustomFieldName(label, customeFieldsMetadata);
      if (customFieldLabel !== undefined) {
        label = customFieldLabel;
      }
    }

    return {
      ...node,
      label
    }
  })

  const translatedEdges = edges.map(edge => {
    let data = edge.data;
    let condition = data.condition;
    if (CONDITION_NAME_MAPPING[condition] !== undefined) {
      if (Array.isArray(data.value) && data.value.length === 1) {
        condition = CONDITION_NAME_MAPPING[condition]['single']
      } else {
        condition = CONDITION_NAME_MAPPING[condition]['multi'];
      }
    }
    data = {
      ...edge.data,
      condition
    }
    return {
      ...edge,
      data
    }
  })

  return {
    nodes: translatedNodes,
    edges: translatedEdges
  }
}

function decisionTreeStructureVisualization(decisionTree, customerMetricsDefinitions, customeFieldMetaData, customer, customerMetrics) {
  if (!decisionTree) {
    return {}
  }

  // global variables that will be set in the recursive processBranch
  const nodes = [];
  const edges = [];
  let currentNodeId = 0;
  let nodesOnPredictionPath = [];
  let edgesOnPredictionPath = [];
  let predictChurn = true;
  
  processBranch(decisionTree, undefined, true);

  for (const node of nodesOnPredictionPath) {
    node.predictChurn = predictChurn;
  }

  for (const edge of edgesOnPredictionPath) {
    edge.predictChurn = predictChurn;
  }

  return translate(nodes, edges, customeFieldMetaData);
  
  function processBranch(branch, parentNode, parentIsInValidPath) {

    let isOnPredictionPath = false;
    if (parentNode) {
      const {type, attribute, condition, value} = branch;
      const edge = {
        source: parentNode.id,
        target: currentNodeId.toString(),
        data: {
          attribute: attributeToString(customerMetricsDefinitions, attribute),
          condition,
          value
        }
      }
      if (parentIsInValidPath) {
        if (validateAttributeCondition(attribute, condition, value, customer, customerMetrics)) {
          isOnPredictionPath = true;
          edgesOnPredictionPath.push(edge);
        }
      }
      parentNode.attributeValue = extractAttributeValue(attribute, customer, customerMetrics);
      parentNode.attributeValues = value;

      edges.push(edge);
    } else {
      isOnPredictionPath = customer === undefined ? false : parentIsInValidPath;
    }

    // assume all the childrens are of the same type
    const nextLevelType = branch.children[0].type;

    if (nextLevelType === 'condition') {
      const nextLevelAttribute = branch.children[0].attribute;
      const node = {
        id: currentNodeId.toString(),
        label: attributeToString(customerMetricsDefinitions, nextLevelAttribute),
        parentAttributeValues: branch.value
      }
      currentNodeId++;
      nodes.push(node);
      if (isOnPredictionPath) {
        nodesOnPredictionPath.push(node);
      }

      for (const child of branch.children) {
        processBranch(child, node, isOnPredictionPath);
      }
    } else if(nextLevelType === 'result') {
      const label = branch.children[0].value.replace('Predict: ', '预测');
      const node = {
        id: currentNodeId.toString(),
        label,
      }
      nodes.push(node);
      if (isOnPredictionPath) {
        nodesOnPredictionPath.push(node);
        predictChurn = label.includes('流失');
      }
      currentNodeId++;
    }
  }
}

/**
 * state structure:
 * {
     'decisionTree': {id, createdAt, sampleSize, decisionTree, visualizedTree},
 * }
 */

const MachineLearning = {
  selectors: {},
  actions: {}
};

MachineLearning.selectors.decisionTree = (state) => state.machineLearning.decisionTree;
MachineLearning.selectors.customersStatusChangeSnapshot = (state) => state.machineLearning.customersStatusChangeSnapshot;

MachineLearning.applyDecisionTreeForCustomer = (decisionTree, customerMetricsDefinitions, customFieldMetaData, customer, customerMetrics) => {
  if (!customer || !customerMetrics) {
    return undefined;
  }
  return decisionTreeStructureVisualization(decisionTree, customerMetricsDefinitions, customFieldMetaData, customer, customerMetrics)
}

MachineLearning.actions.getDecisionTree = () => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'machine-learning/decision-tree/structure',
        action: 'read'
      });

      if (status < 300) {
        const { decisionTree } = data;
        decisionTree.visualizedTree = decisionTreeStructureVisualization(decisionTree.decisionTree, state.customerMetrics.definitions, state.metadata.customFields.customers);
        dispatch({
          type: 'setDecisionTree',
          decisionTree
        });
      }

      return { status, data };
    }
  }
};

MachineLearning.actions.getCustomersStatusChangeSnapshot = () => {
  return {
    type: 'query',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: 'machine-learning/customers-status-change-snapshot',
        action: 'read'
      });

      if (status < 300) {
        const { customersStatusChangeSnapshot } = data;
        dispatch({
          type: 'customersStatusChangeSnapshotRefresh',
          customersStatusChangeSnapshot
        });
      }

      return { status, data };
    }
  }
};

const reducers = {
  machineLearning(state, action) {
    if (state === undefined) {
      return {
        decisionTree: {},
        customersStatusChangeSnapshot: []
      };
    }

    if (action.type === 'setDecisionTree') {
      const { decisionTree } = action;
      return {
        ...state,
        decisionTree,
      };
    } else if (action.type === 'customersStatusChangeSnapshotRefresh') {
      const { customersStatusChangeSnapshot } = action;
      return {
        ...state,
        customersStatusChangeSnapshot
      };
    }

    return state;
  }
}

export { reducers };
export default MachineLearning;
