
import React, { useEffect } from 'react';
import { Tabs } from 'antd';

import NotificaitonsTab from './notification-tab';

function Notifications() {
  useEffect(() => {
    document.title = '云策智慧 - 消息';
  }, []);

  return (
    <div>
      <Tabs>
        <Tabs.TabPane key="new-notification" tab="新消息">
          <NotificaitonsTab type='unarchived'/>
        </Tabs.TabPane>
        <Tabs.TabPane key="archived-notification" tab="已归档">
          <NotificaitonsTab type='archived'/>
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default Notifications;