
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserActivitiesDomain from 'domains/user-activities';
import UserActivityCard from 'components/user-activity-card';
import CreateUserActivity from './create-user-activity';

import './index.scss';

function UserActivities({
  customer
}) {
  const dispatch = useDispatch();

  const activites = useSelector(UserActivitiesDomain.selectors.perCustomer)[customer.id] || [];
  useEffect(() => {
    dispatch(UserActivitiesDomain.actions.readPerCustomer(customer.id));
  }, []);  

  return (
    <div className="user-activities">
      <CreateUserActivity customer={customer}/>
      <div className='divider'></div>
      <div>
        {
          activites.map(activity => {
            return (
              <UserActivityCard 
                key={activity.id}
                activity={activity}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default UserActivities;
