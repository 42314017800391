
import React from 'react';
import { Result } from 'antd';

class Profile extends React.Component {
  componentDidMount() {
    document.title = '云策智慧 - 个人信息'
  }

  render() {
    return (
      <Result
        status="warning"
        title="抱歉，个人信息管理尚在开发中！"
      />
    );
  }
}

export default Profile;
