
import React from 'react';
import {
  Form, InputNumber, Button
} from 'antd';

import BaseModal from 'modals/base-modal';

function ManualMetricModal({
  customerId,
  definition,
  onCancel,
  onSubmit
}) {
  function handleSubmit(v) {
    onSubmit({
      ...v,
      customerId,
      definitionId: definition.id
    });
  }

  const goalField = (
    <div>
      <Form.Item
        name="value"
      >
        <InputNumber style={{ width: '100%' }} placeholder="指标" />
      </Form.Item>
    </div>
  );

  return (
    <BaseModal
      title={`手动记录指标：${definition.name}`}
      onCancelOverride={onCancel}
    >
      <Form
        name="Definition"
        onFinish={handleSubmit}
        labelCol={{ span: 3 }}
      >
        {goalField}
        <div className="submit">
          <Form.Item noStyle>
            <Button
              type="primary"
              htmlType="submit"
            >
              确认
            </Button>
          </Form.Item>
          <Button onClick={onCancel}>取消</Button>
        </div>
      </Form>
    </BaseModal>
  );
}

export default ManualMetricModal;
