
import React from 'react';
import {
  Form, Input, Button
} from 'antd';
import {
  PlusOutlined,
  MinusOutlined
} from '@ant-design/icons';

import BaseModal from 'modals/base-modal';

import './survey-modal.scss';

function SurveyModal({
  survey,
  onCancel,
  onSubmit
}) {
  const actionTitle = survey ? '更新' : '创建';

  return (
    <BaseModal
      title={`${actionTitle}反馈问题`}
      onCancelOverride={onCancel}
    >
      <div className="modal-survey-create-update">
        <SurveyForm
          survey={survey}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </div>
    </BaseModal>
  );
}

function SurveyForm({
  survey,
  onCancel,
  onSubmit
}) {

  const initialData = survey || {
    question: '问题文本',
    description: '描述',
    choices: ['是',  '否', '不确定']
  };

  const questionField = (
    <div>
      <div className="label">问题</div>
      <Form.Item
        name="question"
        rules={[
          { required: true, message: `请输入问题` }
        ]}
      >
        <Input />
      </Form.Item>
    </div>
  );

  const choicesField = (
    <div>
      <div className="label choices">
        <span>选项</span>
        {survey && survey.status !== 'init' && <span>（激活过的问题无法更改）</span>}
      </div>
      <Form.List
        name="choices"
        disabled={survey && survey.status !== 'init'}
        rules={[
          {
            validator: async (_, choices) => {
              if (!choices || choices.length < 2 || choices.length > 5) {
                return Promise.reject(new Error('请输入 2 到 5 个选项'));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                required={false}
                key={field.key}
              >
                {fields.length > 1 ? (
                  <Button
                    className="deletion-circle"
                    shape="circle"
                    icon={<MinusOutlined />}
                    onClick={() => remove(field.name)}
                    disabled={
                      (survey && survey.status !== 'init') ||
                      fields.length < 3
                    }
                  />
                ) : null}
                <Form.Item
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    { required: true, whitespace: true, message: "选项不能为空" }
                  ]}
                  noStyle
                >
                  <Input
                    placeholder="选项"
                    style={{ width: '89%' }}
                    disabled={survey && survey.status !== 'init'}
                  />
                </Form.Item>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                shape="circle"
                onClick={() => add()}
                icon={<PlusOutlined />}
                disabled={
                  (survey && survey.status !== 'init') ||
                  fields.length > 4
                }
              />
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );

  return (
    <div className="survey-form">
      <Form
        name="Survey"
        initialValues={initialData}
        onFinish={onSubmit}
      >
        {questionField}
        {choicesField}
        <div className="submit">
          <Form.Item noStyle>
            <Button
              type="primary"
              htmlType="submit"
            >
              提交
            </Button>             
          </Form.Item>
          <Button onClick={onCancel}>取消</Button>
        </div>
      </Form>
    </div>
  );
}

export default SurveyModal;
