
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { Table, Button } from 'antd';
import classnames from 'classnames';

import TicketsDomain from 'domains/tickets';
import UsersDomain from 'domains/users';
import MeDomain from 'domains/me';
import ListItemActions from 'components/list-item-actions';
import Panel from 'components/panel';

import DeletionModal from './deletion-modal';
import TicketDrawer from './ticket-drawer';

import './index.scss';

function TicketsTable({
  customer,
  titleRow = true,
  showStatuses
}) {
  function getInitialCondition() {
    if (customer) {
      return { customerId: customer.id };
    } else {
      return {};
    }
  }

  function handleHideDrawer() {
    setShowDrawer(false);
  }

  function handleShowDrawer() {
    setShowDrawer(true);
  }

  function handleCreateTicket(v) {
    dispatch(TicketsDomain.actions.create({
      ticket: v,
      success: handleHideDrawer
    }));
  }

  function handleShowDeletionModal() {
    setShowDeletionModal(true);
  }

  function handleHideDeletionModal() {
    setShowDeletionModal(false);
    setInteractingId(null);
  }

  function handleDelete() {
    dispatch(TicketsDomain.actions.delete({
      id: interactingId,
      success: handleHideDeletionModal
    }));
  }

  function translateStatus(status) {
    if (status === 'NOT_STARTED') return '未处理';
    if (status === 'IN_PROGRESS') return '处理中';
    if (status === 'RESOLVED') return '已解决';
    return status;
  }

  function handleTableChange(pagination, filters, sorter) {
    const conditions = getInitialCondition();

    let { current } = pagination;
    if (current < 1) current = 1;

    const { assigneeName, statusTranslation, priority, severity } = filters;
    if (assigneeName && assigneeName[0] === 'ME') conditions.assigneeId = me.id;
    if (statusTranslation && statusTranslation[0] !== 'ALL') conditions.status = statusTranslation[0];
    if (priority && priority[0] !== 'ALL') conditions.priority = priority[0];
    if (severity && severity[0] !== 'ALL') conditions.severity = severity[0];

    if (Array.isArray(sorter)) {
      sorter.forEach((s) => {
        const { field, order } = s;
        const fieldOrder = field + 'Order';
        if (order === 'ascend') conditions[fieldOrder] = 'asc';
        else if (order === 'descend') conditions[fieldOrder] = 'desc';
      })
    } else {
      const { field, order } = sorter;
      const fieldOrder = field + 'Order';
      if (order === 'ascend') conditions[fieldOrder] = 'asc';
      else if (order === 'descend') conditions[fieldOrder] = 'desc';
    }

    dispatch(TicketsDomain.actions.readList(current, conditions));
  }

  const dispatch = useDispatch();

  let tickets = useSelector(TicketsDomain.selectors.data) || [];
  if (showStatuses) {
    tickets = tickets.filter((ticket) => {
      return showStatuses.indexOf(ticket.status) !== -1;
    });
  }

  const ticketsCount = useSelector(TicketsDomain.selectors.count);
  const users = useSelector(UsersDomain.selectors.data);
  const me = useSelector(MeDomain.selectors.data);

  const [interactingId, setInteractingId] = useState(null);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const conditions = getInitialCondition();
  useEffect(() => {
    dispatch(TicketsDomain.actions.readList(1, conditions));
  }, []);

  const columns = [
    {
      title: '#',
      dataIndex: 'ticketId',
      width: '5%'
    },
    {
      title: '时间',
      dataIndex: 'createdTime',
      width: '15%'
    },
    {
      title: '标题',
      dataIndex: 'title',
      ellipsis: true,
      width: '15%'
    },
    {
      title: '正文',
      dataIndex: 'message',
      ellipsis: true,
      width: '26%'
    },
    {
      title: '处理人',
      dataIndex: 'assigneeName',
      filters: [
        { text: "全部", value: "ALL" },
        { text: "自己", value: "ME" }
      ],
      filterMultiple: false,
      width: '9%'
    },
    {
      title: '状态',
      dataIndex: 'statusTranslation',
      filters: [
        { text: "全部", value: "ALL" },
        { text: "未处理", value: "NOT_STARTED" },
        { text: "处理中", value: "IN_PROGRESS" },
        { text: "已解决", value: "RESOLVED" },
      ],
      filterMultiple: false,
      width: '9%'
    },
    {
      title: '优先级',
      dataIndex: 'priority',
      filters: [
        { text: "全部", value: "ALL" },
        { text: "P1", value: "P1" },
        { text: "P2", value: "P2" },
        { text: "P3", value: "P3" },
        { text: "P4", value: "P4" }
      ],
      filterMultiple: false,
      sorter: {
        multiple: 1
      },
      width: '9%'
    },
    {
      title: '严重性',
      dataIndex: 'severity',
      filters: [
        { text: "全部", value: "ALL" },
        { text: "S1", value: "S1" },
        { text: "S2", value: "S2" },
        { text: "S3", value: "S3" },
        { text: "S4", value: "S4" }
      ],
      filterMultiple: false,
      sorter: {
        multiple: 2
      },
      width: '9%'
    },
    {
      width: '5%',
      render: (text, record) => {
        const className = classnames(
          'actions',
          { interacting: record.id === interactingId }
        );
        return (
          <div className={className}>
            <ListItemActions
              actions={[
                { name: '删除', onClick: handleShowDeletionModal }
              ]}
              onClick={() => setInteractingId(record.id)}
              onCancel={() => setInteractingId(null)}
            />
          </div>
        );
      },
    }
  ];

  return (
    <div className='component-advanced-tickets-table'>
      <Panel>
        {
          titleRow && customer &&
          <div className='tickets-topper'>
            <div className="actions">
              <Button
                type="primary"
                onClick={handleShowDrawer}
              >
                手动创建 SOP
              </Button>
            </div>
          </div>
        }
        <Table
          className='tickets-table'
          columns={columns}
          rowKey={ticket => ticket.id}
          dataSource={tickets.map((ticket) => {
            const assignee = users.filter((user) => user.id === ticket.assigneeId)[0];
            return {
              ...ticket,
              ticketId: <Link to={["/ticket", `${ticket.id}`].join('/')}>
                {ticket.id}
              </Link>,
              createdTime: moment(ticket.createdAt).format('YYYY-MM-DD HH:mm:ss'),
              assigneeName: assignee.name,
              statusTranslation: translateStatus(ticket.status),
            };
          })}
          pagination={{
            pageSize: 20,
            total: ticketsCount,
            position: ['bottomLeft'],
            showSizeChanger: false
          }}
          onChange={handleTableChange}
        />
        {
          showDeletionModal &&
          <DeletionModal
            ticket={tickets.find(ticket => ticket.id === interactingId)}
            onSubmit={handleDelete}
            onCancel={handleHideDeletionModal}
          />
        }
        {
          showDrawer &&
          <TicketDrawer
            customer={customer}
            onSubmit={handleCreateTicket}
            onCancel={handleHideDrawer}
          />
        }
      </Panel>
    </div >
  );
}

export default TicketsTable;
