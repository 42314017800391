
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Row, Col
} from 'antd';

import Metadata from 'domains/metadata';
import Panel from 'components/panel';
import InPlaceEdit from 'components/in-place-edit';

function PersonalPanel({
  customer,
  onUpdate
}) {
  function getSelector(fieldName) {
    return Metadata.selectors.getComponentParamsSelector({
      collection: 'customers',
      fieldName,
      record: customer,
      onUpdate
    });
  }

  const emailParams = useSelector(getSelector('email'));
  const mobileParams = useSelector(getSelector('mobile'));

  return (
    <div className="personal-panel">
      <Panel title="个人信息">
        <Row>
          <Col span={12}>
            <InPlaceEdit {...emailParams}/>
          </Col>
          <Col span={12}>
            <InPlaceEdit {...mobileParams}/>        
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <InPlaceEdit
              title="性别"
              value={customer.gender}
              type="select"
              selectOptions={[
                { value: null, title: '' },
                { value: '男', title: '男' },
                { value: '女', title: '女' }
              ]}
              onSubmit={(v) => onUpdate('gender', v)}
            />
          </Col>
          <Col span={12}>
            <InPlaceEdit
              title="生日"
              value={customer.birthdate}
              type="date"
              onSubmit={(v) => onUpdate('birthdate', v)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <InPlaceEdit
              title="收入级别"
              value={customer.incomeLevel}
              type="select"
              selectOptions={[
                { value: null, title: '' },
                { value: '高', title: '高' },
                { value: '中', title: '中' },
                { value: '低', title: '低' },
                { value: '未知', title: '未知' }
              ]}
              onSubmit={(v) => onUpdate('incomeLevel', v)}
            />
          </Col>
          <Col span={12}>
            <InPlaceEdit
              title="目的"
              value={customer.intent}
              type="STRING"
            />
          </Col>
        </Row>
      </Panel>
    </div>
  )
}

export default PersonalPanel;
