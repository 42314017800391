
import React from 'react';
import {
  Button
} from 'antd';

import BaseModal from 'modals/base-modal';

function DeleteModal({
  definition,
  onCancel,
  onSubmit
}) {
  return (
    <BaseModal
      title={`确定删除客户指标：${definition.name}?`}
      onCancelOverride={onCancel}
    >
      <div className="submit">
        <Button type="primary" danger onClick={onSubmit}>
          确定
        </Button>
        <Button onClick={onCancel}>
          取消
        </Button>
      </div>
    </BaseModal>
  );
}

export default DeleteModal;
