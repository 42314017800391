
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Form, Input, Button, Select
} from 'antd';

import BaseDrawer from 'modals/base-drawer';
import MetadataDomain from 'domains/metadata';

import IfElseFormulaField from './if-else-formula-field';

function CustomFormulaDrawer({
  customFormula,
  onSubmit,
  onCancel
}) {
  const actionTitle = customFormula ? '更新' : '创建';

  return (
    <BaseDrawer
      title={`${actionTitle}自定义字段`}
      onClose={onCancel}
    >
      <div className="drawer-custom-formula-create-update">
        <CustomFormulaForm
          customFormula={customFormula}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      </div>
    </BaseDrawer>
  );
}

function CustomFormulaForm({
  customFormula,
  onSubmit,
  onCancel
}) {
  function handleSubmit(v) {
    const logic = form.getFieldValue('logic');
    onSubmit({
      ...v,
      logic
    });
  }

  function handleTypeChange(v) {
    if (v === 'STRING') {
      form.setFieldsValue({
        logic: [
          {
            condition: {
              type: 'ELSE'
            },
            outcome: 0
          }
        ]
      });
    } else if (v === 'NUMBER') {
      form.setFieldsValue({
        logic: {
          preField: 'activeLicenseCount',
          operator: '/',
          sufField: 'licenseCount'
        }
      });
    }

    setType(v);
  }

  function handleSetLogic(v) {
    form.setFieldsValue({
      logic: v
    });
    setCounter(counter + 1);
  }

  function onPreFieldChange(v) {
    if (!v) {
      setPreFieldType(null);
      if (!sufFieldType) {
        setOperators(['-']);
      }
    } else {
      const option = fieldsOptions.filter((option) => option.value === v);
      setPreFieldType(option[0].type);
      if (option[0].type === 'NUMBER') setOperators(['+', '-', '*', '/']);
    }
  }

  function onSufFieldChange(v) {
    if (!v) {
      setSufFieldType(null);
      if (!preFieldType) {
        setOperators(['-']);
      }
    } else {
      const option = fieldsOptions.filter((option) => option.value === v);
      setSufFieldType(option[0].type);
      if (option[0].type === 'NUMBER') setOperators(['+', '-', '*', '/']);
    }
  }

  const validFormulaFieldType = ['NUMBER', 'DATE'];

  const { Option } = Select;

  const fieldsMetadata = useSelector(MetadataDomain.selectors.fieldsMetadata);
  const { customers, customFormulae } = fieldsMetadata;

  const customerFieldsOptions = Object.keys(customers)
    .filter((key) => {
      return (
        validFormulaFieldType.includes(customers[key].type) &&
        (
          !customers[key].applicableTo ||
          (customers[key].applicableTo && customers[key].applicableTo.includes('b2b'))
        )
      );
    })
    .map((key) => {
      return {
        value: key,
        title: customers[key].title,
        type: customers[key].type
      };
    });
  const customFormulaeOptions = Object.keys(customFormulae)
    .filter((key) => validFormulaFieldType.includes(customFormulae[key].type))
    .map((key) => {
      return {
        value: key,
        title: customFormulae[key].title,
        type: customFormulae[key].type
      };
    });
  const fieldsOptions = [
    ...customFormulaeOptions,
    ...customerFieldsOptions
  ];

  let initPreFieldType = null;
  let initSufFieldType = null;
  let initOperators = ['-'];
  if (customFormula) {
    initPreFieldType = fieldsOptions.filter(option => option.value === customFormula.preField)[0].type;
    initSufFieldType = fieldsOptions.filter(option => option.value === customFormula.sufField)[0].type;
    if (initPreFieldType === 'NUMBER') initOperators = ['+', '-', '*', '/'];
  }

  const [type, setType] = useState(customFormula ? customFormula.type : 'NUMBER');
  const [preFieldType, setPreFieldType] = useState(initPreFieldType);
  const [sufFieldType, setSufFieldType] = useState(initSufFieldType);
  const [operators, setOperators] = useState(initOperators);
  const [counter, setCounter] = useState(0); // hack: force update

  const [form] = Form.useForm();

  const logic = form.getFieldValue('logic');

  const nameField = (
    <Form.Item
      name="name"
      label="名称"
      rules={[
        { required: true, message: `请输入名称` }
      ]}
    >
      <Input />
    </Form.Item>
  );

  const typeField = (
    <Form.Item
      name="type"
      label="类型"
      rules={[
        { required: true, message: `请选择类型` }
      ]}
    >
      <Select
        disabled={customFormula}
        onChange={handleTypeChange}
      >
        <Select.Option value="NUMBER">数字</Select.Option>
        <Select.Option value="STRING">条件判断</Select.Option>
      </Select>
    </Form.Item>
  );

  const fieldNameField = (
    <Form.Item
      name="fieldName"
      label="API 名称"
    >
      <Input disabled />
    </Form.Item>
  );

  const emptyFieldNameField = (
    <Form.Item
      label="API 名称"
    >
      <Input
        value="系统会自动生成名称"
        disabled
      />
    </Form.Item>
  );

  const preFieldField = (
    <Form.Item
      name={['logic', 'preField']}
      noStyle
      rules={[
        { required: true, message: `请选择自定义公式字段` }
      ]}
    >
      <Select
        onChange={onPreFieldChange}
        style={{ width: '40%' }}
      >
        {fieldsOptions
          .filter((option) => {
            const fieldType = preFieldType || sufFieldType;
            return !fieldType || option.type === fieldType;
          })
          .map((field, i) => (
            <Option key={i} value={field.value}>
              {field.title}
            </Option>
          ))
        }
      </Select>
    </Form.Item>
  );

  const sufFieldField = (
    <Form.Item
      name={['logic', 'sufField']}
      noStyle
      rules={[
        { required: true, message: `请选择自定义公式字段` }
      ]}
    >
      <Select
        onChange={onSufFieldChange}
        style={{ width: '40%' }}
      >
        {fieldsOptions
          .filter((option) => {
            const fieldType = preFieldType || sufFieldType;
            return !fieldType || option.type === fieldType;
          })
          .map((field, i) => (
            <Option key={i} value={field.value}>
              {field.title}
            </Option>
          ))
        }
      </Select>
    </Form.Item>
  );

  const operatorField = (
    <Form.Item
      name={['logic', 'operator']}
      rules={[
        { required: true, message: `请选择自定义公式运算符` }
      ]}
      noStyle
    >
      <Select style={{ width: '20%' }}>
        {operators.map((operator, i) => (
          <Option key={i} value={operator}>
            {operator}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  const numberFormulaField = (
    <Form.Item
      label="公式"
    >
      <Input.Group compact>
        {preFieldField}
        {operatorField}
        {sufFieldField}
      </Input.Group>
    </Form.Item>
  );

  const ifElseFormulaField = (
    <Form.Item>
      <IfElseFormulaField
        logic={logic}
        setLogic={handleSetLogic}
      />
    </Form.Item>
  );

  return (
    <div className="drawer-custom-formula-form">
      <Form
        name="CustomFormula"
        initialValues={
          customFormula ||
          {
            type: 'NUMBER', title: '',
            logic: {
              preField: 'activeLicenseCount',
              operator: '/',
              sufField: 'licenseCount'
            }
          }
        }
        onFinish={handleSubmit}
        form={form}
        labelCol={{ span: 3 }}
      >
        {nameField}
        {typeField}
        {
          customFormula ?
            fieldNameField :
            emptyFieldNameField
        }
        {type === 'NUMBER' && numberFormulaField}
        {type === 'STRING' && ifElseFormulaField}
        <div className="submit">
          <Form.Item noStyle>
            <Button
              type="primary"
              htmlType="submit"
            >
              确认
            </Button>
          </Form.Item>
          <Button onClick={onCancel}>取消</Button>
        </div>
      </Form>
    </div>
  );
}

export default CustomFormulaDrawer;