
import React from 'react';
import {
  Form, Input, Button, Select
} from 'antd';

import BaseDrawer from 'modals/base-drawer';

/**
 * Drawer for create or update a customer user
 * 
 * If initialData is provided, the drawer is for update, 
 * otherwise it's for creation.
 * 
 * @param {*} param0 
 * @returns 
 */
function CustomerUserDrawer({
  initialData,
  onSubmit,
  onCancel
}) {
  return (
    <BaseDrawer
      title={initialData ? "修改客户员工" : "创建客户员工"}
      onClose={onCancel}
    >
      <div>
        <CustomerUserForm
          customerUser={initialData}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </div>
    </BaseDrawer>
  );
}

function CustomerUserForm({
  customerUser,
  onCancel,
  onSubmit
}) {
  const initialData = customerUser ? customerUser : 
    {
      status: '未激活',
    };

  const labelCol= {span: 3}
  const [form] = Form.useForm();

  const statusField = (
    <div>
      <Form.Item
        name="status"
        label="状态"
        labelCol={labelCol}
      >
        <Select>
          <Select.Option value="ACTIVE">激活</Select.Option>
          <Select.Option value="INACTIVE">未激活</Select.Option>
        </Select>
      </Form.Item>
    </div>
  );

  const nameField = (
    <div>
      <Form.Item
        name="name"
        label="名称"
        labelCol={labelCol}
        rules={[
          { required: true, message: '请输入客户员工名称' }
        ]}
      >
        <Input/>     
      </Form.Item>
    </div>
  );

  const titleField = (
    <div>
      <Form.Item
        name="title"
        label="职称"
        labelCol={labelCol}
      >
        <Input/>
      </Form.Item>
    </div>
  );

  const mobileField = (
    <div>
      <Form.Item
        name="mobile"
        label="手机号"
        labelCol={labelCol}
      >
        <Input placeholder="请输入客户手机号" />
      </Form.Item>
    </div>
  );

  const emailField = (
    <div>
      <Form.Item
        name="email"
        label="电子邮箱"
        rules={[
          { type: 'email', message: '请输入正确的电子邮箱'}
        ]}
        labelCol={labelCol}
      >
        <Input placeholder="请输入客户电子邮箱" />
      </Form.Item>
    </div>
  );

  return (
    <div>
      <Form
        name="note"
        initialValues={initialData}
        onFinish={onSubmit}
        form={form}
      >
        {statusField}
        {nameField}
        {titleField}
        {mobileField}
        {emailField}
        <div className="submit">
          <Form.Item noStyle>
            <Button
              type="primary"
              htmlType="submit"
            >
              确认
            </Button>
          </Form.Item>
          <Button onClick={onCancel}>取消</Button>
        </div>
      </Form>
    </div>
  );
}

export default CustomerUserDrawer;
