
import React, { useEffect, useRef } from 'react';
import { Chart } from '@antv/g2';

import { formatNumber } from 'utils/number';

function YTDChart({
  data
}) {
  const chartContainer = useRef(null);

  useEffect(() => {
    const chart = new Chart({
      container: chartContainer.current,
      autoFit: true
    });

    chart.data(data);

    chart
      .interval()
      .position('timestamp*value*type')
      .adjust('stack')
      .color('type', (type) => {
        return {
          '拉新': '#0BC286',
          '增购': '#96DCB0',
          '降级': '#FFC9B3',
          '流失': '#FFA299'
        }[type];
      })
      .tooltip('timestamp*value*type', (_, value, type) => {
        return {
          name: type,
          value: formatNumber(value)
        };
      });

    chart.scale({
      value: {
        nice: true,
        tickCount: 5
      },
      timestamp: {
        formatter: formatDate
      }
    });

    chart.axis('timestamp', {
      line: false,
      tickLine: false
    });

    chart.axis('value', {
      label: {
        formatter: formatNumber
      }
    });

    chart.render();
  }, []);

  return (
    <div style={{ height: '100%', width: '100%' }} ref={chartContainer}></div>
  );
}

function formatDate(dateString) {
  return `${dateString.split('-')[1]}月`;
}

export default YTDChart;
