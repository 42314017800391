
import API from 'adapters/api';

const Me = {
  selectors: {},
  actions: {}
};

Me.selectors.data = (state) => state.user;

Me.actions.submitFeedback = (message, success) => {
  return {
    type: 'mutation',
    async request(state, dispatch) {
      const {
        status, data
      } = await API.request({
        resource: `user-feedback`,
        action: 'create',
        data: { message }
      });

      if (status < 300) {
        success && success();
      }

      return { status, data };
    }
  };
};

export default Me;
